// The index root route Service
import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import { connect } from 'react-redux';

import ServicePage from './ServiceHome/ServicePage';
import ServiceDetailContainer from './ServiceDetailContainer/ServiceDetailContainer';
import NewServiceContainer from './ServicePostContainer/NewServiceContainer';
import ClientServiceRequest from '../../components/ClientServiceRequest/ClientServiceRequest';


const ServiceContainerIndex = (props) => {
  
  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={match.url}
          component={ServicePage}
        />
        <Route
          path={`${match.url}/detail`}
          component={ServiceDetailContainer}
        />
        <Route
          path={`${match.url}/post/new`}
          component={NewServiceContainer}
        />
        <Route
          path={`${match.url}/post/job`}
          component={ClientServiceRequest}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userCookie: state.getUserFromCookiesReducer,
  }
};

export default connect(
  mapStateToProps,
  null,
)(ServiceContainerIndex);