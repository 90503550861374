import React from 'react';
import { connect } from 'react-redux';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import {
  Row,
  Col,
} from 'antd';
import {
  routers
} from '../../config/routers';
import _ from 'lodash';
import {
  getTranslatedText,
} from '../../services/appServices';
import { toast } from 'react-toastify';

import ServiceCardComponent from '../ServiceComponent/ServiceCardComponent';

const ServiceFeature = (props) => {

  const {
    servicesArray,
    isHot,
    accountType,
  } = props;

  const history = useHistory();

  const renderHotService = (item, index) => {
    if (item.is_hot === 1) {
      return (
        <Col
          xs={24}
          md={24}
          lg={24}
          xl={12}
          style= {{
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <ServiceCardComponent
            serviceObject={item}
            history={history}
            handleRequestEstimateButton={handleRequestEstimateButton}
          />
        </Col>
      )
    } else {
      return null;
    };
  };

  const handleRequestEstimateButton = (item) => {
    if (accountType === 'partner-product' || accountType === 'partner-servive') {
      toast.warn('Vui lòng đăng kí tài khoản khách hàng để thực hiện thao tác này');
    } else {
      history.push(routers.services.service_request_partner_estimate.replace(':service_id', item.id))
    }
  };

  return (
    <React.Fragment>
      {isHot ? (
        <Row className='services-wrapper' gutter={16}>
          {servicesArray.map((item, index) => (
            renderHotService(item, index)
          ))}
        </Row>
      ) : (
        <React.Fragment>
          {servicesArray && servicesArray.length !== 0 ? (
        <Row className='services-wrapper' gutter={16}>
          {servicesArray.map((item, index) => (
            <Col
              xs={24}
              md={24}
              lg={24}
              xl={12}
              style= {{
                width: '100%',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              <ServiceCardComponent
                serviceObject={item}
                history={history}
                handleRequestEstimateButton={handleRequestEstimateButton}
              />
            </Col>
          ))}
        </Row>
      ) : (
          <React.Fragment>
            <div
              style={{
                width: '100%',
              }}
            >
              <p
                style={{
                  backgroundColor: '#CCD874',
                  width: '240px',
                  height: '120px',
                  borderRadius: '12px',
                  color: '#012509',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  textAlign: 'center',
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {getTranslatedText('no_filter_service')}
              </p>
            </div>
          </React.Fragment>
        )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accountType: state.getUserFromCookiesReducer.accountType,
  }
}

export default  connect(
  mapStateToProps,
  null,
)(ServiceFeature);