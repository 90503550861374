import {
  POST_ORDER_REQUEST,
  PUT_ORDER_REQUEST,
} from './action_types';

export const doCreateOrder = (order) => ({
  type: POST_ORDER_REQUEST,
  payload: order
});

export const doPutOrder = (payload) => ({
  type: PUT_ORDER_REQUEST,
  payload: payload
});