import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doPutUserProfileAction,
} from '../../../redux/actions/dashboard';
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Select,
} from 'antd';

import { getTranslatedText } from '../../../services/appServices';

import { resource } from '../../../assets/js/resource';

function ContactInformationComponent(props) {

  const {
    companyProfile,
    companyFieldDisable,
    setCompanyFieldDisable,
    actions,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (companyProfile) {
      form.setFieldsValue({
        company_name: companyProfile.company_name,
        company_address: companyProfile.company_address,
        company_city: companyProfile.company_city,
        company_email: companyProfile.company_email,
        company_website: companyProfile.company_website
      });
    };
  }, [companyProfile]);

  function handleOnContacFormFinish(values) {
    actions.doPutUserProfileAction(values);
    setCompanyFieldDisable(true);
  };

  return (
    <React.Fragment>
      <Form
        className='company-form'
        layout='vertical'
        form={form}
        onFinish={(values) => handleOnContacFormFinish(values)}
      >
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('company_name')}
              name='company_name'
            >
              <Input disabled={companyFieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('company_address')}
              name='company_address'
            >
              <Input disabled={companyFieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('company_city')}
              name='company_city'
            >
              <Select
                disabled={companyFieldDisable}
                showSearch
              >
              {resource.country.map((country) => (
                <Select.Option value={country.name}>
                  {country.name}
                </Select.Option>
              ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('company_email')}
              name='company_email'
            >
              <Input disabled={companyFieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('company_website')}
              name='company_website'
            >
              <Input disabled={companyFieldDisable} />
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item>
              <Button
                id='company-form-submit-btn'
                disabled={companyFieldDisable}
                htmlType='submit'
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doPutUserProfileAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactInformationComponent);