import React from 'react';
import {
  Link,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Table,
} from 'antd';

import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices'
import './styles/table-custom-styles.less';

function CustomerListOrderingService(props) {

  const {
    quoteData,
  } = props;

  const columns = [
    {
      title: getTranslatedText('content_column'),
      dataIndex: "service_name",
      key: "service_name"
    },

    {
      title: getTranslatedText('date_post'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('status_estimated_column'),
      key: "status",
      render: (record => {
        let cls = "btn_post_estimate";
        let text = getTranslatedText('is_being_considered');
        let router = '';
        if (record.status === 'accept') {
          text = getTranslatedText('accepted');
          cls = 'btn_post_estimate';
          router = routers.dashboard.view_estimate.replace(':quotation_id', record.id);
          return (
            <React.Fragment>
              <Link to={router}>
                <button className={cls}>
                  {text}
                </button>
              </Link>
            </React.Fragment>
          );
        } else {
          if (record.status === 'deny') {
            text = getTranslatedText('denied');
            cls = 'btn_post_estimate';
            return (
              <React.Fragment>
                <button
                  className={cls}>
                  {text}
                </button>
              </React.Fragment>
            );
          } else {
            if (record.status === 'close') {
              text = getTranslatedText('close_estimate');
              cls = 'btn_post_estimate';
              return (
                <React.Fragment>
                  <button className={cls}>
                    {text}
                  </button>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <button className={cls}>
                    {text}
                  </button>
                </React.Fragment>
              );
            };
          };
        }
      }),
    }
  ];

  return (
    <React.Fragment>
      <Row gutter={16} id='customer-ordering-service-wrapper'>
        <Col md={24} xs={24}>
          <h3>{getTranslatedText('estimate_request_title')}</h3>
        </Col>
        <Col md={24} xs={24}>
          <Table
            className='custom-table'
            columns={columns}
            dataSource={quoteData}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    quoteData: state.quoteReducer.data,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
      },
      dispatch
    )
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerListOrderingService);