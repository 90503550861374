import * as actionTypes from '../actions/action_types';

const searchResultState = {
  services: [],
  products: [],
};

export const searchReducer = (state = searchResultState, action ) => {
  switch (action.type) {
    case actionTypes.SEARCHING_SUCCESS:
      return {
        ...state,
        services: action.payload.service,
        products: action.payload.product,
      }
    default:
      return state;
  }
}