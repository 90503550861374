import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
	doCreateOrder,
} from '../../redux/actions/order';
import {
	doCreateCartAction
} from '../../redux/actions/products';
import {
	Row,
	Col,
	Table,
	Layout,
	Form,
	Radio,
	Steps,
} from 'antd';

import './styles/new-retail-payment-styles.less';
import {
	getTranslatedText,
	usdCurrencyFormat,
	vndCurrencyFormat
} from '../../services/appServices';

import Modal_Login from '../Modal_Login/Modal_Login';
import Payment_FormAddress from '../Payment_FormAddress/Payment_FormAddress';

const {
	Content,
} = Layout;


function NewRetailPayment(props) {

	const {
		cart,
		isHadAccount,
		userId,
		actions
	} = props;

	let current_language = localStorage.getItem('current_language') || 'vi';

	const [currentStep, setCurrentStep] = useState(1);
	const [paymentStep, setPaymentStep] = useState(0);
	const [contactShipPayload, setContactShipPayload] = useState(null);
	const [totalItem, setTotalItem] = useState(0);

	useEffect(() => {
		console.log('account', isHadAccount)
		if (cart.cart) {
			let total = 0;
			cart.cart.forEach(item => {
				total += 1;
			});
			setTotalItem(total);
		}
	}, []);

	const renderAllTotal = () => {
		if (current_language === 'vi') {
      var total = 0;
		  cart.cart.forEach(item => {
			  total += (item.quantity * item.price);
      });
      return (
        <React.Fragment>
          <div className="number">{vndCurrencyFormat.format(total)}</div>
        </React.Fragment>
      );
    } else {
      var total = 0;
		  cart.cart.forEach(item => {
			  total += (item.quantity * item.price);
		  })
      return (
        <React.Fragment>
          <div className="number">{usdCurrencyFormat.format(total)}</div>
        </React.Fragment>
      );
    }
	};

	function handleOnPaymentFinish(values) {
		setCurrentStep(2);
		const orderPayload = {
			order_name: contactShipPayload.name,
			order_address: contactShipPayload.address,
			order_city: contactShipPayload.city,
			order_phone: contactShipPayload.phone,
			order_email: contactShipPayload.email,
			order: cart.cart,
			total: totalItem,
			order_type: 'le',
			user_id: userId,
			payment_method: values.paymentMethod
		};
		actions.doCreateOrder(orderPayload);
		// toast.success('Your order is sent to suplier!');
	};

	const columns = [
		{
			title: getTranslatedText('product_name'),
			key: 'product_name',
			dataIndex: 'product_name'
		},
		{
			title: getTranslatedText('number_quantity'),
			key: 'quantity',
			dataIndex: 'quantity'
		},
		{
			title: getTranslatedText('total_money'),
			key: 'total',
			render: (record) => {
				if (record.product_price_unit === 1) {
					return (
						<p>{vndCurrencyFormat.format(record.price * record.quantity)}</p>
					);
				} else {
					return (
						<p>{usdCurrencyFormat.format(record.price * record.quantity)}</p>
					);
				}
			}
		},
	];

	return (
		<React.Fragment>
			<Content id='checkout-container-wrapper'>
				<Row gutter={16} id='checkout-wrapper'>
					<Col id='steps-wrapper' md={24} xs={24}>
						<Steps current={currentStep}>
							<Steps.Step title={getTranslatedText('order_title')} />
							<Steps.Step title={getTranslatedText('payment').toUpperCase()} />
							<Steps.Step title={getTranslatedText('send_order')} />
						</Steps>
					</Col>
					<Col md={12} xs={24}>
						{isHadAccount === false ? (
							<Modal_Login />
						) : (
								<React.Fragment>
									{paymentStep === 1 ? (
										<Form
											onFinish={(values) => handleOnPaymentFinish(values)}
											className="form_shipping payment black"
										>
											<h3 className="title">
												{getTranslatedText('payment_title')}
											</h3>
											<Form.Item
												name='paymentMethod'
											>
												<Radio.Group
													className='form-check'
												>
													<Radio
														value='direct'
													>
														{getTranslatedText('cash_pay')}
													</Radio>
													{/* <Radio
											value='banking'
										>
											{getTranslatedText('ngan_luong_pay')}
										</Radio> */}
												</Radio.Group>
											</Form.Item>
											<Form.Item>
												<button
													style={{
														width: '228px',
														height: '40px',
														backgroundColor: "red",
														fontWeight: 'bold',
														color: 'white',
														border: 'none',
														outline: '0'
													}}
												>
													{getTranslatedText('set_order')}
												</button>
											</Form.Item>
										</Form>
									) : (
										<React.Fragment>
											<h1
												id='address_form_title'
											>
												{getTranslatedText('address_for_deliver')}
											</h1>
											<Payment_FormAddress
												setPaymentStep={setPaymentStep}
												setContactShipPayload={setContactShipPayload}
											/>
										</React.Fragment>
										)}
								</React.Fragment>
							)}
					</Col>
					<Col md={12} xs={24}>
						<Row gutter={16} id='table-overview-cart-wrapper'>
							<Col md={24} xs={24}>
								<Table
									className='custom-table'
									dataSource={cart.cart}
									columns={columns}
									pagination={false}
								/>
							</Col>
							<Col md={24} xs={24}>
								<div className="total_cart">
									<div className="title">{getTranslatedText('total_money_pay')}</div>
									{renderAllTotal()}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		cart: state.cartReducer,
		isHadAccount: state.getUserFromCookiesReducer.isHadAccount,
		userId: state.getUserFromCookiesReducer.id,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				doCreateCartAction,
				doCreateOrder,
			},
			dispatch,
		),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewRetailPayment);