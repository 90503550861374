import {
  ORDER_STEP_PAYMENT,
  GET_PRODUCT_REQUEST,
  POST_PRODUCT_REQUEST,
  GET_PRODUCT_WITH_FILTER_REQUEST,
  GET_ALL_PRODUCT_REQUEST,
  POST_ORDER_REQUEST,
  GET_ORDER_REQUEST,
  GET_DETAIL_ORDER_REQUEST,
  CREATE_CART_REQUEST,
  DELETE_ITEM_IN_CART,
} from './action_types';

export const doOrderPayment = (orderInfo) => ({
  type: ORDER_STEP_PAYMENT,
  payload: orderInfo
});

export const doGetProductRequest = (productId) => ({
  type: GET_PRODUCT_REQUEST,
  payload: productId
});

export const doGetAllProduct = (payload) => ({
  type: GET_ALL_PRODUCT_REQUEST,
  payload: payload
});

export const doPostProductRequest = (productPostData) => ({
  type: POST_PRODUCT_REQUEST,
  payload: productPostData,
});

export const doGetProductWithFilter = (filterValue) => ({
  type: GET_PRODUCT_WITH_FILTER_REQUEST,
  payload: filterValue
});

export const doGetOrder = () => ({
  type: GET_ORDER_REQUEST,
});

export const doGetDetailOrder = (orderId) => ({
  type: GET_DETAIL_ORDER_REQUEST,
  payload: orderId
});

export const doCreateCartAction = (cartPayload) => ({
  type: CREATE_CART_REQUEST,
  payload: cartPayload,
});

export const doDeleteCartItemAction = (item) => ({
  type: DELETE_ITEM_IN_CART,
  payload: item
});