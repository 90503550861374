import * as actionTypes from '../actions/action_types';
import { toast } from 'react-toastify';
import { routers } from '../../config/routers';
import {getTranslatedText} from "../../services/appServices";

const urlResponseState = {
  data: [],
  quotation_title: '',
  updateEstimateData: null
};

export const getEstimatesReducer = (state = urlResponseState, action) => {
  switch (action.type) {
    case actionTypes.GET_ESTIMATES_SUCCESS:
      const quotation_title = action.payload[0].service_title
      return {
        ...state,
        data: action.payload,
        quotation_title: quotation_title,
      };
    case actionTypes.GET_ESTIMATES_FAILURE:
      return state;
    case actionTypes.GET_DETAIL_ESTIMATES_SUCCESS:
      const detailEstimate = action.payload;
      state.data.forEach(item => {
        if(item.id === detailEstimate.id) {
          let index = state.data.indexOf(item);
          state.data[index] = detailEstimate;
        }
      });
      return {
        ...state,
      }
    case actionTypes.GET_DETAIL_ESTIMATES_FAILURE:
      toast.error(action.error);
      return state;
    case actionTypes.UPDATE_ESTIMATE_SUCCESS:
      toast.success(getTranslatedText("update_estimate_success"));
      setTimeout(() => {
        window.location.href = routers.dashboard.main;
      }, 2000);
      return {
        ...state,
        updateEstimateData: action.payload,
      }
    case actionTypes.UPDATE_ESTIMATE_FAILURE:
      toast.error(action.error);
      return state;
    default:
      return state;
  }
};