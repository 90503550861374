import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	doGetDetailQuote,
} from '../../../redux/actions/services';

import {
  useRouteMatch,
} from 'react-router-dom';

import PartnerSuggestList from '../../../components/PartnerSuggestList/PartnerSuggestList';
import { routers } from '../../../config/routers';

const SuggestPartnerContainer = (props) => {

  const {
    actions,
    detailQuote,
  } = props;

  const match = useRouteMatch(routers.dashboard.suggest_partner);

  useEffect(() => {
    const quoteId = parseInt(match.params.job_id);
    actions.doGetDetailQuote(quoteId);
  }, [])

  return (
    <React.Fragment>
      <PartnerSuggestList
        detailQuote={detailQuote}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
	return {
		detailQuote: state.quoteReducer.detailQuote,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
			doGetDetailQuote,
		},
		dispatch
		)
	};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuggestPartnerContainer);