import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';

import RegisterOption from '../../../components/Account_RegisterOption/AccountRegisterOption';
import RegisterPartnerComponent from '../../../components/Account_PartnerRegister/AccountPartnerRegister';
import RegisterCustomer from '../../../components/Account_CustomerRegister/AccountCustomerRegister';
import AccountPartnerRegisterOption from '../../../components/Account_PartnerRegisterOption/AccountPartnerRegisterOption';

const SignUpContainer = () => {
  
  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={match.url}
          component={RegisterOption}
        />
        <Route
          path={`${match.url}/customer`}
          component={RegisterCustomer}
        />
        <Route
          exact
          path={`${match.url}/partner`}
          component={AccountPartnerRegisterOption}
        />
        <Route
          path={`${match.url}/partner/new`}
          component={RegisterPartnerComponent}
        />
      </Switch>
    </React.Fragment>
  );
};

export default SignUpContainer;