import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd';

import {
  EnvironmentOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

import { getTranslatedText, truncateText } from '../../services/appServices';
import { routers } from '../../config/routers';

import './styles/service-component-custom-styles.less';

function ServiceCardComponent(props) {

  const {
    serviceObject,
    history,
    handleRequestEstimateButton, 
  } = props;

  function goToDetailService() {
    history.push(routers.services.service_partner_detail.replace(':service_id', serviceObject.id));
  };

  return (
    <React.Fragment>
      <Row
        className='service-card-wrapper'
        gutter={16}
      >
        <Col
          className='title-wrapper'
          md={24}
          xs={24}
        >
          <h3>{truncateText(serviceObject.title, 40)}</h3>
        </Col>
        <Col
          md={16}
          xs={24}
        >
          <div
            className='text-des-wrapper'
          >
            <p
              style={{
                color: "black"
              }}
            >{truncateText(serviceObject.summary, 200)}</p>
          </div>
        </Col>
        <Col
          md={8}
          xs={24}
        >
          <div className='btn-control-wrapper'>
            <Button
              className='btn-top'
              onClick={() => goToDetailService(serviceObject)}
            >
              {getTranslatedText('view_service_detail')}
            </Button>
            <Button
              className='btn-bottom'
              onClick={() => handleRequestEstimateButton(serviceObject)}
            >
              {getTranslatedText('request_estimate')}
            </Button>
          </div>
        </Col>
        <Col
          md={24}
          xs={24}
        >
          <Row
            className='bonus-info-wrapper'
            gutter={16}
          >
            <Col
              md={12}
              xs={12}
              lg={12}
            >
              <div className='info-item'>
                <EnvironmentOutlined />
                <p>{serviceObject.company_city}</p>
              </div>
            </Col>
            {/* <Col
              md={8}
              xs={8}
            >
              <div className='info-item'>
                <FileTextOutlined />
                <p>{(serviceObject.customer) ? serviceObject.customer : 0} {getTranslatedText('customers')}</p>
              </div>
            </Col> */}
            <Col
              md={12}
              xs={12}
              lg={12}
            >
              <div className='info-item'>
                <CalendarOutlined />
                <p>{serviceObject.created_at}</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          md={24}
          xs={24}
        >
          <div className='category-wrapper'>
            <h5>
              {getTranslatedText('service_category')}:
            </h5>
            <div className='category-item-wrapper'>
              <p>{serviceObject.category_name}</p>
              <p>{serviceObject.child_category_name}</p>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ServiceCardComponent;