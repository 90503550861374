// This component use to call api notification and
// render notification by list

import React from 'react';
import { connect } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import { getTranslatedText } from '../../../services/appServices';

import { routers } from '../../../config/routers';

const NotificationComponent = (props) => {

  let history = useHistory();

  const {
    data,
    doPutNotification,
  } = props;

  const handleNotiItem = (item) => {
    console.log(item)
    const payload = {
      notification_id: item.id,
      status: 1
    };
    doPutNotification(payload);
    switch (item.type) {
      case 'new_order':
        history.push(routers.dashboard.main);
        break;
      case "new_estimate":
        history.push(routers.dashboard.main);
        break;
      case 'new_request':
        history.push(routers.dashboard.main);
        break;
      case 'suggest_partner':
        history.push(routers.dashboard.suggest_partner.replace(':job_id', item.request_id));
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="col-12 container_title">
          <p>{getTranslatedText('notification_title')}</p>
        </div>
        <div className='noti_item'>
          {data && data.map(item => (
            <p onClick={() => handleNotiItem(item)}>{item.title}</p>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};


export default connect(
  null,
  null,
)
(NotificationComponent);