import React from 'react';
import {
  Menu,
} from 'antd';
import 'antd/dist/antd.css';
import {
  Link
} from 'react-router-dom';
import { routers } from '../../../config/routers';
import PropTypes from 'prop-types';
import { getTranslatedText } from '../../../services/appServices';



const AccountDropDownMenu = (props) => {

  const {
    handleLogOut,
    accountType,
  } = props;

  // const menuItemRender = () => {
  //   console.log(accountType)
  //   switch(accountType) {
  //     case 'customer':
  //       return (
  //         <Menu.Item>
  //           <Link
  //             to={routers.dashboard.main}
  //           >
  //             <a>
  //               {getTranslatedText('my_request_estimate')}
  //             </a>
  //           </Link>
  //         </Menu.Item>
  //       );
  //     case 'freelancer' || 'partner-service':
  //       return (
  //         <Menu.Item>
  //           <Link
  //             to={routers.dashboard.main}
  //           >
  //             <a>
  //               {getTranslatedText('estimate_waiting')}
  //             </a>
  //           </Link>
  //         </Menu.Item>
  //       );
  //     case 'partner-product':
  //       return (
  //         <Menu.Item>
  //           <Link
  //             to={routers.dashboard.main}
  //           >
  //             <a>
  //               {getTranslatedText('product_waiting_price')}
  //             </a>
  //           </Link>
  //         </Menu.Item>
  //       );
  //     default:
  //       return (
  //         <React.Fragment>
  //         </React.Fragment>
  //       )
  //   }
  // }

  return (
    <React.Fragment>
      <Menu>
        <Menu.Item>
          <p>{accountType}</p>
        </Menu.Item>
        <Menu.Item>
          <Link
            to={routers.dashboard.main}
          >
            <p>{getTranslatedText('account_info')}</p>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to={routers.dashboard.wallet}
          >
            <p>{getTranslatedText('wallet_info')}</p>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <a
            onClick={() => handleLogOut()}
          >
            {getTranslatedText('logout')}
          </a>
        </Menu.Item>
		  </Menu>
    </React.Fragment>
  );
};

AccountDropDownMenu.propTypes = {
  handleLogOut: PropTypes.func,
  accountType: PropTypes.string,
}

export default AccountDropDownMenu;