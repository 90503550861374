import * as actionTypes from '../actions/action_types';
import { put } from 'redux-saga/effects';


export default {
  createOrder: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/order', payload);
      // console.log('response', response);
      if (response.data.isSuccess) {
        let data = response.data.data;
        yield put({
          type: actionTypes.POST_ORDER_SUCCESS,
          payload: data,
        })
      } else {
        yield put({
          type: actionTypes.POST_ORDER_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.POST_ORDER_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  },
  putOrder: function*(action) {
    let orderId = action.payload.orderId;
    let bodyRequest = action.payload.bodyRequest;
    try {
      let response = yield global.apiService.apiCall('put', `v1/order/${orderId}`, bodyRequest);
      // console.log('response', response);
      if (response.data.isSuccess) {
        let data = response.data.data;
        yield put({
          type: actionTypes.PUT_ORDER_SUCCESS,
          payload: data,
        })
      } else {
        yield put({
          type: actionTypes.PUT_ORDER_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.PUT_ORDER_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  }
};