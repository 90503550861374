import { put } from 'redux-saga/effects';
import * as actionTypes from '../actions/action_types';

export default {
  getCategoryService: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/guest/category', action.payload);
      // console.log('category', response);
      if (response.data.isSuccess === true) {
        let categoryArray = response.data.data;
        yield put({
          type: actionTypes.GET_CATEGORY_SERVICE_SUCCESS,
          payload: categoryArray
        })
      } else {
        yield put({
          type: actionTypes.GET_CATEGORY_SERVICE_FAILURE,
          error: ['Something went wrong!']
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_CATEGORY_SERVICE_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getCategory: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/guest/category', action.payload);
      // console.log('category', response);
      if (response.data.isSuccess === true) {
        let categoryArray = response.data.data;
        yield put({
          type: actionTypes.GET_CATEGORY_SUCCESS,
          payload: categoryArray
        })
      } else {
        yield put({
          type: actionTypes.GET_CATEGORY_FAILURE,
          error: ['Something went wrong!']
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_CATEGORY_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getChildrenCategoryService: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', `v1/category/${action.payload}`);
    // console.log('category', response);
    if (response.data.isSuccess === true) {
      let childrenCategoryArray = response.data.data.children;
      yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_SERVICE_SUCCESS,
        payload: childrenCategoryArray
      })
    } else {
      yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_SERVICE_FAILURE,
        error: ['Something went wrong!']
      })
    }
  } catch (error) {
    yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_SERVICE_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getChildrenCategoryServiceMultiChoice: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', `v1/category/${action.payload}`);
    // console.log('category', response);
    if (response.data.isSuccess === true) {
      let childrenCategoryArray = response.data.data.children;
      yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_SUCCESS,
        payload: childrenCategoryArray
      })
    } else {
      yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_FAILURE,
        error: ['Something went wrong!']
      })
    }
  } catch (error) {
    yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getCategoryProduct: function* (action) {
    try {
        let response = yield global.apiService.apiCall('get', `v1/guest/category`, action.payload);
      // console.log('category', response);
      if (response.data.isSuccess === true) {
        let categoryArray = response.data.data;
        yield put({
          type: actionTypes.GET_CATEGORY_PRODUCT_SUCCESS,
          payload: categoryArray
        })
      } else {
        yield put({
          type: actionTypes.GET_CATEGORY_PRODUCT_FAILURE,
          error: ['Something went wrong!']
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_CATEGORY_PRODUCT_FAILURE,
        error: ['Something went wrong!']
      });
    };
  },
  getChildrenCategoryProduct: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', `v1/category/${action.payload}`);
    // console.log('category', response);
    if (response.data.isSuccess === true) {
      let childrenCategoryArray = response.data.data.children;
      yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_PRODUCT_SUCCESS,
        payload: childrenCategoryArray
      })
    } else {
      yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_PRODUCT_FAILURE,
        error: ['Something went wrong!']
      })
    }
  } catch (error) {
    yield put({
        type: actionTypes.GET_CHILDREN_CATEGORY_PRODUCT_FAILURE,
        error: ['Something went wrong!']
      });
    };
  },
  getLocation: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/location', action.payload);
      // console.log('category', response);
      if (response.data.isSuccess === true) {
        let categoryArray = response.data.data;
        yield put({
          type: actionTypes.GET_LOCATION_SUCCESS,
          payload: categoryArray
        })
      } else {
        yield put({
          type: actionTypes.GET_LOCATION_FAILURE,
          error: ['Something went wrong!']
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_LOCATION_FAILURE,
        error: ['Something went wrong!']
      });
    };
  }
}