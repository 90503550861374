import React, {useEffect} from 'react';
import {
	Link,
	useHistory,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Form,
	Button,
	Select,
	Upload,
	Checkbox,
	Input,
} from 'antd';
import 'antd/dist/antd.css';
import './style.scss';
import env from "../../env";
import axios from "axios";
import no_image from "../../assets/icon/input_file_img.png";
import { PlusOutlined } from '@ant-design/icons';
import {
	doGetCategory,
	doGetLocation,
	doGetChildrenCategoryServiceMultiChoice,
} from '../../redux/actions/category';
import {
	doRequestUserCode,
	doRegister,
} from '../../redux/actions/authentication'
import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';
import {resource} from "../../assets/js/resource";

const RegisterPartnerComponent = (props) => {

	const {
		actions,
		partnerAccountType,
		parentServiceCategory,
		childrenServiceCategoryMultiChoice,
		userCode,
		location,
		saleType,
	} = props;

	let history = useHistory();

	let state = {
    avatar: no_image,
    image: [],
		certificate: []
  };

	// COMPONENT DID UPDATED
	useEffect(() => {
		actions.doGetCategory();
		// actions.doGetLocation();
		actions.doRequestUserCode(partnerAccountType);
	}, []);

	const UploadButton = () => {
		return (
			<div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
		)
	};

	const handleMultiSelectOnChange = (value) => {
		actions.doGetChildrenCategoryServiceMultiChoice(value[value.length - 1])
	}

	// RENDER SELECT CATEGORY SERVICE
	const parentCategoryOption = () => {
		const parentCategoryOptionArray = [];
		parentServiceCategory.forEach(service => {
			parentCategoryOptionArray.push(
				<Select.Option
					value={service.id}
				>
					{service.name}
				</Select.Option>
			)
		});
		return parentCategoryOptionArray;
	}

	const childrenCategoryOption = () => {
		const childrenCategoryOptionArray = [];
		if (childrenServiceCategoryMultiChoice !== []) {
			childrenServiceCategoryMultiChoice.forEach(service => {
				childrenCategoryOptionArray.push(
					<Select.Option
						value={service.id}
					>
						{service.name}
					</Select.Option>
				)
			});
			return childrenCategoryOptionArray;
		}
	}
	//////////////////END/////////////////////////////

	const onFinishForm = (values) => {
		let categoriesArray = [];
		if(values.business_category) {
			categoriesArray = values.business_category.concat(values.service_category);
		} else {
			categoriesArray = values.service_category;
		}
		let userRegisterInfomation = {
			short_name: userCode,
			name: values.name,
			email: values.email,
			phone: values.phone,
			password: values.password,
			cf_password: values.cf_password,
			account_type: partnerAccountType,
			categories: categoriesArray || [],
			address: values.address || '',
			city: values.city || '',
			company_name: values.company_name || '',
			company_address: values.company_address || '',
			company_email: values.company_email || '',
			company_city: values.company_city || '',
			company_website: values.company_website || '',
		};
		//send files
		let files = document.getElementById("images").files;
		for (let i = 0; i < files.length; i++){
			userRegisterInfomation["image_" + i] = files[i];
		}
		actions.doRegister(userRegisterInfomation);
	};

	const uploadFile = e => {
    let files = e.target.files;
    let id = e.target.id;
    for (let i = 0; i < 5; i++){
    	if (files[i])
    		document.getElementById(id + "_" + i).src = URL.createObjectURL(files[i]);
    	else
				document.getElementById(id + "_" + i).src = require("../../assets/icon/input_file_img.png");
		}
	};

	const renderPartnerAccountType = () => {
		switch(partnerAccountType) {
			case 'partner-service':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="1" name="example1" checked />
							<label className="custom-control-label" for="1">{getTranslatedText('company_title')}</label>
						</div>
					</React.Fragment>
				);
			case 'partner-product':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="1" name="example1" checked />
							<label className="custom-control-label" for="1">{getTranslatedText('company_title')}</label>
						</div>
					</React.Fragment>
				)
			case 'freelancer':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="1" name="example1" checked />
							<label className="custom-control-label" for="1">Freelancer</label>
						</div>
					</React.Fragment>
				);
			default:
				return (
					<React.Fragment></React.Fragment>
				);
		};
	};

	const renderPartnerProvider = () => {
		switch(partnerAccountType) {
			case 'partner-service':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="2" name="example1" checked />
							<label className="custom-control-label" for="2">{getTranslatedText('service_provide')}</label>
						</div>
					</React.Fragment>
				);
			case 'freelancer':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="2" name="example1" checked />
							<label className="custom-control-label" for="2">{getTranslatedText('service_provide')}</label>
						</div>
					</React.Fragment>
				);
			case 'partner-product':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="2" name="example1" checked />
							<label className="custom-control-label" for="2">{getTranslatedText('sale_product')}</label>
						</div>
					</React.Fragment>
				)
		}
	}

	const renderSaleType = () => {
		switch (saleType) {
			case 'sale':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="2" name="example1" checked />
							<label className="custom-control-label" for="2">{getTranslatedText('each_sale')}</label>
						</div>
					</React.Fragment>
				);
			case 'wholeSale':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="2" name="example1" checked />
							<label className="custom-control-label" for="2">{getTranslatedText('whole_sale')}</label>
						</div>
					</React.Fragment>
				);
			case 'saleAndWholeSale':
				return (
					<React.Fragment>
						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="2" name="example1" checked />
							<label className="custom-control-label" for="2">{getTranslatedText('both_sale')}</label>
						</div>
					</React.Fragment>
				)
			default:
				return (
					<React.Fragment>
					</React.Fragment>
				)
		}
	}

	return (
		<React.Fragment>
			<div className="register_partner container mg-b-60">
				<div className="row">
					<div className="name_page">
						<h3>{getTranslatedText('account_type_for')}</h3>
					</div>
				</div>
				<div className="form_register_partner">
					<Form
						onFinish={(values) => onFinishForm(values)}
					>
						<div className="sec_form">
							<h3 className="title_form">{getTranslatedText('new_register')}</h3>
							<div className="row">
								<div className="col-md-4">
									<p className="color_012509">{getTranslatedText('account_type_text')}</p>
									{renderPartnerAccountType()}
								</div>
								<div className="col-md-4">
									<p className="color_012509">{getTranslatedText('what_provider')}</p>
									{renderPartnerProvider()}
								</div>
								{saleType !== '' ? (
									<div className="col-md-4">
									<p className="color_012509">{getTranslatedText('sale_method')}</p>
									{renderSaleType()}
								</div>
								) : (
									<React.Fragment></React.Fragment>
								)}
							</div>
							<hr className="dashed_707070" />
							<h3 className="title_form">{getTranslatedText('info_contact')}</h3>
							<div className="row">
								<div className="col-md-6 pd-r-30">
									<div className="item-info-account">
										<div className="row item_id_account">
											<div className="left">
											<p>{getTranslatedText('your_id_account')}</p>
											</div>
											<div className="right">
												<h3>{userCode}</h3>
											</div>
										</div>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='email'
											rules={[{ required: true, message: 'Please input your email!' }]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_email')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='password'
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<Input.Password className="library_ui_input" placeholder={getTranslatedText('label_password')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='cf_password'
											rules={[{ required: true, message: 'Please input your password comfirm!' }]}
										>
											<Input.Password className="library_ui_input" placeholder={getTranslatedText('label_comfirm_password')} />
										</Form.Item>
									</div>
								</div>
								<div className="col-md-6 pd-l-30">
									<div className="item-info-account">
										<Form.Item
											name='name'
											rules={[{ required: true, message: 'Please input your name!' }]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_name')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='phone'
											rules={[{
												required: true,
												message: 'Please check agreement'
											}]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_phone')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='address'
											rules={[{
												required: true,
												message: 'Please check agreement'
											}]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_address')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='city'
											rules={[{
												required: true,
												message: 'Please check agreement'
											}]}
										>
											<Select placeholder={getTranslatedText('company_city')} className="form-control">
												{resource.country.map(item => (
													<Select.Option value={item.name}>{item.name}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
								</div>
							</div>
							<hr className="dashed_707070" />
								<h3 className="title_form">{getTranslatedText('company_info_title')}</h3>
								<div className="item-info-account">
								<Form.Item
									name='company_name'
									rules={[{
										required: true,
										message: 'Please check agreement'
									}]}
								>
									<input className="form-control" placeholder={getTranslatedText('company_name')} />
								</Form.Item>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="item-info-account mg-r-15">
										<Form.Item
											name='company_address'
											rules={[{
												required: true,
												message: 'Please check agreement'
											}]}
										>
											<input className="form-control" placeholder={getTranslatedText('company_address')} />
										</Form.Item>
										</div>
										<div className="item-info-account mg-r-15">
										<Form.Item
											name='company_email'
											rules={[{
												required: true,
												message: 'Please check agreement'
											}]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_email')} />
										</Form.Item>
										</div>
									</div>
									<div className="col-md-6">
										<div className="item-info-account mg-l-15">
										<Form.Item
											name='company_city'
											rules={[{
												required: true,
												message: 'Please check agreement',
											}]}
										>
											<Select placeholder={getTranslatedText('company_city')} className="form-control">
												{resource.country.map(item => (
													<Select.Option value={item.name}>{item.name}</Select.Option>
												))}
											</Select>
										</Form.Item>
										</div>
										<div className="item-info-account mg-l-15">
											<Form.Item
											name='company_website'
											rules={[{
												required: true,
												message: 'Please check agreement'
											}]}
										>
											<input className="form-control" placeholder={getTranslatedText('company_website')} />
										</Form.Item>
										</div>
									</div>
								</div>
								{partnerAccountType === 'partner-service' || partnerAccountType === 'partner-product' ? (
									<React.Fragment>
										<p className="font-weight-bold mg-t-30 mg-b-15">{getTranslatedText('bussines_category_title').toUpperCase()}</p>
									</React.Fragment>
								) : (
									<React.Fragment>
										<p className="font-weight-bold mg-t-30 mg-b-15">{getTranslatedText('freelancer_category_title')}</p>
									</React.Fragment>
								)}
							<div className="row">
								<div className="col-md-1"></div>
								<div className="select_job col-md-10 col-md-offset-1">
									<div className="row">
										<div className="col-md-6 pd-r-30-md text-right">
											<div className="form-group select_300">
												<Form.Item
													name='service_category'
													rules={[{
														required: true,
														message: 'Please check agreement'
													}]}
												>
												<Select
													id="example-post"
													name="multiselect[]"
													mode="multiple"
													onChange={(value) => handleMultiSelectOnChange(value)}
												>
													{parentCategoryOption()}
												</Select>
												</Form.Item>
											</div>
										</div>
										<div className="col-md-6 pd-l-30-md">
												<div className="form-group select_300">
													<Form.Item
														name='business_category'
													>
													<Select
														id="job_detail"
														name="countries[]"
														mode='multiple'
														data-default={getTranslatedText('select_job_title')}
													>
														{childrenCategoryOption()}
													</Select>
													</Form.Item>
												</div>
										</div>
									</div>
								</div>
							</div>
							{/* <p className="clear-both mg-b-15">{getTranslatedText('upload_cetificate_title')}</p> */}
							<Form.Item
								label={getTranslatedText('upload_cetificate_title')}
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'start'
								}}
							>										
								<div className="input_file">
									<label htmlFor="images"><input type="file" id="images" multiple={true} onChange={e => uploadFile(e)}/> {getTranslatedText('upload')}</label>
								</div>
								<div className="gril_3_col">
									<img src={require("../../assets/icon/input_file_img.png")} id="images_0" alt=""/>
									<img src={require("../../assets/icon/input_file_img.png")} id="images_1" alt=""/>
									<img src={require("../../assets/icon/input_file_img.png")} id="images_2" alt=""/>
									<img src={require("../../assets/icon/input_file_img.png")} id="images_3" alt=""/>
									<img src={require("../../assets/icon/input_file_img.png")} id="images_4" alt=""/>
								</div>
							</Form.Item>
							<div className="policy_access mg-b-30">
								<div className="custom-control custom-checkbox mb-3 pd-t-30">
								<Form.Item
										name='checker'
										valuePropName='checked'
										rules={[{
											required: true,
											message: 'Please check agreement'
										}]}
									>
										<Checkbox>
											{getTranslatedText('aggrement_title')}
										</Checkbox>
									</Form.Item>
								</div>
							</div>
							<div className="group_btn_submit text-center custom-text-center row">
								<div className="col-md-6 col-sm-6 full_width_mobile">
									<a  onClick={() => history.goBack()} className="back">{getTranslatedText('go_back')}</a>
								</div>
								<div className="btn_yellow_container col-md-6 col-sm-6 full_width_mobile">
									<Form.Item>
										<Button
											className="register"
											htmlType='submit'
										>
											{getTranslatedText('header_btn_sign_up')}
										</Button>
									</Form.Item>
									{/* <a className="register">Đăng ký</a> */}
								</div>
							</div>
							<div className="text-center col-12">
								<div className="text-center have_account mg-t-10">{getTranslatedText('account_already_have')}<Link to={routers.account.login}> <p>{getTranslatedText('login_now')}</p> </Link></div>
							</div>
							<div className="text-center col-12 pd-b-20 dksd text-center">
								<a href="#">{getTranslatedText('rule_title')}</a>
								<a href="#">{getTranslatedText('private_policy')}</a>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		partnerAccountType: state.registerOptionReducer.registerOption,
		parentServiceCategory: state.serviceCategoryReducer.parentServiceCategory,
    childrenServiceCategoryMultiChoice: state.serviceCategoryReducer.childrenServiceCategoryMultiChoice || [],
		userCode: state.userCodeReducer.userCode,
		location: state.serviceCategoryReducer.location,
		saleType: state.registerOptionReducer.saleType,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				doGetCategory,
				doRequestUserCode,
				doRegister,
				doGetLocation,
				doGetChildrenCategoryServiceMultiChoice
			},
			dispatch,
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RegisterPartnerComponent);