import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doGetCategoryService,
  doGetLocation,
  doGetChildrenCategoryService,
} from '../../redux/actions/category';
import {
  doPostNewService,
} from '../../redux/actions/services';
import {
  Layout,
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  Checkbox,
} from 'antd';
import 'antd/dist/antd.css';
import './FormService.scss';
import Cookies from 'universal-cookie';
import { getTranslatedText } from '../../services/appServices';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {resource} from "../../assets/js/resource";


const FormService = (props) => {

  const [userId, setUserId] = useState(null);

  const {
    actions,
    serviceCategory,
  } = props;


  useEffect(() => {
    let cookie = new Cookies();
    let user = cookie.get('_user');
    if(user)
      setUserId(user.id);
    actions.doGetCategoryService();
    // actions.doGetLocation();
  }, []);

  // RENDER SELECT CATEGORY SERVICE
	const parentCategoryOption = () => {
		const parentCategoryOptionArray = [];
		serviceCategory.parentServiceCategory.forEach(service => {
			parentCategoryOptionArray.push(
				<Select.Option
					value={service.id}
				>
					{service.name}
				</Select.Option>
			)
		});
		return parentCategoryOptionArray;
	}

	const childrenCategoryOption = () => {
		const childrenCategoryOptionArray = [];
		serviceCategory.childrenServiceCategory.forEach(service => {
			childrenCategoryOptionArray.push(
				<Select.Option
					value={service.id}
				>
					{service.name}
				</Select.Option>
			)
		});
		return childrenCategoryOptionArray;
	}
  //////////////////END/////////////////////////////

  const handleOnParentCategoryChange = (value) => {
    console.log(value);
    actions.doGetChildrenCategoryService(value);
  }
  
  const handleOnPostServiceFinish = (values) => {
    // console.log('new service', values);
    let newService = {
      title: values.title,
      summary: values.summary,
      detail: values.detail,
      company_name: values.company_name || '',
      company_email: values.company_email || '',
      company_address: values.company_address || '',
      company_city: values.company_city || '',
      company_phone: values.company_phone || '',
      company_website: values.company_website || '',
      location: values.location || '',
      category_id: values.category_id,
      user_create: userId
    };
    let files = document.getElementById("images").files;
    for (let i = 0; i < files.length; i++){
      newService["attachment_" + i] = files[i];
    }
    actions.doPostNewService(newService);
  };

  const uploadFile = e => {
    let files = e.target.files;
    if (files.length > 0)
    {
      for (let i = 0; i < files.length; i++){
        document.getElementById("images_" + i).src = URL.createObjectURL(files[i]);
      }
    }
    else{
      for (let i = 0; i < 6; i++){
        document.getElementById("images_" + i).src = "";
      }
    }
    // console.log(state);
  };

  const renderImage = () => {
    let items = [];

    for (let i = 0; i < 6; i++) {
      items.push(
        <label htmlFor={i}>
          {/*<input id={i} name="files[]" type="file" style={{display: "none"}} onChange={uploadFile} accept="image/*"/>*/}
          <img src={require("../../assets/icon/input_file_plus.png")} id={"images_" + i} alt=""/>
        </label>
      )
    }

    return items;
  }

  return (
    <React.Fragment>
      {/* <BreadCrumb /> */}
      <Layout
        className='container black mg-b-60'
      >
        <Layout.Header
          className="header_form"
        >
          <h1 className="form_title">{getTranslatedText('btn_post_new_service')}</h1>
        </Layout.Header>
        <Layout.Content>
          <p
            className="text-center"
          >
            {getTranslatedText('tell_us_your_service')}
          </p>
          <Form
            className='row_form border-green'
            layout='vertical'
            onFinish={(values) => handleOnPostServiceFinish(values)}
          >
            <Row gutter={16}>
              <Col
                span={8}
              >
                <Form.Item
                  label={getTranslatedText('select_your_service')}
                >
                  <Select
                    className='form_item_select'
                    bordered={false}
                    onChange={(value) => handleOnParentCategoryChange(value)}
                  >
                    {parentCategoryOption()}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={8}
              >
                <Form.Item
                  label={getTranslatedText('select_job_title')}
                  name='category_id'
                >
                  <Select
                    className='form_item_select'
                    bordered={false}
                  >
                    {childrenCategoryOption()}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={8}
              >
                <Form.Item
                  name='location'
                  label={getTranslatedText('address_job')}
                >
                  <Select defaultValue={"VIET NAM"} className="form-control">
                    {resource.country.map(item => (
                      <Select.Option value={item.name}>{item.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
                <Form.Item
                  name='title'
                  label={getTranslatedText('service_name_title')}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
                <Form.Item
                  name='summary'
                  label={getTranslatedText('summary_title')}
                >
                  <Input.TextArea
                    placeholder={getTranslatedText('suggest_title'), getTranslatedText('summary_suggest')}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
                <Form.Item
                  name='detail'
                  label={getTranslatedText('detail_title')}
                >
                  <SunEditor
                    name="my-editor" 
                    enableToolbar={true}
                    height='700'
                    setOptions={{
                      imageFileInput: true,
                      buttonList: [
                        ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                        ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                      ]
                    }}
                    onFocus={(event) => {console.log(event)}}
                    placeholder={getTranslatedText('suggest_title'), getTranslatedText('detail_suggest')}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
                <Form.Item
                  label={getTranslatedText('upload_cetificate_title')}
                >
                  <div className="input_file">
                    <label htmlFor="images">
                      <input type="file" id="images" style={{display: "none"}} onChange={uploadFile} accept="image/*" multiple={true}/>
                      {getTranslatedText('upload')}
                    </label>
                  </div>
                  <div className="gril_3_col">
                    {renderImage()}
                  </div>
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
                <Layout>
                  <Layout.Header
                    className='contact_info_form_header'
                  >
                    <p>{getTranslatedText('info_contact')}</p>
                  </Layout.Header>
                  <Layout.Content
                    className='contact_info_form_content'
                  >
                    <Row
                      gutter={16}
                    >
                      <Col
                        span={12}
                      >
                        <Form.Item
                          name='company_name'
                          label={getTranslatedText('company_or_person_title')}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                      >
                        <Form.Item
                          name='company_email'
                          label={getTranslatedText('label_email')}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                      >
                        <Form.Item
                          name='company_address'
                          label={getTranslatedText('label_address')}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                      >
                        <Form.Item
                          name='company_city'
                          label={getTranslatedText('label_city')}
                        >
                          <Select defaultValue={"VIET NAM"} className="form-control">
                            {resource.country.map(item => (
                              <Select.Option value={item.name}>{item.name}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                      >
                        <Form.Item
                          name='company_phone'
                          label={getTranslatedText('label_phone')}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                      >
                        <Form.Item
                          name='company_website'
                          label={getTranslatedText('company_website')}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Layout.Content>
                </Layout>
              </Col>
              <Col
                span={24}
              >
                <Form.Item
                  name='checker'
                  valuePropName='checked'
                  rules={[{
                    required: true,
                    message: 'Please check agreement'
                  }]}
                >
                  <Checkbox
                    className="checkbox_cs"
                  >
                    {getTranslatedText('post_new_service_rule_title')}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col
                span={6}
              >
                <Form.Item>
                  <Button
                    className='btn green shadow'
                    htmlType='submit'
                  >
                    {getTranslatedText('post_service_now')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
	return {
		serviceCategory: state.serviceCategoryReducer,
    location: state.serviceCategoryReducer.location,
  };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
        doGetCategoryService,
        doPostNewService,
        doGetLocation,
        doGetChildrenCategoryService,
			},
			dispatch,
		)
	};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormService);