import React from 'react';
import {
  Layout,
  Typography,
} from 'antd';
import './postnewservicestyles.less';


const {
  Content
} = Layout;

const {
  Title,
  Paragraph,
  Link,
} = Typography;


const PostNewProduct = () => {
  return (
    <React.Fragment>
      <Content id='layout-content-container'>
        <Typography>
          <Title
            id='title'
            level={3}
          >
            HƯỚNG DẪN ĐĂNG SẢN PHẨM MỚI
          </Title>
          <Paragraph>
          <strong>- Bước 1:</strong> Tại đường link <Link>https://b2bgosell.com/account/login</Link> , đăng nhập vào tài khoản của bạn bằng email và mật khẩu đã đăng ký -> chọn <strong>ĐĂNG NHẬP</strong>
          </Paragraph>
          <img src='https://lh6.googleusercontent.com/df0Y4gh5PBeBf13zV_rAB9OIVJMZZCVghXek47hNGLXBcWUoY8wglCj2fu1jGJEzxhoX3UGme7UWqGZ64_GglAA-VaYfDzqX2yjY6nYIUu-0vcqlHouXW6BB4Xz2acPh-EQE_RdEuAtCKnGCkQ' alt='' />
          <Paragraph>
          <strong>- Bước 2:</strong> Tại đường link <Link>https://b2bgosell.com/products</Link>, chọn <strong>ĐĂNG SẢN PHẨM MỚI</strong>
          </Paragraph>
          <img src='https://lh5.googleusercontent.com/Sk5O_tEHrP8tOCeGIOlkO2gJEtAhCb6sq0SR4uJMTM7ycIRVKzysDCttWiH-xQbxO4kBGkGM9cxH4qHujo0vhq_rI2bHLnb45qSRraxAr3rmBV_F82Hqrt9OHHxQot18XSEDk1SkYiraUpszmQ' alt='' />
          <Paragraph>
          <strong>- Bước 3:</strong> Tại đường link <Link>https://b2bgosell.com/products/post/new</Link>, điền vào các ô <strong>thông tin, hình ảnh về SẢN PHẨM</strong> -> upload các <strong>giấy tờ, chứng nhận</strong> có liên quan đến <strong>SẢN PHẨM</strong> của bạn 
          -> chọn <strong>“ Khi đăng tin mới, bạn xác nhận …”</strong> -> chọn <strong>ĐĂNG SẢN PHẨM NGAY</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/oo39RJ-ZVFSkFXPHrUihusjIlupHbKgdsD_Q5p6mC__uPmWcpm5ffWvbMIhMHhj-4ARR4URdCRAMMaDL9twmbV-z4fjPnTV_hRmCmtzh7YQYg4LHXUSTMERHzBERYqHVUXyBMo3C8UyXkGmbtw' alt='' />
          <img src='https://lh3.googleusercontent.com/pqLqnQY2L633L7AX9oluG2kRrq3UeiMUM021iRTaFh-IbD8ivgmRgihUtA5YGR69UUS_9hJMHI-H-CV9n6QHK5v26ZuXNjyT8jTvrpoUfHy6Y9RmjuelspOd3NKZhQiE_vxDfn0qnlc4Qbas-Q' alt='' />
          <img src='https://lh4.googleusercontent.com/7fLEndSm8TsVxu72Ey4HZLFQFbc_h9GFMbyxoe1m6UcJo0XQ6SMZUTUdFc3UiLZSb0k6pX5yJgyH2OSqYOa0tinVvfuOMPPvGt52xiuhqr_4JVnGmbcw50mlza8RG8-PAOCnvJ40oz8uMhRiyg' alt='' />
          <Paragraph>
          <strong>- Bước 4:</strong> Hệ thống sẽ kiểm tra và phê duyệt bài đăng tin của bạn trước khi được hiển thị trên website
          </Paragraph>
          <Paragraph>
          <strong>- Bước 5:</strong> Khi bài đăng được duyệt, hệ thống sẽ thông báo cho bạn bằng mail và đường link của bài đăng đó
          </Paragraph>
          <Paragraph
            strong
          >
            Nếu sau 3 ngày kể từ lúc bạn đăng bài nhưng chưa thấy hiển thị, hãy liên hệ Admin để được hỗ trợ.
          </Paragraph>
          <Paragraph
            strong
            type='danger'
          >
            THÔNG TIN LIÊN HỆ HỖ TRỢ:
          </Paragraph>
          <Paragraph>
            <strong>GREATLINK MAIHOUSE INVESTMENT COMPANY LIMITED (B2BGOSELL IN VIETNAM)</strong><br/>
            <strong>Add (new):</strong> 11A, Hong Ha, Ward 2, Tan Binh Distric, HoChiMinh City, Zip Code 700000, Vietnam<br/>
            Mobile: <strong>+84 936.168.503</strong><br/>
            Email: <Link>admin@b2bgosell.com;</Link><br/>
            Website:<Link>www.b2bgosell.com</Link>
          </Paragraph>
          <Paragraph
            strong
          >
            CHÚC CÁC BẠN THÀNH CÔNG!
          </Paragraph>
        </Typography>
      </Content>
    </React.Fragment>
  );
};

export default PostNewProduct;