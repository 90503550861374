import React, { useEffect } from 'react';
import {bindActionCreators} from "redux";
import { connect } from 'react-redux';
import {
	doGetServices,
	getServiceWithFilter,
} from '../../redux/actions/services';
import {
	useHistory,
} from 'react-router-dom';

import {
	Row,
	Col,
} from 'antd';

import {
	routers
} from '../../config/routers';
import { toast } from 'react-toastify';

import ServiceNewFilterComponent from '../ServiceFilterComponent/ServiceFilterNewComponent';
import ServiceCardComponent from '../ServiceComponent/ServiceCardComponent';

const ServiceFilterCategory = (props) => {

	const {
		actions,
		match,
		services,
		accountType
	} = props;

	const history = useHistory();

	useEffect(() => {
		if(match.params.category_id === '0') {
			actions.doGetServices();
		} else {
			const filterValue = {
				category_id: match.params.category_id,
				service_business_category: null,
				location: null,
			};
			actions.getServiceWithFilter(filterValue);
		}
	}, []);

	const handleRequestEstimateButton = (item) => {
    if (accountType === 'partner-product' || accountType === 'partner-servive') {
      toast.warn('Vui lòng đăng kí tài khoản khách hàng để thực hiện thao tác này');
    } else {
      history.push(routers.services.service_request_partner_estimate.replace(':service_id', item.id))
    }
  };

	return (
		<React.Fragment>
			{/* <!-- Service --> */}
			<div>
				<ServiceNewFilterComponent />
				{/* <!-- Service list --> */}
				<Row gutter={16}>
				{services && services.map((item, index) => (
					<Col md={12} xs={24}>
						<ServiceCardComponent
							serviceObject={item}
							history={history}
							handleRequestEstimateButton={handleRequestEstimateButton}
						/>
					</Col>
					))}
					{/* <!-- Login --> */}
					<div className="modal black fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered mw_1048" role="document">
							<div className="modal-content modal_login">
								<div className="left">
									<span className="close" data-dismiss="modal"></span>
									<form action="" className="login_form black">
										<h3 className="title">
											ĐĂNG NHẬP TÀI KHOẢN
                                </h3>
										<div className="mg-b-15">Vui lòng đăng nhập để tiến hành mua hàng</div>
										<div className="form-group">
											<input type="text" className="form-control" id="femail" name="femail" placeholder="Email/Số điện thoại" />
											<input type="password" className="form-control" id="fpw" name="fpw" placeholder="Mật khẩu" />
										</div>
										<div className="remember">
											<div className="form-check">
												<input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
												<label className="form-check-label" for="defaultCheck1">
													Remember me
                                        </label>
											</div>
											<a href="#">Quên mật khẩu</a>
										</div>
										<button className="btn green full mg-tb-25">Đăng nhập</button>
										<div className="mg-t-50">
											Bạn chưa có tài khoản? <a href="">Đăng kí ngay miễn phí</a>
										</div>
									</form>
								</div>
								<div className="right">
									<img className="left_center" src={require("../../assets/logo.png")} alt="" />
								</div>
							</div>
						</div>
					</div>

					{/* <!-- navigation --> */}
					{/* <div className="col col-12 center">
						<nav aria-label="Page navigation example">
							<ul className="pagination category_pagination">
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</a>
								</li>
								<li className="page-item"><a className="page-link active" href="#">1</a></li>
								<li className="page-item"><a className="page-link" href="#">2</a></li>
								<li className="page-item"><a className="page-link" href="#">3</a></li>
								<li className="page-item"><a className="page-link" href="#">4</a></li>
								<li className="page-item"><a className="page-link" href="#">5</a></li>
								<li className="pd-5">...</li>
								<li className="page-item"><a className="page-link" href="#">30</a></li>
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</a>
								</li>
								<li className="page-item">
									<select name="" id="">
										<option value="">20 / trang</option>
										<option value="">15 / trang</option>
										<option value="">20 / trang</option>
										<option value="">25 / trang</option>
									</select>
								</li>
							</ul>
						</nav>
					</div> */}
					{/* <!-- End navigation --> */}

				</Row>
			</div>
			<div className="col_line mg-b-60"></div>
			{/* <!-- End Service --> */}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		services: state.serviceReducer.services,
		accountType: state.getUserFromCookiesReducer.accountType,
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
				doGetServices,
        getServiceWithFilter,
      },
      dispatch,
    ),
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ServiceFilterCategory);