import * as actionTypes from '../actions/action_types';
import { toast } from 'react-toastify';

const notificationState = {
  data: [],
  isLoading: false,
};

export const notificationReducer = (state = notificationState, action) => {
  switch (action.type) {
    // case actionTypes.GET_NOTIFICATION_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      //Handle map data here
      let notificationArray = [];
      action.payload.forEach(item => {
        if (item.status === 0) {
          notificationArray.push(item);
        }
      });
      return {
        ...state,
        isLoading: false,
        data: notificationArray
      };
    case actionTypes.GET_NOTIFICATION_FAILURE:
      //Handle error here
      toast.warn(action.error)
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}