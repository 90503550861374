import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Form,
  Row,
  Col,
  Layout,
  Input,
  Button,
} from 'antd';
import 'antd/dist/antd.css';
import './ResetPassword.scss';
import {doResetPassword} from "../../redux/actions/authentication";
import queryString from "query-string";

const ResetPasswordComponent = (props) => {

  const {
    actions
  } = props;

  const pwToken = queryString.parse(window.location.search);

  const onFinish = (values) => {
    if (pwToken)
      values.token = pwToken.token;
    actions.doResetPassword(values);
  };

  return (
    <React.Fragment>
      <div className="container black mg-b-60">
				<Row
          className='login_container'
        >
          <Col
            lg={8}
            xs={24}
          >
            <Layout
              className='layout_login'
            >
                <h1
                  className='layout_title'
                >
                  NHẬP MẬT KHẨU MỚI
                </h1>
                <p
                  className='layout_subtitle'
                >
                  Chào mừng bạn! Hãy đặt lại mật khẩu mới.
                </p>
              <Layout.Content>
                <Form
                  layout='vertical'
                  onFinish={(values) => onFinish(values)}
                >
                  <Row gutter={16}>
                    <Col
                      lg={16}
                      xs={16}
                      offset={4}
                    >
                      <Form.Item
                        name='password'
                      >
                        <Input.Password
                          placeholder='New password'
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      lg={16}
                      xs={16}
                      offset={4}
                    >
                      <Form.Item
                        name='cf_password'
                      >
                        <Input.Password
                          placeholder='Comfirm new password'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                  >
                  <Col
                    lg={12}
                    xs={24}
                  >
                    <Form.Item>
                      <Button
                        className='login_button'
                        htmlType='submit'
                      >
                        Đặt lại mật khẩu
                      </Button>
                    </Form.Item>
                  </Col>
                  </Row>
                </Form>
              </Layout.Content>
            </Layout>
          </Col>
          <Col
            lg={16}
            xs={0}
          >
            <img src={require('../../assets/cover_login.png')} alt='' />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doResetPassword
      },
     dispatch,
    ),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ResetPasswordComponent);