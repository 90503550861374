import * as actionTypes from '../actions/action_types';

const documentState = {
  isLoading: false,
  data: {},
  error: null,
};

export const documentReducer = (state = documentState, action) => {
  switch (action.type) {
    case actionTypes.GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      }
    case actionTypes.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    default:
      return state;
  }
}