// Usage: This component display introduce about company

import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import './ContactBody.scss';
import {getTranslatedText} from '../../services/appServices';

const ContactBody = () => {

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <div className='body_header flex_start_col_container'>
              <h4>{getTranslatedText('host_intro_title')}</h4>
              <p
                style={{color: '#000000 !important'}}
              >
                {getTranslatedText('intro_para')}
              </p>
            </div>
            <div class='body_main flex_start_col_container'>
              <h4>
                {getTranslatedText('host_contact_title')}
              </h4>
              <p style={{color: '#000000 !important'}}>{getTranslatedText('host_name')}</p>
              <div className='main__footer'>
                <div className='footer_row'>
                  <img src={require('../../assets/icon/location.png')}/>
                  <p
                    style={{color: '#000000 !important'}}
                  >{getTranslatedText('host_address')}</p>
                </div>
                <div className='footer_row'>
                  <img src={require('../../assets/icon/call.png')}/>
                  <p
                    style={{color: '#000000 !important'}}
                  >
                    0936 168 503
                  </p>
                </div>
                <div className='footer_row'>
                  <img src={require('../../assets/icon/mail.png')}/>
                  <p
                    style={{color: '#000000 !important'}}
                  >admin@b2bgosell.com</p>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className='map_container flex_start_col_container'>
              <h4>{getTranslatedText('host_map_location_title')}</h4>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.9730887043124!2d106.66663821472217!3d10.813371261467763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752918978b5a39%3A0xfc689623f37423c0!2zMTFBIEjhu5NuZyBIw6AsIFBoxrDhu51uZyAyLCBUw6JuIELDrG5oLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1608755761599!5m2!1svi!2s"
                width="100%" height="400" frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false"
                tabIndex="0"/>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ContactBody;