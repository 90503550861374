import { put } from 'redux-saga/effects';
import * as actionTypes from '../actions/action_types';

export default {
  getAds: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/guest/ads', payload);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_ADS_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_ADS_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_ADS_FAILURE,
        error: error
      })
    };
  },
}