import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  Row,
  Col,
  Form,
  Button,
  Select,
  Input,
  Checkbox,
} from 'antd';
import {doUploadAction} from '../../redux/actions/upload';
import {doPostProductRequest,} from '../../redux/actions/products';
import {
  doGetCategoryProduct,
  doGetChildrenCategoryProduct,
} from '../../redux/actions/category';
import { getTranslatedText } from '../../services/appServices';

import SunEditor from 'suneditor-react';

import placeholder from "../../assets/icon/input_file_plus.png";

import './styles/new-product-form-styles.less';

function NewFormPostProduct(props) {

  const {
    actions,
		rootProductCategory,
		childProductCategory,
  } = props;

  useEffect(() => {
    actions.doGetCategoryProduct();
  }, []);

  const handleOnFinishChange = (value) => {
    const productData = {
      category_id: value.category_id,
      child_category_id: value.child_category_id,
      name: value.name,
      price: value.price,
      market_price: value.market_price,
      unit: value.unit,
      unit_price: value.unit_price,
      summary: value.summary,
      detail: value.detail
    };
    let avatar = document.getElementById("avatar").files;
    if (avatar.length > 0)
      productData["avatar"] = avatar[0];
    let gallery = document.getElementById("images").files;
    for (let i = 0; i < gallery.length; i++){
      productData["images_" + i] = gallery[i];
    }
    let certificate = document.getElementById("certificate").files;
    for (let i = 0; i < certificate.length; i++){
      productData["certificate_" + i] = certificate[i];
    }
    actions.doPostProductRequest(productData);
  };

  const handleOnParentCategoryChange = (value) => {
    console.log(value);
    actions.doGetChildrenCategoryProduct(value);
  };

  const uploadFile = async (id) => {
    let files = document.getElementById(id).files;
    for (let i = 0; i < 6; i++){
      if (document.getElementById(id + "_" + i)){
        if (files[i])
          document.getElementById(id + "_" + i).src = URL.createObjectURL(files[i]);
        else
          document.getElementById(id + "_" + i).src = placeholder;
      }
    }
  };

  return (
    <React.Fragment>
      <Row gutter={16} id='new-form-product-wrapper'>
        <Col md={24} xs={24} id='title-wrapper'>
          <h1>
            {getTranslatedText('products_title')}
          </h1>
        </Col>
        <Col md={24} xs={24}>
        <Form
          className='custom-post-product-form'
          layout='vertical'
          onFinish={(value) => handleOnFinishChange(value)}
        >
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('select_product_category')}
              name='category_id'
            >
              <Select
                onChange={(value) => handleOnParentCategoryChange(value)}
              >
                {rootProductCategory.map(item => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('select_invidual_product')}
              name='child_category_id'
            >
              <Select>
                {childProductCategory.map(item => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item
              label={getTranslatedText('product_name')}
              name='name'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item
              label={getTranslatedText('select_avatar')}
            >
              <div className="input_file">
                <label htmlFor="avatar">
                <input
                  type="file"
                  id="avatar"
                  accept="image/*"
                 onChange={() => uploadFile("avatar")}/> {getTranslatedText('upload')}</label>
              </div>
              <div className="gril_3_col">
                <img src={placeholder} id="avatar_0" alt=""/>
              </div>
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item
              label={getTranslatedText('unit_count')}
              name='unit'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item
              label={getTranslatedText('price')}
              name='price'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item
              label={getTranslatedText('market_price')}
              name='market_price'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item
              label={getTranslatedText('money_unit')}
              name='unit_price'
            >
              <Select>
                <Select.Option value={1}>
                  VND
                </Select.Option>
                <Select.Option value={2}>
                  USD
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item
              label={getTranslatedText('product_summary_info')}
              name='summary'
            >
              <Input.TextArea
                className='custom-text-area'
              />  
            </Form.Item>
          </Col>
          <Col md={16} xs={24}>
            <Form.Item
              name='detail'
              label='Detail'
            >
              <SunEditor
                name="my-editor" 
                enableToolbar={true}
                height='700'
                setOptions={{
                  imageFileInput: true,
                  buttonList: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                  ]
                }}
                onFocus={(event) => {console.log(event)}}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              label={getTranslatedText('upload')}
            >
              <input
                type="file"
                id="images"
                accept="image/*"
                multiple={true}
                onChange={() => uploadFile("images")}
              />
              <div className="gril_3_col">
                <img src={placeholder} id="images_0" alt=""/>
                <img src={placeholder} id="images_1" alt=""/>
                <img src={placeholder} id="images_2" alt=""/>
                <img src={placeholder} id="images_3" alt=""/>
                <img src={placeholder} id="images_4" alt=""/>
              </div>
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item
              label={getTranslatedText('product_upload_suggest')}
            >
              <input type="file" id="certificate" accept="image/*" multiple={true} onChange={() => uploadFile("certificate")}/>
              <div className="gril_3_col">
                <img src={placeholder} id="certificate_0" alt=""/>
                <img src={placeholder} id="certificate_1" alt=""/>
                <img src={placeholder} id="certificate_2" alt=""/>
                <img src={placeholder} id="certificate_3" alt=""/>
                <img src={placeholder} id="certificate_4" alt=""/>
              </div>
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item>
              <Checkbox />
              {getTranslatedText('post_product_aggrement_1')} <a href="">{getTranslatedText('post_product_aggrement_2')}</a> {getTranslatedText('post_product_aggrement_3')} <b>B2B
              GOSELL</b>
              {getTranslatedText('post_product_aggrement_4')}
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item>
              <Button
                htmlType='submit'
                id='post-product-btn'
              >
                {getTranslatedText('post_product_now')}
              </Button>
            </Form.Item>
          </Col>
        </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    rootProductCategory: state.productCategoryReducer.parentProductCategory,
    childProductCategory: state.productCategoryReducer.childrenProductCategory,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doUploadAction,
        doPostProductRequest,
        doGetCategoryProduct,
        doGetChildrenCategoryProduct,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewFormPostProduct);