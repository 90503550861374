import React from 'react';
import { connect } from 'react-redux';

import PostEstimate from '../../../components/PartnerPostEstimate/PartnerPostEstimate';
import { useRouteMatch } from 'react-router-dom';
import { routers } from '../../../config/routers';

const PostEstimateContainer = (props) => {

  const match = useRouteMatch(routers.dashboard.send_quotation);
  console.log(match)

  const {
    userCookie,
  } = props;

  return (
    <React.Fragment>
      <PostEstimate
        service_id={match.params.service_id}
        quotation_id={match.params.quotation_id}
        userId={userCookie.id}
        balance={userCookie.balance}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
	return {
		userCookie: state.getUserFromCookiesReducer,
	};
};

export default connect(
  mapStateToProps
)(PostEstimateContainer);