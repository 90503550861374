import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  doCreateCartAction,
} from '../../redux/actions/products';
import {
  getTranslatedText,
  usdCurrencyFormat,
  vndCurrencyFormat,
} from '../../services/appServices';


const Product_OrderTable = (props) => {

  const {
    actions,
    cart,
    productCart,
    cartRender,
    totalOfOrder,
    currency,
  } = props;

  let current_language = localStorage.getItem('current_language') || 'vi'

  const handleMinusOnClick = (item) => {
    if (item.quantity > 0) {
      const newQuantity = parseInt(item.quantity) - 1;
      const amount = (newQuantity * item.price);
      const cartPayload = {
        cartItem: {
          product_name: item.product_name,
          product_id: item.product_id,
          price: item.price,
          product_unit: item.product_unit,
          quantity: newQuantity,
          user_create: item.user_create,
          product_price_unit: item.product_price_unit,
          amount: amount,
          provider_short_name: item.provider_short_name,
          partner_id: item.partner_id,
          product_avatar: item.product_avatar,
          partner_name: item.partner_name
        },
        orderType: 'si'
      };
      actions.doCreateCartAction(cartPayload);
    }
  };

  const handleAddOnClick = (item) => {
    const newQuantity = parseInt(item.quantity) + 1;
    const amount = (newQuantity * item.price);
    const cartPayload = {
      cartItem: {
        product_name: item.product_name,
        product_id: item.product_id,
        price: item.price,
        product_unit: item.product_unit,
        quantity: newQuantity,
        user_create: item.user_create,
        product_price_unit: item.product_price_unit,
        amount: amount,
        provider_short_name: item.provider_short_name,
        partner_id: item.partner_id,
        product_avatar: item.product_avatar,
        partner_name: item.partner_name
      },
      orderType: 'si'
    };
    actions.doCreateCartAction(cartPayload);
  };

  const handleInputOnChange = (e, item) => {
    console.log(item);
    item.quantity = e.target.value;
    if (item.quantity === '') {
      item.quantity = null;
    } else {
      item.quantity = parseInt(item.quantity)
    }
    const newQuantity = item.quantity;
    const amount = (newQuantity * item.price);
    const cartPayload = {
      cartItem: {
        product_name: item.product_name,
        product_id: item.product_id,
        price: item.price,
        product_unit: item.product_unit,
        quantity: newQuantity,
        user_create: item.user_create,
        product_price_unit: item.product_price_unit,
        amount: amount,
        provider_short_name: item.provider_short_name,
        partner_id: item.partner_id,
        product_avatar: item.product_avatar,
        partner_name: item.partner_name
      },
      orderType: 'si'
    };
    actions.doCreateCartAction(cartPayload);
  }

  const renderAllTotal = () => {
    if (current_language === 'vi') {
      var total = 0;
      cart.cart.forEach(item => {
        total += (item.quantity * item.price);
      });
      return (
          <React.Fragment>
            <div className="number">{vndCurrencyFormat.format(total)}</div>
          </React.Fragment>
      );
    } else {
      var total = 0;
      cart.cart.forEach(item => {
        total += (item.quantity * item.price);
      })
      return (
          <React.Fragment>
            <div className="number">{usdCurrencyFormat.format(total)}</div>
          </React.Fragment>
      );
    }
  };

  return (
      <React.Fragment>
        <table className="cart_product">
          <tr className="top">
            <th>{getTranslatedText('product_name')}</th>
            <th className="center">{getTranslatedText('unit')}</th>
            <th className="left" style={{minWidth: 120,}}>{getTranslatedText('quantity')}</th>
            <th>{getTranslatedText('cost')}</th>
            <th className="right">{getTranslatedText('amount')}</th>
          </tr>
          {cartRender === 'server' ? (
              <tbody>
              {productCart && productCart.map(item => (
                  <tr>
                    <td className="left">
                      <div className="p_2_col">
                        <div className="left">
                          {item.avatar !== '' ? (
                              <img src={item.avatar} alt=""/>
                          ) : (
                              <img src={require("../../assets/product/wireframe.png")} alt=""/>
                          )}
                          <div>
                            <a href="">{getTranslatedText('delete')}</a>
                            <a href="">{getTranslatedText('view_product')}</a>
                          </div>
                        </div>
                        <div className="right">
                          <h3 className="title">{item.product_name}</h3>
                          <div>{getTranslatedText('suplier')}: <b>{item.provider_short_name}</b></div>
                        </div>
                      </div>
                    </td>
                    <td className="dvt">{item.product_unit}</td>
                    <td>
                      <div className="amount quantity">
                        {/* <button className="icon minus" onClick={() => handleMinusOnClick(item)}>-</button> */}
                        <input type="text" name="value" className="value qty" value={item.amount}/>
                        {/* <button className="icon plus" onClick={() => handleAddOnClick(item)}>+</button> */}
                      </div>
                    </td>
                    {currency === 'VND' ? (
                        <React.Fragment>
                          <td className="price">{vndCurrencyFormat.format(item.price)}</td>
                          <td className="total">{vndCurrencyFormat.format(item.amount * item.price)}</td>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <td className="price">{usdCurrencyFormat.format(item.price)}</td>
                          <td className="total">{usdCurrencyFormat.format(item.amount * item.price)}</td>
                        </React.Fragment>
                    )}
                  </tr>
              ))}
              </tbody>
          ) : (
              <tbody>
              {cart.cart && cart.cart.map(item => (
                  <tr>
                    <td className="left">
                      <div className="p_2_col">
                        <div className="left">
                          {item.product_avatar !== '' ? (
                              <img src={item.product_avatar} alt=""/>
                          ) : (
                              <img src={require("../../assets/product/wireframe.png")} alt=""/>
                          )}
                          <div>
                            <a href="">{getTranslatedText('delete')}</a>
                            <a href="">{getTranslatedText('view_product')}</a>
                          </div>
                        </div>
                        <div className="right">
                          <h3 className="title">{item.product_name}</h3>
                          {/* <div>{getTranslatedText('suplier')}: <b>{item.provider_short_name}</b></div> */}
                        </div>
                      </div>
                    </td>
                    <td className="dvt">{item.product_unit}</td>
                    <td>
                      <div className="amount quantity">
                        <button className="icon minus" onClick={() => handleMinusOnClick(item)}>-</button>
                        <input type="text" name="value" className="value qty" value={item.quantity}
                               onChange={(e) => handleInputOnChange(e, item)}/>
                        <button className="icon plus" onClick={() => handleAddOnClick(item)}>+</button>
                      </div>
                    </td>
                    {item.product_price_unit === 1 ? (
                        <React.Fragment>
                          <td className="price">{vndCurrencyFormat.format(item.price)}</td>
                          <td className="total">{vndCurrencyFormat.format((item.quantity * item.price))}</td>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <td className="price">{usdCurrencyFormat.format(item.price)}</td>
                          <td className="total">{usdCurrencyFormat.format((item.quantity * item.price))}</td>
                        </React.Fragment>
                    )}
                  </tr>
              ))}
              </tbody>
          )}

        </table>
        {(cartRender !== 'server' && cart.cart[0])? (
            <div className="total_cart" style={{alignItems: "center", display: "block", textAlign: "center"}}>
              <div className="number">Nhà cung cấp: {cart.cart[0].partner_name}</div>
            </div>
        ) : <React.Fragment/>}
        <div className="total_cart" style={{alignItems: "center"}}>
          {cartRender === 'server' ? (
              <>
                <div className="title">{getTranslatedText('total_money_pay')}</div>
                <div className="number">{totalOfOrder}</div>
              </>
          ) : (
              <>
                <div className="title">{getTranslatedText('total_money_pay')}</div>
                {renderAllTotal()}
              </>
          )}
        </div>

      </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cartReducer,
    isHadAccount: state.getUserFromCookiesReducer.isHadAccount,
    currency: state.getOrderReducer.detail_order.currency || '',
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
          doCreateCartAction,
        },
        dispatch,
    )
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Product_OrderTable);