import * as actionTypes from '../actions/action_types';

const urlResponseState = {
  url: '',
};

export const uploadFileReducer = (state = urlResponseState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return state;
    case actionTypes.UPLOAD_FILE_FAILURE:
      return state;
    default:
      return state;
  }
};