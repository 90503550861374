/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
	Link,
} from 'react-router-dom';
import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';
import './style.scss';

const FooterComponent = () => {

	return (
		<footer className="footer_main">
			<div className="container">
				<div className="row row_top">
					<div style={{ paddingTop: '45px', textAlign: 'left'}} className="col col-lg-4">
						<a href="">
							<img src={require('../../assets/logo.png')} className="_logo" alt="Logo Footer" />
						</a>
						<div className="col_info">
							<h2>{getTranslatedText('host_info')}</h2>
							<h3>{getTranslatedText('host_name')}</h3>
							<p>{getTranslatedText('company_location')}</p>
							<p>Email: admin@b2bgosell.com</p>
							<p>Hotline: (+84) 936 168 503</p>
							<p>{getTranslatedText('company_registe_document')}:</p>
							<p>{getTranslatedText('business_code')}: 0315661691</p>
							<p>{getTranslatedText('ho_chi_minh_registration_office')}</p>
							<p>{getTranslatedText('contact_footer_title')}</p>
						</div>
					</div>
					<div style={{ textAlign: 'left' }} className="col col_footer">
						<div className="_title">{getTranslatedText('service_title')}</div>
						<li><Link><a href="">{getTranslatedText('menu_item_marketing')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_accountant')}</a></Link></li>
						{/* <li><Link><a href="">{getTranslatedText('menu_item_sale')}</a></Link></li> */}
						<li><Link><a href="">{getTranslatedText('menu_item_architecture')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_translator')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_real_estate')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_logistic')}</a></Link></li>
					</div>
					<div style={{ textAlign: 'left' }} className="col col_footer">
						<div className="_title">{getTranslatedText('products_title')}</div>
						<li><Link><a href="">{getTranslatedText('menu_item_agriculture')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_cosmetic')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_food')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_tool')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_technology')}</a></Link></li>
						<li><Link><a href="">{getTranslatedText('menu_item_fashion')}</a></Link></li>
					</div>
					<div style={{ textAlign: 'left' }} className="col col_footer">
						<div className="_title">{getTranslatedText('support_title')}</div>
						<li><Link to={routers.documents.replace(':slug', 'huong-dan-thay-doi-thong-tin-tai-khoan')}><a hrer="">{getTranslatedText('menu_item_change_info_tutorial')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'huong-dan-thanh-toan')}><a hrer="">{getTranslatedText('menu_item_payment_tutorial')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'huong-dan-tao-yeu-cau-bao-gia-dich-vu')}><a href="">{getTranslatedText('menu_item_contact_partner_tutorial')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'huong-dan-tao-don-hang')}><a href="">{getTranslatedText('menu_item_create_order_tutorial')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'huong-dan-dang-tin')}><a href="">{getTranslatedText('menu_item_post_tutorial')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'huong-dan-dang-san-pham-moi')}><a href="">{getTranslatedText('menu_item_deposit_tutorial')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'huong-dan-dang-ky-tai-khoan')}><a href="">{getTranslatedText('menu_item_project_manage_tutorial')}</a></Link></li>
					</div>
					<div style={{ textAlign: 'left' }} className="col col_footer">
						<div className="_title">{getTranslatedText('about_us_title')}</div>
						<li><Link to={routers.documents.replace(':slug', 'gioi-thieu-chung')}><a href="">{getTranslatedText('menu_item_general_title')}</a></Link></li>
						<li><a href="">{getTranslatedText('menu_item_support_title')}</a></li>
						<li><a href="">{getTranslatedText('menu_item_contact_title')}</a></li>
						<li><a href="">{getTranslatedText('menu_item_question_answer')}</a></li>
					</div>
					<div style={{ textAlign: 'left' }} className="col col_footer">
						<div className="_title">{getTranslatedText('info_title')}</div>
						<li><Link to={routers.documents.replace(':slug', 'dieu-khoan-su-dung')}><a href="">{getTranslatedText('menu_item_term_of_use_title')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'dieu-kien-giao-dich-chung')}><a href="">{getTranslatedText('menu_item_trading_condition')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'chinh-sach-bao-mat-thong-tin')}><a href="">{getTranslatedText('menu_item_privacy')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'phuong-thuc-van-chuyen')}><a href="">{getTranslatedText('menu_item_shipment')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'quy-trinh-thanh-toan')}><a href="">{getTranslatedText('menu_item_paymnet')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'chinh-sach-doi-tra-va-hoan-tien')}><a href="">{getTranslatedText('menu_item_refund')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'chinh-sach-giai-quyet-khieu-nai')}><a href="">{getTranslatedText('menu_item_complain')}</a></Link></li>
						<li><Link to={routers.documents.replace(':slug', 'quy-che-hoat-dong-website')}><a href="">{getTranslatedText('menu_item_how_we_work')}</a></Link></li>
					</div>
				</div>
				<div className="row row_copyright">
					<div className="col-12">
						<div className="col_line mg-t-40"></div>
					</div>
					<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mg-t-20 text-left left footer-copyright">
						Copyright 2021-2022 @ GreatLink Maihouse. Alright reserved.<br/>
						Powered by GKC Software
					</div>
					<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mg-t-20 text-right right">
						<span className="_title">{getTranslatedText('follow_us_title')}</span>
						<a href="https://www.facebook.com/b2bgosell" target="_blank">
							<img src={require("../../assets/icon/facebook.png")} alt="" />
						</a>
						<a href="https://www.instagram.com/b2bgosell/" target="_blank">
							<img src={require("../../assets/icon/intagram.png")} alt="" />
						</a>
						<a href="https://www.youtube.com/channel/UCCvVhwykooAKR6c9486z6hg" target="_blank">
							<img src={require("../../assets/icon/youtube.png")} alt="" />
						</a>
						<a href="http://linkedin.com/in/b2bgosell-in-vietnam-85547a207" target="_blank">
							<img src={require("../../assets/icon/linkedin.png")} alt="" />
						</a>
						<a href="https://twitter.com/b2bgosell" target="_blank">
							<img src={require("../../assets/icon/twitter.png")} alt="" />
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterComponent;
