import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';

import { getPartnerProfileAction } from '../../../redux/actions/dashboard';

import { routers } from '../../../config/routers';

import { getTranslatedText } from '../../../services/appServices';
const PartnerProfileContainer = (props) => {

  const {
    actions,
    partnerProfile,
  } = props;

  const match = useRouteMatch()

  useEffect(() => {
    let partnerId = match.params.partner_id;
    actions.getPartnerProfileAction(partnerId);
  }, []);

  return (
    <React.Fragment>
			<div className="container bg_white black mg-t-30 mg-b-40 pd-b-40">
				<div className="row">
					<div className="col col-xs-12 col-sm-12 col-md-8 mg-b-15">
						<h3 className="title_sec bg_green">{getTranslatedText('our_contact_info')}</h3>
						<form action="" className="form_contact border-green">
							<div className="row">
								<div className="col">
									<label for="product" className="">{getTranslatedText('name')}</label>
									<input type="text" className="icon name" placeholder={partnerProfile.company_name} disabled/>
								</div>
								<div className="col mw-330 radius-0">
									<label for="product" className="">{getTranslatedText('label_email')}</label>
									<input type="text" className="icon email" placeholder={partnerProfile.company_email} disabled />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label for="product" className="">{getTranslatedText('label_address')}</label>
									<input type="text" className="icon pin" placeholder={partnerProfile.company_address} disabled />
								</div>
								<div className="col mw-330 radius-0">
									<label for="product" className="">{getTranslatedText('label_city')}</label>
									<input type="text" className="icon pin" placeholder={partnerProfile.company_city} disabled />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label for="product" className="">{getTranslatedText('label_phone')}</label>
									<input type="text" className="icon phone" placeholder={partnerProfile.phone} />
								</div>
								<div className="col mw-330 radius-0">
									<label for="product" className="">{getTranslatedText('company_website')}</label>
									<input type="text" className="icon web" placeholder={partnerProfile.company_website} disabled />
								</div>
							</div>
						</form>
					</div>
					<div className="col col-xs-12 col-sm-12 col-md-4 pd-lr-30">
						<b>{getTranslatedText('our_cefiticate')}</b>
						{partnerProfile.attachment ? (
							<div style={{ width: '390px', margin: 'auto', marginTop: '50px'}} className="row flex_5 mg-t-15">
							<div className="col">
								<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
							</div>
							<div className="col">
								<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
							</div>
							<div className="col">
								<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
							</div>
							<div className="col">
								<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
							</div>
							<div className="col">
								<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
							</div>
						</div>
						) : (
							<div style={{ width: '390px', margin: 'auto', marginTop: '50px'}} className="row flex_5 mg-t-15">
								<div className="col">
									<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
								</div>
								<div className="col">
									<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
								</div>
								<div className="col">
									<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
								</div>
								<div className="col">
									<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
								</div>
								<div className="col">
									<img style={{ maxWidth: '63px', height: '63px'}} src={require("../../../assets/icon/icon_camera.svg")} alt="upload file" />
								</div>
							</div>
						)}
						</div>
					<div className="col col-xs-12 col-sm-12 col-md-8">
						<div className="mg-t-25">
							<label className="checkbox_cs ">
								{getTranslatedText('connect_checkbox')};
                    <input type="checkbox" checked />
								<span className="checkmark"></span>
							</label>
							<div className="text-center mg-t-30 pd-b-10 ">
								<Link
									to={routers.home}
								>
								<button
								 className="btn green shadow radius_50 "
								>
									{getTranslatedText('homepage_title').toUpperCase()}
								</button>
								</Link>
							</div>
						</div>
					</div>
					{/* <!-- end row --> */}
				</div>
			</div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    partnerProfile: state.partnerProfileReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      getPartnerProfileAction,
    },
    dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnerProfileContainer);