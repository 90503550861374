import React, { useState, useEffect } from 'react';
import {
  Carousel,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doPostQuote,
  doSetCurrentService,
} from '../../redux/actions/services';
import {
  Link,
} from 'react-router-dom';
import ServiceNewFilter from '../ServiceFilterComponent/ServiceFilterNewComponent';
import {
  routers
} from '../../config/routers';
import './Service_ListServices.scss';

import { PropTypes } from 'prop-types';
import { getTranslatedText } from '../../services/appServices';
import _ from 'lodash';
import ServiceFeature from '../ServiceFeature/ServiceFeature';

const Service_ListServices = (props) => {

  const {
    servicesArray,
    hideFilterComponent,
    isHot,
    vipBanner,
    adsData,
  } = props;

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    let bannerArray = [];
    adsData.forEach(element => {
      if (element.type === 'service-vip') {
        bannerArray.push(element);
      }
    });
    setBanner(bannerArray);
  }, [adsData]);

  return (
    <React.Fragment>
      {/* <!-- Service --> */}
      <div className="container clear-both">
        {hideFilterComponent ? (
          <React.Fragment></React.Fragment>
        ) : (
          <div className="row">
            <ServiceNewFilter />
          </div>
        )}
        {/* <!-- Service list --> */}
        <div className="row row_service">
          {!vipBanner ? (
            null
          ) : (
            <React.Fragment>
              <div className="col-12">
                <h2 className="title_sec white mg-t-5 mg-b-20">{getTranslatedText('suggest_service_title')}</h2>
                <Carousel
                  autoplay
                  autoplaySpeed={3000}
                  style={{
                    marginBottom: '12px'
                  }}
                >
                  {banner && banner.map(item => (
                    <div className="banner_qc">
                      <img src={item.banner} alt=""/>
                      <div className="content">
                        <h2 className="title">{item.title}</h2>
                        <a href={item.url} target='_blank' className="btn btn-main radius_50 arrow_right">{getTranslatedText('btn_view_service_now')}</a>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </React.Fragment>
          )}
          <ServiceFeature
            servicesArray={servicesArray}
            isHot={isHot}
          />
          {hideFilterComponent ? (
            <React.Fragment></React.Fragment>
          ) : (
            <div className="col col-12 text-center mg-t-20 mg-b-60">
              <Link
                to={routers.services.filter_category.replace(':category_id', '0')}
              >
                <a href="#" className="btn yellow arrow_left radius_50">{getTranslatedText('btn_view_all_service')}</a>
              </Link>
            </div>
          )}
        </div>
      </div>
      {/* <div className="col_line mg-b-60"></div> */}
      {/* <!-- End Service --> */}
    </React.Fragment>
  );
};

Service_ListServices.propTypes = {
  servicesArray: PropTypes.array,
  hideFilterComponent: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isHadUserLogin: state.getUserFromCookiesReducer.isHadAccount,
    adsData: state.adsReducer.data,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doPostQuote,
        doSetCurrentService,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Service_ListServices);