import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doLogin } from '../../redux/actions/authentication';
import {
  Form,
  Row,
  Col,
  Layout,
  Input,
  Checkbox,
  Button,
} from 'antd';
import 'antd/dist/antd.css';
import './Login.scss';
import {
  Link
} from 'react-router-dom';
import {
  routers
} from '../../config/routers';
import Cookies from 'universal-cookie';
import { getTranslatedText } from '../../services/appServices';

const LoginComponent = (props) => {

  const {
    actions
  } = props;

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');


  const onFinish = (values) => {
    console.log('value form', values);
    actions.doLogin(values.email, values.password);
  };

  // const handleOnChangeEmail = (e) => {
  //   setUserEmail(e);
  //   console.log(e);
  // };

  // const handleOnChangePassword = (e) => {

  // };

  const handleOnChangeRememberLogin = (e) => {
    let cookies = new Cookies();
    if (e.target.checked) {

    }
  }

  return (
    <React.Fragment>
      <div className="container black mg-b-60">
				<Row
          className='login_container'
        >
          <Col
            lg={8}
            xs={24}
          >
            <Layout
              className='layout_login'
            >
                <h1
                  className='layout_title'
                >
                  {getTranslatedText('login_title')}
                </h1>
                <p
                  className='layout_subtitle'
                >
                  {getTranslatedText('login_welcome_title')}
                </p>
              <Layout.Content>
                <Form
                  layout='vertical'
                  onFinish={(values) => onFinish(values)}
                  initialValues={{ remember: true }}
                >
                  <Row gutter={16}>
                    <Col
                      lg={16}
                      xs={16}
                      offset={4}
                    >
                      <Form.Item
                        name='email'
                      >
                        <Input
                          placeholder={getTranslatedText('label_email')}
                          // onChange={(e) => handleOnChangeEmail(e)}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      lg={16}
                      xs={16}
                      offset={4}
                    >
                      <Form.Item
                        name='password'
                      >
                        <Input.Password
                          placeholder={getTranslatedText('label_password')}
                          // onChange={(e) => handleOnChangePassword(e)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                  >
                    <Col
                      span={8}
                      offset={3}
                      className='footer_item'
                    >
                      <Form.Item name='remember' valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      offset={4}
                      className='footer_item'
                    >
                      <Link
                        to={routers.account.forget_password}
                      >
                        <p
                          className='forget_password_button'
                        >
                          {getTranslatedText('forget_password_title')}
                        </p>
                      </Link>
                    </Col>
                    <Col
                      lg={12}
                      xs={24}
                    >
                      <Form.Item>
                        <Button
                          className='login_button'
                          htmlType='submit'
                        >
                          {getTranslatedText('header_btn_login')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col
                      lg={12}
                      xs={24}
                    >
                      <Link
                        to={routers.account.sign_up}
                      >
                        <button
                          className='register_button'
                        >
                          {getTranslatedText('header_btn_sign_up')}
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </Form>
              </Layout.Content>
            </Layout>
          </Col>
          <Col
            lg={16}
            xs={0}
          >
            <img src={require('../../assets/cover_login.png')} alt='' />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doLogin,
      },
     dispatch,
    ),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(LoginComponent);