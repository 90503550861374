import { put } from 'redux-saga/effects';
import * as actionTypes from '../actions/action_types';

export default {
  getSettings: function* (action) {
    let setting_code = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', `v1/setting/code/${setting_code}`);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_SETTING_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_SETTING_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_SETTING_FAILURE,
        error: error
      })
    };
  },
}