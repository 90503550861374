import React from 'react';
import Login from '../../../components/Login/Login';

const LoginContainer = () => {

  return (
    <React.Fragment>
      <Login />
    </React.Fragment>
  );
};

export default LoginContainer;