import React, { useState } from 'react';
import {
  useHistory,
} from 'react-router-dom';
import {
  Row,
  Col,
  Button,
} from 'antd';

import { getTranslatedText } from '../../services/appServices';

import ContactInformationComponent from './component/ContactInformationComponent';
import InformationComponent from './component/InformationComponent';
import ProvideInfoComponent from './component/ProvideInfoComponent';

import './styles/profile-component-styles.less';
import { routers } from '../../config/routers';


function PartnerProfileComponent(props) {

  const {
    profileData,
  } = props;

  const history = useHistory();

  const [fieldDisable, setFieldDisable] = useState(true);
  const [companyFieldDisable, setCompanyFieldDisable] = useState(true);

  return (
    <React.Fragment>
      <Row id='customer-profile-wrapper' gutter={16}>
        <Col md={24} xs={24}>
          <h2 id='title-wrapper'>{getTranslatedText('dashboard').toUpperCase()}</h2>
        </Col>
        <Col md={24} xs={24}>
          <div id='profile-header-wrapper'>
            <div id='profile-account-type-wrapper'>
              <h4>{getTranslatedText('account_type_title').toUpperCase()}</h4>
              {profileData.personalData.account_type === 'freelancer' ? (
                <h3>{getTranslatedText('freelancer').toUpperCase()}</h3>
              ) : (
                <h3>{getTranslatedText('company_title').toUpperCase()}</h3>
              )}
            </div>
            <div id='provider-wrapper'> 
              <h4>{getTranslatedText('account_type_provider')}</h4>
              <h3>{getTranslatedText('service_provide').toUpperCase()}</h3>
            </div>
            <div id='profile-coin-wrapper'>
              <div id='coin-info-wrapper'>
                <h4>{getTranslatedText('current_coin').toUpperCase()}</h4>
                <p>{profileData.personalData.balance}</p>
              </div>
              <div id='coin-control-button-wrapper'>
                <Button
                  id='add-coin-btn'
                  onClick={() => history.push(routers.dashboard.payment)}
                >
                  {getTranslatedText('add_more_coin')}
                </Button>
                <Button
                  id='history-coin-btn'
                  onClick={() => history.push(routers.dashboard.wallet)}
                >
                  {getTranslatedText('history_payment')}
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col md={24} xs={24}>
          <div id='profile-form-wrapper'>
            <div id='profile-title-wrapper'>
              <h4>{getTranslatedText('contact_information').toUpperCase()}</h4>
              <Button
                onClick={() => setFieldDisable(!fieldDisable)}
              >
                {getTranslatedText('edit_profile_btn')}
              </Button>
            </div>
            <InformationComponent
              personalProfile={profileData.personalData}
            />
          </div>
        </Col>
        <Col md={24} xs={24}>
          <div id='company-form-wrapper'>
            <div id='company-title-wrapper'>
              <h4>{getTranslatedText('your_company_data').toUpperCase()}</h4>
              <Button
                onClick={() => setCompanyFieldDisable(!companyFieldDisable)}
              >
                {getTranslatedText('edit_profile_btn')}
              </Button>
            </div>
            <ContactInformationComponent
              companyProfile={profileData.companyData}
            />
          </div>
        </Col>
        <Col md={24} xs={24}>
          <ProvideInfoComponent
            attachment={profileData.personalData.attachment}
            personalProfile={profileData.personalData}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PartnerProfileComponent;