import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { doPostQuote, doGetDetailServices } from "../../redux/actions/services";
import { routers } from "../../config/routers";
import { Link, useHistory } from "react-router-dom";
import { getTranslatedText } from "../../services/appServices";
import { Helmet } from "react-helmet-async";
import env from "../../env";

const PartnerService = (props) => {
  const { isHadUserLogin, actions, match, accountType } = props;

  const history = useHistory();

  const { currentServiceDetail } = useSelector((state) => ({
    currentServiceDetail: state.serviceReducer.currentServiceDetail,
  }));

  useEffect(() => {
    console.log(match);
    actions.doGetDetailServices(match.params.service_id);
  }, []);

  const handleRequestEstimate = () => {
    if (
      accountType === "partner-product" ||
      accountType === "partner-service"
    ) {
      toast.warn(
        "Vui lòng đăng kí tài khoản khách hàng để thực hiện thao tác này"
      );
    } else {
      history.push(
        routers.services.service_request_partner_estimate.replace(
          ":service_id",
          currentServiceDetail.id
        )
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{currentServiceDetail?.title}</title>
        {/*<meta name="description" content={currentServiceDetail.summary}/>*/}
        {/* OpenGraph tags */}
        {/*<meta property="og:url"*/}
        {/*      content={encodeURI(`${env.frontend_url}/services/detail/${currentServiceDetail.id}`)}/>*/}
        <meta property="og:title" content={currentServiceDetail?.title} />
        <meta
          property="og:description"
          content={currentServiceDetail?.summary}
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/tainguyengkc/image/upload/v1606991548/b2b_logo_mmmm2g.png"
        />
        <meta
          property="og:image:alt"
          content="https://res.cloudinary.com/tainguyengkc/image/upload/v1606991548/b2b_logo_mmmm2g.png"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="container bg_white black">
        <div className="row mg-t-30">
          <h1 className="title_sec bg_green">{currentServiceDetail.title}</h1>
          <div className="col-12 single_service">
            <div className="content">
              <div className="left">
                <div className="table_2">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../../assets/service/avatar_2.png")}
                      alt=""
                    />
                    <h3 className="title fs-17">
                      {currentServiceDetail.short_name}
                    </h3>
                    <div className="rating mg-t-30">
                      <span className="item active"></span>
                      <span className="item active"></span>
                      <span className="item active"></span>
                      <span className="item active"></span>
                      <span className="item"></span>
                    </div>
                    <div>
                      <a
                        href=""
                        className="btn yellow_2 xs"
                        data-toggle="modal"
                        data-target="#modal_feedback"
                      >
                        Xem hồ sơ
                      </a>
                    </div>
                  </div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentServiceDetail.detail,
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className="category mg-t-15"
                >
                  <p>{getTranslatedText("service_category")}:</p>
                  <div style={{ paddingLeft: "36px" }}>
                    {currentServiceDetail.child_category_name ? (
                      <a href="" className="item">
                        {currentServiceDetail.child_category_name}
                      </a>
                    ) : (
                      <a href="" className="item">
                        {getTranslatedText("not_update")}
                      </a>
                    )}
                    {currentServiceDetail.category_name ? (
                      <a
                        style={{ marginLeft: "16px" }}
                        href=""
                        className="item"
                      >
                        {currentServiceDetail.category_name}
                      </a>
                    ) : (
                      <a style={{ marginLeft: "16px" }} className="item">
                        {getTranslatedText("not_update")}
                      </a>
                    )}
                  </div>
                  {/* <a href="" className="mg-l-15">Giám sát công trình</a> */}
                </div>
                {/* <!-- table_2 --> */}
              </div>
              <div className="right">
                <div className="_info">
                  <div className="_date mg-b-15">
                    {currentServiceDetail.created_at}
                  </div>
                  <div className="_location mg-b-15">
                    {currentServiceDetail.location}
                  </div>
                  <div className="_customer mg-b-15">
                    {getTranslatedText("estimated_number")}{" "}
                    {currentServiceDetail.customer}{" "}
                    {getTranslatedText("customers")}
                  </div>
                </div>
                <div className="pd-t-30 text-center align_center">
                  {/* <Link
										to={routers.services.service_request_partner_estimate.replace(":service_id", currentServiceDetail.id)}
									> */}
                  <button
                    onClick={() => handleRequestEstimate()}
                    className="btn green full shadow radius_50"
                  >
                    {getTranslatedText("request_estimate")}
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Send Quote --> */}
        </div>
      </div>
      <div className="container bg_white black mg-t-30 mg-b-40 pd-b-40">
        <div className="row">
          <div className="col col-12">
            <div className="notifi err">
              {getTranslatedText("hide_info_title")}
            </div>
          </div>
          <div className="col col-xs-12 col-sm-12 col-md-8 mg-b-15">
            <h3 className="title_sec bg_green">
              {getTranslatedText("our_contact_info")}
            </h3>
            <form action="" className="form_contact border-green">
              <div className="row">
                <div className="col">
                  <label for="product" className="">
                    {getTranslatedText("name")}
                  </label>
                  <input
                    type="text"
                    className="icon name"
                    placeholder="*********"
                  />
                </div>
                <div className="col mw-330 radius-0">
                  <label for="product" className="">
                    {getTranslatedText("label_email")}
                  </label>
                  <input
                    type="text"
                    className="icon email"
                    placeholder="*********"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label for="product" className="">
                    {getTranslatedText("label_address")}
                  </label>
                  <input
                    type="text"
                    className="icon pin"
                    placeholder="*********"
                  />
                </div>
                <div className="col mw-330 radius-0">
                  <label for="product" className="">
                    {getTranslatedText("label_city")}
                  </label>
                  <input
                    type="text"
                    className="icon pin"
                    placeholder="*********"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label for="product" className="">
                    {getTranslatedText("label_phone")}
                  </label>
                  <input
                    type="text"
                    className="icon phone"
                    placeholder="*********"
                  />
                </div>
                <div className="col mw-330 radius-0">
                  <label for="product" className="">
                    {getTranslatedText("company_website")}
                  </label>
                  <input
                    type="text"
                    className="icon web"
                    placeholder="*********"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col col-xs-12 col-sm-12 col-md-4 pd-lr-30">
            <b>{getTranslatedText("our_cefiticate")}</b>
            <div
              style={{ width: "390px", margin: "auto", marginTop: "50px" }}
              className="row flex_5 mg-t-15"
            >
              <div className="col">
                <img
                  style={{ maxWidth: "63px", height: "63px" }}
                  src={require("../../assets/icon/icon_camera.svg")}
                  alt="upload file"
                />
              </div>
              <div className="col">
                <img
                  style={{ maxWidth: "63px", height: "63px" }}
                  src={require("../../assets/icon/icon_camera.svg")}
                  alt="upload file"
                />
              </div>
              <div className="col">
                <img
                  style={{ maxWidth: "63px", height: "63px" }}
                  src={require("../../assets/icon/icon_camera.svg")}
                  alt="upload file"
                />
              </div>
              <div className="col">
                <img
                  style={{ maxWidth: "63px", height: "63px" }}
                  src={require("../../assets/icon/icon_camera.svg")}
                  alt="upload file"
                />
              </div>
              <div className="col">
                <img
                  style={{ maxWidth: "63px", height: "63px" }}
                  src={require("../../assets/icon/icon_camera.svg")}
                  alt="upload file"
                />
              </div>
            </div>
          </div>
          <div className="col col-xs-12 col-sm-12 col-md-8">
            <div className="mg-t-25">
              <label className="checkbox_cs ">
                {getTranslatedText("connect_checkbox")};
                <input type="checkbox" checked />
                <span className="checkmark"></span>
              </label>
              <div className="text-center mg-t-30 pd-b-10 ">
                {/* <Link
									to={routers.services.service_request_partner_estimate.replace(":service_id", currentServiceDetail.id)}
								> */}
                <button
                  className="btn green shadow radius_50 "
                  onClick={() => handleRequestEstimate()}
                >
                  {getTranslatedText("request_estimate").toUpperCase()}
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
          {/* <!-- end row --> */}
        </div>
      </div>

      {/* <!-- Popup feedback --> */}
      <div
        className="modal black fade "
        id="modal_feedback"
        tabindex="-1 "
        role="dialog "
        aria-labelledby="modal_feedback"
        aria-hidden="true "
      >
        <div
          className="modal-dialog modal-dialog-centered mw_1048 "
          role="document "
        >
          <div className="modal-content modal_feedback">
            <div className="left ">
              <div className="avatar">
                <img src={require("../../assets/service/avatar.png")} alt="" />
              </div>
              <div className="info">
                <div className="title">{currentServiceDetail.short_name}</div>
                {/*<div className="sub">{currentServiceDetail.title}</div>*/}
              </div>
              <div className="rating">
                <span className="item active"></span>
                <span className="item active"></span>
                <span className="item active"></span>
                <span className="item active"></span>
                <span className="item"></span>
                <span className="text">
                  1.200 {getTranslatedText("number_comment")}
                </span>
              </div>
              <div className="career_box">
                <p className="title">
                  {getTranslatedText("category_active_title")}
                </p>
                {currentServiceDetail.child_category_name ? (
                  <a href="" className="item">
                    {currentServiceDetail.child_category_name}
                  </a>
                ) : (
                  <a className="item">{getTranslatedText("not_update")}</a>
                )}
                {currentServiceDetail.category_name ? (
                  <a href="" className="item">
                    {currentServiceDetail.category_name}
                  </a>
                ) : (
                  <a className="item">{getTranslatedText("not_update")}</a>
                )}
              </div>
              <div className="content">
                <p>{currentServiceDetail.detail}</p>
              </div>

              <form className="review_form">
                <div className="title">{getTranslatedText("your_comment")}</div>
                <div className="rating review">
                  <span className="item"></span>
                  <span className="item"></span>
                  <span className="item"></span>
                  <span className="item"></span>
                  <span className="item"></span>
                </div>
                <div>
                  <button className="btn gray ">
                    {getTranslatedText("Đánh giá")}
                  </button>
                </div>
              </form>
            </div>
            {/* <!-- end left --> */}
            <div className="right ">
              <span className="close" data-dismiss="modal"></span>
              <div className="comment_box">
                <div className="top">
                  <span className="title">
                    {getTranslatedText("PHẢN HỒI Ý KIẾN")}
                  </span>
                  <a href="http://" className="share">
                    share
                  </a>
                </div>
                <form action="" className="">
                  <textarea
                    name="comment"
                    id=""
                    placeholder="Viết nhận xét của bạn tại đây..."
                  ></textarea>
                  <button className="btn yellow_2 small send pd-lr-20">
                    {getTranslatedText("GỬI")}
                  </button>
                </form>
                <div className="list_comment">
                  <div className="item">
                    <div className="img">
                      <img
                        src={require("../../assets/service/avt_small_1.png")}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <div>
                        <span className="name">Nguyễn Hoàng</span>
                        <span className="time">
                          1h {getTranslatedText("before_title")}
                        </span>
                      </div>
                      <div>
                        Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống
                        kịp thời, giúp chúng tôi giải quyết nhanh chóng công
                        việc.
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src={require("../../assets/service/avt_small_2.png")}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <div>
                        <span className="name">Phạm Thúy Vân</span>
                        <span className="time">
                          2 {getTranslatedText("day_title")}{" "}
                          {getTranslatedText("before_title")}
                        </span>
                      </div>
                      <div>
                        Cho tôi sự yên tâm trọn vẹn khi thực hiện công việc, và
                        mang đến những giá trị cao khi hợp tác giải quyết các
                        công việc với đơn vị chức năng.
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="img">
                      <img
                        src={require("../../assets/service/avatar.png")}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <div>
                        <span className="name">Nguyễn Hoàng</span>
                        <span className="time">
                          1h {getTranslatedText("before_title")}
                        </span>
                      </div>
                      <div>
                        Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống
                        kịp thời, giúp chúng tôi giải quyết nhanh chóng công
                        việc.
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End list cmt --> */}
              </div>
            </div>
            {/* <!-- End right --> */}
          </div>
        </div>
      </div>
      {/* <!-- End popup --> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isHadUserLogin: state.getUserFromCookiesReducer.isHadAccount,
    currentServiceId: state.serviceReducer.currentServiceId,
    accountType: state.getUserFromCookiesReducer.accountType,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doPostQuote,
        doGetDetailServices,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(PartnerService);
