import React from 'react';
import {getTranslatedText} from "../../services/appServices";

const BreadCrumb = () => {

	return (
		<React.Fragment>
			<div className="container breadcrumb">
				<div className="content">
					<a href="2.html" className="link">{getTranslatedText("service_title")}</a><span>>></span>
					<a href="" className="link">{getTranslatedText("btn_request_quotation")}</a>
				</div>
				<div className="post_product">
					<a href="#" className="btn blue new_post">{getTranslatedText("btn_post_new_service")}</a>
				</div>
			</div>
		</React.Fragment>
	);
};

export default BreadCrumb;