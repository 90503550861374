// Usage: This component display banner of contact page

import React from 'react';

import './ContactBanner.scss';

const ContactBanner = () => {

  return (
    <React.Fragment>
      <img className='img_banner' src={require('../../assets/contact_banner.png')} />
    </React.Fragment>
  );
};

export default ContactBanner;