// this javascript file contain function reducer to handle logic reducer for services table
import * as actionTypes from '../actions/action_types';
import {toast} from 'react-toastify';
import {routers} from '../../config/routers';
import {getTranslatedText} from "../../services/appServices";

const serviceInitialState = {
  isLoading: false,
  services: [],
  serviceId: 0,
  service: null,
  currentServiceId: null,
  currentServiceDetail: {},
};

export const serviceReducer = (state = serviceInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DETAIL_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_DETAIL_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentServiceDetail: action.payload
      };
    case actionTypes.GET_DETAIL_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_CURRENT_SERVICE:
      return {
        ...state,
        currentServiceId: action.payload
      };
    case actionTypes.POST_NEW_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.POST_NEW_SERVICE_SUCCESS:
      setTimeout(() => {
        window.location.pathname = routers.services.main;
      }, 2000);
      toast.success('Post new service success');
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.POST_NEW_SERVICE_FAILURE:
      toast.error(action.error.join(', '));
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_SERVICES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_SERVICES_SUCCESS:
      let data = action.payload;
      return {
        ...state,
        services: data,
        isLoading: false,
      };
    case actionTypes.GET_SERVICES_FAILURE:
      toast.error(action.error.join(', '));
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_SERVICE_WITH_FILTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_SERVICE_WITH_FILTER_SUCCESS:
      let serviceFilterArray = action.payload;
      return {
        ...state,
        isLoading: false,
        services: serviceFilterArray,
      }
    case actionTypes.GET_SERVICE_WITH_FILTER_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
};

const quoteState = {
  data: [],
  postQuoteSuccess: {},
  detailQuote: {},
};
export const quoteReducer = (state = quoteState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUOTE_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case actionTypes.GET_QUOTE_FAILURE:
      toast.error(action.error.toString());
      return {
        ...state
      }
    case actionTypes.GET_DETAIL_QUOTE_SUCCESS:
      return {
        ...state,
        detailQuote: action.payload
      }
    case actionTypes.GET_DETAIL_SERVICE_FAILURE:
      toast.error(action.error.data.errors);
      return state;
    case actionTypes.POST_QUOTE_SUCCESS:
      toast.success('Request quotation success!');
      setTimeout(() => {
        window.location.href = routers.home
      }, 500);
      return {
        ...state,
        postQuoteSuccess: action.payload
      }
    case actionTypes.POST_QUOTE_FAILURE:
      toast.error(action.error.data.errors.toString());
      return state;
    case actionTypes.PUT_QUOTATION_SUCCESS:
      toast.success('Request quotation is closed');
      setTimeout(() => {
        window.location.href = routers.dashboard.main
      }, 1000);
      return state;
    case actionTypes.PUT_QUOTATION_FAILURE:
      toast.error(action.error.data.errors.toString());
      return state;
    default:
      return state;
  }
};

const estimateState = {
  isLoading: false,
};

export const estimateReducer = (state = estimateState, action) => {
  switch (action.type) {
    case actionTypes.POST_ESTIMATE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.POST_ESTIMATE_SUCCESS:
      let data = action.payload;
      setTimeout(() =>{
        window.location.href = routers.services.main
      }, 1000);
      toast.success(getTranslatedText("post_estimate_success"));
      return {
        ...state,
        isLoading: false,
        data: data,
      }
      case actionTypes.POST_ESTIMATE_FAILURE:
        let error = action.error;
        toast.warn(error.join(','));
        setTimeout(() => {
          window.location = routers.account.payment
        }, 2000);
        return {
          ...state,
          isLoading: false,
          error: error,
        }
      default:
        return state;
  }
}

const packageState = {
  isLoading: false,
  packageList: [],
  dataSucces: {},
};

export const packageReducer = (state = packageState, action) => {
  switch (action.type) {
    case actionTypes.GET_PACKAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_PACKAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        packageList: action.payload
      }
    case actionTypes.GET_PACKAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case actionTypes.UPDATE_PACKAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.UPDATE_PACKAGE_SUCCESS:
      toast.success(getTranslatedText("purchase_success"));
      setTimeout(() => {
        window.open(action.payload);
      }, 1000);
      return {
        ...state,
        dataSuccess: action.payload,
        isLoading: false,
      }
    case actionTypes.UPDATE_PACKAGE_FAILURE:
        return {
          ...state,
          dataSuccess: action.error,
          isLoading: false,
        }
    default:
        return state;
  }
};

