import { put } from 'redux-saga/effects';
import * as actionTypes from '../actions/action_types';

export default {
  getNotification: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/notifications');
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_NOTIFICATION_SUCCESS,
            payload: data.data,
          });
        } else {
          yield put({
            type: actionTypes.GET_NOTIFICATION_FAILURE,
            error: data.errors
          });
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_NOTIFICATION_FAILURE,
        error: error
      });
    }
  },
  putNotification: function* (action) {
    let notification_id = action.payload.notification_id;
    try {
      let response = yield global.apiService.apiCall('put', `v1/notifications/${notification_id}`, action.payload);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.PUT_NOTIFICATION_SUCCESS,
            payload: data.data,
          });
        } else {
          yield put({
            type: actionTypes.PUT_NOTIFICATION_FAILURE,
            error: data.errors
          });
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.PUT_NOTIFICATION_FAILURE,
        error: error
      });
    }
  }
};