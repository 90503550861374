import * as actionTypes from '../actions/action_types';
import { put } from 'redux-saga/effects';


export default {
  getPayments: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/payment', payload);
      console.log('response', response);
      if (response.data.isSuccess) {
        let newService = response.data.data
        yield put({
          type: actionTypes.GET_PAYMENTS_SUCCESS,
          payload: newService,
        })
      } else {
        yield put({
          type: actionTypes.GET_PAYMENTS_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_PAYMENTS_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  },
  putPayments: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('put', `v1/payment/${payload.id}`, payload);
      console.log('response', response);
      if (response.data.isSuccess) {
        let newService = response.data.data
        yield put({
          type: actionTypes.PUT_PAYMENTS_SUCCESS,
          payload: newService,
        })
      } else {
        yield put({
          type: actionTypes.PUT_PAYMENTS_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.PUT_PAYMENTS_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  }
};