import * as actionTypes from '../actions/action_types';
import { toast } from 'react-toastify';
import { routers } from '../../config/routers';
import {getTranslatedText} from "../../services/appServices";

const urlResponseState = {
  isLoading: false,
  order: null,
  user_id: 0,
  order_name: '',
  order_address: '',
  order_city: '',
  order_phone: '',
  order_email: '',
  order_type: '',
  total: 0,
};

export const createOrderReducer = (state = urlResponseState, action) => {
  switch (action.type) {
    case actionTypes.POST_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.POST_ORDER_SUCCESS:
      toast.success(getTranslatedText("post_order_is_procceses"));
      // setTimeout(() => {
      //   window.location.href = routers.home
      // }, 3000);
      // console.log(action.payload)
      if (action.payload.payment_method === 'banking') {
        const url = action.payload.url
        window.open(url, '_blank');
        window.location.pathname = routers.home;
      } else {
        window.location.pathname = routers.home;
      }
      localStorage.removeItem('localStorageCart');
      return state;
    case actionTypes.POST_ORDER_FAILURE:
      return state;
    default:
      return state;
  }
};

const putOrderState = {
  isLoading: false,
  data: {}
}

export const putOrderReducer = (state = putOrderState, action) => {
  switch (action.type) {
    case actionTypes.PUT_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.PUT_ORDER_SUCCESS:
      // toast.success(getTranslatedText("post_response_order_success"));
      // setTimeout(() => {
      //   window.location.href = routers.home
      // }, 3000);
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case actionTypes.PUT_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state;
  }
}