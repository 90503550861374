import * as actionTypes from '../actions/action_types';
import { put } from 'redux-saga/effects';

export default {
  getUserProfile: function* (action) {
    let param = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/profile', param);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_USER_PROFILE_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_USER_PROFILE_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_USER_PROFILE_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  putUserProfile: function* (action) {
    let param = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', `v1/profile`, param, true);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.PUT_USER_PROFILE_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.PUT_USER_PROFILE_FAILURE,
            error: data.errors
          })
        }
      } else {
        yield put({
          type: actionTypes.PUT_USER_PROFILE_FAILURE,
          error: ['Something went wrong!']
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.PUT_USER_PROFILE_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getPartnerProfile: function* (action) {
    let partnerId = action.payload;
    const condition = {
      show: 1
    };
    try {
      let response = yield global.apiService.apiCall('get', `v1/get-profile-partner/${partnerId}`, condition);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_PARTNER_PROFILE_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_PARTNER_PROFILE_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_PARTNER_PROFILE_FAILURE,
        error: ['Something went wrong!']
      });
    }
  }
}