import React from 'react';

import CartRetail from '../../../components/Product_RetailCart/Product_DetailOrder';

const OrderRetailContainer = () => {

  return (
    <React.Fragment>
      <CartRetail />
    </React.Fragment>
  );
};

export default OrderRetailContainer;
