import {
  GET_USER_PROFILE_REQUEST,
  PUT_USER_PROFILE_REQUEST,
  GET_PARTNER_PROFILE_REQUEST,
} from './action_types';

export const doGetUserProfileAction = () => ({
  type: GET_USER_PROFILE_REQUEST,
});

export const doPutUserProfileAction = (userData) => ({
  type: PUT_USER_PROFILE_REQUEST,
  payload: userData,
});

export const getPartnerProfileAction = (partnerId) => ({
  type: GET_PARTNER_PROFILE_REQUEST,
  payload: partnerId,
});
