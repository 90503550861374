import React from 'react';

import RechargeCoin from '../../../components/Service_Desposit/ServiceDesposit';

const RechargeCoinContainer = () => {

  return (
    <React.Fragment>
      <RechargeCoin />
    </React.Fragment>
  );
};

export default RechargeCoinContainer;