import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doPutUserProfileAction,
} from '../../../redux/actions/dashboard';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
} from 'antd';
import { getTranslatedText } from '../../../services/appServices';

import { resource } from '../../../assets/js/resource';

function InformationComponent(props) {
  
  const {
    personalProfile,
    fieldDisable,
    setFieldDisable,
    actions,
  } = props;

  const [form] = Form.useForm();

  //SET INITIAL VALUE FOR FORM
  useEffect(() => {
    if (personalProfile) {
      form.setFieldsValue({
        email: personalProfile.email,
        name: personalProfile.name,
        phone: personalProfile.phone,
        address: personalProfile.address,
        city: personalProfile.city
      });
    };
  }, [personalProfile]);

  function handleOnInforFormFinish(values) {
    actions.doPutUserProfileAction(values);
    setFieldDisable(true);
  };

  return (
    <React.Fragment>
      <Form
        className='profile-form'
        layout='vertical'
        form={form}
        onFinish={(values) => handleOnInforFormFinish(values)}
      >
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <div id='shortname-wrapper'>
              <p id='shortname-label'>{getTranslatedText('your_id_account')}</p>
              <p id='shortname'>{personalProfile.short_name}</p>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('label_email')}
              name='email'
            >
              <Input disabled={fieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('label_change_password')}
              name='password'
            >
              <Input.Password disabled={fieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('label_name')}
              name='name'
            >
              <Input disabled={fieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
          <Form.Item
            label={getTranslatedText('label_phone')}
            name='phone'
          >
              <Input disabled={fieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label={getTranslatedText('label_address')}
              name='address'
            >
              <Input disabled={fieldDisable} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}
          >
            <Form.Item
              label={getTranslatedText('label_city')}
              name='city'
            >
              <Select
                disabled={fieldDisable}
                showSearch
              >
                {resource.country.map((country) => (
                  <Select.Option value={country.name}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item>
              <Button
                id='info-form-submit-btn'
                htmlType='submit'
                disabled={fieldDisable}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doPutUserProfileAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InformationComponent);