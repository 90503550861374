import React, {useState, useEffect} from 'react';
import {
  Table, Row, Col,
} from 'antd';

import { getTranslatedText } from '../../services/appServices';
import {bindActionCreators} from "redux";
import {doGetPayments} from "../../redux/actions/payment";
import {getUserFromCookieAction} from "../../redux/actions/authentication";
import {connect} from "react-redux";
import {getUserFromCookiesReducer} from "../../redux/reducers/authentication";
import {
  doGetUserProfileAction,
} from '../../redux/actions/dashboard'
import { Link } from 'react-router-dom';
import { routers } from '../../config/routers';

import './styles/dashboard-wallet-styles.less';

const Dashboard_Wallet = (props) => {

  const {
    actions,
    payments,
    user,
    isLoading,
  } = props;

  const [totalTransaction, setTotalTransaction] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalRecharged, setTotalRecharge] = useState(0);

  useEffect(() => {
    actions.doGetPayments();
    actions.doGetUserProfileAction();
  }, []);

  // effect calculate transaction general information
  useEffect(() => {
      sumTransactionSpecify(payments);
  }, [isLoading]);

  const sumTransactionSpecify = (payments) => {
    // console.log("user data", user);
    setTotalTransaction(payments.length); 
    let paid = 0;
    let recharged = 0;
    payments.forEach(payment => {
      if (payment.status === "accept"){
        if (payment.method == "paid")
          paid += parseFloat(payment.amount);
        else
          recharged += parseFloat(payment.amount);
      }
    });
    setTotalPaid(paid);
    setTotalRecharge(recharged);
  }

  const columns = [
    {
      title: getTranslatedText('created_at'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('payment_code'),
      dataIndex: "code",
      key: "code"
    },
    {
      title: getTranslatedText('exchange_content_column'),
      dataIndex: "action",
      key: "action"
    },
    {
      title: getTranslatedText('cost_column'),
      dataIndex: "amount",
      key: "amount"
    },
    {
      title: getTranslatedText('status_estimated_column'),
      dataIndex: "status",
      key: "status",
      render: (status => {
        let cls = "btn_cancel";
        let text = "Thất bại";
        if (status === "accept") {
          cls = "btn_post_estimate";
          text = "Thành công";
        }
        if (status === "waiting") {
          cls = "btn_waiting_estimate";
          text = "Đang chờ";
        }
        return (
        <React.Fragment>
          <button className={cls}>
            {text}
          </button>
        </React.Fragment>
      )
      })
    }
  ];
  //payments
  return (
    <>
      <div style={{ marginTop: '100px', marginBottom: '100px'}} className='container bg_white black'>
        <div className="row mg-t-30">
          <h1 className="title_sec bg_green">{getTranslatedText('wallet')}</h1>
          <div className="col-12 single_service">
            <Row gutter={16}>
              <Col
                md={16}
                xs={24}
              >
                <div className='history_data_container'>
                  <span>
                    <h1 className='data_number_display'>{totalTransaction}</h1>
                    <p className='data_number_label'>{getTranslatedText('total_exchange')}</p>
                  </span>
                  <span>
                    <h1 className='data_number_display'>{totalPaid}</h1>
                    <p className='data_number_label'>{getTranslatedText('total_payed_coin')}</p>
                  </span>
                  <span>
                    <h1 className='data_number_display'>{totalRecharged}</h1>
                    <p className='data_number_label'>{getTranslatedText('total_coin')}</p>
                  </span>
                </div>
              </Col>
              <Col
                md={8}
                xs={24}
              >
                <div className='coin_card'>
                  <div className='coin_row'>
                    <p>{getTranslatedText('current_coin')}</p>
                    <span className='coin_display'>
                      <p>{user.personalData.balance || 0}</p>
                    </span>
                  </div>
                  <div className='button_row'>
                    <Link
                      to={routers.dashboard.payment}
                    >
                      <button className='btn_add'>{getTranslatedText('add_more_coin')}</button>
                    </Link>
                    {/* <button className='btn_history'>{getTranslatedText('history_payment')}</button> */}
                  </div>
                </div>
              </Col>
              <Col
                md={24}
                xs={24}
              >
                <h3 className='table_title'>{getTranslatedText('history_exchange')}</h3>
                <div className='table_container'>
                  <Table
                    dataSource={payments}
                    columns={columns}
                  >
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    payments: state.getPaymentsReducer.data || [],
    user: state.userProfileReducer || {},
    isLoading: state.getPaymentsReducer.isLoading,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetPayments,
        getUserFromCookieAction,
        doGetUserProfileAction
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard_Wallet);