import React from 'react';
import {connect} from 'react-redux';
import Dashboard_EstimatedHistory from '../../../components/Dashboard_EstimatedHistory/Dashboard_EstimatedHistory';

const Profile_EstimatedHistory = (props) => {

  const {
    accountType,
  } = props;

  return (
    <React.Fragment>
      <Dashboard_EstimatedHistory
        accountType={accountType}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accountType: state.getUserFromCookiesReducer.accountType,
  }
}

export default connect(
  mapStateToProps,
  null,
)(Profile_EstimatedHistory);