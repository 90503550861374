import * as actionTypes from '../actions/action_types';

const currentLanguageState = {
  language: null,
  isLoading: true,
};

export const currentLanguageReducer = (state = currentLanguageState, action) => {
  switch(action.type) {
    case actionTypes.GET_CURRENT_LANGUAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_CURRENT_LANGUAGE_SUCCESS:
       return {
         ...state,
         language: action.payload,
         isLoading: false,
       }
    case actionTypes.SET_CURRENT_LANGUAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        lang: null,
      }
    case actionTypes.SET_CURRENT_LANGUAGE_SUCCESS:
       return {
         ...state,
         language: action.payload,
         isLoading: false,
       }
    default:
      return state;
  };
};