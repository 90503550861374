export const routers = {
	home: '/',
	search: '/search',
	contact: '/contact',
	products: {
		main: '/products',
		list: '/products/all/:category_id',
		detail_product: '/products/detail/:short_name/:category_id/:product_id',
		post_new_product: '/products/post/new',
	},
	account: {
		main: '/account',
		login: '/account/login',
		forget_password: '/account/forget-password',
		reset_password: '/account/reset-password',
		sign_up: '/account/sign-up',
		customer_regiser: '/account/sign-up/customer',
		partner_register_select: '/account/sign-up/partner',
		partner_register: '/account/sign-up/partner/new',
	},
	order: {
		cart: '/cart',
		cart_retail: '/cart/retail',
		cart_retail_checkout: '/cart/retail/checkout',
		cart_wholesale: '/cart/wholesale',
	},
	services: {
		main: '/services',
		filter_category: '/all/:category_id',
		post_new_services: '/services/post/new',
		post_new_job: '/services/post/job',
		service_partner_detail: '/services/detail/:service_id',
		service_request_partner_estimate: '/services/request-estimate/:service_id',
	},
	dashboard: {
		main: '/dashboard',
		send_quotation: '/dashboard/quotation/:quotation_id/:service_id',
		estimated_history: '/dashboard/estimated-history',
		request_estimate: '/dashboard/request-estimate',
		payment: '/dashboard/payment',
		wallet: '/dashboard/wallet',
		suggest_partner: '/dashboard/suggest-partner/:job_id',
		estimate_comfirm: '/dashboard/estimate-comfirm',
		view_estimate: '/dashboard/view-estimate/:quotation_id',
		view_order: '/dashboard/order',
		view_detail_order: '/dashboard/order/detail/:order_id',
		view_partner_profile: '/dashboard/contact/partner/:partner_id',
	},
	payment: {
		main: '/payment',
		success: '/success',
		failure: '/failure',
	},
	tutorial: {
		new_service: '/tutorial/new-service',
		new_product: '/tutorial/new-product',
		register: '/tutorial/register',
		request_quote: '/tutorial/request-quote',
	},
	about_us: '/introduce-us',
	documents: '/documents/:slug'
};