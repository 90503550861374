import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  doGetCategoryService,
  doGetChildrenCategoryService,
} from "../../redux/actions/category";
import {
  getServiceWithFilter,
  doGetServices,
} from '../../redux/actions/services';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import {
  routers
} from '../../config/routers';
import {
  Form,
  Select,
  Button,
  Row,
  Col,
} from 'antd';
import { getTranslatedText } from '../../services/appServices';
import {resource} from "../../assets/js/resource";

import './service-filter-new-styles.less';

function ServiceFilterNewComponent(props) {

  const {
    actions,
    parentServiceCategory,
    childrenServiceCategory,
    accountType,
    userCookie,
  } = props;

  const history = useHistory();

  const [selectButtonText, setSelectButtonText] = useState(getTranslatedText('dropdown_service_category'));
  const [categoryParentId, setCategoryParentId] = useState(null);
  const [bussinessCategory, setBussinessCategory] = useState(getTranslatedText('select_category'));
  const [locationFilter, setLocationFilter] = useState(getTranslatedText('location_select_title'));

  useEffect(() => {
    actions.doGetCategoryService();
  }, []);

  const handleOnFinish = (values) => {
    console.log(values);
    const filterValue = {
      category_id: values.category_id || 0,
      service_business_category: values.service_business_category || 0,
      location: values.location || " ",
    };
    if(filterValue) {
      actions.getServiceWithFilter(filterValue);
    } else {
      actions.doGetServices();
    }
    setSelectButtonText(getTranslatedText('dropdown_service_category'));
  };

  const handleOnSelectItem = (e) => {
    console.log(e);
    setCategoryParentId(e);
    actions.doGetChildrenCategoryService(e);
  };

  const handleOnResetButton = () => {
    setCategoryParentId(null);
    setSelectButtonText(getTranslatedText('dropdown_service_category'));
    setBussinessCategory(null);
    actions.doGetServices();
  };

  const goToPostNewService = () => {
    if (userCookie.isHadAccount) {
      history.push(routers.services.post_new_services);
    } else {
      history.push(routers.account.login);
    }
  };

  const goToPostNewJob = () => {
    if (userCookie.isHadAccount) {
      history.push(routers.services.post_new_job);
    } else {
      history.push(routers.account.login);
    }
  }

  return (
    <React.Fragment>
      <Form
        id='service-filter-form'
        layout='vertical'
        onFinish={(values) => handleOnFinish(values)}
      >
        <Row gutter={[16,16]}>
          <Col
            id='green-select-border-radius-wrapper'
            xs={12}
            md={8}
            lg={6}
            xl={8}
          >
            <Form.Item
              name='category_id'
            >
              <Select
                id='green-select-border-radius'
                onChange={(e) => handleOnSelectItem(e)}
                defaultValue={selectButtonText}
              >
                {parentServiceCategory && parentServiceCategory.map(item => (
                  <Select.Option
                    value={item.id}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={4} xs={12}>
            <Form.Item
              label={getTranslatedText('label_bussines')}
              name='service_business_category'
            >
              <Select
                defaultValue={bussinessCategory}
              >
                {childrenServiceCategory && childrenServiceCategory.map(item => (
                  <Select.Option
                    value={item.id}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={4} xs={12}>
            <Form.Item
              label={getTranslatedText('label_city')}
              name='location'
            >
              <Select
                defaultValue={locationFilter}
              >
                {resource.country.map(item => (
                  <Select.Option value={item.name}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={2} xs={12}>
            <Form.Item
              label=' '
            >
              <Button
                htmlType='submit'
                id='btn-search'
              >
                {getTranslatedText('btn_find_now')}
              </Button>
            </Form.Item>
          </Col>
          <Col
            xs={12}
            md={2}
            lg={4}
            xl={2}
          >
            <Form.Item
             label=' '
            >
              <Button
                id='reset-filter-btn'
                onClick={() => handleOnResetButton()}
              >
                {getTranslatedText('reset_filter')}
              </Button>
            </Form.Item>
          </Col>
          { accountType === 'customer' ? (
            <Col
              xs={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Form.Item
                label=' '
              >
                <Button
                  id='btn-post-product'
                  onClick={() => goToPostNewJob()}
                >
                  {getTranslatedText('btn_request_quotation')}
                </Button>
              </Form.Item>
            </Col>
          ) : (
            <Col
              xs={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Form.Item
                label=' '
              >
                <Button
                  id='btn-post-product'
                  onClick={() => goToPostNewService()}
                >
                  {getTranslatedText('btn_post_new_service')}
                </Button>
              </Form.Item>
            </Col>  
          )}
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isHadUserLogin: state.getUserFromCookiesReducer.isHadAccount,
    parentServiceCategory: state.serviceCategoryReducer.parentServiceCategory,
    childrenServiceCategory: state.serviceCategoryReducer.childrenServiceCategory,
    accountType: state.getUserFromCookiesReducer.accountType,
    userCookie: state.getUserFromCookiesReducer,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetCategoryService,
        getServiceWithFilter,
        doGetChildrenCategoryService,
        doGetServices,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceFilterNewComponent);