import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doGetCategoryService,
	doGetLocation,
	doGetChildrenCategoryService
} from '../../redux/actions/category';
import {
	doPostNewService,
	doPostQuote,
} from '../../redux/actions/services';
import {
	Form,
	Select,
	Button,
} from 'antd';
import 'antd/dist/antd.css';
import CreatableSelect from 'react-select/creatable';
import './style.scss';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import Cookies from 'universal-cookie';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {resource} from "../../assets/js/resource";
import {getTranslatedText} from "../../services/appServices";

// border-green
const ClientServiceRequest = (props) => {

	const [userId, setUserId] = useState(null);


	const {
		actions,
		serviceCategory,
	} = props;

	useEffect(() => {
    let cookie = new Cookies();
		let user = cookie.get('_user');
		console.log(user);
    setUserId(user.id);
    actions.doGetCategoryService();
		actions.doGetLocation();
	}, []);

	// RENDER SELECT CATEGORY SERVICE
	const parentCategoryOption = () => {
		const parentCategoryOptionArray = [];
		serviceCategory.parentServiceCategory.forEach(service => {
			parentCategoryOptionArray.push(
				<Select.Option
					value={service.id}
				>
					{service.name}
				</Select.Option>
			)
		});
		return parentCategoryOptionArray;
	}

	const childrenCategoryOption = () => {
		const childrenCategoryOptionArray = [];
		serviceCategory.childrenServiceCategory.forEach(service => {
			childrenCategoryOptionArray.push(
				<Select.Option
					value={service.id}
				>
					{service.name}
				</Select.Option>
			)
		});
		return childrenCategoryOptionArray;
	}
	//////////////////END/////////////////////////////

	const handleParentCategorySelectChange = (value) => {
		actions.doGetChildrenCategoryService(value);
	}

	const handleSkillValue = (skillValues) => {
		const skillArray = [];
		if(skillValues) {
			skillValues.forEach(item => {
				const skill = item.value;
				skillArray.push(skill);
			});
			return skillArray;
		} else {
			return skillArray;
		}
	}
	
	const handleOnPostServiceFinish = (values) => {
		console.log('new service', values);
		const skillArray = handleSkillValue(values.skill);
    const newService = {
      title: values.title,
      description: values.detail,
      request_info: values.detail,
      location: values.location || 'VIET NAM',
			category_id: values.category_id ?? values.parent_category_id,
			skill: skillArray,
			is_official: 1,
		};
		console.log(newService);
    actions.doPostQuote(newService);
  }

	return (
		<React.Fragment>
			<BreadCrumb />
			<div className="container black mg-b-60">
				<div className="row justify-content-md-center">
					<div className="col col-xs-12 col-sm-12 col-md-8 col-lg-8 bg_white content_col_8">
						<div className="_box">
							<h1 className="title full center">{getTranslatedText("btn_request_quotation").toUpperCase()}</h1>
							<p className="text-center">{getTranslatedText("what_need_service")}</p>
							<Form
								className="row row_form border-green"
								layout='vertical'
								onFinish={(values) => handleOnPostServiceFinish(values)}
							>
								<div className="col  radius-0">
									<Form.Item
										label={getTranslatedText("select_your_service")}
										name='parent_category_id'
									>
										<Select
											className='form_item_select'
											bordered={false}
											onChange={(value) => handleParentCategorySelectChange(value)}
                  	>
                    {parentCategoryOption()}
                  	</Select>
									</Form.Item>
								</div>
								<div className="col mw-330 radius-0">
									<Form.Item
										label={getTranslatedText("select_job_title")}
										name='category_id'
									>
										<Select
                    	className='form_item_select'
                    	bordered={false}
                  	>
                    {childrenCategoryOption()}
                  	</Select>
									</Form.Item>
								</div>
								<div className="col-12">
									{/* <label for="product" className="">Tiêu đề cần báo giá</label> */}
									<Form.Item
										name='title'
										label={getTranslatedText("request_title")}
									>
										<input type="text" className="form-control"/>
									</Form.Item>
								</div>
								<div className="col-12">
									{/* <label for="product" className="">Hãy mô tả chi tiết về dự án của bạn</label> */}
									<Form.Item
										name='detail'
										label={getTranslatedText("request_content")}
									>
										<SunEditor
											name="my-editor" 
											enableToolbar={true}
											height='700'
											setOptions={{
												imageFileInput: true,
												buttonList: [
													['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
													['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
													['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
													['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
												]
											}}
											onFocus={(event) => {console.log(event)}}
										/>
									</Form.Item>
								</div>
								<div className="col-12">
									<p className="mg-b-10">{getTranslatedText("skill_need")}</p>
									<Form.Item
										name='skill'
									>
										<CreatableSelect
											isMulti
										/>
									</Form.Item>
								</div>
								<div className="col-12 mw-330 radius-0">
									{/* <label for="product" className="">Địa điểm làm việc</label> */}
									<Form.Item
										label={getTranslatedText("address_job")}
										name='location'
									>
										<Select defaultValue="VIET NAM" className="form-control">
											{resource.country.map(item => (
												<Select.Option value={item.name}>{item.name}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</div>
								<div className="col-12">
									<label className="checkbox_cs">{getTranslatedText("confirm_request_quote")}
										<input type="checkbox" />
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="col-12 mg-t-10 mg-b-40">
									<Button
										className="btn green shadow"
										htmlType='submit'
									>
										{getTranslatedText("send_request_now")}
									</Button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => {
	return {
		serviceCategory: state.serviceCategoryReducer,
		location: state.serviceCategoryReducer.location,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
        doGetCategoryService,
        doPostNewService,
				doGetLocation,
				doPostQuote,
				doGetChildrenCategoryService
			},
			dispatch,
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ClientServiceRequest);