import React from 'react';
import { connect } from 'react-redux';

import ProductComponent from '../../components/Product/ProductComponent';
import Service_ListServices from '../../components/Service_ListServices/Service_ListServices';

const SearchPage = (props) => {

  const {
    productsArray,
    servicesArray,
  } = props;

  return (
    <React.Fragment>
      <ProductComponent
        hideFilterComponent={true}
        productsArray={productsArray}
      />
      <Service_ListServices
        hideFilterComponent={true}
        servicesArray={servicesArray}
      />
    </React.Fragment>
  );
};

const mapStateToProp = (state) => {
  return {
    productsArray: state.searchReducer.products,
    servicesArray: state.searchReducer.services,
  }
}

export default connect(
  mapStateToProp,
  null,
)(SearchPage);