import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  doUpdateQuotation,
} from '../../redux/actions/services';
import {
  Link,
} from 'react-router-dom';
import {
  Row,
  Col,
  Table,
} from 'antd';

import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

import './styles/table-custom-styles.less';

function PartnerOrderedServices(props) {

  const {
    actions,
    quoteData,
  } = props;

  function handleOnDenyClick(record) {
    const payload = {
      id: record.id,
      status: 'denied'
    };
    actions.doUpdateQuotation(payload);
  };

  const columns = [
    {
      title: getTranslatedText('content_column'),
      dataIndex: "service_name",
      key: "service_name"
    },
    {
      title: getTranslatedText('customer_id'),
      dataIndex: 'user_request_code',
      key: 'user_request_code'
    },
    {
      title: getTranslatedText('date_post'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('status_estimated_column'),
      key: "status",
      render: (record => {
        let cls = 'btn_post_estimate';
        let text = getTranslatedText('is_being_considered');
        if (record.status === 'waiting') {
          return (
            <React.Fragment>
              <Link
                to={routers.dashboard.send_quotation.replace(':quotation_id', record.id).replace(':service_id', record.service_id)}
              >
                <button
                  // onClick={() => handleAcceptButtonClick(record)}
                  className='btn_post_estimate'
                >
                  {getTranslatedText('send_estimate_now')}
                </button>
              </Link>
              <button
                className='btn_cancel'
                onClick={() => handleOnDenyClick(record)}
              >
                {getTranslatedText('btn_ignore')}
              </button>
            </React.Fragment>
          )
        }
        if (record.status === 'accept') {
          return (
            <React.Fragment>
                <button className={cls}>
                  {text}
                </button>
            </React.Fragment>
          );
        }                
      }),
    }
  ]

  return (
    <React.Fragment>
      <Row gutter={16} id='partner-ordered-service-wrapper'>
        <Col md={24} xs={24}>
          <h3>{getTranslatedText('quotation_waiting')}</h3>
        </Col>
        <Col md={24} xs={24}>
          <Table
            className='custom-table'
            columns={columns}
            dataSource={quoteData}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    quoteData: state.quoteReducer.data,
    orderArrayData: state.getOrderReducer.data,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doUpdateQuotation,
      },
      dispatch
    )
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnerOrderedServices);