import React from 'react';
import ProductCardComponent from '../ProductCardComponent/ProductCardComponent';
import {
  getTranslatedText,
} from '../../services/appServices';

const ProductFeature = (props) => {

  const {
    productsArray,
    isHot
  } = props;

  const renderHotProduct = (product) => {
    if (product.is_hot === 1) {
      return (
        <ProductCardComponent
          id={product.id}
          unit={product.unit}
          unit_price={product.unit_price}
          short_name={product.short_name}
          category_id={product.category_id}
          avatar={product.avatar}
          name={product.name}
          price={product.price}
          market_price={product.market_price}
        />
      )
    } else {
      return null;
    };
  };

  return (
    <React.Fragment>
      {isHot ? (
        <React.Fragment>
          <div className="list_product">
            {productsArray.map((product) => (
              renderHotProduct(product)
            ))}
          </div>
        </React.Fragment>
      ) : (
          <React.Fragment>
            {productsArray.length !== 0 ? (
              <div className="list_product">
                {productsArray.map((product) => (
                  <ProductCardComponent
                    id={product.id}
                    unit={product.unit}
                    unit_price={product.unit_price}
                    short_name={product.short_name}
                    category_id={product.category_id}
                    avatar={product.avatar}
                    name={product.name}
                    price={product.price}
                    market_price={product.market_price}
                  />
                ))
                }
              </div>
            ) : (
                <React.Fragment>
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: '#CCD874',
                        width: '240px',
                        height: '120px',
                        borderRadius: '12px',
                        color: '#012509',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        textAlign: 'center',
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {getTranslatedText('no_filter_product')}
                    </p>
                  </div>
                </React.Fragment>
              )}
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default ProductFeature;