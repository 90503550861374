import {
  GET_CURRENT_LANGUAGE_REQUEST,
  SET_CURRENT_LANGUAGE_REQUEST
} from './action_types';

export const getCurrentLanguage = () => ({
  type: GET_CURRENT_LANGUAGE_REQUEST,
});

export const setCurrentLanguage = (language) => ({
  type: SET_CURRENT_LANGUAGE_REQUEST,
  payload: language,
});