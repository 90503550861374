// this javascript file contain function action to handle logic action for services table
import {
  POST_NEW_SERVICE_REQUEST,
  GET_SERVICES_REQUEST,
  GET_QUOTE_REQUEST,
  POST_ESTIMATE_REQUEST,
  POST_QUOTE_REQUEST,
  GET_PACKAGE_REQUEST,
  UPDATE_PACKAGE_REQUEST,
  SET_CURRENT_SERVICE,
  GET_DETAIL_SERVICE_REQUEST,
  GET_SERVICE_WITH_FILTER_REQUEST,
  GET_DETAIL_QUOTE_REQUEST,
  PUT_QUOTATION_REQUEST,
} from './action_types'

export const doPostNewService = serviceValues => ({
  type: POST_NEW_SERVICE_REQUEST,
  payload: serviceValues
});

export const doGetDetailServices = serviceId => ({
  type: GET_DETAIL_SERVICE_REQUEST,
  payload: serviceId,
});

export const doGetServices = serviceValues => ({
  type: GET_SERVICES_REQUEST,
  payload: serviceValues
});

export const doGetQuote = (arg) => ({
  type: GET_QUOTE_REQUEST,
  payload: arg,
});

export const doGetDetailQuote = (quoteId) => ({
  type: GET_DETAIL_QUOTE_REQUEST,
  payload: quoteId,
}) 

export const doPostEstimate = (estimateData) => ({
  type: POST_ESTIMATE_REQUEST,
  payload: estimateData
});

export const doPostQuote = (requestQuoteData) => ({
  type: POST_QUOTE_REQUEST,
  payload: requestQuoteData
});

export const doGetPackagePayment = () => ({
  type: GET_PACKAGE_REQUEST,
});

export const doUpdatePackage = (packageId, amount, action, online) => ({
  type: UPDATE_PACKAGE_REQUEST,
  payload: {
    package_id: packageId,
    amount: amount,
    action: action,
    method: online
  }
});

export const doSetCurrentService = (service_id) => ({
  type: SET_CURRENT_SERVICE,
  payload: service_id
});

export const getServiceWithFilter = (filterValue) => ({
  type: GET_SERVICE_WITH_FILTER_REQUEST,
  payload: {
    category_id: filterValue.category_id,
    service_business_category: filterValue.service_business_category,
    location: filterValue.location,
  }
});

export const doUpdateQuotation = (payload) => ({
  type: PUT_QUOTATION_REQUEST,
  payload: payload,
});