import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  doPostEstimate,
  doGetDetailServices,
  doGetDetailQuote
} from '../../redux/actions/services';
import {
  doGetSetting,
} from '../../redux/actions/settings';
import {
  Layout,
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
} from 'antd';
import 'antd/dist/antd.css';
import PropTypes from 'prop-types';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

import './styles/partner-post-estimate-styles.less';

const PostEstimate = (props) => {

  const {
    userId,
    balance,
    service_id,
    quotation_id,
    currentServiceDetail,
    currentQuoteDetail,
    actions,
    setting_code,
  } = props;

  useEffect(() => {
    actions.doGetSetting('cost_send_estimate');
    if(service_id === null) {
      actions.doGetDetailServices(service_id);
    } else {
      actions.doGetDetailQuote(quotation_id);
    }
  }, []);

  const handleEstimateFormFinish = (values) => {
    const cost = parseFloat(values.cost);
    const estimateData = {
      user_id: userId,
      cost: cost,
      detail: values.detail,
      quotation_id: parseInt(quotation_id),
      service_id: parseInt(service_id),
      currency: values.price_unit_selection
    };
    actions.doPostEstimate(estimateData);
  }
  
  return (
    <React.Fragment>
      <div className="container black mg-b-60">
				<div className="row">
					<div className="col-12 single_service">
						<h1 className="title">{currentServiceDetail.title || currentQuoteDetail.title}</h1>
						<div className="content">
							<div className="left">
                <div dangerouslySetInnerHTML={{__html: currentServiceDetail.detail || currentQuoteDetail.request_info}}></div>
								{/* <p>{currentServiceDetail.detail || currentQuoteDetail.request_info}</p> */}
							</div>
							<div className="right">
								<div className="_info">
									<div className="_date mg-b-15">{currentServiceDetail.created_at || currentQuoteDetail.created_at}</div>
									<div className="_location mg-b-15">{currentServiceDetail.location || currentQuoteDetail.location}</div>
									{/* <div className="_customer mg-b-15">2 đối tác chào giá</div> */}
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Send Quote --> */}
					<div className="col-12 bg_white mg-t-40">
            <Layout
              className='layout_section'
            >
              <h1 className="title">{getTranslatedText('infor_estimated_title')}</h1>
              <Layout.Content
                className='content_form_container'
              >
                <Form
                  layout='vertical'
                  onFinish={(values) => handleEstimateFormFinish(values)}
                >
                  <Row>
                    <Col
                      span={24}
                    >
                        <Row
                          gutter={16}
                          className='row_container_give_price'
                        >
                          <Col
                            span={8}
                            className='row_container_give_price_label'
                          >
                            {getTranslatedText('budget')}
                          </Col>
                          <Col
                            span={8}
                          >
                            <Form.Item
                              name='cost'
                            >
                              <Input
                                className='price_input'
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={6}
                          >
                            <Form.Item
                             name='price_unit_selection'
                            >
                              <Select
                                className='price_unit_selection'
                                bordered={false}
                              >
                                <Select.Option value='vnd'>
                                  VND
                                </Select.Option>
                                <Select.Option value='usd'>
                                  USD
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                    </Col>
                    <Col
                      id='fee-title-wrapper'
                      md={16}
                      xs={24}
                    >
                      <p>{getTranslatedText('service_send_estimate_fee')}</p>
                      <p>{setting_code.value} XU</p>
                    </Col>
                    <Col
                      md={16}
                      xs={24}
                    >
                      <Form.Item
                        label={getTranslatedText('bonus_info_mess')}
                        name='detail'
                      >
                        <Input.TextArea
                          className='information_project_input_area'
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      md={8}
                      xs={24}
                    >
                      <span
                        className='fee_container information_project_tips'
                      >
                        <p className='fee_heading'>{getTranslatedText('current_xu_title')}</p>
                        <span className='circle_green_coin'>{balance}</span>
                        {balance < setting_code.value ? (
                          <React.Fragment>
                            <div className='fee_footer_notify'>
                              <img src={require('../../assets/icon/warn_icon.png')} />
                              <p>{getTranslatedText('coin_not_enoungh')}</p>
                            </div>
                            <Link to={routers.account.payment}>
                              <button className='btn_add_coin_fee'>
                                {getTranslatedText('charge_now')}
                              </button>
                            </Link>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </span>
                    </Col>
                    <Col
                      md={16}
                      xs={24}
                    >
                        <label className="text_cs">
                            {getTranslatedText('connect_checkbox')}
                            <Form.Item>
                              <input type="checkbox" checked />
                            </Form.Item>
                            {/* <span className="checkmark"></span> */}
                        </label>
                    </Col>
                    <Col
                      md={4}
                      xs={12}
                    >
                      <Form.Item>
                        <Button
                          className='comfirm_button'
                          htmlType='submit'
                        >
                          {getTranslatedText('send_estimate_now')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col
                      md={4}
                      xs={12}
                    >
                      <Button
                        className='cancel_button'
                      >
                        {getTranslatedText('btn_ignore').toUpperCase()}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Layout.Content>
            </Layout>
					</div>
				</div>
			</div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentServiceDetail: state.serviceReducer.currentServiceDetail,
    currentQuoteDetail: state.quoteReducer.detailQuote,
    setting_code: state.settingReducer.settings,
  }
}

const mapDipatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doPostEstimate,
        doGetDetailServices,
        doGetDetailQuote,
        doGetSetting,
      },
      dispatch
    )
  };
};

PostEstimate.propTypes = {
  userId: PropTypes.number,
  balance: PropTypes.number,
  service_id: PropTypes.string,
  quotation_id: PropTypes.string,
};

export default
  connect(
  mapStateToProps,
  mapDipatchToProps,
  )
(PostEstimate);