import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentLanguage, setCurrentLanguage } from '../../redux/actions/lang';
import { useHistory, } from 'react-router-dom';

function LanguageContainer(props) {

  const {
		currentLanguage,
    actions,
    isLoading,
    getCurrentLanguageFrom,
  } = props;

  const history = useHistory();

  useEffect(() => {
    actions.getCurrentLanguage();
  }, []);

  useEffect(() => {
    const param = new URLSearchParams();
    const search = history.location.search;
    if (search) {
      const urlLanguage = search.split('=')[1];
      if (urlLanguage === 'null') {
        param.append('lang', 'vi');
        history.push({search: param.toString()});
        actions.setCurrentLanguage('vi');
      } else {
        param.append('lang', urlLanguage);
        history.push({search: param.toString()});
        actions.setCurrentLanguage(urlLanguage);
      }

    } else {
      param.append('lang', currentLanguage);
      history.push({search: param.toString()});
      actions.setCurrentLanguage(currentLanguage);
    };
  }, [currentLanguage, history.location.pathname]);

  return (
    <React.Fragment />
  );
};

const mapStateToProps = (state) => {
	return {
		currentLanguage: state.currentLanguageReducer.language,
    isLoading: state.currentLanguageReducer.isLoading,
    getCurrentLanguageFrom: state.currentLanguageReducer.getCurrentLanguageFrom,
}
};

const mapDipatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getCurrentLanguage,
        setCurrentLanguage,
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps,
)(LanguageContainer);