import React, {useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Link,
} from 'react-router-dom';
import {
  routers,
} from '../../config/routers'
import {
  Row,
  Col,
  Radio,
} from 'antd';
import 'antd/dist/antd.css';
import '../Account_RegisterOption/AccountRegisterOption.scss';
import {
  selectPartnerRegister,
  selectSaleType
} from '../../redux/actions/authentication';
import { getTranslatedText } from '../../services/appServices';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AccountPartnerRegisterOption.scss';

const PartnerRegisterOption = (props) => {

  const [providerRadioDisable, setProviderRadioDisable] = useState(false);
  const [partnerType, setPartnerType] = useState('');
  const [disabled, setDisabled] = useState(true);

  const {
    registerOption,
    actions
  } = props;

  const handlePartnerTypeRadioOptionOnChange = (e) => {
    if (e.target.value == 'freelancer') {
      setProviderRadioDisable(true);
    } else {
      setProviderRadioDisable(false);
    }
    setPartnerType(e.target.value);
  };

  const handlePartnerProviderRadioOptionOnChange = (e) => {
    if (partnerType == 'partner') {
      const accountPartnerType = partnerType + '-' + e.target.value;
      actions.selectPartnerRegister(accountPartnerType);
    } else {
      actions.selectPartnerRegister(partnerType);
    }
    if (e.target.value === 'product') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSaleTypeRadioOptionOnChange = (e) => {
    actions.selectSaleType(e.target.value);
  }

  return (
    <React.Fragment>
      <div className="register_partner container mg-b-60 ">
				<div className="row">
					<div className="name_page">
						<h3>{getTranslatedText('account_type_for')}</h3>
					</div>
          <Row>
            <Col
              span={24}
              className='heading_container'
            >
              <h3>{getTranslatedText('new_register')}</h3>
              <p>{getTranslatedText('welcome_partner')}</p>
            </Col>
            <Col
              lg={12}
              xs={24}
            >
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <p>{getTranslatedText('account_type_select_title')}</p>
                  <Radio.Group
                    style={{
                      display: 'flex'
                    }}
                    onChange={(e) => handlePartnerTypeRadioOptionOnChange(e)}
                  >
                    <div className="custom-control custom-checkbox mb-3">
                      <Radio value='freelancer'>{getTranslatedText('freelancer')}</Radio>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Radio value='partner'>{getTranslatedText('company_title')}</Radio>
                    </div>
                  </Radio.Group>
              </div>
            </Col>
            <Col
              lg={12}
              xs={24}
            >
              <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <p>{getTranslatedText('what_provider')}</p>
                  <Radio.Group
                    style={{
                      display: 'flex'
                    }}
                    onChange={(e) => handlePartnerProviderRadioOptionOnChange(e)}
                  >
                    <div className="custom-control custom-checkbox mb-3">
                      <Radio value='service'>{getTranslatedText('service_provide')}</Radio>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Radio value='product' disabled={providerRadioDisable}>{getTranslatedText('company_product_title')}</Radio>
                    </div>
                  </Radio.Group>
              </div>
            </Col>
            <Col
              lg={24}
              xs={24}
            >
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <p>{getTranslatedText('select_sale_type_title')}</p>
                  <Radio.Group
                    style={{
                      display: 'flex'
                    }}
                    onChange={(e) => handleSaleTypeRadioOptionOnChange(e)}
                  >
                    <div className="custom-control custom-checkbox mb-3">
                      <Radio disabled={disabled} value='sale'>{getTranslatedText('each_sale')}</Radio>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Radio disabled={disabled} value='wholeSale'>{getTranslatedText('whole_sale')}</Radio>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <Radio disabled={disabled} value='saleAndWholeSale'>{getTranslatedText('both_sale')}</Radio>
                    </div>
                  </Radio.Group>
              </div>
            </Col>
            <Col
              span={24}
            >
              <Link
                to={routers.account.partner_register}
              >
                <button
                  style={{
                    marginBottom: '58px',
                  }}
                  className='next_button'
                >
                  {getTranslatedText('btn_next')}
                </button>
              </Link>
            </Col>
          </Row>
				</div>
			</div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    registerOption: state.registerOptionReducer.registerOption,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        selectPartnerRegister,
        selectSaleType
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnerRegisterOption);