// The index root route Product
import React from 'react';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';


import ProductPage from './ProductHome/ProductPage';
import ProductDetail from '../../components/Product_ProductSingle/Product_ProductSingle';
import NewProductContainer from './NewProductContainer/NewProductContainer';
import ProductListContainer from './ProductListContainer/ProductListContainer';

const ProductContainerIndex = (props) => {

  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={match.url}
        >
          <ProductPage />
        </Route>
        <Route
          path={`${match.url}/all`}
          component={ProductListContainer}
        />
        <Route
          path={`${match.url}/detail`}
          component={ProductDetail}
        />
        <Route
          path={`${match.url}/post/new`}
          component={NewProductContainer}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userCookie: state.getUserFromCookiesReducer,
  }
};


export default connect(
  mapStateToProps,
  null,
)(ProductContainerIndex);