import React from 'react';
import Dashboard_Wallet from '../../../components/Dashboard_Wallet/Dashboard_Wallet';


const Wallet = () => {

  return (
    <React.Fragment>
      <Dashboard_Wallet />
    </React.Fragment>
  );
};

export default Wallet;