import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	doLogout,
} from '../../redux/actions/authentication';
import {
	getCurrentLanguage,
	setCurrentLanguage,
} from '../../redux/actions/lang';
import {
	getNotificationAction,
	doPutNotification,
} from '../../redux/actions/notification';
import {
  Link,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  Avatar,
  Dropdown,
  Row,
  Col,
  Button,
  Drawer,
  Badge
} from 'antd';
import {
  SearchOutlined,
  MenuOutlined,
  DownOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { getTranslatedText } from '../../services/appServices';

import logo from '../../assets/logo.png';

import './header-styles.less';

import MobileNavigation from './component/MobileNavigation';
import LanguagueDropdown from './component/LanguageDropdown';
import AccountDropDownMenu from './component/AccountDropDownMenuComponent';
import NotificationComponent from './component/NotificationComponent';
import { routers } from '../../config/routers';


function Header(props) {

  const {
		actions,
		isHadAccountCookie,
		shortName,
		accountType,
		currentLanguage,
		notification
  } = props;
  
  const location = useLocation();
  const history = useHistory();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [home_active, setHomeActive] = useState('home_active');
  const [products_active, setProductsActive] = useState('');
  const [services_active, setServicesActive] = useState('');
  const [contact_active, setContactActive] = useState('');

  // CALL API
  useEffect(() => {
    actions.getCurrentLanguage();
    actions.getNotificationAction();
  }, []);

  // SUM NOTIFICATION
  useEffect(() => {
    let totalNotifcations = 0;
      notification.forEach(item => {
        totalNotifcations += 1;
      });
      setBadgeCount(totalNotifcations);
  }, [notification]);

  useEffect(() => {
    switch(location.pathname) {
      case '/':
        setHomeActive('home_active');
        setProductsActive('');
        setServicesActive('');
        setContactActive('');
        break;
      case '/products':
        setHomeActive('');
        setProductsActive('products_active');
        setServicesActive('');
        setContactActive('');
        break;
      case '/services':
        setHomeActive('');
        setProductsActive('');
        setServicesActive('services_active');
        setContactActive('');
        break;
      case '/contact':
        setHomeActive('');
        setProductsActive('');
        setServicesActive('');
        setContactActive('contact_active');
        break;
      default:
        break;
    }
  }, [location])

  function handleOpenMenuDrawer() {
    if (drawerVisible) {
      setDrawerVisible(false);
    } else {
      setDrawerVisible(true);
    }
  };

  function handleLanguageSelectChange(lang) {
    const param = new URLSearchParams();
    param.append("lang", lang);
    history.push({search: param.toString()});
    actions.setCurrentLanguage(lang);
    window.location.reload();
  };

  function handleLogOut(){
		actions.doLogout();
		window.location.pathname = routers.home;
  };
  
  return (
    <React.Fragment>
      <Row id='header-wrapper' gutter={16}>
        <Col md={24}>
          <Row id='authenticate-wrapper' gutter={16}>
            {isHadAccountCookie ? (
              <React.Fragment>
                <Col md={8}>
                  <Dropdown
                    className='short-name-wrapper'
                    overlay={
                      <AccountDropDownMenu
                        handleLogOut={handleLogOut}
                        accountType={accountType}
                      />
                    }
                  >
                    <p>{shortName} <DownOutlined /></p>
                  </Dropdown>
                </Col>
                <Col md={4}>
                  <Avatar
                    src={require('../../assets/logo.png')}
                  />
                </Col>
                <Col md={4}>
                  <Badge
                    count={badgeCount}
                  >
                    <Dropdown
                      className='notifi-wrapper'
                      overlay={
                        <NotificationComponent
                          data={notification}
                          doPutNotification={actions.doPutNotification}
                        />
                      }
                    >
                      <BellOutlined />
                    </Dropdown>
                  </Badge>
                </Col>
              </React.Fragment>
            ) : (
             <React.Fragment>
              <Col md={8}>
                <Link
                  to={routers.account.login}
                >
                  <p className='auth-btn'>{getTranslatedText('header_btn_login')}</p>
                </Link>
              </Col>
              <Col md={8}>
                <Link
                  to={routers.account.sign_up}
                >
                  <p className='auth-btn'>{getTranslatedText('header_btn_sign_up')}</p>
                </Link>
              </Col>
             </React.Fragment>
            )}
            <Col md={8}>
              <Dropdown
                className='language-dropdown-wrapper'
                overlay={
                  <LanguagueDropdown
                    handleLanguageSelectChange={handleLanguageSelectChange}
                  />
                }
              >
                <p>{getTranslatedText(`lang_${currentLanguage}`)}</p>
              </Dropdown>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            id='navigation-wrapper'
            gutter={16}
          >
            <Col md={5}>
              <Link
                to={routers.home}
              >
                <p
                  id={home_active}
                >{getTranslatedText('homepage_title')}</p>
              </Link>
            </Col>
            <Col md={5}>
              <Link
                to={routers.products.main}
              >
                <p
                  id={products_active}
                >{getTranslatedText('products_title')}</p>
              </Link>
            </Col>
            <Col id='col-logo-wrapper' md={4}>
              <Link
                to={routers.home}
              >
                <div id='logo-wrapper'>
                  <img src={logo} alt='' />
                </div>
              </Link>
            </Col>
            <Col md={5}>
              <Link
                to={routers.services.main}
              >
                <p
                  id={services_active}
                >{getTranslatedText('service_title')}</p>
              </Link>
            </Col>
            <Col md={5}>
              <Link
                to={routers.contact}
              >
                <p
                  id={contact_active}
                >{getTranslatedText('contact_title')}</p>
              </Link>
            </Col>
            {/* <Col md={1}>
              <Button id='search-btn'>
                <SearchOutlined />
              </Button>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Row id='mobile-header-wrapper' gutter={16}>
        <Col xs={6}>
          <Button
            id='menu-btn'
            onClick={() => handleOpenMenuDrawer()}
          >
            <MenuOutlined />
          </Button>
        </Col>
        <Col id='col-logo-wrapper' xs={8}>
          <Link
            to={routers.home}
          >
            <div id='logo-wrapper'>
              <img src={logo} alt='' />
            </div>
          </Link>
        </Col>
        <Col xs={6}>
          <Dropdown
            className='language-dropdown-wrapper'
            overlay={
              <LanguagueDropdown
                handleLanguageSelectChange={handleLanguageSelectChange}
              />
            }
          >
            <p>{getTranslatedText(`lang_${currentLanguage}`)}</p>
          </Dropdown>
        </Col>
        {/* <Col xs={4}>
          <Button id='search-btn'>
            <SearchOutlined />
          </Button>
        </Col> */}
      </Row>
      <Drawer
        title='B2BGOSELL'
        className='custom-drawer'
        visible={drawerVisible}
        placement='left'
        onClose={() => setDrawerVisible(false)}
        destroyOnClose
      >
        <MobileNavigation
          isHadAccountCookie={isHadAccountCookie}
          shortName={shortName}
          accountType={accountType}
          handleLogOut={handleLogOut}
          notification={notification}
          doPutNotification={actions.doPutNotification}
          badgeCount={badgeCount}
        />
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
	return {
		isHadAccountCookie: state.getUserFromCookiesReducer.isHadAccount,
		shortName: state.getUserFromCookiesReducer.short_name,
		accountType: state.getUserFromCookiesReducer.accountType,
		currentLanguage: state.currentLanguageReducer.language,
		notification: state.notificationReducer.data,
}
};

const mapDipatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getNotificationAction,
        doPutNotification,
        getCurrentLanguage,
        setCurrentLanguage,
        doLogout,
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps,
)(Header);