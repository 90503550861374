import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Layout,
  Radio
} from 'antd';
import 'antd/dist/antd.css';
import './AccountRegisterOption.scss';
import {
  Link
} from 'react-router-dom';
import {
  routers
} from '../../config/routers';
import {
  selectPartnerRegister,
  selectCustomerRegister,
} from '../../redux/actions/authentication';
import { getTranslatedText } from '../../services/appServices';

const RegisterOption = (props) => {

  const {
    registerOption,
    actions
  } = props;


  const handleRadioOptionOnChange = (e) => {
    switch (e.target.value) {
      case 'partner':
        actions.selectPartnerRegister('partner');
        break;
      case 'customer':
        actions.selectCustomerRegister('customer');
    }
  }

  return (
    <React.Fragment>
      <div className="container black mg-b-60">
        <Row
          className='register_option_container'
        >
          <Col
            span={24}
          >
            <Layout
              className='layout_register_option'
            >
              <h1
                className='layout_title'
              >
                {getTranslatedText('welcome_title')}
                </h1>
              <p
                className='layout_subtitle'
              >
                {getTranslatedText('thank_letter')}
              </p>
              <Layout.Content
                className='register_select_container'
              >
                <Row
                  justify='center'
                >
                  <Col
                    span={24}
                  >
                    <p>{getTranslatedText('account_type_select_title')}</p>
                  </Col>
                  <Radio.Group
                    onChange={(e) => handleRadioOptionOnChange(e)}
                  >
                    <Row
                      className='radio_group_row'
                    >
                      <Col
                        lg={8}
                        xs={24}
                        className='select_option_container'
                      >
                        <Radio value="partner">{getTranslatedText('partner')}</Radio>
                      </Col>
                      <Col
                        lg={2}
                        xs={0}
                        offset={2}
                      >
                        <div
                          className='vertical_line'
                        ></div>
                      </Col>
                      <Col
                        lg={8}
                        xs={24}
                        className='select_option_container'
                      >
                        <Radio value="customer">{getTranslatedText('customer')}</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                  <Col
                    span={24}
                  >
                    <div className='mt-4 d-flex justify-content-center'>
                      <p>{getTranslatedText('policy_remind_title')} <a href='https://b2bgosell.com/documents/chinh-sach-bao-mat-thong-tin?lang=vi' target='_blank'>{getTranslatedText('here_title')}.</a></p>
                    </div>
                    <Link
                      to={location => {
                        if (registerOption === 'customer') {
                          return {
                            ...location,
                            pathname: routers.account.customer_regiser,
                          };
                        } else {
                          return {
                            ...location,
                            pathname: routers.account.partner_register_select,
                          };
                        }
                      }}
                    >
                      <button
                        className='next_button'
                      >
                        {getTranslatedText('btn_next')}
                      </button>
                    </Link>
                  </Col>
                </Row>
              </Layout.Content>
            </Layout>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    registerOption: state.registerOptionReducer.registerOption,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        selectCustomerRegister,
        selectPartnerRegister,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterOption);