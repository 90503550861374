import { put } from 'redux-saga/effects';
import * as actionTypes from '../actions/action_types';
import Cookies from 'universal-cookie';

export default {
  getUserFromCookie: function* (action) {
    let cookie = new Cookies();

    const userCookie = cookie.get('_user');
    if (userCookie) {
      yield put({
        type: actionTypes.GET_USER_FROM_COOKIE_SUCCESS,
        payload: userCookie,
      });
    } else {
      yield put({
        type: actionTypes.GET_USER_FROM_COOKIE_FAILURE,
        payload: false,
      })
    }
  },
  login: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/auth/login', payload);
      if (response.data) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.LOGIN_SUCCESS,
            payload: data.data
          });
        } else {
          yield put({
            type: actionTypes.LOGIN_FAILURE,
            error: data.errors
          });
        }
      } else {
        yield put({
          type: actionTypes.LOGIN_FAILURE,
          error: ['Something went wrong!']
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.LOGIN_FAILURE,
        error: ['Something went wrong']
      });
    }
  },
  register: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/auth/register', payload, true);
      // console.log('register', response);
      if (response.data) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.CREATE_ACCOUNT_SUCCESS,
            payload: data,
          });
        } else {
          yield put({
            type: actionTypes.CREATE_ACCOUNT_FAILURE,
            error: response.data.errors
          });
        }
      } else {
        yield put({
          type: actionTypes.CREATE_ACCOUNT_FAILURE,
          error: ['Something went wrong!']
        });
      }
    } catch (error) {
        yield put({
          type: actionTypes.CREATE_ACCOUNT_FAILURE,
          error: ['Something went wrong!']
        });
    }
  },
  getUserCode: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/user-code', payload);
      console.log('user-code, ',response.data.data);
      let userCode = response.data.data;
      if (response.data.isSuccess === true) {
        yield put({
          type: actionTypes.GET_USER_CODE_SUCCESS,
          payload: userCode,
        })
      } else {
        yield put({
          type: actionTypes.GET_USER_CODE_FAILURE,
          error: ['Something went wrong, contact admin!']
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_USER_CODE_FAILURE,
        error: ['Something went wrong, contact admin!']
      })
    };
  },
  sendEmailResetPassword: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/auth/password/email', payload);
      if (response.data) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.SEND_EMAIL_RESET_PASSWORD_SUCCESS,
            payload: data.data
          });
        } else {
          yield put({
            type: actionTypes.SEND_EMAIL_RESET_PASSWORD_FAILURE,
            error: data.errors
          });
        }
      } else {
        yield put({
          type: actionTypes.SEND_EMAIL_RESET_PASSWORD_FAILURE,
          error: ['Something went wrong!']
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.LOGIN_FAILURE,
        error: ['Something went wrong']
      });
    }
  },
  resetPassword: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/auth/password/reset', payload);
      if (response.data) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.RESET_PASSWORD_SUCCESS,
            payload: data.data
          });
        } else {
          yield put({
            type: actionTypes.RESET_PASSWORD_FAILURE,
            error: data.errors
          });
        }
      } else {
        yield put({
          type: actionTypes.RESET_PASSWORD_FAILURE,
          error: ['Something went wrong!']
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.RESET_PASSWORD_FAILURE,
        error: ['Something went wrong']
      });
    }
  },
};