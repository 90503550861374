// The index root route Cart

import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';

import OrderRetailContainer from './OrderRetailContainer/OrderRetailContainer';
import OrderWholesaleContainer from './OrderWholesaleContainer/OrderWholesaleContainer';
import CheckoutRetailContainer from './CheckoutRetailContainer/CheckoutRetailContainer';

const CartContainerIndex = () => {

  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={match.url}
        >
          <Redirect
            to={{
              pathname: `${match.url}/retail`,
            }}
          />
        </Route>
        <Route
          exact
          path={`${match.url}/retail`}
          component={OrderRetailContainer}
        />
        <Route
          path={`${match.url}/wholesale`}
          component={OrderWholesaleContainer}
        />
        <Route
          path={`${match.url}/retail/checkout`}
          component={CheckoutRetailContainer}
        />
      </Switch>
    </React.Fragment>
  );
};

export default CartContainerIndex;