import React, {useEffect} from 'react';
import {
  Table,
} from 'antd';
import './Dashboard_EstimatedHistory.scss';

import {getTranslatedText} from '../../services/appServices';
import {bindActionCreators} from "redux";
import {doGetEstimates} from "../../redux/actions/estimate";
import {doGetQuote} from "../../redux/actions/services";
import {
  doCreateCartAction,
} from '../../redux/actions/products';
import {connect} from "react-redux";
import Cookies from "universal-cookie";
import {Link} from "react-router-dom";
import {routers} from "../../config/routers";

const Dashboard_EstimatedHistory = (props) => {

  const {
    actions,
    estimates,
    orders,
    accountType,
  } = props;
  const cookie = new Cookies();
  const user = cookie.get("_user");

  useEffect(() => {
    actions.doGetEstimates();
  }, []);

  const columns = [
    {
      title: getTranslatedText('content_column'),
      dataIndex: "service_title",
      key: "service_title"
    },
    {
      title: getTranslatedText('date_post'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('status_estimated_column'),
      dataIndex: "status",
      key: "status",
      render: (status => {
        let cls = "btn_post_estimate";
        let text = getTranslatedText('is_being_considered');
        if (status === "accept")
          text = getTranslatedText('accepted');
        if (status == "deny") {
          cls = "btn_cancel";
          text = getTranslatedText('is_denied');
        }
        return (
            <React.Fragment>
              <button className={cls}>
                {text}
              </button>
            </React.Fragment>
        )
      }),
    }
  ];

  const order_column = [
    {
      title: getTranslatedText('order_code'),
      dataIndex: "code",
      key: "code"
    },
    {
      title: getTranslatedText('sale_method_column'),
      dataIndex: "order_type",
      key: "order_type"
    },
    {
      title: getTranslatedText('date_create_order'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('total_amount'),
      dataIndex: "total",
      key: "total"
    },
    {
      title: getTranslatedText('status_column'),
      render: (record => {
        let cls = "btn_post_estimate";
        let text = getTranslatedText('is_being_considered');
        if (record.status == "accept")
          text = getTranslatedText('accepted');
        if (record.status == "deny" ) {
          cls = "btn_cancel";
          text = getTranslatedText('is_denied');
        }
        return (
            <React.Fragment>
              <Link
                to={location => {
                  const cartPayload = {
                    cartItem: {
                      partner_id: record.partner_id
                    }
                  };
                  actions.doCreateCartAction(cartPayload);
                  return ({
                    ...location,
                    pathname: routers.dashboard.view_detail_order.replace(':order_id', record.id)
                  });
                }}
              >
                <button className={cls}>
                  {text}
                </button>
              </Link>
              
            </React.Fragment>
        )
      }),
    },
    {
      title: getTranslatedText('partner_status_column'),
      render: (record => {
        let cls = "btn_post_estimate";
        let text = getTranslatedText('is_being_considered');
        if (record.partner_status == "accept")
          text = getTranslatedText('accepted');
        if (record.partner_status == "deny" ) {
          cls = "btn_cancel";
          text = getTranslatedText('is_denied');
        }
        return (
          <React.Fragment>
            <Link
              to={location => {
                const cartPayload = {
                  cartItem: {
                    partner_id: record.partner_id
                  }
                };
                actions.doCreateCartAction(cartPayload);
                return ({
                  ...location,
                  pathname: routers.dashboard.view_detail_order.replace(':order_id', record.id)
                });
              }}
            >
              <button className={cls}>
                {text}
              </button>
            </Link>

          </React.Fragment>
        )
      }),
    }
  ];

  // estimates
  return (
    <>
      {accountType === 'customer' ? (
        <div className='container bg_white black estimated_history_container'>
        <div className="row mg-t-30">
          <h1 className='title_sec bg_green'>{getTranslatedText('my_order_list').toUpperCase()}</h1>
          <div className="col-12 single_service">
            <Table
              scroll={{x: true}}
              dataSource={orders}
              columns={order_column}
            >
            </Table>
            <Link
              to={routers.dashboard.view_order}
            >
              <button className='btn_show'>{getTranslatedText('btn_show_more')}</button>
            </Link>
          </div>
        </div>
      </div>
      ) : (
        <div className='container bg_white black estimated_history_container'>
        <div className="row mg-t-30">
          <h1 className='title_sec bg_green'>{getTranslatedText('estimate_sent_client').toUpperCase()}</h1>
          <div className="col-12 single_service">
            <Table
              scroll={{x: true}}
              dataSource={estimates}
              columns={columns}
            >
            </Table>
            <Link
              to={routers.dashboard.estimated_history}
            >
              <button className='btn_show'>{getTranslatedText('btn_show_more')}</button>
            </Link>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // request: state.getRequestEstimateReducer.data || [],
    estimates: state.getEstimatesReducer.data || [],
    orders: state.getOrderReducer.data || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetEstimates,
        doGetQuote,
        doCreateCartAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard_EstimatedHistory);