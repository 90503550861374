import {
  GET_CATEGORY_SERVICE_REQUEST,
  GET_LOCATION_REQUEST,
  GET_CATEGORY_PRODUCT_REQUEST,
  GET_CATEGORY_REQUEST,
  GET_CHILDREN_CATEGORY_PRODUCT_REQUEST,
  GET_CHILDREN_CATEGORY_SERVICE_REQUEST,
  GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_REQUEST
} from './action_types';

export const doGetCategoryService = () => ({
  type: GET_CATEGORY_SERVICE_REQUEST,
  payload: {
    type: 0
  }
});

export const doGetCategory = () => ({
  type: GET_CATEGORY_REQUEST,
  payload: {}
});

export const doGetChildrenCategoryService = (parentCategoryId) => ({
  type: GET_CHILDREN_CATEGORY_SERVICE_REQUEST,
  payload: parentCategoryId,
});

export const doGetChildrenCategoryServiceMultiChoice = (parentCategoryId) => ({
  type: GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_REQUEST,
  payload: parentCategoryId,
});

export const doGetLocation = () => ({
  type: GET_LOCATION_REQUEST,
});

export const doGetCategoryProduct = () => ({
  type: GET_CATEGORY_PRODUCT_REQUEST,
  payload: {
    type: 1,
  }
});

export const doGetChildrenCategoryProduct = (parentCategoryId) => ({
  type: GET_CHILDREN_CATEGORY_PRODUCT_REQUEST,
  payload: parentCategoryId,
});