import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Col,
  Button,
  Divider
} from 'antd';
import {
  getTranslatedText,
} from '../../services/appServices';

import './styles/styles.scss';

import {
  doUpdatePackage,
} from '../../redux/actions/services';
import { toast } from 'react-toastify';
import axios from "axios";
import Cookies from "universal-cookie";
import env from "../../env";


const RechargeCoinOption = (props) => {
  

  const {
    actions,
    currentPackage,
    setModalVisible,
  } = props;

  const handleOnClickButton = () => {
    actions.doUpdatePackage(currentPackage.id, currentPackage.receive, 'Recharge', 'online');
  };

  const postRechargeTransfer = () => {

    let cookie = new Cookies();
    let token = cookie.get('_token');

    axios.post(env.site_url + "/api/v1/payment/recharge", [], {
      "Authorization": token
    }).then(data => {
      setModalVisible(false);
      toast.success(getTranslatedText('transfer_noti'));
    });

  }

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col className='col-container' span={12}>
            <h3>{getTranslatedText('ngan_luong_pay')}</h3>
            <Button
              style={{
                backgroundColor: '#4EA752',
                color: 'white',
                fontWeight: 'bold'
              }}
              onClick={() => handleOnClickButton()}
            >
              {getTranslatedText('ngan_luong_button')}
            </Button>
        </Col>
        {/* <Divider id='line' type="vertical" /> */}
        <Col className='col-container' span={12}>
            <h3>{getTranslatedText('bank_pay')}</h3>
            <div>
              <ul>
                <li>{getTranslatedText('host_bank_account')}</li>
                <li>{getTranslatedText('host_bank_number')}</li>
                <li>{getTranslatedText('host_bank_name')}</li>
                <li>{getTranslatedText('host_bank_branch')}</li>
                <li>Swift Code: VPBKVNVX</li>
                <li>
                {getTranslatedText('host_transfer')}
                </li>
                <li>
                {getTranslatedText('host_transfer_example')}
                </li>
              </ul>
            </div>
            <Button
              style={{
                backgroundColor: '#4EA752',
                color: 'white',
                fontWeight: 'bold'
              }}
              onClick={() => postRechargeTransfer()}
            >
              {getTranslatedText('pay_via_bank')}
            </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      doUpdatePackage,
    }, 
      dispatch
    )
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RechargeCoinOption);