// The index root route Cart

import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import ProfileContainer from './Profile/Profile';
import Wallet from './Wallet/Wallet';
import RechargeCoinContainer from './RechargeCoin/RechargeCoinContainer';
import Profile_RequestEstimate from './EstimateRequest/Profile_RequestEstimate';
import Profile_EstimatedHistory from './EstimateHistory/Profile_EstimatedHistory';
import ViewEstimateContainer from './ViewEstimate/ViewEstimateContainer';
import SuggestPartnerContainer from './SuggestPartner/SuggestPartnerContainer';
import PostEstimateContainer from './PostEstimateContainer/PostEstimateContainer';
import OrderListContainer from './OrderListContainer/OrderListContainer';
import OrderWholesaleContainer from '../Cart/OrderWholesaleContainer/OrderWholesaleContainer';
import PartnerProfileContainer from './PartnerProfile/PartnerProfileContainer';

const DashboardContainerIndex = () => {

  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={match.url}
          component={ProfileContainer}
        />
        <Route
          path={`${match.url}/wallet`}
          component={Wallet}
        />
        <Route
          path={`${match.url}/payment`}
          component={RechargeCoinContainer}
        />
        <Route
          path={`${match.url}/request-estimate`}
          component={Profile_RequestEstimate}
        />
        <Route
          path={`${match.url}/estimated-history`}
          component={Profile_EstimatedHistory}
        />
        <Route
          path={`${match.url}/view-estimate`}
          component={ViewEstimateContainer}
        />
        <Route
          path={`${match.url}/suggest-partner`}
          component={SuggestPartnerContainer}
        />
        <Route
          path={`${match.url}/quotation`}
          component={PostEstimateContainer}
        />
        <Route
          exact
          path={`${match.url}/order`}
          component={OrderListContainer}
        />
        <Route
          path={`${match.url}/order/detail/:order_id`}
          component={OrderWholesaleContainer}
        />
        <Route
          path={`${match.url}/contact/partner/:partner_id`}
          component={PartnerProfileContainer}
        />
      </Switch>
    </React.Fragment>
  );
};

export default DashboardContainerIndex;