import React from 'react';
import {
  Form,
  Row,
  Col,
  Layout,
  Input,
  Checkbox,
} from 'antd';
import 'antd/dist/antd.css';
import './ForgetPassword.scss';
import { number } from 'prop-types';
import {bindActionCreators} from "redux";
import {doSendEmailResetPassword} from "../../redux/actions/authentication";
import {connect} from 'react-redux';

const ForgetPasswordComponent = (props) => {

  const {actions} = props;

  const send = (values) => {
    actions.doSendEmailResetPassword(values);
  }

  return (
    <React.Fragment>
      <div className="container black mg-b-60">
				<Row
          className='login_container'
        >
          <Col
            span={24}
          >
            <Layout
              className='layout_login'
            >
                <h1
                  className='layout_title'
                >
                  QUÊN MẬT KHẨU?
                </h1>
                <p
                  className='layout_subtitle'
                >
                  Vui lòng nhập email của bạn và chúng tôi sẽ gửi đường link để tạo mật khẩu mới
                </p>
              <Layout.Content>
                <Form
                  layout='vertical'
                  onFinish={(values) => send(values)}
                >
                  <Form.Item
                  >
                    <Input
                      placeholder='Email'
                      className='form_input_item'
                      name="email"
                    />
                  </Form.Item>
                  <Form.Item>
                    <button
                      className='login_button'
                    >
                      Gửi yêu cầu
                    </button>
                  </Form.Item>
                </Form>
              </Layout.Content>
            </Layout>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doSendEmailResetPassword,
      },
      dispatch,
    ),
  };
};
export default connect(null, mapDispatchToProps)(ForgetPasswordComponent);