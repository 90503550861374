import {
  GET_NOTIFICATION_REQUEST,
  PUT_NOTIFICATION_REQUEST,
} from '../actions/action_types';


export const getNotificationAction = () => ({
  type: GET_NOTIFICATION_REQUEST
});

export const doPutNotification = (payload) => ({
  type: PUT_NOTIFICATION_REQUEST,
  payload: payload,
});