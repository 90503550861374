import React from 'react';
import {
  Layout,
  Typography,
} from 'antd';

import './postnewservicestyles.less';


const {
  Content
} = Layout;

const {
  Title,
  Paragraph,
  Link,
} = Typography;


const PostNewService = () => {
  return (
    <React.Fragment>
      <Content id='layout-content-container'>
        <Typography>
          <Title
            id='title'
            level={3}
          >
            HƯỚNG DẪN ĐĂNG TIN
          </Title>
          <Paragraph>
          <strong>- Bước 1:</strong> Tại đường link  <Link>https://b2bgosell.com/account/login</Link> , đăng nhập vào tài khoản của bạn bằng email và mật khẩu đã đăng ký -> chọn <strong>ĐĂNG NHẬP</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/TUWWK66Jli4DnkAoCMJkDp-vkrfQF3quiMG3jYN4BKakKAo5Hv0EVP6-vUEo_Fy3P7JzlKJoSw6kRI4-EeYkoUzH9gzhBSNjKG9jinFOIeMeyNio9YLQJYAd5mY1-oNhKTUr61E24PBfOXUhCA' alt='' />
          <Paragraph>
          <strong>- Bước 2:</strong> Tại đường link  <Link>https://b2bgosell.com/services/</Link>, chọn <strong>ĐĂNG TIN MỚI</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/pe6v4Bql-1KaGcs0NL_7beUA20FCStf7yuUUE-5uT3tpgRmqdKKmZtRv6b5Wnq5u4ZIAIRozP4Bs5YhXkwQTVpSxutfkN9aWce9zAVYIb4tIoxeL4EYZtZiw2RoCg0T8o6HB_4-TX7FRrAar0g' alt='' />
          <Paragraph>
          <strong>- Bước 3:</strong> Tại đường link  <Link>https://b2bgosell.com/services/post/new</Link>, điền vào các ô <strong>thông tin về dịch vụ</strong> và <strong>thông tin liên hệ</strong> của bạn -> upload các <strong>chứng chỉ, bằng cấp</strong> có liên quan đến dịch vụ của bạn -> chọn <strong>“ Khi đăng tin mới, bạn xác nhận …”</strong> -> chọn <strong>ĐĂNG TIN NGAY</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/P94AGsRFYcJcUEKwl5Mw-TUKQfcKsePyTQdAqsWA-g1fq6UA3mJPc7MPNtHs1DAWExWLchERjO9a9gJRGUSQ0Ab7gOY6y0_gZn6774zkScUGqr2QGf21xrZPvMapO3DXQN419EFRtM3JTWTrEw' alt=''/>
          <img src='https://lh5.googleusercontent.com/8s9AyBcMlmZcKWVvuRJasb2USq7srnr9Ypbq-goCaZrKLAigLK80VpFuyKycRMmBmTDXFvXAhlSF0O4xtArgXq8T-hYAcC6cblnyuibEtfVD4wcR89ivvNKx3nVvykfDuSv93FZ1gPZqWuAWoQ' alt='' />
          <Paragraph>
          <strong>- Bước 4:</strong> Hệ thống sẽ kiểm tra và phê duyệt bài đăng tin của bạn trước khi được hiển thị trên website
          </Paragraph>
          <Paragraph>
          <strong>- Bước 5:</strong> Khi bài đăng được duyệt, hệ thống sẽ thông báo cho bạn bằng mail và đường link của bài đăng đó
          </Paragraph>
          <Paragraph
            strong
          >
            Nếu sau 3 ngày kể từ lúc bạn đăng bài nhưng chưa thấy hiển thị, hãy liên hệ Admin để được hỗ trợ.
          </Paragraph>
          <Paragraph
            strong
            type='danger'
          >
            THÔNG TIN LIÊN HỆ HỖ TRỢ:
          </Paragraph>
          <Paragraph>
            <strong>GREATLINK MAIHOUSE INVESTMENT COMPANY LIMITED (B2BGOSELL IN VIETNAM)</strong><br/>
            <strong>Add (new)</strong>: 11A, Hong Ha, Ward 2, Tan Binh Distric, HoChiMinh City, Zip Code 700000, Vietnam<br/>
            Mobile: <strong>+84 936.168.503</strong><br/>
            Email: <Link>admin@b2bgosell.com;</Link><br/>
            Website: <Link>www.b2bgosell.com</Link>
          </Paragraph>
          <Paragraph
            strong
          >
            CHÚC CÁC BẠN THÀNH CÔNG!
          </Paragraph>
        </Typography>
      </Content>
    </React.Fragment>
  );
};

export default PostNewService;