import * as actionTypes from '../actions/action_types';
import { toast } from 'react-toastify';
import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

const productArrayState = {
  isLoading: false,
  productArray: [],
};

export const getProductsReducer = (state = productArrayState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_ALL_PRODUCT_SUCCESS:
      let productsArray = action.payload;
      return {
        ...state,
        isLoading: false,
        productArray: productsArray,
      };
    case actionTypes.GET_ALL_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_PRODUCT_WITH_FILTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PRODUCT_WITH_FILTER_SUCCESS:
      let productFilterArray = action.payload;
      return {
        ...state,
        productArray: productFilterArray,
        isLoading: false
      }
    case actionTypes.GET_PRODUCT_WITH_FILTER_FAILURE:
      return {
        ...state,
        isLoading: false,
      }  
    default:
      return state;
  };
};

const productState = {
  isLoading: false,
  product: {},
};

export const getProductReducer = (state = productState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PRODUCT_SUCCESS:
      let product = action.payload;
      return {
        ...state,
        isLoading: false,
        product: product,
      };
    case actionTypes.GET_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };  
    default:
      return state;
  };
};

const postProductState = {
  isLoading: false,
};

export const postProductReducer = (state = postProductState, action) => {
  switch (action.type) {
    case actionTypes.POST_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.POST_PRODUCT_SUCCESS:
      toast.success(actionTypes.POST_PRODUCT_SUCCESS);
      setTimeout(() => {
        window.location.pathname = routers.products.main;
      }, 4000);
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.POST_PRODUCT_FAILURE:
      toast.error(actionTypes.POST_PRODUCT_FAILURE)
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const orderArrayState = {
  isLoading: false,
  data: [],
  detail_order: {
    request_from_client: '',
    response_from_partner: '',
    name: '',
    address: '',
    email: '',
    city: '',
    phone: '',
    show: 'password',
    is_accepted: 0,
  },
  total: null,
  product_cart: [],
};

export const getOrderReducer = (state = orderArrayState, action) => {
  let hideText = '';
  switch (action.type) {
    case actionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.GET_ORDER_FAILURE:
      return state;
    case actionTypes.GET_DETAIL_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_DETAIL_ORDER_SUCCESS:
      if(action.payload.show === 0) {
        hideText = 'password'
      } else {
        hideText = 'text'
      }
      return {
        ...state,
        isLoading: false,
        detail_order: {
          request_from_client: action.payload.request_from_client,
          response_from_partner: action.payload.response_from_partner,
          name: action.payload.order_name,
          address: action.payload.order_address,
          email: action.payload.order_email,
          city: action.payload.order_city,
          phone: action.payload.order_phone, 
          show: hideText,
          is_accepted: action.payload.is_accepted,
          currency: action.payload.currency,
          fee: action.payload.fee
        },
        total: action.payload.total,
        product_cart: action.payload.details,
      }
    case actionTypes.GET_DETAIL_SERVICE_FAILURE:
      return state;

    case actionTypes.PUT_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.PUT_ORDER_SUCCESS:
      if(action.payload.is_accepted === 0) {
        toast.success(getTranslatedText("payment_success"));
        if(action.payload.show === 0) {
          hideText = 'password'
        } else {
          hideText = 'text'
        }
        return {
          ...state,
          isLoading: false,
          detail_order: {
            request_from_client: action.payload.request_from_client,
            response_from_partner: action.payload.response_from_partner,
            name: action.payload.order_name,
            address: action.payload.order_address,
            email: action.payload.order_email,
            city: action.payload.order_city,
            phone: action.payload.order_phone, 
            show: hideText,
            is_accepted: action.payload.is_accepted,
            currency: action.payload.currency,
            fee: action.payload.fee
          },
        }
      } else {
        toast.success(getTranslatedText("post_response_order_success"));
        setTimeout(() => {
          window.location.href = routers.home
        }, 3000);
        return {
          ...state,
          isLoading: false,
          detail_order: {
            request_from_client: action.payload.request_from_client,
            response_from_partner: action.payload.response_from_partner,
            name: action.payload.order_name,
            address: action.payload.order_address,
            email: action.payload.order_email,
            city: action.payload.order_city,
            phone: action.payload.order_phone, 
            show: hideText,
            is_accepted: action.payload.is_accepted,
            currency: action.payload.currency,
            fee: action.payload.fee
          },
        }
      }
      
    case actionTypes.PUT_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state;
  }
};

const cartState = {
  cart: [],
  cart_product_id: [],
  orderType: ''
};

export const cartReducer = (state = cartState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CART_REQUEST:
      // Get local storage cart, if not assign to pure object
      // Parse json text to javascript json
      if (action.payload.orderType === 'le') {
        let localStorageCart = localStorage.getItem('localStorageCart') || JSON.stringify(cartState);
        console.log(localStorageCart);
        localStorageCart = JSON.parse(localStorageCart);
        if (localStorageCart.orderType === 'si') {
          localStorageCart = {
            cart: [],
            cart_product_id: [],
            orderType: 'le'
          }
        };
        if (localStorageCart.cart.length === 0) {
          localStorageCart.cart.push(action.payload.cartItem);
          localStorageCart.cart_product_id.push(action.payload.cartItem.product_id);
        } else {
          localStorageCart.cart.forEach(item => {
            console.log(action.payload.cartItem.product_id);
            if (item.product_id === action.payload.cartItem.product_id) {
              let index = localStorageCart.cart.indexOf(item);
              localStorageCart.cart[index] = action.payload.cartItem;
            } else if (!localStorageCart.cart_product_id.includes(action.payload.cartItem.product_id)) {
              localStorageCart.cart_product_id.push(action.payload.cartItem.product_id);
              localStorageCart.cart.push(action.payload.cartItem);
              }
            });
        }
        localStorage.setItem('localStorageCart', JSON.stringify(localStorageCart));
        state = localStorageCart;
        return {
          ...localStorageCart
        };
      } else {
        let localStorageCart = localStorage.getItem('localStorageCart') || JSON.stringify(cartState);
        console.log(localStorageCart)
        localStorageCart = JSON.parse(localStorageCart);
        if (localStorageCart.orderType === 'le') {
          localStorageCart = {
            cart: [],
            cart_product_id: [],
            orderType: 'si'
          }
        };
        if (localStorageCart.cart.length === 0) {
          localStorageCart.cart.push(action.payload.cartItem);
          localStorageCart.cart_product_id.push(action.payload.cartItem.product_id);
          localStorageCart.orderType = 'si';
        } else {
          localStorageCart.cart.forEach(item => {
            // console.log(action.payload.cartItem.product_id);
            if (item.product_id === action.payload.cartItem.product_id) {
              let index = localStorageCart.cart.indexOf(item);
              localStorageCart.cart[index] = action.payload.cartItem;
            } else if (!localStorageCart.cart_product_id.includes(action.payload.cartItem.product_id)) {
              localStorageCart = {
                  cart: [],
                  cart_product_id: [],
                  orderType: 'si'
                };
                localStorageCart.cart.push(action.payload.cartItem);
                localStorageCart.cart_product_id.push(action.payload.cartItem.product_id);
              }
            });
        }
        localStorage.setItem('localStorageCart', JSON.stringify(localStorageCart));
        state = localStorageCart;
        return {
          ...localStorageCart
        };
      }
    case actionTypes.DELETE_ITEM_IN_CART:
      let item = action.payload;
      let localStorageCart = localStorage.getItem('localStorageCart');
      localStorageCart = JSON.parse(localStorageCart);
      if (localStorageCart.cart_product_id.includes(item.product_id)) {
        let itemIdIndex = localStorageCart.cart_product_id.indexOf(item.product_id);
        localStorageCart.cart.splice(itemIdIndex, 1);
        localStorageCart.cart_product_id.splice(itemIdIndex, 1);
      }
      localStorage.setItem('localStorageCart', JSON.stringify(localStorageCart));
        state = localStorageCart;
        return {
          ...localStorageCart
        };
    default:
      return state;
  };
};