import React from 'react';
import {
  Link
} from 'react-router-dom';
import {
  List,
  Row,
  Col,
  Avatar,
  Badge,
  Dropdown,
} from 'antd';
import {
  DownOutlined,
  BellOutlined,
} from '@ant-design/icons';

import { getTranslatedText } from '../../../services/appServices';
import { routers } from '../../../config/routers';

import AccountDropDownMenu from './AccountDropDownMenuComponent';
import NotificationComponent from './NotificationComponent';

const navigationItem = [
  {
    title: getTranslatedText('homepage_title'),
    router: routers.home
  },
  {
    title: getTranslatedText('products_title'),
    router: routers.products.main
  },
  {
    title: getTranslatedText('service_title'),
    router: routers.services.main
  },
  {
    title: getTranslatedText('contact_title'),
    router: routers.contact
  },
]


function MobileNavigation(props) {

  const {
    isHadAccountCookie,
    shortName,
    accountType,
    handleLogOut,
    notification,
    doPutNotification,
    badgeCount
  } = props;

  return (
    <React.Fragment>
      <Row id='mobile-navigation-wrapper' gutter={16}>
        {isHadAccountCookie ? (
          <React.Fragment>
            <Col xs={8}>
              <Dropdown
                className='short-name-wrapper'
                overlay={
                  <AccountDropDownMenu
										handleLogOut={handleLogOut}
										accountType={accountType}
									/>
                }
              >
                <p>{shortName} <DownOutlined /></p>
              </Dropdown>
            </Col>
            <Col xs={8}>
              <Avatar
                src={require('../../../assets/logo.png')}
              />
            </Col>
            <Col xs={8}>
              <Badge
                count={badgeCount}
              >
                <Dropdown
                  className='notifi-wrapper'
                  overlay={
                    <NotificationComponent
                      doPutNotification={doPutNotification}
                      data={notification}
                    />
                  }
                >
                  <BellOutlined />
                </Dropdown>
              </Badge>
            </Col>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <Col xs={12}>
                <Link
                  to={routers.account.login}
                >
                  <p className='auth-btn'>{getTranslatedText('header_btn_login')}</p>
                </Link>
              </Col>
              <Col xs={12}>
                <Link
                  to={routers.account.sign_up}
                >
                  <p className='auth-btn'>{getTranslatedText('header_btn_sign_up')}</p>
                </Link>
              </Col>
            </React.Fragment>
          )}
        <Col xs={24}>
          <List
            className='navigation-item-list'
            dataSource={navigationItem}
            renderItem={item => (
              <Link to={item.router}>
                <p>{item.title}</p>
              </Link>
            )}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};


export default MobileNavigation;