import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  doGetUserProfileAction,
} from '../../../redux/actions/dashboard';
import {
  doPutPayments,
} from '../../../redux/actions/payment';
import {
  doPutOrder,
} from '../../../redux/actions/order';
import {
  useRouteMatch,
  useLocation,
  Link
} from 'react-router-dom';
import {
  routers
} from '../../../config/routers';
import {
  getTranslatedText,
  catchTextFromUrl,
} from '../../../services/appServices';


const PaymentContainer = (props) => {

  const {
    actions,
  } = props;

  const match = useRouteMatch();
  const location = useLocation()
  const [imageSrc, setImageSrc] = useState('');
  const [paymentStatusText, setPaymentStatusText] = useState('');

  const apiCallWhenSucess = (url) => {
    let returnNameApiAndObjectKey = catchTextFromUrl(url);
    let payload = {
      id: parseInt(returnNameApiAndObjectKey.object_id),
      status: 'accept',
      payment_id: parseInt(returnNameApiAndObjectKey.payment_id),
    };
    actions.doPutPayments(payload);
  };

  const apiCallWhenFailure = (url) => {
    let returnNameApiAndObjectKey = catchTextFromUrl(url);
    switch (returnNameApiAndObjectKey.pay_type) {
      case 'recharge':
        let payload = {
          id: parseInt(returnNameApiAndObjectKey.object_id),
          status: 'denied'
        };
        actions.doPutPayments(payload);
        break;
      case 'order':
        let orderPayload = {
          orderId: parseInt(returnNameApiAndObjectKey.object_id),
          bodyRequest: { banking_status: 'failure'}
        };
        actions.doPutOrder(orderPayload);
        break;
    }
  }

  useEffect(() => {
    apiCallWhenSucess(location.search);
    setImageSrc(require('../../../assets/icon/success_icon.png'));
    setPaymentStatusText(getTranslatedText('payment_success'));
  }, []);

  

  return (
    <>
      <div className="container black mg-b-60">
        <div
          className="container bg_white black mg-t-30 mg-b-40 pd-b-40"
          style={{
            height: '500px',
            paddingTop: '10%',
          }}
        >
          <div
            style={{
              marginBotton: '50px'
            }}
          >
            <img src={imageSrc} />
            <p>{paymentStatusText}</p>
          </div>
          <Link
            to={routers.home}
          >
            <button
              style={{
                width: '160px',
                height: '36px',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: 'green',
                color: 'white',
                fontSize: 'bold'
              }}
            >
              {getTranslatedText("back_home_page")}
            </button>
          </Link>
          
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      doGetUserProfileAction,
      doPutPayments,
      doPutOrder
    },
    dispatch
    )
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(PaymentContainer);