import React from 'react';
import {
  Layout,
  Typography,
} from 'antd';
import './postnewservicestyles.less';


const {
  Content
} = Layout;

const {
  Title,
  Paragraph,
  Link,
  Text,
} = Typography;


const PostNewRequestQuote = () => {
  return (
    <React.Fragment>
      <Content
        id='layout-content-container'
      >
        <Typography>
          <Title
            id='title'
            level={3}
          >
            HƯỚNG DẪN TẠO YÊU CẦU BÁO GIÁ DỊCH VỤ
          </Title>
          <Paragraph>
            <Text>
              A. Yêu cầu báo giá trực tiếp: (chỉ nhận được 1 báo giá)<br/>
            </Text>
            <strong>- Bước 1:</strong> Tại đường link  <Link>https://b2bgosell.com/account/login</Link> , đăng nhập vào tài khoản của bạn bằng email và mật khẩu đã đăng ký -> chọn <strong>ĐĂNG NHẬP</strong>
          </Paragraph>
          <img src='https://lh6.googleusercontent.com/7Hq7B_-P1ZuWpECvh5_REmx5PLA_mwIt1GWg_wT9hwi2WlxnJcGcPyjIZmXMoEq8mbNW-jWg9KrWVSEqugAxvWJxHog53OgdmHYzElZtslE7TSGlsjUkL-T9HeIwU5jrO_15ewhv6TWcMdVR9g' alt='' />
          <Paragraph>
            <strong>- Bước 2:</strong> Tại đường link  <Link>https://b2bgosell.com/services/</Link>, kéo xuống để chọn cụ thể một trong số những tin đã đăng sẵn trên hệ thống mà bạn cần báo giá, chọn xem chi tiết hoặc chọn <strong>YÊU CẦU BÁO GIÁ</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/oKP30IcFeIKNhLXlUOqKualbBH07q188b56D1Y_4KkuRC1sb0rXcj_BMMpmgWbYgYPQGXD_rddrcc2RbmVOPBKVASbAxfZS52VKdO6NHAm1tiAIsblR1Z-qEjhX6SxG7bRMyojm1tIJ-KoAx8Q' alt='' />
          <Paragraph>
            <strong>- Bước 3:</strong> Tại đường link  <Link>https://b2bgosell.com/services/post/job</Link>, điền vào các ô thông tin về yêu cầu báo giá của bạn  -> chọn “ Khi gửi yêu cầu báo giá này, bạn xác nhận …” -> chọn  <strong>YÊU CẦU BÁO GIÁ</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/hAEBcIENZSUhSz-MGz2AwAYDbhQP6mpfH9SHEDmoexmgkArYkSWPS96siSJwG7VOO5ztFDCRNUhWCj4s17RcdafRavAdp2oqQhq0cWs_cROmqUXtOAM1YxPOhF2AoyYGLrJeU_6Y2MnS-hb6zg' alt='' />
          <img src='https://lh4.googleusercontent.com/67ic6ScC2QsajPIOdutOdShaTpgnxNOoS_fahafUP9TD7JG3RkB-g4IqmN4MPbw_hNoAp4Mr1_K1NdoZyJQmET0xXJNhc2FuolyVoJWMsh1I7Q_7p4XKU575uG1VYlND64BODg-eDr2BpzpvIQ' alt='' />
          <Paragraph>
            <strong>- Bước 4:</strong> Khi đối tác gửi báo giá, hệ thống sẽ thông báo cho bạn bằng mail và đường link của báo giá.
            <strong>- Bước 5:</strong> Tại đường link  <Link>https://b2bgosell.com/dashboard</Link> và mục THÔNG TIN TÀI KHOẢN CỦA BẠN, bạn được miễn phí khi mở xem thông tin báo giá và chọn ->  <strong>KẾT THÚC YÊU CẦU BÁO GIÁ</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/SVcwZJVsiCKQjG_aHRQYd_quUMORXTaoMCJpsQCy8lVz4tmBdoZv_jFZDwCe-mX9UKDiGXExOAdievAn62sj--pO7vvwt5FdOYHQVfzUC6aTMRNzYTJw2LrYoSK_dmrKfHXLwTm1BociWI1lhA' alt='' />
          <img src='https://lh5.googleusercontent.com/kXWzyuq_ZvHZNVam7dLyc1tZnykR4klIAgkM9ennhlJCUBSyxor9go7pPqJWRYZjXUHfmMEMrXddfHUbRqKAxVA7pVsrredg_LvVgYitDKBTN-3mqoB3WRja2U28lHKBnO-Uyz30aYdzCkxv0g' alt='' />
          <Paragraph>
            B. Yêu cầu báo giá gián tiếp: (sẽ nhận được nhiều báo giá) <br/>
            <strong>- Bước 1:</strong> Tại đường link  <Link>https://b2bgosell.com/account/login</Link>, đăng nhập vào tài khoản của bạn bằng email và mật khẩu đã đăng ký -> chọn <strong>ĐĂNG NHẬP</strong>
          </Paragraph>
          <img src='https://lh6.googleusercontent.com/7Hq7B_-P1ZuWpECvh5_REmx5PLA_mwIt1GWg_wT9hwi2WlxnJcGcPyjIZmXMoEq8mbNW-jWg9KrWVSEqugAxvWJxHog53OgdmHYzElZtslE7TSGlsjUkL-T9HeIwU5jrO_15ewhv6TWcMdVR9g' alt='' />
          <Paragraph>
            <strong>- Bước 2:</strong> Tại đường link  <Link>https://b2bgosell.com/services/</Link>, chọn <strong>YÊU CẦU BÁO GIÁ</strong>
          </Paragraph>
          <img src='https://lh5.googleusercontent.com/HHVfCloLkkTPGv__KVqEGalAv_YRAm2Mh7jMAtUU_2KMgPVsU_DNY00TFx2zRSQ3ppCUnU9H49qzB3emG92NbgGgX463-qxZls1EhYoWxVz-sB2mx46GFzN-sfnee-rX9CFcwSeRwKqw_ijuug' alt='' />
          <Paragraph>
            <strong>- Bước 3:</strong> Tại đường link  <Link>https://b2bgosell.com/services/post/job</Link>, điền vào các ô thông tin về yêu cầu báo giá của bạn  -> chọn “ Khi gửi yêu cầu báo giá này, bạn xác nhận …” -> chọn <strong>GỬI YÊU CẦU NGAY</strong>
          </Paragraph>
          <img src='https://lh5.googleusercontent.com/ABEDBi8FX0d54xHWfZXxOmhh--greh4yAj31a_A2pnFzaNYOGsWNfMHwaqtIhwgp2AUm1Ua5eoveMn3YYsqsnHLUPMR7oUDc24euoYzXN1eArlj2nGDFARd_nymq9IcSTbya2fhiYzygfj2RzQ' alt='' />
          <img src='https://lh5.googleusercontent.com/rSLEAY7EjVr4HqKrQVBE89kD3SrOYhyyODHnp3DldxwkEQdnNM1YiIaGWDzH-eJgIF3q_DNSIlZLNmbOyaMpC9_Kzryn5S2uaqy2wRsCpUsymxng2N--yjxUd9389leAu8gxCwli4E1yD2QslQ' alt='' />
          <Paragraph>
            <strong>- Bước 4:</strong> Khi các đối tác gửi báo giá, hệ thống sẽ thông báo cho bạn bằng mail và đường link của báo giá đó.<br/>
            <strong>- Bước 6:</strong> Tại đường link  <Link>https://b2bgosell.com/dashboard</Link> tại mục THÔNG TIN TÀI KHOẢN CỦA BẠN bạn được chọn 1 báo giá để mở xem thông tin miễn phí trong số các báo giá bạn nhận được khi bạn yêu cầu, từ báo giá thứ 2, hệ thống sẽ yêu cầu bạn trả 1 khoản phí nhỏ khi bạn muốn mở xem thông tin và chi tiết trong các báo giá còn lại cho đến khi bạn chọn  ->  <strong>KẾT THÚC YÊU CẦU BÁO GIÁ</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/SVcwZJVsiCKQjG_aHRQYd_quUMORXTaoMCJpsQCy8lVz4tmBdoZv_jFZDwCe-mX9UKDiGXExOAdievAn62sj--pO7vvwt5FdOYHQVfzUC6aTMRNzYTJw2LrYoSK_dmrKfHXLwTm1BociWI1lhA' alt='' />
          <img src='https://lh4.googleusercontent.com/Sr6CRylNyxVZuaD7xRhlYDTYMenD9ZC2tGxGMEJ3qkCT2i0hd7sH-5_rACXeRVu3go2_sjiAA7XNhhbOwbnNWZ122atrvCPsZGdyDTZsR5s-aVcuGwHF2PsJZ2TqOn-iJ48w_RVYf1AMTkDBUw' alt='' />
          <img src='https://lh5.googleusercontent.com/kXWzyuq_ZvHZNVam7dLyc1tZnykR4klIAgkM9ennhlJCUBSyxor9go7pPqJWRYZjXUHfmMEMrXddfHUbRqKAxVA7pVsrredg_LvVgYitDKBTN-3mqoB3WRja2U28lHKBnO-Uyz30aYdzCkxv0g' alt='' />
          <Paragraph>
            <strong>Nếu sau 3 ngày kể từ lúc bạn đăng bài nhưng chưa thấy hiển thị kết quả, hãy liên hệ Admin để được hỗ trợ.</strong><br/>
            <Text
              strong
              type='danger'
            >
              THÔNG TIN LIÊN HỆ HỖ TRỢ:
            </Text><br/>
            <strong>GREATLINK MAIHOUSE INVESTMENT COMPANY LIMITED (B2BGOSELL IN VIETNAM)</strong><br/>
            <strong>Add (new):</strong> 11A, Hong Ha, Ward 2, Tan Binh Distric, HoChiMinh City, Zip Code 700000, Vietnam<br/>
            Mobile: <strong>+84 936.168.503</strong>
            Email: <Link>admin@b2bgosell.com</Link>;
            Website: <Link>www.b2bgosell.com</Link>
            <strong>CHÚC CÁC BẠN THÀNH CÔNG!</strong>
          </Paragraph>
        </Typography>
      </Content>
    </React.Fragment>
  );
};

export default PostNewRequestQuote;