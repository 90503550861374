import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  doGetDetailEstimates,
  doUpdateEstimate,
} from '../../redux/actions/estimate';

import {
  doGetSetting,
} from '../../redux/actions/settings';

import {
  Modal,
} from 'antd';
import 'antd/dist/antd.css';
import './OpenEstimateModal.scss';

import PropTypes from 'prop-types';

const OpenEstimateModal = (props) => {

  const {
    modalVisible,
    setModalVisible,
    currentEstimateId,
    actions,
    setting_code,
  } = props;

  useEffect(() => {
    actions.doGetSetting('cost_view_estimate');
  }, []);

  const onClickGetDetailEstimate = () => {
    const acceptBody = {
			'status': 'accept'
    };
    const requestPayload = [acceptBody, currentEstimateId];
    actions.doUpdateEstimate(requestPayload);
    actions.doGetDetailEstimates(currentEstimateId);
    setModalVisible(false);
  }

  return (
    <React.Fragment>
      <Modal
        visible={modalVisible}
        destroyOnClose={true}
        footer={false}
        onCancel={() => setModalVisible(false)}
      >
        <div className='modal_container'>
          <img className='modal_container_header' src={require('../../assets/logo.png')} />
          <p className='container_body'>Tiến hành mở thêm báo giá vui lòng thanh toán {setting_code.value} XU.</p>
          <div className='container_footer'>
            <button onClick={() => onClickGetDetailEstimate()} className='modal_button pay_btn'>
              THANH TOÁN NGAY
            </button>
            <button className='modal_button check_wallet_btn'>
              KIỂM TRA VÍ
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
};

OpenEstimateModal.propTypes = {
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  currentEstimateId: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    setting_code: state.settingReducer.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      doGetDetailEstimates,
      doUpdateEstimate,
      doGetSetting
    },
    dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenEstimateModal);