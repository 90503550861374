import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  doCreateCartAction,
} from '../../redux/actions/products';
import {
  Link,
} from 'react-router-dom';
import {
  Table,
  Row,
  Col,
} from 'antd';

import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

import './styles/table-custom-styles.less';

function CustomerListOrderingProduct(props) {

  const {
    actions,
    orders,
  } = props;

  const columns = [
    {
      title: getTranslatedText('order_code'),
      dataIndex: "code",
      key: "code"
    },
    {
      title: getTranslatedText('sale_method_column'),
      dataIndex: "order_type",
      key: "order_type"
    },
    {
      title: getTranslatedText('date_create_order'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('total_amount'),
      dataIndex: "total",
      key: "total"
    },
    {
      title: getTranslatedText('status_column'),
      render: (record => {
        let cls = "btn_post_estimate";
        let text = getTranslatedText('is_being_considered');
        if (record.status == "accept")
          text = getTranslatedText('accepted');
        if (record.status == "deny") {
          cls = "btn_cancel";
          text = getTranslatedText('is_denied');
        }
        return (
          <React.Fragment>
            <Link
              to={location => {
                const cartPayload = {
                  cartItem: {
                    partner_id: record.partner_id
                  }
                };
                actions.doCreateCartAction(cartPayload);
                return ({
                  ...location,
                  pathname: routers.dashboard.view_detail_order.replace(':order_id', record.id)
                });
              }}
            >
              <button className={cls}>
                {text}
              </button>
            </Link>

          </React.Fragment>
        )
      }),
    },
    {
      title: getTranslatedText('partner_status_column'),
      render: (record => {
        let cls = "btn_post_estimate";
        let text = getTranslatedText('is_being_considered');
        if (record.partner_status == "accept")
          text = getTranslatedText('accepted');
        if (record.partner_status == "deny") {
          cls = "btn_cancel";
          text = getTranslatedText('is_denied');
        }
        return (
          <React.Fragment>
            <Link
              to={location => {
                const cartPayload = {
                  cartItem: {
                    partner_id: record.partner_id
                  }
                };
                actions.doCreateCartAction(cartPayload);
                return ({
                  ...location,
                  pathname: routers.dashboard.view_detail_order.replace(':order_id', record.id)
                });
              }}
            >
              <button className={cls}>
                {text}
              </button>
            </Link>

          </React.Fragment>
        )
      }),
    }
  ]

  return (
    <React.Fragment>
      <Row gutter={16} id='customer-ordering-table-wrapper'>
        <Col md={24} xs={24}>
          <h3>{getTranslatedText('my_order_list')}</h3>
        </Col>
        <Col md={24} xs={24}>
          <Table
            className='custom-table'
            columns={columns}
            dataSource={orders}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.getOrderReducer.data || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doCreateCartAction,
      },
      dispatch,
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerListOrderingProduct);