import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  vi: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "new_service": "đăng tin mới",
      "service_category": "Chọn danh mục dịch vụ của bạn",
      "job_category": "Chọn ngành nghề cụ thể",
      "service_address": "Địa điểm làm việc",
      "service_name": "Đặt một tên cho dịch vụ của bạn",
      "short_description": "Thông tin tóm tắt chính về dịch vụ của bạn",
      "detail_description": "Hãy mô tả chi tiết về dịch vụ của bạn",
      "tips": "Gợi ý:",
      "button_upload_image": "Thêm hình ảnh/ Video vào bài viết mô tả",
      "button_upload_certificate": "Upload những bằng cấp, chứng chi, chứng nhận liên quan để thể hiện năng lực của bạn với khách hàng:",
      "button_post_service": "ĐĂNG TIN NGAY",
      "contact_info": "THÔNG TIN LIÊN HỆ CỦA BẠN",
      "partner_name": "Tên công ty/ Cá nhân của bạn",
      "partner_email": "Email liên hệ",
      "partner_address": "Địa chỉ liên hệ",
      "partner_city": "Tỉnh/ Thành",
      "partner_phone": "Điện thoại liên hệ",
      "partner_website": "Website của bạn (nếu có)",
      "client_request_quotation_title": "YÊU CẦU BÁO GIÁ",
      "client_service_category": "Chọn danh mục dịch vụ",
      "client_service_job": "Chọn ngành nghề cụ thể",
      "client_quotation_name": "Tiêu đề cần báo giá",
      "client_project_description": "Hãy mô tả chi tiết về dự án của bạn",
      "client_request_skill": "Kỹ năng yêu cầu Công ty/ Freelancer phải có",
      "client_project_location": "Địa điểm làm việc",
      "client_post_request_quotation_button": "GỬI YÊU CẦU NGAY",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "vi",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;