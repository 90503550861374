import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  doCreateCartAction,
} from '../../redux/actions/products';
import {
  doPutOrder
} from '../../redux/actions/order';
import {
  Link,
} from 'react-router-dom';
import {
  Table,
  Row,
  Col,
} from 'antd';

import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

import './styles/table-custom-styles.less';

function PartnerListOrderingProduct(props) {

  const {
    actions,
    orders,
  } = props;

  const columns = [
    {
      title: getTranslatedText('order_code'),
      dataIndex: "code",
      key: "code"
    },
    {
      title: getTranslatedText('sale_method_column'),
      dataIndex: "order_type",
      key: "order_type"
    },
    {
      title: getTranslatedText('date_create_order'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('total_amount'),
      dataIndex: "total",
      key: "total"
    },
    {
      title: getTranslatedText('status_column'),
      render: (record => {
        console.log('table', record);
        let cls = "btn_post_estimate";
        let text = getTranslatedText('view_order');
        if (record.partner_status == "accept") {
          text = getTranslatedText('accepted');
          return (
            <React.Fragment>
              <button className={cls}>
                {text}
              </button>
            </React.Fragment>
          );
        } else if (record.partner_status == "deny") {
          cls = "btn_cancel";
          text = getTranslatedText('is_denied');
          return (
            <React.Fragment>
              <button
                style={{
                  border: 'none',
                  color: 'white'
                }}
                className={cls}
              >
                {text}
              </button>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <Link
                to={location => {
                  const cartItem = {
                    cartItem: {
                      product_id: record.details[0].product_id,
                    },
                    partner_id: record.partner_id
                  };
                  actions.doCreateCartAction(cartItem);
                  return ({
                    ...location,
                    pathname: routers.dashboard.view_detail_order.replace(':order_id', record.id)
                  });}
                }
              >
                <button className={cls}>
                  {text}
                </button>
              </Link>
              <button
                style={{
                  border: 'none',
                  color: 'white'
                }}
                className='btn_cancel'
                onClick={() => {
                  const payload = {
                    orderId: record.id,
                    bodyRequest: {
                      partner_status: 'deny'
                    }
                  }
                  actions.doPutOrder(payload);
                }}
              >
                {getTranslatedText('btn_cancel')}
              </button>
            </React.Fragment>
          );
        };
      }),
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={16} id='partner-ordering-table-wrapper'>
        <Col md={24} xs={24}>
          <h3>{getTranslatedText('my_order_list')}</h3>
        </Col>
        <Col md={24} xs={24}>
          <Table
            className='custom-table'
            columns={columns}
            dataSource={orders}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.getOrderReducer.data || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doCreateCartAction,
        doPutOrder,
      },
      dispatch,
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnerListOrderingProduct);