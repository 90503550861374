import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doLogin } from '../../redux/actions/authentication';
import {
  doGetLocation,
} from '../../redux/actions/category';
import {
  Form,
  Row,
  Col,
  Layout,
  Input,
  Button,
  Select,
} from 'antd';
import './Payment_FormAddress.less';
import { getTranslatedText } from '../../services/appServices';
import {resource} from "../../assets/js/resource";

const Payment_FormAddress = (props) => {

  const {
    actions,
    setPaymentStep,
    setContactShipPayload,
  } = props;

  const onFinish = (values) => {
    console.log('value form', values);
    setContactShipPayload(values);
    setPaymentStep(1);
  };

  return (
    <React.Fragment>
      <Form
        layout='vertical'
        onFinish={(values) => onFinish(values)}
        className='form_address_container'
      >
        <Form.Item
          name='name'
          label={getTranslatedText('label_name')}
          rules={[{required: true, message: 'Please dont let this input emty!'}]}
        >
          <Input
            className='address_form_input_item'
          />
        </Form.Item>
        <Form.Item
          name='phone'
          label={getTranslatedText('label_phone')}
          rules={[{required: true, message: 'Please dont let this input emty!'}]}
        >
          <Input
            className='address_form_input_item'
          />
        </Form.Item>
        <Form.Item
          name='email'
          label={getTranslatedText('label_email')}
          rules={[{required: true, message: 'Please dont let this input emty!'}]}
        >
          <Input
            className='address_form_input_item'
          />
        </Form.Item>
        <Form.Item
          name='address'
          label={getTranslatedText('label_address')}
          rules={[{required: true, message: 'Please dont let this input emty!'}]}
        >
          <Input
            className='address_form_input_item'
          />
        </Form.Item>
        <Form.Item
          name='city'
          label={getTranslatedText('label_city')}
          rules={[{required: true, message: 'Please dont let this input emty!'}]}
        >
          <Select className="form-control address_form_input_item">
            {resource.country.map(item => (
              <Select.Option value={item.name}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row
        >
          <Col span={24}>
            <Form.Item>
              <Button
                className='address_modal_login_button'
                htmlType='submit'
              >
                {getTranslatedText('btn_payment')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doLogin,
        doGetLocation,
      },
      dispatch,
    ),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(Payment_FormAddress);