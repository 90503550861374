/*
This component display data for request estimate, order
Follow account type, we have there different data to display
1. Customer:
  - title: Your Request Estimate
  - Display data for orders which customer create
  - Column: Content, Date, Status
2. Partner: (Use both for partner-service and partner-product)
  - title: Request Estimate
  - Display data for request estimate which create by customer
  - Column: Content, Client, Date, Action

This componet fetch data from 2 api:
1. order [GET]: api/v1/order
2. estimate [GET]: /api/v1/request-quote
*/


import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  doGetQuote,
  doUpdateQuotation,
  doPostEstimate,
} from '../../redux/actions/services';
import {
  doGetOrder,
} from '../../redux/actions/products';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
  Link
} from 'react-router-dom';
import { routers } from '../../config/routers';
import './Dashboard_EstimateRequest.scss';
import { getTranslatedText } from '../../services/appServices';


const customer_columns = [
  {
    title: getTranslatedText('content_column'),
    dataIndex: "service_name",
    key: "service_name"
  },
    
  {
    title: getTranslatedText('date_post'),
    dataIndex: "created_at",
    key: "created_at"
  },
  {
    title: getTranslatedText('status_estimated_column'),
    key: "status",
    render: (record => {
      let cls = "btn_post_estimate";
      let text = getTranslatedText('is_being_considered');
      let router = '';
      if (record.status === 'accept') {
        text = getTranslatedText('accepted');
        cls = 'btn_post_estimate';
        router = routers.dashboard.view_estimate.replace(':quotation_id', record.id);
        return (
          <React.Fragment>
            <Link to={router}>
              <button className={cls}>
                {text}
              </button>
            </Link>
          </React.Fragment>
        );
      } else {
        if (record.status === 'deny') {
          text = getTranslatedText('denied');
          cls = 'btn_post_estimate';
          return (
            <React.Fragment>
              <button
                className={cls}>
                {text}
              </button>
            </React.Fragment>
          );
        } else {
          if (record.status === 'close') {
            text = getTranslatedText('close_estimate');
            cls='btn_post_estimate';
            return (
              <React.Fragment>
                <button className={cls}>
                  {text}
                </button>
              </React.Fragment>
            ); 
          } else {
            return (
              <React.Fragment>
                  <button className={cls}>
                    {text}
                  </button>
              </React.Fragment>
            );
          };
        };
      }
    }),
  }
];


const Dashboard_EstimateRequest = (props) => {


  const {
    quoteData,
    actions,
    accountType
  } = props;

  useEffect(() => {
    actions.doGetQuote();
    actions.doGetOrder();
    console.log('Account', accountType);
  }, [accountType]);

  function handleOnDenyClick(record) {
    const payload = {
      id: record.id,
      status: 'denied'
    };
    actions.doUpdateQuotation(payload);
  };

  const partner_service_column = [
    {
      title: getTranslatedText('content_column'),
      dataIndex: "service_name",
      key: "service_name"
    },
    {
      title: getTranslatedText('customer_id'),
      dataIndex: 'user_request_code',
      key: 'user_request_code'
    },
    {
      title: getTranslatedText('date_post'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('status_estimated_column'),
      key: "status",
      render: (record => {
        let cls = 'btn_post_estimate';
        let text = getTranslatedText('is_being_considered');
        if (record.status === 'waiting') {
          return (
            <React.Fragment>
              <Link
                to={routers.dashboard.send_quotation.replace(':quotation_id', record.id).replace(':service_id', record.service_id)}
              >
                <button
                  // onClick={() => handleAcceptButtonClick(record)}
                  className='btn_post_estimate'
                >
                  {getTranslatedText('send_estimate_now')}
                </button>
              </Link>
              <button
                className='btn_cancel'
                onClick={() => handleOnDenyClick(record)}
              >
                {getTranslatedText('btn_ignore')}
              </button>
            </React.Fragment>
          )
        }
        if (record.status === 'accept') {
          return (
            <React.Fragment>
                <button className={cls}>
                  {text}
                </button>
            </React.Fragment>
          );
        }                
      }),
    }
  ]


  // quoteData
  return (
    <React.Fragment>
      <div style={{marginTop: '100px', marginBottom: '100px'}} className="container bg_white black">
        <div className="row mg-t-30">
          {accountType === 'customer' ? (
            <h1 className="title_sec bg_green">{getTranslatedText('estimate_request_title')}</h1>
          ) : (
            <h1 className="title_sec bg_green">{getTranslatedText('quotation_waiting')}</h1>
          )}
          <div className="col-12 single_service">
            {accountType === 'customer' ? (
              <Table
                dataSource={quoteData}
                columns={customer_columns}
              />
            ) : (
              <Table
                dataSource={quoteData}
                columns={partner_service_column}
              />
            )}
            {/* {quoteData === [] ? (
              <Skeleton/>
            ) : ( */}
              {/* <Table
                dataSource={quoteData}
              >
                {renderTableColumn()}
              </Table> */}
            {/* )} */}
            <Link
              to={routers.dashboard.request_estimate}
            >
              <button className='btn_show'>{getTranslatedText('btn_show_more')}</button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    quoteData: state.quoteReducer.data,
    orderArrayData: state.getOrderReducer.data,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetQuote,
        doPostEstimate,
        doGetOrder,
        doUpdateQuotation,
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard_EstimateRequest);