import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	doGetEstimates,
	doUpdateEstimate,
} from '../../redux/actions/estimate';
import {
	doUpdateQuotation,
} from '../../redux/actions/services';
import {
	useRouteMatch, Link
} from 'react-router-dom';
import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';
import './ClientCheckQuotation.scss';

import OpenEstimateModal from '../OpenEstimateModal/OpenEstimateModal';

const ClientCheckQuotation = (props) => {

	const match = useRouteMatch(routers.dashboard.view_estimate);

	const {
		actions,
		estimatesData,
	} = props;

	const [modalVisible, setModalVisible] = useState(false);
	const [currentEstimateId, setCurrentEstimateId] = useState(null);

	useEffect(() => {
		const quotation_id = parseInt(match.params.quotation_id);
		const requestData = {
			quotation_id: quotation_id
		};
		actions.doGetEstimates(requestData);
	}, []);

	const handleOnAcceptClick = (estimateData, index) => {
		if (estimateData.free) {
			const acceptBody = {
				'status': 'accept'
			};
			const currentEstimateId = estimateData.id;
			const requestPayload = [acceptBody, currentEstimateId];
			actions.doUpdateEstimate(requestPayload);
		} else {
			setModalVisible(true);
			setCurrentEstimateId(estimateData.id);
		}
	};

	const handleOnIgnoreClick = (estimateData) => {
		const acceptBody = {
			'status': 'deny'
		};
		const requestPayload = [acceptBody, estimateData.id]
		actions.doUpdateEstimate(requestPayload)
	};

	const handleFinishServiceRequestProcess = () => {
		const quotation_id = parseInt(match.params.quotation_id);
		const requestBody = {
			id: quotation_id,
			status: "close"
		};
		actions.doUpdateQuotation(requestBody);
	}

	return (
		<React.Fragment>
			<div className="container bg_white black mg-b-60 pd-b-40">
				<div className="row">
					<div className="col-12 p6_service">
						<h1 className="title">{getTranslatedText('estimate_request_title')}</h1>
						<div className="row">
							{/* <div className="col-12">
								<div className="service_name">Tên dịch vụ</div>
							</div> */}
							<div className="col-10">
								<div>{estimatesData.quotation_title}</div>
							</div>
							<div className="col-2 text-right">
								<a href="#">{getTranslatedText('btn_view_service_detail')}</a>
							</div>
						</div>

					</div>
					{/* <!-- Send Quote --> */}
				</div>
				<div className="row">
					<div className="col col-12">
						<h3 className="fs-17 mg-t-20 mg-b-15">{getTranslatedText('client_check_quotation_title')}</h3>
						<p>{getTranslatedText('free_first_quotation')}</p>
					</div>
					{estimatesData.data && estimatesData.data.map((estimateData, index) => (
						<>
						{/*{index === 0 ? (*/}
						{/*	<div className="col col-sm-12 col-md-11">*/}
						{/*			<div className="partner_info mg-l-30 shadow">*/}
						{/*			<div className="left">*/}
						{/*				<img src={require("../../assets/service/avatar.png")} alt="" />*/}
						{/*				<div className="rating">*/}
						{/*					<span className="item active"></span>*/}
						{/*					<span className="item active"></span>*/}
						{/*					<span className="item active"></span>*/}
						{/*					<span className="item active"></span>*/}
						{/*					<span className="item"></span>*/}
						{/*				</div>*/}
						{/*				<div>*/}
						{/*					<a href="" className="btn yellow_2 xs" data-toggle="modal" data-target="#modal_feedback">Xem hồ sơ</a>*/}
						{/*				</div>*/}
						{/*			</div>*/}
						{/*			<div className="right">*/}
						{/*				<div className="title">{estimateData.partner_short_code}</div>*/}
						{/*				<div>Location</div>*/}
						{/*				<div>{estimateData.detail}</div>*/}
						{/*				<div className="career_box">{getTranslatedText('your_business')}*/}
						{/*					/!* <a href="" className="item">Kế toán tài chính</a>*/}
						{/*					<a href="" className="item">Luật doanh nghiệp</a>*/}
						{/*					<a href="" className="item"> Hồ sơ pháp lý </a> *!/*/}
						{/*				</div>*/}
						{/*			</div>*/}
						{/*				<div className="quote">*/}
						{/*					<div className="box">*/}
						{/*						<div className="fs-14 text-gray">{getTranslatedText('quotation_suggest_price')}</div>*/}
						{/*						<div className="flex_2 mg-t-10">*/}
						{/*							<span className="btn white price h36 text_blue auto">{estimateData.cost}</span>*/}
						{/*							/!* <span className="btn white h36 cur auto">VND</span> *!/*/}
						{/*						</div>*/}
						{/*							{estimateData.status == 'waiting' ? (*/}
						{/*							<div className="flex_2 mg-t-20">*/}
						{/*								<button onClick={() => handleOnAcceptClick(estimateData, index)} className="btn green h36 radius_50 mg-r-15 shadow">ĐỒNG Ý</button>*/}
						{/*								<button onClick={() => handleOnIgnoreClick(estimateData)} className="btn red h36 radius_50 shadow">TỪ CHỐI</button>*/}
						{/*							</div>*/}
						{/*						) : (*/}
						{/*								<div className="flex_2 mg-t-20">*/}
						{/*									{estimateData.status == 'accept' ? (*/}
						{/*										<Link*/}
						{/*										to={routers.dashboard.view_partner_profile.replace(':partner_id', estimateData.partner_id)}*/}
						{/*										>*/}
						{/*											<button className="btn green h36 radius_50 mg-r-15 shadow">{getTranslatedText('btn_contact_info')}</button>*/}
						{/*										</Link>*/}
						{/*									) : (*/}
						{/*										<React.Fragment>*/}
						{/*											<button className="btn green h36 radius_50 mg-r-15 shadow">{getTranslatedText('btn_reject_quotation')}</button>*/}
						{/*										</React.Fragment>*/}
						{/*									)}*/}
						{/*								</div>*/}
						{/*							)}*/}
						{/*					</div>*/}
						{/*				</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*) : (*/}
							<div className="col col-sm-12 col-md-11">
								<div className="partner_info mg-l-30 shadow">
									<div className="left">
										<img src={require("../../assets/service/avatar.png")} alt="" />
										<div className="rating">
											<span className="item active"></span>
											<span className="item active"></span>
											<span className="item active"></span>
											<span className="item active"></span>
											<span className="item"></span>
										</div>
										<div>
											<a href="" className="btn yellow_2 xs" data-toggle="modal" data-target="#modal_feedback">Xem hồ sơ</a>
										</div>
									</div>
									<div className="right">
										<div className="title">{estimateData.partner_short_code}</div>
										<div>Location: {estimateData.partner_location}</div>
										<div>{estimateData.detail}</div>
										<div className="career_box">{getTranslatedText('your_business')}
											{estimateData.partner_categories.length > 0 && estimateData.partner_categories.map((item, index) => (
												<a href={null} className="item" key={index}>{item}</a>
											))}
										</div>
									</div>
										<div className="quote">
											<div className="box">
												<div className="fs-14 text-gray">{getTranslatedText('quotation_suggest_price')}</div>
												<div className="flex_2 mg-t-10">
													<span className="btn white price h36 text_blue auto">{estimateData.cost}</span>
													{/* <span className="btn white h36 cur auto">VND</span> */}
												</div>
												{estimateData.status == 'waiting' ? (
													<div className="flex_2 mg-t-20">
														<button onClick={() => handleOnAcceptClick(estimateData, index)} className="btn green h36 radius_50 mg-r-15 shadow">ĐỒNG Ý</button>
														<button onClick={() => handleOnIgnoreClick(estimateData)} className="btn red h36 radius_50 shadow">TỪ CHỐI</button>
													</div>
												) : (
														<div className="flex_2 mg-t-20">
															{estimateData.status == 'accept' ? (
																<Link
																to={routers.dashboard.view_partner_profile.replace(':partner_id', estimateData.partner_id)}
																>
																	<button className="btn green h36 radius_50 mg-r-15 shadow">{getTranslatedText('btn_contact_info')}</button>
																</Link>
															) : (
																<React.Fragment>
																	<button className="btn green h36 radius_50 mg-r-15 shadow">{getTranslatedText('btn_reject_quotation')}</button>
																</React.Fragment>
															)}
														</div>
													)}
											</div>
										</div>
									{/* ) : (
										<div className="quote">
											<div className="box">
												<div className="flex_2 mg-t-20">
													<button onClick={() => handleOpenEstimateClick(estimateData)} className="btn green h36 radius_50 mg-r-15 shadow">MỞ BÁO GIÁ</button>
												</div>
											</div>
										</div>
									)} */}
								</div>
							</div>
						{/*)}*/}
						</>
					))}
				</div>
				{/* <!-- end row --> */}
				{estimatesData.close === 1 ? (
					null
				) : (
					<button
					id='finish_process_btn'
					onClick={() => handleFinishServiceRequestProcess()}
					>
						{getTranslatedText('finish_process_btn')}
					</button>
				)}
				
			</div>

			{/* Popup open estimate */}
			<OpenEstimateModal
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				currentEstimateId={currentEstimateId}
			/>

			{/* <!-- Popup feedback --> */}
			<div className="modal black fade " id="modal_feedback" tabindex="-1 " role="dialog " aria-labelledby="modal_feedback" aria-hidden="true ">
				<div className="modal-dialog modal-dialog-centered mw_1048 " role="document ">
					<div className="modal-content modal_feedback">
						<div className="left ">
							<div className="avatar">
								<img src={require("../../assets/service/avatar.png")} alt="" />
							</div>
							<div className="info">
								<div className="title">Jame Nguyen</div>
								<div className="sub">Chuyên viên báo cáo tài chính</div>
							</div>
							<div className="rating">
								<span className="item active"></span>
								<span className="item active"></span>
								<span className="item active"></span>
								<span className="item active"></span>
								<span className="item"></span>
								<span className="text">1.200 người đã đánh giá</span>
							</div>
							<div className="career_box">
								<p className="title">NGÀNH NGHỀ HOẠT ĐỘNG</p>
								<a href="" className="item">Kế toán tài chính</a>
								<a href="" className="item">Luật doanh nghiệp</a>
								<a href="" className="item"> Hồ sơ pháp lý </a>
							</div>
							<div className="content">
								<p>Hiện tại mình đang làm việc cho công ty nước ngoài, vị trí kế toán trưởng. Mình tham gia B2BGOSELL để tìm kiếm việc làm thêm ngoài giờ ( nhận sổ sách về làm, làm báo cáo thuế, báo cáo tài chính cuối năm,.._</p>
								<p>Mình có 5 năm kinh nghiệm về báo cáo tài chính.</p>
								<p>Hy vọng sẽ giúp cho công việc của các công ty được giải quyết nhanh chóng và hiệu quả</p>
							</div>

							<form className="review_form">
								<div className="title">ĐÁNH GIÁ CỦA BẠN CHO ĐỐI TÁC NÀY</div>
								<div className="rating review">
									<span className="item"></span>
									<span className="item"></span>
									<span className="item"></span>
									<span className="item"></span>
									<span className="item"></span>
								</div>
								<div>
									<button className="btn gray ">Đánh giá</button>
								</div>
							</form>
						</div>
						{/* <!-- end left --> */}
						<div className="right ">
							<span className="close" data-dismiss="modal"></span>
							<div className="comment_box">
								<div className="top">
									<span className="title">PHẢN HỒI Ý KIẾN</span>
									<a href="http://" className="share">share</a>
								</div>
								<form action="" className="">
									<textarea name="comment" id="" placeholder="Viết nhận xét của bạn tại đây..."></textarea>
									<button className="btn yellow_2 small send pd-lr-20">GỬI</button>
								</form>
								<div className="list_comment">
									<div className="item">
										<div className="img">
											<img src={require("../../assets/service/avt_small_1.png")} alt="" />
										</div>
										<div className="content">
											<div>
												<span className="name">Nguyễn Hoàng</span>
												<span className="time">1h trước</span>
											</div>
											<div>
												Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống kịp thời, giúp chúng tôi giải quyết nhanh chóng công việc.
											</div>
										</div>
									</div>
									<div className="item">
										<div className="img">
											<img src={require("../../assets/service/avt_small_2.png")} alt="" />
										</div>
										<div className="content">
											<div>
												<span className="name">Phạm Thúy Vân</span>
												<span className="time">2 ngày trước</span>
											</div>
											<div>
												Cho tôi sự yên tâm trọn vẹn khi thực hiện công việc, và mang đến những giá trị cao khi hợp tác giải quyết các công việc với đơn vị chức năng.
											</div>
										</div>
									</div>
									<div className="item">
										<div className="img">
											<img src={require("../../assets/service/avatar.png")} alt="" />
										</div>
										<div className="content">
											<div>
												<span className="name">Nguyễn Hoàng</span>
												<span className="time">1h trước</span>
											</div>
											<div>
												Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống kịp thời, giúp chúng tôi giải quyết nhanh chóng công việc.
											</div>
										</div>
									</div>
								</div>
								{/* <!-- End list cmt --> */}
							</div>
						</div>
						{/* <!-- End right --> */}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
  return {
    estimatesData: state.getEstimatesReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
			{
				doGetEstimates,
				doUpdateEstimate,
				doUpdateQuotation
      },
      dispatch
    )
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ClientCheckQuotation);