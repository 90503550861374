import {
  GET_PAYMENTS_REQUEST,
  PUT_PAYMENTS_REQUEST,
} from './action_types';

export const doGetPayments = (payload) => ({
  type: GET_PAYMENTS_REQUEST,
  payload: payload
});

export const doPutPayments = (payload) => ({
  type: PUT_PAYMENTS_REQUEST,
  payload: payload
});