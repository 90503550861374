import React from 'react';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
// Saga Import
import createSagaMiddleware from 'redux-saga';
import mainSaga from '../src/redux/sagas/mainSaga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './redux/reducers/rootReducer';

import apiService from './services/apiService';
import { render, hydrate } from 'react-dom';
import { createBrowserHistory } from 'history';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { HelmetProvider } from 'react-helmet-async';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  // composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(mainSaga);

global.apiService = new apiService();

export const history = createBrowserHistory();

const rootElement = document.getElementById('root');
hydrate(
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
