import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  Button,
  Form,
  Select,
} from 'antd';
import 'antd/dist/antd.css';
import {doUploadAction} from '../../redux/actions/upload';
import {doPostProductRequest,} from '../../redux/actions/products';
import {
  doGetCategoryProduct,
  doGetChildrenCategoryProduct,
} from '../../redux/actions/category';
import { getTranslatedText } from '../../services/appServices';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import placeholder from "../../assets/icon/input_file_plus.png";


const ProductForm = (props) => {

  const {
    actions,
		rootProductCategory,
		childProductCategory,
  } = props;

  useEffect(() => {
    actions.doGetCategoryProduct();
  }, []);

  const uploadFile = async (id) => {
    let files = document.getElementById(id).files;
    for (let i = 0; i < 6; i++){
      if (document.getElementById(id + "_" + i)){
        if (files[i])
          document.getElementById(id + "_" + i).src = URL.createObjectURL(files[i]);
        else
          document.getElementById(id + "_" + i).src = placeholder;
      }
    }
  };

  const handleOnFinishChange = (value) => {
    const productData = {
      category_id: value.category_id,
      child_category_id: value.child_category_id,
      name: value.name,
      price: value.price,
      market_price: value.market_price,
      unit: value.unit,
      unit_price: value.unit_price,
      summary: value.summary,
      detail: value.detail
    };
    let avatar = document.getElementById("avatar").files;
    if (avatar.length > 0)
      productData["avatar"] = avatar[0];
    let gallery = document.getElementById("images").files;
    for (let i = 0; i < gallery.length; i++){
      productData["images_" + i] = gallery[i];
    }
    let certificate = document.getElementById("certificate").files;
    for (let i = 0; i < certificate.length; i++){
      productData["certificate_" + i] = gallery[i];
    }
    actions.doPostProductRequest(productData);
  };

  const handleOnParentCategoryChange = (value) => {
    console.log(value);
    actions.doGetChildrenCategoryProduct(value);
  };


  return (
    <React.Fragment>
      <div className="container main_product mg-b-60">
        <div className="row justify-content-center">
          <Form onFinish={(value) => handleOnFinishChange(value)}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
              <h1 className="title_sec center border_bottom">
                {getTranslatedText('btn_post_new_product')}
              </h1>
              <div className="text-center pd-b-30">
                {getTranslatedText('tell_about_product')}
              </div>
              <h2 className="title_sec bg_green">{getTranslatedText('products_title')}</h2>
              <div className="box_product_post border-green">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="name">{getTranslatedText('select_product_category')}</label>
                      <Form.Item
                        name='category_id'
                      >
                        <Select onChange={(value) => handleOnParentCategoryChange(value)}>
                          {rootProductCategory.map(item => (
                            <Select.Option value={item.id}>{item.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="name">{getTranslatedText('select_invidual_product')}</label>
                      <div className="dropdown">
												<Form.Item
													name='child_category_id'
												>
													<Select >
														{childProductCategory.map(item => (
															<Select.Option value={item.id}>{item.name}</Select.Option>
														))}
													</Select>
												</Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <Form.Item name='name'>
                      <div className="form-group">
                        <label htmlFor="name">{getTranslatedText('product_name')}</label>
                        <input type="text" className="form-control" placeholder={getTranslatedText('product_name')}/>
                      </div>
                    </Form.Item>
                    <div className="flex_4_pr">
                      <Form.Item name='unit'>
                        <div className="form-group">
                          <label htmlFor="name">{getTranslatedText('unit_count')}</label>
                          <input type="text" className="form-control" placeholder="Kg"/>
                        </div>
                      </Form.Item>
                      <Form.Item name='price'>
                        <div className="form-group">
                          <label htmlFor="name">{getTranslatedText('price')}</label>
                          <input type="text" className="form-control" placeholder={getTranslatedText('price')}/>
                        </div>
                      </Form.Item>
                      <Form.Item name='market_price'>
                        <div className="form-group">
                          <label htmlFor="name">{getTranslatedText('market_price')}</label>
                          <input type="text" className="form-control" placeholder={getTranslatedText('market_price')}/>
                        </div>
                      </Form.Item>
                      <div className="form-group">
                        <label htmlFor="name">{getTranslatedText('money_unit')}</label>
                        <Form.Item name='unit_price'>
                          <Select>
                            <Select.Option value={1}>
                              VND
                            </Select.Option>
                            <Select.Option value={2}>
                              USD
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <Form.Item name='summary'>
                      <div className="form-group">
                        <label htmlFor="name">{getTranslatedText('product_summary_info')}</label>
                        <textarea className="form-control info" placeholder={getTranslatedText('product_summary_info')}></textarea>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="name">{getTranslatedText('select_avatar')}</label>
                      <div className="input_file">
                        <label htmlFor="avatar">
                          <input type="file" id="avatar" accept="image/*" onChange={() => uploadFile("avatar")}/> {getTranslatedText('upload')}</label>
                      </div>
                      <div className="gril_3_col">
                        <img src={placeholder} id="avatar_0" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <Form.Item name='detail'>
                      <SunEditor
                        name="my-editor" 
                        enableToolbar={true}
                        height='700'
                        setOptions={{
                          imageFileInput: true,
                          buttonList: [
                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                            ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                          ]
                        }}
                        onFocus={(event) => {console.log(event)}}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4">
                    <div className="form-group">
                      <Form.Item>
                        <div className="input_file">
                          <label htmlFor="images">

                            <input
                              type="file"
                              id="images"
                              accept="image/*"
                              multiple={true}
                              onChange={() => uploadFile("images")}
                            />
                            {getTranslatedText('upload')}
                          </label>
                        </div>
                        <div className="gril_3_col">
                          <img src={placeholder} id="images_0" alt=""/>
                          <img src={placeholder} id="images_1" alt=""/>
                          <img src={placeholder} id="images_2" alt=""/>
                          <img src={placeholder} id="images_3" alt=""/>
                          <img src={placeholder} id="images_4" alt=""/>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 mg-t-15">
                    <div className="form-group">
                      <Form.Item>
                        <label htmlFor="name">{getTranslatedText('product_upload_suggest')}</label>
                        <div className="input_file">
													<label htmlFor="certificate">
                            <input type="file" id="certificate" accept="image/*" multiple={true} onChange={() => uploadFile("certificate")}/> {getTranslatedText('upload')}</label>
												</div>
                        <div className="gril_3_col">
                          <img src={placeholder} id="certificate_0" alt=""/>
                          <img src={placeholder} id="certificate_1" alt=""/>
                          <img src={placeholder} id="certificate_2" alt=""/>
                          <img src={placeholder} id="certificate_3" alt=""/>
                          <img src={placeholder} id="certificate_4" alt=""/>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End box post --> */}
              <div className="mg-tb-40">
                <Form.Item>
                  <label className="checkbox_cs">
                    {getTranslatedText('post_product_aggrement_1')} <a href="">{getTranslatedText('post_product_aggrement_2')}</a> {getTranslatedText('post_product_aggrement_3')} <b>B2B
                    GOSELL</b>
                    {getTranslatedText('post_product_aggrement_4')}
                    <input type="checkbox"/>
                    <span className="checkmark"></span>
                  </label>
                </Form.Item>
              </div>
              <div className="mg-b-30">
                <Form.Item>
                  <Button htmlType='submit' className="btn green shadow text_black">{getTranslatedText('post_product_now')}</Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {/* <!-- End Content --> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    rootProductCategory: state.productCategoryReducer.parentProductCategory,
    childProductCategory: state.productCategoryReducer.childrenProductCategory,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doUploadAction,
        doPostProductRequest,
        doGetCategoryProduct,
        doGetChildrenCategoryProduct,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductForm);