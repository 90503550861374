import { put } from "redux-saga/effects";
import * as actionTypes from "../actions/action_types";
import { supportedLanguage } from "../../config/lang";

export default {
  getCurrentLanguage: function* () {
    try {
      let lang = localStorage.getItem("current_language") || "vi";
      if (supportedLanguage.indexOf(lang) < 0) {
        lang = "vi";
      }
      yield put({
        type: actionTypes.GET_CURRENT_LANGUAGE_SUCCESS,
        payload: lang,
      });
    } catch (error) {
      yield put({
        type: actionTypes.GET_CURRENT_LANGUAGE_FAILURE,
        error: error,
      });
    }
  },
  setCurrentLanguage: function* (action) {
    try {
      let languageToSet = action.payload;
      if (supportedLanguage.indexOf(languageToSet) < 0) {
        languageToSet = "vi";
      }
      localStorage.setItem("current_language", languageToSet);
      yield put({
        type: actionTypes.SET_CURRENT_LANGUAGE_SUCCESS,
        payload: languageToSet,
      });
    } catch (error) {
      yield put({
        type: actionTypes.SET_CURRENT_LANGUAGE_FAILURE,
        error: error,
      });
    }
  },
};
