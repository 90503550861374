import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  Link,
} from 'react-router-dom';
import {
  Row,
  Col,
  Table,
} from 'antd';

import { getTranslatedText } from '../../services/appServices';
import { routers } from '../../config/routers';
import './styles/table-custom-styles.less';


function PartnerListQuotation(props) {

  const {
    estimates,
  } = props;

  const columns = [
    {
      title: getTranslatedText('content_column'),
      dataIndex: "service_title",
      key: "service_title"
    },
    {
      title: getTranslatedText('date_post'),
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: getTranslatedText('status_estimated_column'),
      dataIndex: "status",
      key: "status",
      render: (status => {
        let cls = "btn_post_estimate";
        let text = getTranslatedText('is_being_considered');
        if (status === "accept")
          text = getTranslatedText('accepted');
        if (status == "deny") {
          cls = "btn_cancel";
          text = getTranslatedText('is_denied');
        }
        return (
            <React.Fragment>
              <button className={cls}>
                {text}
              </button>
            </React.Fragment>
        )
      }),
    }
  ]

  return (
    <React.Fragment>
      <Row gutter={16} id='partner-list-quotation-wrapper'>
        <Col md={24} xs={24}>
          <h3>{getTranslatedText('estimate_sent_client')}</h3>
        </Col>
        <Col md={24} xs={24}>
          <Table
            className='custom-table'
            columns={columns}
            dataSource={estimates}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    estimates: state.getEstimatesReducer.data || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerListQuotation);