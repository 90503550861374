import React, { useEffect } from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {
	doGetAllProduct,
	doGetProductWithFilter,
} from '../../redux/actions/products';
import { getTranslatedText } from '../../services/appServices';

import ProductCardComponent from '../ProductCardComponent/ProductCardComponent';


import ProductNewFilterComponent from '../ProductFilterComponent/ProductFilterNewComponent';

const ProductListComponent = (props) => {

	const {
		actions,
		match,
		productsArray
	} = props;

	useEffect(() => {
		if(match.params.category_id === '0') {
			actions.doGetAllProduct();
		} else {
			const filterValue = {
				category_id: match.params.category_id,
				product_business_category: null,
				location: null,
			};
			actions.doGetAllProduct(filterValue);
		}
	}, []);

	return (
		<React.Fragment>
			<div className="container">
				<div className="row">
					<ProductNewFilterComponent />
					<h2 className="title_sec border_top white">SẢN PHẨM NỔI BẬT</h2>
					<div className="list_product">
						{productsArray && productsArray.map((product) => (
						<ProductCardComponent
							id={product.id}
							unit={product.unit}
							short_name={product.short_name}
							unit_price={product.unit_price}
							category_id={product.category_id}
							avatar={product.avatar}
							name={product.name}
							price={product.price}
							market_price={product.market_price}
						/>
						))}
					</div>
					{/* <!-- End san pham ban chay --> */}
					{/* <div className="col col-12 center">
						<nav aria-label="Page navigation example">
							<ul className="pagination category_pagination">
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</a>
								</li>
								<li className="page-item"><a className="page-link active" href="#">1</a></li>
								<li className="page-item"><a className="page-link" href="#">2</a></li>
								<li className="page-item"><a className="page-link" href="#">3</a></li>
								<li className="page-item"><a className="page-link" href="#">4</a></li>
								<li className="page-item"><a className="page-link" href="#">5</a></li>
								<li className="pd-5">...</li>
								<li className="page-item"><a className="page-link" href="#">30</a></li>
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</a>
								</li>
								<li className="page-item">
									<select name="" id="">
										<option value="">20 / trang</option>
										<option value="">15 / trang</option>
										<option value="">20 / trang</option>
										<option value="">25 / trang</option>
									</select>
								</li>
							</ul>
						</nav>
					</div> */}
				</div>
			</div>
			{/* <!-- End Product --> */}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		productsArray: state.getProductsReducer.productArray,
	}
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
				doGetAllProduct,
				doGetProductWithFilter
      },
      dispatch,
    ),
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)
(ProductListComponent);