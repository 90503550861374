import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {
  Table,
} from 'antd';
// import './Dashboard_EstimatedHistory.scss';
import {
  Link,
} from 'react-router-dom';
import {
  doCreateCartAction,
} from '../../redux/actions/products';
import {
  doPutOrder
} from '../../redux/actions/order';

import {getTranslatedText} from '../../services/appServices';
import {routers} from '../../config/routers';

const {Column} = Table;

const OrderTableList = (props) => {

  const {
    orderArrayData,
    accountType,
    actions,
  } = props;

  const renderTableColumn = () => {
    switch (accountType) {
      case 'customer':
        return (
          <React.Fragment>
            <Column
              title={getTranslatedText('order_code')}
              dataIndex='code'
              key='code'
            />
            <Column
              title={getTranslatedText('sale_method_column')}
              dataIndex='order_type'
              key='order_type'
            />
            <Column
              title={getTranslatedText('date_create_order')}
              dataIndex='created_at'
              key='created_at'
            />
            <Column
              title={getTranslatedText('total_amount')}
              dataIndex='total'
              key='total'
            />
            <Column
              title={getTranslatedText('status_column')}
              dataIndex='status'
              key='status'
              render={(record) => {
                // console.log(record);
                let cls = "btn_post_estimate";
                let text = getTranslatedText('is_being_considered');
                if (record == "accept") {
                  text = getTranslatedText('accepted');
                  return (
                    <React.Fragment>
                      <Link
                        to={routers.dashboard.view_detail_order.replace(':order_detail', record.id)}
                      >
                        <button className={cls}>
                          {text}
                        </button>
                      </Link>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment>
                      <button className={cls}>
                        {text}
                      </button>
                    </React.Fragment>
                  )
                }
              }}
            />
            <Column
              title={getTranslatedText('partner_status_column')}
              dataIndex='partner_status'
              key='partner_status'
              render={(record) => {
                // console.log(record);
                let cls = "btn_post_estimate";
                let text = getTranslatedText('is_being_considered');
                if (record == "accept") {
                  text = getTranslatedText('accepted');
                  return (
                    <React.Fragment>
                      <Link
                        to={routers.dashboard.view_detail_order.replace(':order_detail', record.id)}
                      >
                        <button className={cls}>
                          {text}
                        </button>
                      </Link>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment>
                      <button className={cls}>
                        {text}
                      </button>
                    </React.Fragment>
                  )
                }
              }}
            />
          </React.Fragment>
        )
      case 'partner-product':

        return (
          <React.Fragment>
            <Column
              title={getTranslatedText('order_code')}
              dataIndex='code'
              key='code'
            />
            <Column
              title={getTranslatedText('sale_method_column')}
              dataIndex='order_type'
              key='order_type'
            />
            <Column
              title={getTranslatedText('date_create_order')}
              dataIndex='created_at'
              key='created_at'
            />
            <Column
              title={getTranslatedText('total_amount')}
              dataIndex='total'
              key='total'
            />
            <Column
              title={getTranslatedText('status_column')}
              render={(record) => {

                let cls = "btn_post_estimate";
                let text = getTranslatedText('view_order');
                if (record.partner_status === "accept") {
                  text = getTranslatedText('accepted');
                  return (
                    <React.Fragment>
                      <button className={cls}>
                        {text}
                      </button>
                    </React.Fragment>
                  )
                } else if (record.partner_status === 'deny') {
                  cls = "btn_cancel";
                  text = getTranslatedText('is_denied');
                  return (
                    <React.Fragment>
                      <button
                        style={{
                          border: 'none',
                          color: 'white'
                        }}
                        className={cls}
                      >
                        {text}
                      </button>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment>
                      <Link
                        to={location => {
                          const cartItem = {
                            cartItem: {
                              product_id: record.details[0].product_id,
                            },
                            partner_id: record.partner_id
                          };
                          actions.doCreateCartAction(cartItem);
                          return ({
                            ...location,
                            pathname: routers.dashboard.view_detail_order.replace(':order_id', record.id)
                          });
                          // routers.dashboard.view_detail_order.replace(':order_id', record.id)
                        }
                        }
                      >
                        <button className={cls}>
                          {text}
                        </button>
                      </Link>
                      <button
                        style={{
                          border: 'none',
                          color: 'white'
                        }}
                        className='btn_cancel'
                        onClick={() => {
                          const payload = {
                            orderId: record.id,
                            bodyRequest: {
                              partner_status: 'deny'
                            }
                          }
                          actions.doPutOrder(payload);
                        }}
                      >
                        {getTranslatedText('btn_cancel')}
                      </button>
                    </React.Fragment>
                  )
                }
              }}
            />
          </React.Fragment>
        )
      default:
        return (
          <React.Fragment>
          </React.Fragment>
        )
    }
  }

  // const order_column = [
  //   {
  //     title: getTranslatedText('order_code'),
  //     dataIndex: "code",
  //     key: "code"
  //   },
  //   {
  //     title: getTranslatedText('sale_method_column'),
  //     dataIndex: "order_type",
  //     key: "order_type"
  //   },
  //   {
  //     title: getTranslatedText('date_create_order'),
  //     dataIndex: "created_at",
  //     key: "created_at"
  //   },
  //   {
  //     title: getTranslatedText('total_amount'),
  //     dataIndex: "total",
  //     key: "total"
  //   },
  //   {
  //     title: getTranslatedText('status_column'),
  //     dataIndex: "status",
  //     key: "status",
  //     render: ((record) => {
  //       console.log(record)
  //       let cls = "btn_post_estimate";
  //       let text = getTranslatedText('is_being_considered');
  //       switch (accountType) {
  //         case 'customer' || 'partner-service':
  //           if (record.status === "accept") {
  //             text = getTranslatedText('accepted');
  //             return (
  //               <React.Fragment>
  //                 <Link
  //                   to={routers.dashboard.view_detail_order.replace(':order_detail', record.id)}
  //                 >
  //                   <button className={cls}>
  //                     {text}
  //                   </button>
  //                 </Link>
  //               </React.Fragment>
  //             )
  //           }
  //           else if (record.status == "deny") {
  //             cls = "btn_cancel";
  //             text = getTranslatedText('is_denied');
  //             return (
  //               <React.Fragment>
  //                 <button className={cls}>
  //                   {text}
  //                 </button>
  //               </React.Fragment>
  //             )
  //           }
  //           else {
  //             return (
  //               <React.Fragment>
  //                 <button className={cls}>
  //                   {text}
  //                 </button>
  //               </React.Fragment>
  //             )
  //           }
  //         case 'partner-product':
  //           let cls = "btn_post_estimate";
  //           let text = getTranslatedText('view_order');
  //           if (record.status === "accept") {
  //             text = getTranslatedText('accepted');
  //             return (
  //               <React.Fragment>
  //                   <button className={cls}>
  //                     {text}
  //                   </button>
  //               </React.Fragment>
  //             )
  //           }
  //           else if (record.status == "deny") {
  //             cls = "btn_cancel";
  //             text = getTranslatedText('is_denied');
  //             return (
  //               <React.Fragment>
  //                 <button className={cls}>
  //                   {text}
  //                 </button>
  //               </React.Fragment>
  //             )
  //           }
  //           else {
  //             return (
  //               <React.Fragment>
  //                 <Link
  //                   to={routers.dashboard.view_detail_order.replace(':order_id', record.id)}
  //                 >
  //                   <button className={cls}>
  //                     {text}
  //                   </button>
  //                 </Link>
  //               </React.Fragment>
  //             )
  //           }
  //         }
  //       }
  //     )
  //   }
  // ];

  return (
    <React.Fragment>
      <div style={{marginBottom: '50px'}} className='container bg_white black estimated_history_container'>
        <div className="row mg-t-30">
          <h1 className='title_sec bg_green'>{getTranslatedText('my_order_list').toUpperCase()}</h1>
          <div className="col-12 single_service">
            <Table
              scroll={{x: true}}
              dataSource={orderArrayData}
            >
              {renderTableColumn()}
            </Table>
            <Link
              to={routers.dashboard.view_order}
            >
              <button className='btn_show'>Show More</button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.getOrderReducer.data || [],
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
        doCreateCartAction,
        doPutOrder,
      },
      dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderTableList);