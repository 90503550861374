import * as actionTypes from '../actions/action_types';
import { put } from 'redux-saga/effects';


export default {
  postFile: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/upload-file', payload);
      console.log('response', response);
      if (response.data.isSuccess) {
        let newService = response.data.data
        yield put({
          type: actionTypes.UPLOAD_FILE_SUCCESS,
          payload: newService,
        })
      } else {
        yield put({
          type: actionTypes.UPLOAD_FILE_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.UPLOAD_FILE_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  }
};