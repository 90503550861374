// GET USER FROM COOKIE
export const GET_USER_FROM_COOKIE_REQUEST = 'GET_USER_FROM_COOKIE_REQUEST';
export const GET_USER_FROM_COOKIE_SUCCESS = 'GET_USER_FROM_COOKIE_SUCCESS';
export const GET_USER_FROM_COOKIE_FAILURE = 'GET_USER_FROM_COOKIE_FAILURE';

// AUTHENTICATE ACTION TYPES
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// AUTHENTICATE ACTION TYPES
export const SEND_EMAIL_RESET_PASSWORD_REQUEST = 'SEND_EMAIL_RESET_PASSWORD_REQUEST';
export const SEND_EMAIL_RESET_PASSWORD_SUCCESS = 'SEND_EMAIL_RESET_PASSWORD_SUCCESS';
export const SEND_EMAIL_RESET_PASSWORD_FAILURE = 'SEND_EMAIL_RESET_PASSWORD_FAILURE';

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GET_USER_CODE_REQUEST = 'GET_USER_CODE_REQUEST';
export const GET_USER_CODE_SUCCESS = 'GET_USER_CODE_SUCCESS';
export const GET_USER_CODE_FAILURE = 'GET_USER_CODE_FAILURE';

// USER TYPES
export const PARTNER_REGISTER = 'PARTNER_REGISTER';
export const CUSTOMER_REGISTER = 'CUSTOMER_REGISTER';
export const FREELANCER_REGISGER = 'FREELANCER_REGISGER';
export const COMPANY_REGISTER = 'COMPANY_REGISTER';
export const SALE_TYPE = 'SALE_TYPE';


// LANGUAGE TYPES
export const GET_CURRENT_LANGUAGE_REQUEST = 'GET_CURRENT_LANGUAGE_REQUEST';
export const GET_CURRENT_LANGUAGE_SUCCESS = 'GET_CURRENT_LANGUAGE_SUCCESS';
export const GET_CURRENT_LANGUAGE_FAILURE = 'GET_CURRENT_LANGUAGE_FAILURE';
export const SET_CURRENT_LANGUAGE_REQUEST = 'SET_CURRENT_LANGUAGE_REQUEST';
export const SET_CURRENT_LANGUAGE_SUCCESS = 'SET_CURRENT_LANGUAGE_SUCCESS';
export const SET_CURRENT_LANGUAGE_FAILURE = 'SET_CURRENT_LANGUAGE_FAILURE';

// GET CATEGORY TYPES
export const GET_CATEGORY_SERVICE_REQUEST = 'GET_CATEGORY_SERVICE_REQUEST';
export const GET_CATEGORY_SERVICE_SUCCESS = 'GET_CATEGORY_SERVICE_SUCCESS';
export const GET_CATEGORY_SERVICE_FAILURE = 'GET_CATEGORY_SERVICE_FAILURE';

export const GET_CATEGORY_PRODUCT_REQUEST = 'GET_CATEGORY_PRODUCT_REQUEST';
export const GET_CATEGORY_PRODUCT_SUCCESS = 'GET_CATEGORY_PRODUCT_SUCCESS';
export const GET_CATEGORY_PRODUCT_FAILURE = 'GET_CATEGORY_PRODUCT_FAILURE';

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

export const GET_CHILDREN_CATEGORY_PRODUCT_REQUEST = 'GET_CHILDREN_CATEGORY_PRODUCT_REQUEST';
export const GET_CHILDREN_CATEGORY_PRODUCT_SUCCESS = 'GET_CHILDREN_CATEGORY_PRODUCT_SUCCESS';
export const GET_CHILDREN_CATEGORY_PRODUCT_FAILURE = 'GET_CHILDREN_CATEGORY_PRODUCT_FAILURE';
export const GET_CHILDREN_CATEGORY_SERVICE_REQUEST = 'GET_CHILDREN_CATEGORY_SERVICE_REQUEST';
export const GET_CHILDREN_CATEGORY_SERVICE_SUCCESS = 'GET_CHILDREN_CATEGORY_SERVICE_SUCCESS';
export const GET_CHILDREN_CATEGORY_SERVICE_FAILURE = 'GET_CHILDREN_CATEGORY_SERVICE_FAILURE';

export const GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_REQUEST = 'GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_REQUEST';
export const GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_SUCCESS = 'GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_SUCCESS';
export const GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_FAILURE = 'GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_FAILURE';

// GET LOCATION
export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';

// SERVICES TYPES
export const POST_NEW_SERVICE_REQUEST = 'POST_NEW_SERVICE_REQUEST';
export const POST_NEW_SERVICE_SUCCESS = 'POST_NEW_SERVICE_SUCCESS';
export const POST_NEW_SERVICE_FAILURE = 'POST_NEW_SERVICE_FAILURE';

export const SET_CURRENT_SERVICE = 'SET_CURRENT_SERVICE';

// GET SERVICES
export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';

export const GET_SERVICE_WITH_FILTER_REQUEST = 'GET_SERVICE_WITH_FILTER_REQUEST';
export const GET_SERVICE_WITH_FILTER_SUCCESS = 'GET_SERVICE_WITH_FILTER_SUCCESS';
export const GET_SERVICE_WITH_FILTER_FAILURE = 'GET_SERVICE_WITH_FILTER_FAILURE';


export const GET_DETAIL_SERVICE_REQUEST = 'GET_DETAIL_SERVICE_REQUEST';
export const GET_DETAIL_SERVICE_SUCCESS = 'GET_DETAIL_SERVICE_SUCCESS';
export const GET_DETAIL_SERVICE_FAILURE = 'GET_DETAIL_SERVICE_FAILURE';

export const GET_QUOTE_REQUEST = 'GET_QUOTE_REQUEST';
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS';
export const GET_QUOTE_FAILURE = 'GET_QUOTE_FAILURE';

export const GET_DETAIL_QUOTE_REQUEST = 'GET_DETAIL_QUOTE_REQUEST';
export const GET_DETAIL_QUOTE_SUCCESS = 'GET_DETAIL_QUOTE_SUCCESS';
export const GET_DETAIL_QUOTE_FAILURE = 'GET_DETAIL_QUOTE_FAILURE';

export const POST_QUOTE_REQUEST = 'POST_QUOTE_REQUEST';
export const POST_QUOTE_SUCCESS = 'POST_QUOTE_SUCCESS';
export const POST_QUOTE_FAILURE = 'POST_QUOTE_FAILURE';

export const POST_ESTIMATE_REQUEST = 'POST_ESTIMATE_REQUEST';
export const POST_ESTIMATE_SUCCESS = 'POST_ESTIMATE_SUCCESS';
export const POST_ESTIMATE_FAILURE = 'POST_ESTIMATE_FAILURE';

// PAYMENT
export const GET_PACKAGE_REQUEST = 'GET_PACKAGE_REQUEST';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_FAILURE = 'GET_PACKAGE_FAILURE';
export const UPDATE_PACKAGE_REQUEST = 'UPDATE_PACKAGE_REQUEST';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILURE = 'UPDATE_PACKAGE_FAILURE';

//CART
export const ORDER_STEP_PAYMENT = 'ORDER_STEP_PAYMENT';

//PRODUCT
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const GET_ALL_PRODUCT_REQUEST = 'GET_ALL_PRODUCT_REQUEST';
export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';
export const GET_ALL_PRODUCT_FAILURE = 'GET_ALL_PRODUCT_FAILURE'; 

export const POST_PRODUCT_REQUEST = 'POST_PRODUCT_REQUEST';
export const POST_PRODUCT_SUCCESS = 'POST_PRODUCT_SUCCESS';
export const POST_PRODUCT_FAILURE = 'POST_PRODUCT_FAILURE';

export const GET_PRODUCT_WITH_FILTER_REQUEST = 'GET_PRODUCT_WITH_FILTER_REQUEST';
export const GET_PRODUCT_WITH_FILTER_SUCCESS = 'GET_PRODUCT_WITH_FILTER_SUCCESS';
export const GET_PRODUCT_WITH_FILTER_FAILURE = 'GET_PRODUCT_WITH_FILTER_FAILURE';

export const CREATE_CART_REQUEST = 'CREATE_CART_REQUEST';
export const CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS';
export const CREATE_CART_FAILURE = 'CREATE_CART_FAILURE';

export const POST_ORDER_REQUEST = 'POST_ORDER_REQUEST';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAILURE = 'POST_ORDER_FAILURE';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

export const PUT_ORDER_REQUEST = 'PUT_ORDER_REQUEST';
export const PUT_ORDER_SUCCESS = 'PUT_ORDER_SUCCESS';
export const PUT_ORDER_FAILURE = 'PUT_ORDER_FAILURE';

export const GET_DETAIL_ORDER_REQUEST = 'GET_DETAIL_ORDER_REQUEST';
export const GET_DETAIL_ORDER_SUCCESS = 'GET_DETAIL_ORDER_SUCCESS';
export const GET_DETAIL_ORDER_FAILURE = 'GET_DETAIL_ORDER_FAILURE';

//UPLOAD
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

//PROFILE
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';
export const PUT_USER_PROFILE_REQUEST = 'PUT_USER_PROFILE_REQUEST';
export const PUT_USER_PROFILE_SUCCESS = 'PUT_USER_PROFILE_SUCCESS';
export const PUT_USER_PROFILE_FAILURE = 'PUT_USER_PROFILE_FAILURE';

//ORDER
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

//REQUEST
export const GET_ESTIMATES_REQUEST = 'GET_ESTIMATES_REQUEST';
export const GET_ESTIMATES_SUCCESS = 'GET_ESTIMATES_SUCCESS';
export const GET_ESTIMATES_FAILURE = 'GET_ESTIMATES_FAILURE';

export const GET_DETAIL_ESTIMATES_REQUEST = 'GET_DETAIL_ESTIMATES_REQUEST';
export const GET_DETAIL_ESTIMATES_SUCCESS = 'GET_DETAIL_ESTIMATES_SUCCESS';
export const GET_DETAIL_ESTIMATES_FAILURE = 'GET_DETAIL_ESTIMATES_FAILURE';

export const UPDATE_ESTIMATE_REQUEST = 'UPDATE_ESTIMATE_REQUEST';
export const UPDATE_ESTIMATE_SUCCESS = 'UPDATE_ESTIMATE_SUCCESS';
export const UPDATE_ESTIMATE_FAILURE = 'UPDATE_ESTIMATE_FAILURE';

//PAYMENT
export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';

//NOTIFICATION

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const PUT_NOTIFICATION_REQUEST = 'PUT_NOTIFICATION_REQUEST';
export const PUT_NOTIFICATION_SUCCESS = 'PUT_NOTIFICATION_SUCCESS';
export const PUT_NOTIFICATION_FAILURE = 'PUT_NOTIFICATION_FAILURE';

// ADS
export const GET_ADS_REQUEST = 'GET_ADS_REQUEST';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAILURE = 'GET_ADS_FAILURE';

// PARTNER CONTACT
export const GET_PARTNER_PROFILE_REQUEST = 'GET_PARTNER_PROFILE_REQUEST';
export const GET_PARTNER_PROFILE_SUCCESS = 'GET_PARTNER_PROFILE_SUCCESS';
export const GET_PARTNER_PROFILE_FAILURE = 'GET_PARTNER_PROFILE_FAILURE';

// SEARCHING
export const SEARCHING_REQUEST = 'SEARCHING_REQUEST';
export const SEARCHING_SUCCESS = 'SEARCHING_SUCCESS';
export const SEARCHING_FAILURE = 'SEARCHING_FAILURE';

// DELETE ITEM IN CART
export const DELETE_ITEM_IN_CART = 'DELETE_ITEM_IN_CART';

export const PUT_PAYMENTS_REQUEST = 'PUT_PAYMENTS_REQUEST';
export const PUT_PAYMENTS_SUCCESS = 'PUT_PAYMENTS_SUCCESS';
export const PUT_PAYMENTS_FAILURE = 'PUT_PAYMENTS_FAILURE';


// SETTING CONFIG
export const GET_SETTING_REQUEST = 'GET_SETTING_REQUEST';
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS';
export const GET_SETTING_FAILURE = 'GET_SETTING_FAILURE';

export const PUT_QUOTATION_REQUEST = 'PUT_QUOTATION_REQUEST';
export const PUT_QUOTATION_SUCCESS = 'PUT_QUOTATION_SUCCESS';
export const PUT_QUOTATION_FAILURE = 'PUT_QUOTATION_FAILURE';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';