import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doGetDetailOrder,
} from '../../../redux/actions/products';

import {
  useRouteMatch,
} from 'react-router-dom';
import Product_OrderTable from '../../../components/Product_OrderTable/Product_OrderTable';
import OrderWholeSale from '../../../components/OrderWholeSale/OrderWholeSale';
import { getTranslatedText } from '../../../services/appServices';
import { routers } from '../../../config/routers';

const Product_WholeSale = (props) => {

  const {
    accountType,
    actions,
    orderInformation,
    isLoading,
    productCart,
    totalOfOrder
  } = props;

  const [cartRender, setCartRender] = useState('');
  const [orderId, setOrderId] = useState(null);

  const match = useRouteMatch(routers.dashboard.view_detail_order);

  useEffect(() => {
    if(match) {
      setCartRender('server');
      actions.doGetDetailOrder(parseInt(match.params.order_id));
      setOrderId(parseInt(match.params.order_id));
    } else {
      setCartRender('local');
    }
  }, []);

  return (
    <React.Fragment>
      <div className="container bg_white mg-b-60">
      <div className="row">
        <div className="title_sec bg_green">
						{getTranslatedText('my_current_order')}
        </div>
        <div className="col-12 product_sg_content cart_content">
          <div className="mw_1000">
            <Product_OrderTable
              productCart={productCart}
              cartRender={cartRender}
              totalOfOrder={totalOfOrder}
            />
          </div>
        </div>
        <OrderWholeSale
          accountType={accountType}
          orderInformation={orderInformation}
          isLoading={isLoading}
          cartRender={cartRender}
          orderId={orderId}
        />
      </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
	return {
		orderInfo: state.orderReducer,
    isHadAccount: state.getUserFromCookiesReducer.isHadAccount,
    accountType: state.getUserFromCookiesReducer.accountType,
    orderInformation: state.getOrderReducer.detail_order,
    isLoading: state.getOrderReducer.isLoading,
    productCart: state.getOrderReducer.product_cart,
    totalOfOrder: state.getOrderReducer.total,
	}
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetDetailOrder,
      },
      dispatch
    )
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Product_WholeSale);