import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	doGetAllProduct,
} from '../../redux/actions/products';
import {
  doGetServices,
} from '../../redux/actions/services';
import SliderComponent from '../../components/Slider/SliderComponent';
import ProductComponent from '../../components/Product/ProductComponent';
import PartnerLogoSlider from '../../components/Partner/PartnerLogoSlider';
import Service_ListServices from '../../components/Service_ListServices/Service_ListServices';


const HomePage = (props) => {

	const {
		actions,
		productsArray,
		servicesArray,
	} = props;

	useEffect(() => {
		const paramsProductLimit = {
			limit: 10,
			hot: 1,
		};
		const paramsServiceLimit = {
			limit: 10,
			hot: 1,
		};
		actions.doGetAllProduct(paramsProductLimit);
		actions.doGetServices(paramsServiceLimit);
	}, []);

	return (
		<React.Fragment>
			<SliderComponent
				type='home'
			/>
			<ProductComponent
				hideFilterComponent={false}
				productsArray={productsArray}
			/>
			<PartnerLogoSlider />
			<Service_ListServices
				hideFilterComponent={false}
				servicesArray={servicesArray}
			/>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		productsArray: state.getProductsReducer.productArray,
		servicesArray: state.serviceReducer.services,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				doGetAllProduct,
				doGetServices,
			},
			dispatch,
		),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(HomePage);