export class resource {
  static country = [{iso3: "AFG",name: "AFGHANISTAN"},
    {iso3: "ALB",name: "ALBANIA"},
    {iso3: "DZA",name: "ALGERIA"},
    {iso3: "ASM",name: "AMERICAN SAMOA"},
    {iso3: "AND",name: "ANDORRA"},
    {iso3: "AGO",name: "ANGOLA"},
    {iso3: "AIA",name: "ANGUILLA"},
    {iso3: "",name: "ANTARCTICA"},
    {iso3: "ATG",name: "ANTIGUA AND BARBUDA"},
    {iso3: "ARG",name: "ARGENTINA"},
    {iso3: "ARM",name: "ARMENIA"},
    {iso3: "ABW",name: "ARUBA"},
    {iso3: "AUS",name: "AUSTRALIA"},
    {iso3: "AUT",name: "AUSTRIA"},
    {iso3: "AZE",name: "AZERBAIJAN"},
    {iso3: "BHS",name: "BAHAMAS"},
    {iso3: "BHR",name: "BAHRAIN"},
    {iso3: "BGD",name: "BANGLADESH"},
    {iso3: "BRB",name: "BARBADOS"},
    {iso3: "BLR",name: "BELARUS"},
    {iso3: "BEL",name: "BELGIUM"},
    {iso3: "BLZ",name: "BELIZE"},
    {iso3: "BEN",name: "BENIN"},
    {iso3: "BMU",name: "BERMUDA"},
    {iso3: "BTN",name: "BHUTAN"},
    {iso3: "BOL",name: "BOLIVIA"},
    {iso3: "BIH",name: "BOSNIA AND HERZEGOVINA"},
    {iso3: "BWA",name: "BOTSWANA"},
    {iso3: "",name: "BOUVET ISLAND"},
    {iso3: "BRA",name: "BRAZIL"},
    {iso3: "",name: "BRITISH INDIAN OCEAN TERRITORY"},
    {iso3: "BRN",name: "BRUNEI DARUSSALAM"},
    {iso3: "BGR",name: "BULGARIA"},
    {iso3: "BFA",name: "BURKINA FASO"},
    {iso3: "BDI",name: "BURUNDI"},
    {iso3: "KHM",name: "CAMBODIA"},
    {iso3: "CMR",name: "CAMEROON"},
    {iso3: "CAN",name: "CANADA"},
    {iso3: "CPV",name: "CAPE VERDE"},
    {iso3: "CYM",name: "CAYMAN ISLANDS"},
    {iso3: "CAF",name: "CENTRAL AFRICAN REPUBLIC"},
    {iso3: "TCD",name: "CHAD"},
    {iso3: "CHL",name: "CHILE"},
    {iso3: "CHN",name: "CHINA"},
    {iso3: "",name: "CHRISTMAS ISLAND"},
    {iso3: "",name: "COCOS (KEELING) ISLANDS"},
    {iso3: "COL",name: "COLOMBIA"},
    {iso3: "COM",name: "COMOROS"},
    {iso3: "COG",name: "CONGO"},
    {iso3: "COD",name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE"},
    {iso3: "COK",name: "COOK ISLANDS"},
    {iso3: "CRI",name: "COSTA RICA"},
    {iso3: "CIV",name: "COTE D'IVOIRE"},
    {iso3: "HRV",name: "CROATIA"},
    {iso3: "CUB",name: "CUBA"},
    {iso3: "CYP",name: "CYPRUS"},
    {iso3: "CZE",name: "CZECH REPUBLIC"},
    {iso3: "DNK",name: "DENMARK"},
    {iso3: "DJI",name: "DJIBOUTI"},
    {iso3: "DMA",name: "DOMINICA"},
    {iso3: "DOM",name: "DOMINICAN REPUBLIC"},
    {iso3: "ECU",name: "ECUADOR"},
    {iso3: "EGY",name: "EGYPT"},
    {iso3: "SLV",name: "EL SALVADOR"},
    {iso3: "GNQ",name: "EQUATORIAL GUINEA"},
    {iso3: "ERI",name: "ERITREA"},
    {iso3: "EST",name: "ESTONIA"},
    {iso3: "ETH",name: "ETHIOPIA"},
    {iso3: "FLK",name: "FALKLAND ISLANDS (MALVINAS)"},
    {iso3: "FRO",name: "FAROE ISLANDS"},
    {iso3: "FJI",name: "FIJI"},
    {iso3: "FIN",name: "FINLAND"},
    {iso3: "FRA",name: "FRANCE"},
    {iso3: "GUF",name: "FRENCH GUIANA"},
    {iso3: "PYF",name: "FRENCH POLYNESIA"},
    {iso3: "",name: "FRENCH SOUTHERN TERRITORIES"},
    {iso3: "GAB",name: "GABON"},
    {iso3: "GMB",name: "GAMBIA"},
    {iso3: "GEO",name: "GEORGIA"},
    {iso3: "DEU",name: "GERMANY"},
    {iso3: "GHA",name: "GHANA"},
    {iso3: "GIB",name: "GIBRALTAR"},
    {iso3: "GRC",name: "GREECE"},
    {iso3: "GRL",name: "GREENLAND"},
    {iso3: "GRD",name: "GRENADA"},
    {iso3: "GLP",name: "GUADELOUPE"},
    {iso3: "GUM",name: "GUAM"},
    {iso3: "GTM",name: "GUATEMALA"},
    {iso3: "GIN",name: "GUINEA"},
    {iso3: "GNB",name: "GUINEA-BISSAU"},
    {iso3: "GUY",name: "GUYANA"},
    {iso3: "HTI",name: "HAITI"},
    {iso3: "",name: "HEARD ISLAND AND MCDONALD ISLANDS"},
    {iso3: "VAT",name: "HOLY SEE (VATICAN CITY STATE)"},
    {iso3: "HND",name: "HONDURAS"},
    {iso3: "HKG",name: "HONG KONG"},
    {iso3: "HUN",name: "HUNGARY"},
    {iso3: "ISL",name: "ICELAND"},
    {iso3: "IND",name: "INDIA"},
    {iso3: "IDN",name: "INDONESIA"},
    {iso3: "IRN",name: "IRAN, ISLAMIC REPUBLIC OF"},
    {iso3: "IRQ",name: "IRAQ"},
    {iso3: "IRL",name: "IRELAND"},
    {iso3: "ISR",name: "ISRAEL"},
    {iso3: "ITA",name: "ITALY"},
    {iso3: "JAM",name: "JAMAICA"},
    {iso3: "JPN",name: "JAPAN"},
    {iso3: "JOR",name: "JORDAN"},
    {iso3: "KAZ",name: "KAZAKHSTAN"},
    {iso3: "KEN",name: "KENYA"},
    {iso3: "KIR",name: "KIRIBATI"},
    {iso3: "PRK",name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"},
    {iso3: "KOR",name: "KOREA, REPUBLIC OF"},
    {iso3: "KWT",name: "KUWAIT"},
    {iso3: "KGZ",name: "KYRGYZSTAN"},
    {iso3: "LAO",name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC"},
    {iso3: "LVA",name: "LATVIA"},
    {iso3: "LBN",name: "LEBANON"},
    {iso3: "LSO",name: "LESOTHO"},
    {iso3: "LBR",name: "LIBERIA"},
    {iso3: "LBY",name: "LIBYAN ARAB JAMAHIRIYA"},
    {iso3: "LIE",name: "LIECHTENSTEIN"},
    {iso3: "LTU",name: "LITHUANIA"},
    {iso3: "LUX",name: "LUXEMBOURG"},
    {iso3: "MAC",name: "MACAO"},
    {iso3: "MKD",name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"},
    {iso3: "MDG",name: "MADAGASCAR"},
    {iso3: "MWI",name: "MALAWI"},
    {iso3: "MYS",name: "MALAYSIA"},
    {iso3: "MDV",name: "MALDIVES"},
    {iso3: "MLI",name: "MALI"},
    {iso3: "MLT",name: "MALTA"},
    {iso3: "MHL",name: "MARSHALL ISLANDS"},
    {iso3: "MTQ",name: "MARTINIQUE"},
    {iso3: "MRT",name: "MAURITANIA"},
    {iso3: "MUS",name: "MAURITIUS"},
    {iso3: "",name: "MAYOTTE"},
    {iso3: "MEX",name: "MEXICO"},
    {iso3: "FSM",name: "MICRONESIA, FEDERATED STATES OF"},
    {iso3: "MDA",name: "MOLDOVA, REPUBLIC OF"},
    {iso3: "MCO",name: "MONACO"},
    {iso3: "MNG",name: "MONGOLIA"},
    {iso3: "MSR",name: "MONTSERRAT"},
    {iso3: "MAR",name: "MOROCCO"},
    {iso3: "MOZ",name: "MOZAMBIQUE"},
    {iso3: "MMR",name: "MYANMAR"},
    {iso3: "NAM",name: "NAMIBIA"},
    {iso3: "NRU",name: "NAURU"},
    {iso3: "NPL",name: "NEPAL"},
    {iso3: "NLD",name: "NETHERLANDS"},
    {iso3: "ANT",name: "NETHERLANDS ANTILLES"},
    {iso3: "NCL",name: "NEW CALEDONIA"},
    {iso3: "NZL",name: "NEW ZEALAND"},
    {iso3: "NIC",name: "NICARAGUA"},
    {iso3: "NER",name: "NIGER"},
    {iso3: "NGA",name: "NIGERIA"},
    {iso3: "NIU",name: "NIUE"},
    {iso3: "NFK",name: "NORFOLK ISLAND"},
    {iso3: "MNP",name: "NORTHERN MARIANA ISLANDS"},
    {iso3: "NOR",name: "NORWAY"},
    {iso3: "OMN",name: "OMAN"},
    {iso3: "PAK",name: "PAKISTAN"},
    {iso3: "PLW",name: "PALAU"},
    {iso3: "",name: "PALESTINIAN TERRITORY, OCCUPIED"},
    {iso3: "PAN",name: "PANAMA"},
    {iso3: "PNG",name: "PAPUA NEW GUINEA"},
    {iso3: "PRY",name: "PARAGUAY"},
    {iso3: "PER",name: "PERU"},
    {iso3: "PHL",name: "PHILIPPINES"},
    {iso3: "PCN",name: "PITCAIRN"},
    {iso3: "POL",name: "POLAND"},
    {iso3: "PRT",name: "PORTUGAL"},
    {iso3: "PRI",name: "PUERTO RICO"},
    {iso3: "QAT",name: "QATAR"},
    {iso3: "REU",name: "REUNION"},
    {iso3: "ROM",name: "ROMANIA"},
    {iso3: "RUS",name: "RUSSIAN FEDERATION"},
    {iso3: "RWA",name: "RWANDA"},
    {iso3: "SHN",name: "SAINT HELENA"},
    {iso3: "KNA",name: "SAINT KITTS AND NEVIS"},
    {iso3: "LCA",name: "SAINT LUCIA"},
    {iso3: "SPM",name: "SAINT PIERRE AND MIQUELON"},
    {iso3: "VCT",name: "SAINT VINCENT AND THE GRENADINES"},
    {iso3: "WSM",name: "SAMOA"},
    {iso3: "SMR",name: "SAN MARINO"},
    {iso3: "STP",name: "SAO TOME AND PRINCIPE"},
    {iso3: "SAU",name: "SAUDI ARABIA"},
    {iso3: "SEN",name: "SENEGAL"},
    {iso3: "",name: "SERBIA AND MONTENEGRO"},
    {iso3: "SYC",name: "SEYCHELLES"},
    {iso3: "SLE",name: "SIERRA LEONE"},
    {iso3: "SGP",name: "SINGAPORE"},
    {iso3: "SVK",name: "SLOVAKIA"},
    {iso3: "SVN",name: "SLOVENIA"},
    {iso3: "SLB",name: "SOLOMON ISLANDS"},
    {iso3: "SOM",name: "SOMALIA"},
    {iso3: "ZAF",name: "SOUTH AFRICA"},
    {iso3: "",name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"},
    {iso3: "ESP",name: "SPAIN"},
    {iso3: "LKA",name: "SRI LANKA"},
    {iso3: "SDN",name: "SUDAN"},
    {iso3: "SUR",name: "SURINAME"},
    {iso3: "SJM",name: "SVALBARD AND JAN MAYEN"},
    {iso3: "SWZ",name: "SWAZILAND"},
    {iso3: "SWE",name: "SWEDEN"},
    {iso3: "CHE",name: "SWITZERLAND"},
    {iso3: "SYR",name: "SYRIAN ARAB REPUBLIC"},
    {iso3: "TWN",name: "TAIWAN, PROVINCE OF CHINA"},
    {iso3: "TJK",name: "TAJIKISTAN"},
    {iso3: "TZA",name: "TANZANIA, UNITED REPUBLIC OF"},
    {iso3: "THA",name: "THAILAND"},
    {iso3: "",name: "TIMOR-LESTE"},
    {iso3: "TGO",name: "TOGO"},
    {iso3: "TKL",name: "TOKELAU"},
    {iso3: "TON",name: "TONGA"},
    {iso3: "TTO",name: "TRINIDAD AND TOBAGO"},
    {iso3: "TUN",name: "TUNISIA"},
    {iso3: "TUR",name: "TURKEY"},
    {iso3: "TKM",name: "TURKMENISTAN"},
    {iso3: "TCA",name: "TURKS AND CAICOS ISLANDS"},
    {iso3: "TUV",name: "TUVALU"},
    {iso3: "UGA",name: "UGANDA"},
    {iso3: "UKR",name: "UKRAINE"},
    {iso3: "ARE",name: "UNITED ARAB EMIRATES"},
    {iso3: "GBR",name: "UNITED KINGDOM"},
    {iso3: "USA",name: "UNITED STATES"},
    {iso3: "",name: "UNITED STATES MINOR OUTLYING ISLANDS"},
    {iso3: "URY",name: "URUGUAY"},
    {iso3: "UZB",name: "UZBEKISTAN"},
    {iso3: "VUT",name: "VANUATU"},
    {iso3: "VEN",name: "VENEZUELA"},
    {iso3: "VNM",name: "VIET NAM"},
    {iso3: "VGB",name: "VIRGIN ISLANDS, BRITISH"},
    {iso3: "VIR",name: "VIRGIN ISLANDS, U.S."},
    {iso3: "WLF",name: "WALLIS AND FUTUNA"},
    {iso3: "ESH",name: "WESTERN SAHARA"},
    {iso3: "YEM",name: "YEMEN"},
    {iso3: "ZMB",name: "ZAMBIA"},
    {iso3: "ZWE",name: "ZIMBABWE"}]
}