import * as actionTypes from '../actions/action_types';
import {toast} from 'react-toastify';
import { getTranslatedText } from '../../services/appServices';

const userProfileState = {
  isLoading: false,
  personalData: {
    id: null,
    name: '',
    short_name: '',
    email: '',
    phone: '',
    balance: 0,
    avatar: null,
    address: '',
    city: '',
    account_type: '',
    attachment: ''
  },
  companyData: {
    company_name: null,
    company_address: null,
    company_city: null,
    company_email: null,
    company_website: null,
  },
  verify_code: null,
  verify_expire: null,
  is_lock: null,
  is_active: null,
  partner_product_type: '',
  attachment: null,
};

export const userProfileReducer = (state = userProfileState, action) => {
  let userData = action.payload;
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        personalData: {
          id: userData.id,
          name: userData.name,
          short_name: userData.short_name,
          email: userData.email,
          phone: userData.phone,
          balance: userData.balance,
          avatar: userData.avatar,
          address: userData.address,
          city: userData.city,
          account_type: userData.account_type,
          attachment: userData.attachment,
          categories: userData.categories,
          payment_charge:userData.payment_charge,
          payment_pay: userData.payment_pay
        },
        companyData: {
          company_name: userData.company_name,
          company_address: userData.company_address,
          company_city: userData.company_city,
          company_email: userData.company_email,
          company_website: userData.company_website,
        },
        verify_code: userData.verify_code,
        verify_expire: userData.verify_expire,
        is_lock: userData.is_lock,
        is_active: userData.is_active,
        partner_product_type: userData.partner_product_type,
        attachment: userData.attachment,
      };
    case actionTypes.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.PUT_USER_PROFILE_SUCCESS:
      toast.success(getTranslatedText('update_profile_success'));
      return {
        ...state,
        isLoading: false,
        personalData: {
          id: userData.id,
          name: userData.name,
          short_name: userData.short_name,
          email: userData.email,
          phone: userData.phone,
          balance: userData.balance,
          avatar: userData.avatar,
          address: userData.address,
          city: userData.city,
          account_type: userData.account_type,
          attachment: userData.attachment,
          categories: userData.categories,
          payment_charge:userData.payment_charge,
          payment_pay: userData.payment_pay
        },
        companyData: {
          company_name: userData.company_name,
          company_address: userData.company_address,
          company_city: userData.company_city,
          company_email: userData.company_email,
          company_website: userData.company_website,
        },
        verify_code: userData.verify_code,
        verify_expire: userData.verify_expire,
        is_lock: userData.is_lock,
        is_active: userData.is_active,
        partner_product_type: userData.partner_product_type,
        attachment: userData.attachment,
      };
    case actionTypes.PUT_USER_PROFILE_FAILURE:
      toast.error(getTranslatedText('update_profile_failure'));
      return state;
    default:
      return state;
  }
};

const partnerProfileState = {
  data: {}
};

export const partnerProfileReducer = (state = partnerProfileState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARTNER_PROFILE_SUCCESS:
      let partnerData = action.payload;
      return {
        ...state,
        data: partnerData,
      };
    case actionTypes.GET_PARTNER_PROFILE_FAILURE:
      toast.error(action.error);
      return state;
    default:
      return state;
  }
}