import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  doGetOrder,
} from '../../../redux/actions/products';

import OrderTableList from '../../../components/OrderTableList/OrderTableList';

const OrderListContainer = (props) => {

  const {
    orderArrayData,
    accountType,
  } = props;

  return (
    <React.Fragment>
      <OrderTableList
        orderArrayData={orderArrayData}
        accountType={accountType}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    quoteData: state.quoteReducer.data,
    orderArrayData: state.getOrderReducer.data,
    accountType: state.getUserFromCookiesReducer.accountType
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetOrder,
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderListContainer);