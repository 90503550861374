import React from 'react';
import {
	useRouteMatch,
} from 'react-router-dom';
import PartnerService from '../../../components/Service_PartnerServices/PartnerService';


const ServiceDetailContainer = () => {

	const match = useRouteMatch('/services/detail/:service_id');
	console.log(match);

	return (
		<React.Fragment>
			<PartnerService match={match} />
		</React.Fragment>
	);
};

export default ServiceDetailContainer;