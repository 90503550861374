// this javascript file contain function reducer to handle logic reducer for services table
import * as actionTypes from '../actions/action_types';

const initialState = {
  isLoading: false,
  settings: [],
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTING_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: action.payload,
      };
    case actionTypes.GET_SETTING_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};