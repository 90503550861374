// The index root route Account

import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { routers } from '../../config/routers';

import LoginContainer from './Login/LoginContainer';
import SignUpContainer from './SignUp/SignUpContainer';

const AccountContainerIndex = (props) => {

  const {
    authed,
  } = props;

  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={match.url}
        >
          {authed === true ? (
            <Redirect
              to={{
                pathname: routers.dashboard.main
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: `${match.url}/login`
              }}
            />
          )} 
        </Route>
        <Route
          path={`${match.url}/login`}
          component={LoginContainer}
        />
        <Route
          path={`${match.url}/sign-up`}
          component={SignUpContainer}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authed: state.getUserFromCookiesReducer.isHadAccount
  };
};

export default connect(
  mapStateToProps,
  null,
)(AccountContainerIndex);