import React, { useState, useEffect } from 'react';
import {
	Modal,
} from 'antd';
import RechargeCoinOption from '../RechargeCoinOption/RechargeCoinOption';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	doGetPackagePayment,
	doUpdatePackage,
} from '../../redux/actions/services';
import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

const ButtonCard = (props) => {

	const {
		item,
		doUpdatePackage,
	} = props;

	const handleButtonOnClick = () => {
		console.log(item);
		doUpdatePackage(item.id, item.receive, 'Recharge');
	}

	return (
		<React.Fragment>
			<a
				style={{
					background: 'transparent',
					fontWeight: 'bold',
					fontSize: '16px'
				}}
				onClick={() => handleButtonOnClick()}>{getTranslatedText('recharge_now')}</a>
		</React.Fragment>
	)
}

const Deposit = (props) => {

	const {
		balance,
		packageList,
		actions,
	} = props;
	
	const [modalVisible, setModalVisible] = useState(false);
	const [currentPackage, setCurrenPackage] = useState(null);

	useEffect(() => {
		actions.doGetPackagePayment();
	}, []);

	const handleButtonOnClick = (item) => {
		setCurrenPackage(item);
		setModalVisible(true);
	}

	return (
		<React.Fragment>
			<div style={{ marginTop: '50px'}} className="container black mg-b-60">
				<div className="row">
					<div className="col-12 balance_service">
						<div className="content sec_title row">
							<div className="left">
								<h3 className="title mg-t-5">{getTranslatedText('select_package')}</h3>
								<p className="des">{getTranslatedText('xu_intro_title')}</p>
							</div>
							<div className="right">
								<div className="_info row">
									<p className="left">{getTranslatedText('current_xu_title')}</p>
									<div className="right">
										<p className="number">{balance}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row ">
							<div className="list_services">
								<ul className="no_list_style">
									{packageList.map((item, index) => (
										<li>
											<div className="item_service">
												<div className="icon">
													<h1>{item.promotion_show}</h1>
												</div>
												<div className="prices">
													<h3>{item.receive} XU</h3>
													<p>{item.show}</p>
													<a href="#">{getTranslatedText("saving")} {item.promotion}%</a>
												</div>
												<div className="btn-plus">
												<a
													style={{
														background: 'transparent',
														fontWeight: 'bold',
														fontSize: '16px'
													}}
													onClick={() => handleButtonOnClick(item)}>{getTranslatedText('recharge_now')}</a>
												</div>
											</div>
										</li>
									))}
								</ul>
								<hr />
							</div>
						</div>
						<div className="row support_services">
							<div className="col-md-4">
								<img src={require("../../assets/service/telesale.jpg")} />
							</div>
							<div className="col-md-4">
								<h2 className="title">{getTranslatedText('quick_intro')}</h2>
								<ul className="no_list_style list_qs">
									<li>
										<a href="#">{getTranslatedText('xu_what_use_for')}</a>
									</li>
									<li>
										<a href="#">{getTranslatedText('tutorial')}</a>
									</li>
									<li>
										<a href="#">{getTranslatedText('upgrade_account_tutorial')}</a>
									</li>
								</ul>
							</div>
							<div className="col-md-4 have-border">
								<h2 className="title">{getTranslatedText('quick_support')}</h2>
								<ul className="no_list_style list_contact">
									<li>
										<a href="#"></a>
										<div className="item_contact">
											<div className="img">
												<img src={require("../../assets/icon/2J.png")} />
											</div>
											<div className="info">
												<p>Hotline</p>
												<h3>(+84) 936 168 503</h3>
											</div>
										</div>
									</li>
									<li>
										<a href="#"></a>
										<div className="item_contact">
											<div className="img">
												<img src={require("../../assets/icon/2J.png")} />
											</div>
											<div className="info">
												<p>Hotline</p>
												<h3>(+84) 817 594 642</h3>
											</div>
										</div>
									</li>
									<li>
										<a href="#"></a>
										<div className="item_contact">
											<div className="img">
												<img src={require("../../assets/icon/2J.png")} />
											</div>
											<div className="info">
												<p>Hotline</p>
												<h3>(028) 665 32 159</h3>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<Modal
					title="Tuỳ chọn thanh toán XU"
					visible={modalVisible}
					closable
					maskClosable
					footer={null}
					destroyOnClose={true}
					onCancel={() => setModalVisible(false)}
					width={800}
				>
					<RechargeCoinOption
						currentPackage={currentPackage}
						setModalVisible={setModalVisible}
					/>
				</Modal>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		isHadAccountCookie: state.getUserFromCookiesReducer.isHadAccount,
		balance: state.getUserFromCookiesReducer.balance,
		userName: state.getUserFromCookiesReducer.userName,
		accountType: state.getUserFromCookiesReducer.accountType,
		currentLanguage: state.currentLanguageReducer.language,
		packageList: state.packageReducer.packageList
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				doGetPackagePayment,
				doUpdatePackage,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Deposit);