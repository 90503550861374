import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
	doPostQuote,
} from '../../redux/actions/services';

import PropsType from 'prop-types';

import './PartnerSuggestList.scss';

const PartnerSuggestList = (props) => {

	const {
		detailQuote,
		actions,
	} = props;

	const [selectedPartnerList, setSelectedPartnerList] = useState([]);

	const handleCheckboxOnClick = (event, item) => {
		if (selectedPartnerList === []) {
			setSelectedPartnerList([item.id]);
		} else {
			if (selectedPartnerList.includes(item.id)) {
				let index = selectedPartnerList.indexOf(item.id)
				selectedPartnerList.splice(index);
			} else {
				selectedPartnerList.push(item.id)
			}
		}
		console.log(selectedPartnerList);
	};

	const handleSubmitOnClick = () => {
		selectedPartnerList.forEach(item => {
			const requestQuotationData = {
				request_info: detailQuote.request_info,
				category_id: parseInt(detailQuote.category_id),
				title: detailQuote.title,
				description: detailQuote.description,
				location: detailQuote.location,
				partner_id: item,
				request_id: detailQuote.id,
			};
			actions.doPostQuote(requestQuotationData);
		});
	};

	return (
		<React.Fragment>
			<div class="container black mg-b-60">
				<div class="row">
					<div className="col-12 single_service">
						<h1 className="__title title">{detailQuote.title}</h1>
						<div className="content">
							<div className="left" dangerouslySetInnerHTML={{__html: detailQuote.description}}>
								{/* <p >{detailQuote.description}</p> */}
							</div>
							<div className="right">
								<div className="_info">
									<div className="_date mg-b-15">{detailQuote.created_at}</div>
									<div className="_location mg-b-15">{detailQuote.location}</div>
									<div className="_customer mg-b-15">{detailQuote.have_suggest_partner} đối tác chào giá</div>
								</div>
							</div>
						</div>
						<div className="row ">
							<div className="col">
								<div className="category">
									{/* Danh mục/ Ngành nghề: <a href="">Kế toán</a> <a href="">Báo cáo tài chính</a> */}
								</div>
							</div>
							<div className="col">
								<div className="category">
									Kỹ năng yêu cầu: <a href="">{detailQuote.skill}</a>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Send Quote --> */}
          <div className="col-12 bg_white mg-t-40">
						<h3 class="__title title">CÔNG VIỆC NÀY HIỆN TẠI CÓ CÁC ĐỐI TÁC SAU PHÙ HỢP VỚI BẠN:</h3>
						{detailQuote.suggest_partner && detailQuote.suggest_partner.map((item, index) => (
							<div class="partner_col col col-sm-12 col-md-11">
								<div class="partner_info mg-l-30 shadow">
									<div class="left">
										<img src={require("../../assets/service/avatar.png")} alt="" />
										<div class="rating">
											<span class="item active"></span>
											<span class="item active"></span>
											<span class="item active"></span>
											<span class="item active"></span>
											<span class="item"></span>
										</div>
									</div>
									<div class="right">
										<div class="title">{item.short_name}</div>
										<div>{item.city}</div>
										{/* <div>Chuyên viên báo cáo tài chính</div> */}
										{/* <div class="career_box">Ngành nghề hoạt động:
																		<a href="" class="item">Kế toán tài chính</a>
											<a href="" class="item">Luật doanh nghiệp</a>
											<a href="" class="item"> Hồ sơ pháp lý </a>
										</div> */}
									</div>
									<div>
										<a href="" class="btn yellow_2 xs" data-toggle="modal" data-target="#modal_feedback">Xem hồ sơ</a>
									</div>
								</div>
								<div>
									<input onClick={(event) => handleCheckboxOnClick(event, item)} type='checkbox' />
								</div>
							</div>
						))}
          <div className='list_footer'>
            <div className='_list_content'>
              <input type='checkbox' />
              <p>Bạn đồng ý rằng bên B2BGOSELL  chỉ là cầu nối thông tin cho bên CUNG CẤP DỊCH VỤ, và bên YÊU CẦU DỊCH VỤ có cơ hội làm việc với nhau, và CHÚNG TÔI không chịu trách nhiệm liên quan đến pháp luật trong các thỏa thuận, hợp đồng làm việc giữa 2 bên với nhau.</p>
            </div>
						<button
							className='btn_border_radius_green'
							onClick={() => handleSubmitOnClick()}
						>
              YÊU CẦU BÁO GIÁ
            </button>
          </div>
				</div>
			</div>
				{/* <!-- end row --> */}
		</div>
			{/* <!-- Popup feedback --> */}
			<div class="modal black fade " id="modal_feedback" tabindex="-1 " role="dialog " aria-labelledby="modal_feedback" aria-hidden="true ">
				<div class="modal-dialog modal-dialog-centered mw_1048 " role="document ">
					<div class="modal-content modal_feedback">
						<div class="left ">
							<div class="avatar">
								<img src={require("../../assets/service/avatar.png")} alt="" />
							</div>
							<div class="info">
								<div class="title">Jame Nguyen</div>
								<div class="sub">Chuyên viên báo cáo tài chính</div>
							</div>
							<div class="rating">
								<span class="item active"></span>
								<span class="item active"></span>
								<span class="item active"></span>
								<span class="item active"></span>
								<span class="item"></span>
								<span class="text">1.200 người đã đánh giá</span>
							</div>
							<div class="career_box">
								<p class="title">NGÀNH NGHỀ HOẠT ĐỘNG</p>
								<a href="" class="item">Kế toán tài chính</a>
								<a href="" class="item">Luật doanh nghiệp</a>
								<a href="" class="item"> Hồ sơ pháp lý </a>
							</div>
							<div class="content">
								<p>Hiện tại mình đang làm việc cho công ty nước ngoài, vị trí kế toán trưởng. Mình tham gia B2BGOSELL để tìm kiếm việc làm thêm ngoài giờ ( nhận sổ sách về làm, làm báo cáo thuế, báo cáo tài chính cuối năm,.._</p>
								<p>Mình có 5 năm kinh nghiệm về báo cáo tài chính.</p>
								<p>Hy vọng sẽ giúp cho công việc của các công ty được giải quyết nhanh chóng và hiệu quả</p>
							</div>

							<form class="review_form">
								<div class="title">ĐÁNH GIÁ CỦA BẠN CHO ĐỐI TÁC NÀY</div>
								<div class="rating review">
									<span class="item"></span>
									<span class="item"></span>
									<span class="item"></span>
									<span class="item"></span>
									<span class="item"></span>
								</div>
								<div>
									<button class="btn gray ">Đánh giá</button>
								</div>
							</form>
						</div>
						{/* <!-- end left --> */}
						<div class="right ">
							<span class="close" data-dismiss="modal"></span>
							<div class="comment_box">
								<div class="top">
									<span class="title">PHẢN HỒI Ý KIẾN</span>
									<a href="http://" class="share">share</a>
								</div>
								<form action="" class="">
									<textarea name="comment" id="" placeholder="Viết nhận xét của bạn tại đây..."></textarea>
									<button class="btn yellow_2 small send pd-lr-20">GỬI</button>
								</form>
								<div class="list_comment">
									<div class="item">
										<div class="img">
											<img src={require("../../assets/service/avt_small_1.png")} alt="" />
										</div>
										<div class="content">
											<div>
												<span class="name">Nguyễn Hoàng</span>
												<span class="time">1h trước</span>
											</div>
											<div>
												Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống kịp thời, giúp chúng tôi giải quyết nhanh chóng công việc.
											</div>
										</div>
									</div>
									<div class="item">
										<div class="img">
											<img src={require("../../assets/service/avt_small_2.png")} alt="" />
										</div>
										<div class="content">
											<div>
												<span class="name">Phạm Thúy Vân</span>
												<span class="time">2 ngày trước</span>
											</div>
											<div>
												Cho tôi sự yên tâm trọn vẹn khi thực hiện công việc, và mang đến những giá trị cao khi hợp tác giải quyết các công việc với đơn vị chức năng.
											</div>
										</div>
									</div>
									<div class="item">
										<div class="img">
											<img src={require("../../assets/service/avatar.png")} alt="" />
										</div>
										<div class="content">
											<div>
												<span class="name">Nguyễn Hoàng</span>
												<span class="time">1h trước</span>
											</div>
											<div>
												Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống kịp thời, giúp chúng tôi giải quyết nhanh chóng công việc.
											</div>
										</div>
									</div>
								</div>
								{/* <!-- End list cmt --> */}
							</div>
						</div>
						{/* <!-- End right --> */}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

PartnerSuggestList.propType = {
	detailQuote: PropsType.object
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
			doPostQuote,
		},
			dispatch
		)
	};
};

export default connect(
	null,
	mapDispatchToProps,
)
(PartnerSuggestList);