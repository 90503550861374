import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	doGetServices,
} from '../../../redux/actions/services';
import SliderComponent from '../../../components/Slider/SliderComponent';
import Service_ListServices from '../../../components/Service_ListServices/Service_ListServices';
import PRComponent from '../../../components/AdsPRFeature/AdsPRFeature';
import PartnerLogoSlider from '../../../components/Partner/PartnerLogoSlider';


const ServicePage = (props) => {

	const {
		actions,
		servicesArray,
	} = props;

	useEffect(() => {
		// const paramsServiceLimit = {
		// 	limit: 10,
		// };
		actions.doGetServices();
	}, []);

	return (
		<React.Fragment>
			<SliderComponent
				type='service'
			/>
			<Service_ListServices
				servicesArray={servicesArray}
				isHot={true}
				vipBanner={true}
			/>
			<PRComponent
				type='service'
				key={6}
			/>
			<Service_ListServices
				servicesArray={servicesArray}
				hideFilterComponent={true}
			/>
			<PartnerLogoSlider />
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		servicesArray: state.serviceReducer.services
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
			doGetServices,
		},
		dispatch
		)
	};
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(ServicePage);