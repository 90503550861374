import { language, supportedLanguage } from "../config/lang";

export const getTranslatedText = (key) => {
  key = key.toLowerCase();
  let currentLang = localStorage.getItem("current_language") || "vi";

  if (supportedLanguage.indexOf(currentLang) < 0) {
    currentLang = "vi";
    localStorage.setItem("current_language", currentLang);
  }
  if (typeof language[key] == "undefined") return key;
  return language[key][currentLang];
};

export const usdCurrencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const vndCurrencyFormat = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
  minimumFractionDigits: 0,
});

export const catchTextFromUrl = (url) => {
  // split url to array
  let url_array = url.split("&");
  let pay_type = "";
  let object_id = 0;
  let payment_id = 0;
  //get object id
  if (url_array[1].includes("xu")) {
    pay_type = "recharge";
  } else {
    pay_type = "order";
  }
  let get_object_id_array = url_array[0].split("=");
  object_id = get_object_id_array[1];
  let get_payment_id_array = url_array[4].split("=");
  payment_id = get_payment_id_array[1];
  return {
    pay_type: pay_type,
    object_id: object_id,
    payment_id: payment_id,
  };
};

export const truncateText = (text, maxLength, ellipsis = "…") => {
  if (text?.length > maxLength) {
    return (
      text.substr(0, maxLength - ellipsis.length + 1).replace(/\s+\S*$/, "") +
      ellipsis
    );
  }

  return text;
};
