import {
  combineReducers,
} from 'redux';
import {
  registerOptionReducer,
  authenticationReducer,
  getUserFromCookiesReducer,
  userCodeReducer,
} from './authentication';
import {
  currentLanguageReducer,
} from './lang';
import {
  serviceCategoryReducer,
  productCategoryReducer,
} from './category';
import {
  serviceReducer,
  quoteReducer,
  estimateReducer,
  packageReducer
 } from './services';
import {
  getOrderReducer,
  getProductsReducer,
  getProductReducer,
  postProductReducer,
  cartReducer
} from './products';
import {
  uploadFileReducer,
} from './upload';
import {
  userProfileReducer,
  partnerProfileReducer,
} from './dashboard';

import {
  createOrderReducer,
  putOrderReducer,
} from './order';

import {
  getEstimatesReducer,
} from './estimate';

import {
  getPaymentsReducer,
} from './payment';

import {
  notificationReducer,
} from './notification';

import {
  adsReducer,
} from './ads';

import {
  searchReducer
} from './search';

import {
  settingReducer
} from './settings';

import { documentReducer } from './documents';

export default combineReducers({
  registerOptionReducer,
  authenticationReducer,
  getUserFromCookiesReducer,
  currentLanguageReducer,
  userCodeReducer,
  serviceCategoryReducer,
  productCategoryReducer,
  serviceReducer,
  quoteReducer,
  estimateReducer,
  packageReducer,
  uploadFileReducer,
  userProfileReducer,
  getOrderReducer,
  getProductsReducer,
  getProductReducer,
  postProductReducer,
  createOrderReducer,
  getEstimatesReducer,
  getPaymentsReducer,
  cartReducer,
  notificationReducer,
  adsReducer,
  partnerProfileReducer,
  searchReducer,
  putOrderReducer,
  settingReducer,
  documentReducer,
});
