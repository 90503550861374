import React from 'react';
import {
  Menu
} from 'antd';
import { getTranslatedText } from '../../../services/appServices';


function LanguagueDropdown(props) {

  const {
    handleLanguageSelectChange,
  } = props;

  return (
    <React.Fragment>
      <Menu>
        <Menu.Item
          onClick={() => handleLanguageSelectChange('vi')}
        >
          <p>{getTranslatedText('lang_vi')}</p>
        </Menu.Item>
        <Menu.Item
          onClick={() => handleLanguageSelectChange('en')}
        >
          <p>{getTranslatedText('lang_en')}</p>
        </Menu.Item>
      </Menu>
    </React.Fragment>
  );
};

export default LanguagueDropdown;