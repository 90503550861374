import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doGetCategoryService,
  doGetCategoryProduct,
  doGetChildrenCategoryService,
  doGetChildrenCategoryProduct,
} from '../../../redux/actions/category';
import {
  doPutUserProfileAction,
} from '../../../redux/actions/dashboard';
import {
  Select,
  Button,
  Tag,
  Row,
  Col
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { getTranslatedText } from '../../../services/appServices';

import placeholder from "../../../assets/icon/input_file_plus.png";


function ProvideInfoComponent(props) {

  const {
    actions,
    parentServiceCategory,
    childrenServiceCategory,
    parentProductCategory,
    childrenProductCategory,
    personalProfile,
  } = props;


  const [parentProductCategoryItem, setParentProductCategoryItem] = useState(null);
  const [parentServiceCategoryItem, setParentServiceCategoryItem] = useState(null)
  const [serviceCategoryValue, setServiceCategoryValue] = useState(null);
  const [productCategoryValue, setProductCategoryValue] = useState(null);
  const [fieldDisable, setFieldDisable] = useState(true);

  useEffect(() => {
    if (personalProfile.account_type === 'partner-product') {
      actions.doGetCategoryProduct();
    }
    if (personalProfile.account_type === 'partner-service' || 'freelancer') {
      actions.doGetCategoryService();
    }
  }, []);
  
  const uploadFile = async (id) => {
    let files = document.getElementById(id).files;
    for (let i = 0; i < 6; i++){
      if (document.getElementById(id + "_" + i)){
        if (files[i])
          document.getElementById(id + "_" + i).src = URL.createObjectURL(files[i]);
        else
          document.getElementById(id + "_" + i).src = placeholder;
      }
    }
  };

  const handleOnUpdateCefiticate = () => {
    let payload = {}
    let certificate = document.getElementById("certificate").files;
    console.log(certificate);
    for (let i = 0; i < certificate.length; i++){
      payload["image_" + i] = certificate[i];
    };
    actions.doPutUserProfileAction(payload);
  };

  const handleProductCategoryOnChange = (value) => {
    actions.doGetChildrenCategoryProduct(value);
    setParentProductCategoryItem(value);
  };

  const handleServiceCategoryOnChange = (value) => {
    actions.doGetChildrenCategoryService(value);
    setParentServiceCategoryItem(value);
  };

  const handleChildrenProductCategoryOnChange = (value) => {
    setProductCategoryValue(value);
  };
  
  const handleChildrenServiceCategoryOnChange = (value) => {
    setServiceCategoryValue(value);
  };

  function handleOnSubmitCategory() {
    if (personalProfile.account_type === 'partner-product') {
      const payload = {
        categories: [parentProductCategoryItem, productCategoryValue] 
      };
      actions.doPutUserProfileAction(payload);
    }
    if (personalProfile.account_type === 'partner-service' || personalProfile.account_type === 'freelancer') {
      const payload = {
        categories: [parentServiceCategoryItem, serviceCategoryValue] 
      };
      actions.doPutUserProfileAction(payload);
    }
  };

  return (
    <React.Fragment>
      <Row gutter={16} id='bussiness-information-wrapper'>
        <Col md={12} xs={24}>
          <div id='bussines-list-wrapper'>
            <h4>{getTranslatedText('your_business')}</h4>
            <div id='bussines-list-item-wrapper'>
              {personalProfile.categories && personalProfile.categories.map(item => (
                <Tag color='#2699FB'>{item.name}</Tag>
              ))}
            </div>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div id='update-bussiness-wrapper'>
            <div id='update-btn-control-wrapper'>
              <Button
                onClick={() => setFieldDisable(!fieldDisable)}
              >
                <PlusOutlined />
              </Button>
              <p>{getTranslatedText('add_service_category')}</p>
            </div>
            <div id='update-selector-wrapper'>
            {personalProfile.account_type === 'partner-product' ? (
              <Select
                onChange={(value) => handleProductCategoryOnChange(value)}
                className='select_item'
                disabled={fieldDisable}
                // mode='multiple'
              >
                {parentProductCategory && parentProductCategory.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>  
            ) : (
              <Select disabled={fieldDisable} onChange={(value) => handleServiceCategoryOnChange(value)} className='select_item'>
                {parentServiceCategory && parentServiceCategory.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
            {personalProfile.account_type === 'partner-product' ? (
              <Select
                onChange={(value) => handleChildrenProductCategoryOnChange(value)}
                className='select_item'
                disabled={fieldDisable}
                // mode='multiple'
              >
                {childrenProductCategory && childrenProductCategory.map((item, index) => (
                  <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            ) : (
              <Select disabled={fieldDisable} onChange={(value) => handleChildrenServiceCategoryOnChange(value)} className='select_item'>
                {childrenServiceCategory && childrenServiceCategory.map((item, index) => (
                  <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            )}
            </div>
            <Button
              onClick={() => handleOnSubmitCategory()}
              disabled={fieldDisable}
            >
              Submit
            </Button>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div id='bussiness-certification-wrapper'>
            <h4>{getTranslatedText('certificates')}</h4>
            <div style={{width: "100%"}}>
              <input type="file" id="certificate" multiple={true} accept="image/*" onChange={() => uploadFile('certificate')} style={{display: "none"}}/>
              <label htmlFor="certificate" style={{cursor: "pointer"}}>
                <img src={(personalProfile.attachment)? personalProfile.attachment[0] : placeholder} id="certificate_0" width="20%" />
                <img src={(personalProfile.attachment)? personalProfile.attachment[1] : placeholder} id="certificate_1" width="20%" />
                <img src={(personalProfile.attachment)? personalProfile.attachment[2] : placeholder} id="certificate_2" width="20%" />
                <img src={(personalProfile.attachment)? personalProfile.attachment[3] : placeholder} id="certificate_3" width="20%" />
                <img src={(personalProfile.attachment)? personalProfile.attachment[4] : placeholder} id="certificate_4" width="20%" />
              </label>
              <button
                style={{
                  backgroundColor: '#4EA752',
                  height: '40px',
                  padding: '0 16px',
                  color: 'white',
                  fontWeight: 'bold',

                }}
                onClick={() => handleOnUpdateCefiticate()}
              >
                {getTranslatedText('update_cefiticate')}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    parentServiceCategory: state.serviceCategoryReducer.parentServiceCategory,
    childrenServiceCategory: state.serviceCategoryReducer.childrenServiceCategory,
    parentProductCategory: state.productCategoryReducer.parentProductCategory,
    childrenProductCategory: state.productCategoryReducer.childrenProductCategory,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doPutUserProfileAction,
        doGetCategoryService,
        doGetCategoryProduct,
        doGetChildrenCategoryService,
        doGetChildrenCategoryProduct,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProvideInfoComponent);