import React, {useState, useEffect} from 'react';
import {
  useHistory,
} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {
  selectPartnerRegister,
  selectSaleType
} from "../../redux/actions/authentication";
import {
  Carousel,
  Button,
  Row,
  Col,
} from 'antd';
import { enquireScreen } from 'enquire-js';
import { getTranslatedText } from '../../services/appServices';
import {routers} from '../../config/routers';

import './styles/partner-logo-slider-styles.less';



function PartnerLogoSlider(props) {

  const {
    actions,
    adsData,
  } = props;

  const history = useHistory();

  const [logoArray, setLogoArray] = useState([]);
  const [slideToShow, setSlideToShow] = useState(6);
  const [isMobile, setIsMobile] = useState(false);

  //SET LOGO FOR ARRAY TO DISLAY ON SLIDER
  useEffect(() => {
    let array = [];
    adsData.forEach(item => {
      if (item.type === 'partner') {
        array.push(item);
      }
    });
    setLogoArray(array);
  }, [adsData]);

  //CHECK IS MOBILE
  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(b);
    })
  }, []);

  //SET SLIDE TO SHOW
  useEffect(() => {
    if (isMobile) {
      setSlideToShow(2);
    } else {
      setSlideToShow(6);
    }
  }, [isMobile]);

  function handlePartnerProductButton() {
    actions.selectPartnerRegister('partner-product');
    actions.selectSaleType('saleAndWholeSale');
    history.push(routers.account.partner_register);
  };

  function handlePartnerServiceButton() {
    actions.selectPartnerRegister('partner-service');
    history.push(routers.account.partner_register);
  }

  return (
    <React.Fragment>
      <Row id='partner-logo-slider-wrapper' gutter={16}>
        <Col
          id='title-wrapper'
          md={24}
          xs={24}
        >
          <h2 id='title'>
            {getTranslatedText('become_partner_1')}
            <p id='big-part-title'>{getTranslatedText('partner')}</p>
            {getTranslatedText('become_partner_2')}
          </h2>
        </Col>
        <Col
          md={24}
          xs={24}
          id='col-partner-carousel-wrapper'
        >
          <Carousel
            className='partner-carousel'
            slidesToShow={slideToShow}
            dots={false}
            arrows
            autoplay
            speed={2000}
          >
            {logoArray && logoArray.map((item) => (
              <div
                className='logo-wrapper'
              >
                <a
                  href={item.url}
                  target='_blank'
                >
                  <img src={item.logo} alt='' />
                </a>
              </div>
            ))}
          </Carousel>
        </Col>
        <Col
          md={12}
          xs={12}
        >
          <Button
            className='slider-btn'
            onClick={() => handlePartnerProductButton()}
          >
            {getTranslatedText('btn_sign_up_partner_product')}
          </Button>
        </Col>
        <Col
          md={12}
          xs={12}
        >
          <Button
            className='slider-btn'
            onClick={() => handlePartnerServiceButton()}
          >
          {getTranslatedText('btn_sign_up_partner_service')}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    adsData: state.adsReducer.data || [],
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        selectPartnerRegister,
        selectSaleType
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnerLogoSlider);