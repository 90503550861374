import * as actionTypes from '../actions/action_types';

const adsState = {
  isLoading: false,
  data: [],
};

export const adsReducer = (state = adsState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      }
    case actionTypes.GET_ADS_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
}