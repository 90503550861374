import * as actionTypes from '../actions/action_types';
import { put } from 'redux-saga/effects';

export default {
  getProduct: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', `v1/product/${payload}`);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_PRODUCT_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_PRODUCT_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_PRODUCT_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getAllProduct: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/guest/product', action.payload);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_ALL_PRODUCT_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_ALL_PRODUCT_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_ALL_PRODUCT_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getProductWithFilter: function* (action) {
    // send params url
    let params = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/guest/product', params);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_PRODUCT_WITH_FILTER_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_PRODUCT_WITH_FILTER_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_PRODUCT_WITH_FILTER_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  postProduct: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/product', payload, true);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.POST_PRODUCT_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.POST_PRODUCT_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.POST_PRODUCT_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  postOrder: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/order', payload);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.POST_ORDER_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.POST_ORDER_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.POST_ORDER_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getOrder: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/order');
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_ORDER_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_ORDER_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_ORDER_FAILURE,
        error: ['Something went wrong!']
      });
    }
  },
  getDetailOrder: function* (action) {
    let payload = action.payload
    try {
      let response = yield global.apiService.apiCall('get', `v1/order/${payload}`);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_DETAIL_ORDER_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_DETAIL_ORDER_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_DETAIL_ORDER_FAILURE,
        error: ['Something went wrong!']
      });
    }
  }
};