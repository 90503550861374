import React from 'react';
import {
	Link,
} from 'react-router-dom';
import {
	routers
} from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';
import { PropTypes } from 'prop-types'; 

import ProductFilterNewComponent from '../ProductFilterComponent/ProductFilterNewComponent';
import ProductFeature from '../ProductFeature/ProductFeature';

const ProductComponent = (props) => {

	const {
		productsArray,
		hideFilterComponent,
	} = props;


	return (
		<React.Fragment>
			<div className="container">
				<div className="row">
					{hideFilterComponent ? (
						<React.Fragment></React.Fragment>
					) : (
						<ProductFilterNewComponent />
					)}
					<ProductFeature
						productsArray={productsArray}
					/>
					{hideFilterComponent ? (
						<React.Fragment></React.Fragment>
					) : (
						<div className="col text-center mg-t-20 mg-b-60">
							<Link
								to={routers.products.list.replace(':category_id', '0')}
							>
								<a className="btn yellow arrow_left radius_50">{getTranslatedText('btn_view_all_product')}</a>
							</Link>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

ProductComponent.propTypes = {
	productsArray: PropTypes.array,
	hideFilterComponent: PropTypes.bool,
};

export default ProductComponent;