import {
  PARTNER_REGISTER,
  CUSTOMER_REGISTER,
  FREELANCER_REGISGER,
  COMPANY_REGISTER,
  LOGIN_REQUEST,
  CREATE_ACCOUNT_REQUEST,
  GET_USER_CODE_REQUEST,
  GET_USER_FROM_COOKIE_REQUEST,
  LOGOUT_REQUEST,
  SEND_EMAIL_RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
  SALE_TYPE,
} from './action_types';

// action do app first load check
export const getUserFromCookieAction = () => ({
  type: GET_USER_FROM_COOKIE_REQUEST,
})

export const doLogin = (email, password) => ({
  type: LOGIN_REQUEST,
  payload: {
    email: email,
    password: password,
  }
});

export const doSendEmailResetPassword = data => ({
  type: SEND_EMAIL_RESET_PASSWORD_REQUEST,
  payload: data
});

export const doResetPassword = data => ({
  type: RESET_PASSWORD_REQUEST,
  payload: data
});

export const doRegister = (registerValues) => ({
  type: CREATE_ACCOUNT_REQUEST,
  payload: registerValues
});

export const doLogout = () => ({
  type: LOGOUT_REQUEST
})

export const selectPartnerRegister = option => ({
  type: PARTNER_REGISTER,
  payload: option,
});

export const selectCustomerRegister = option => ({
  type: CUSTOMER_REGISTER,
  payload: option,
});

export const selectSaleType = option => ({
  type: SALE_TYPE,
  payload: option,
})

export const doRequestUserCode = userType => ({
  type: GET_USER_CODE_REQUEST,
  payload: {
    type: userType
  }
});