import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAdsAction,	
} from '../../redux/actions/ads';

import {
  Carousel,
  Button
} from 'antd';
import {
  ArrowRightOutlined,
} from '@ant-design/icons'
import { getTranslatedText } from '../../services/appServices';

import './styles/slider-custom-styles.less';


function SliderComponent(props) {

  const {
    actions,
    adsData,
    type,
  } = props;

  const [slider, setSlider] = useState([]);

	useEffect(() => {
		actions.getAdsAction();
	}, []);

	useEffect(() => {
		let sliderArray = [];
		switch(type) {
			case 'home':
				adsData.forEach(element => {
					if (element.type === 'main-banner') {
						sliderArray.push(element)
					}
				});
			case 'product':
				adsData.forEach(element => {
					if (element.type === 'product-banner') {
						sliderArray.push(element)
					}
				});
			case 'service':
				adsData.forEach(element => {
					if (element.type === 'service-banner') {
						sliderArray.push(element)
					}
				});
		}
		
		setSlider(sliderArray);
	}, [adsData]);

  return (
    <React.Fragment>
      <Carousel
        className='custom-carousel'
        autoplay
        infinite
        arrows
        speed={2000}
      >
        {slider && slider.map(ad => (
          <div className='carousel-content-wrapper'>
            <img src={(ad.banner) ? ad.banner: require('../../assets/banner.jpg')} alt='' />
            <div className='des-wrapper'>
              <h2>{ad.title}</h2>
              <a
                className='view-more-btn'
                href={ad.url}
                target="_blank"
              >
                {getTranslatedText('view_service')} <ArrowRightOutlined />
              </a>
            </div>
          </div>
        ))}
      </Carousel>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
	return {
		adsData: state.adsReducer.data,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				getAdsAction,
			},
			dispatch
		),
	};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SliderComponent);