// Usage: This is container for Contact Page

import React from 'react';

import ContactBanner from '../../components/ContactBanner/ContactBanner';
import ContactBody from '../../components/ContactBody/ContactBody';
import ContactFooter from '../../components/ContactFooter/ContactFooter';

import './ContactPage.scss';

const ContactPage = () => {

  return (
    <React.Fragment>
      <div>
        <ContactBanner />
        <div className='contact_body_container'>
          <ContactBody />
          <div className='divider'></div>
          {/* <ContactFooter /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactPage;