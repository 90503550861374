// this javascript file contain function saga to handle logic saga for services table
import * as actionTypes from '../actions/action_types';
import { put } from 'redux-saga/effects'; 

export default {
  getServices: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/guest/service', payload);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_SERVICES_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_SERVICES_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_SERVICES_FAILURE,
        error: ['Something went wrong!']
      })
    }
  },
  getServiceWithFilter: function* (action) {
    // param query
    let params = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/guest/service', params);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_SERVICE_WITH_FILTER_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_SERVICE_WITH_FILTER_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_SERVICE_WITH_FILTER_FAILURE,
        error: ['Something went wrong!']
      })
    }
  },
  getDetailService: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', `v1/service/${payload}`);
      if (response) {
        let data = response.data;
        if (data.isSuccess === true) {
          yield put({
            type: actionTypes.GET_DETAIL_SERVICE_SUCCESS,
            payload: data.data,
          })
        } else {
          yield put({
            type: actionTypes.GET_DETAIL_SERVICE_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_DETAIL_SERVICE_FAILURE,
        error: ['Something went wrong!']
      })
    }
  },
  getQuote: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/request-quote');
      let data = response.data;
      if (data.isSuccess) {
        yield put({
          type: actionTypes.GET_QUOTE_SUCCESS,
          payload: data.data,
        });
      } else {
        yield put({
          type: actionTypes.GET_QUOTE_FAILURE,
          error: response,
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_QUOTE_FAILURE,
        error: error,
      });
    }
  },
  putQuote: function * (action) {
    let quoteId = action.payload.id;
    let requestBody = action.payload;
    try {
      let response = yield global.apiService.apiCall('put', `v1/request-quote/${quoteId}`, action.payload);
      let data = response.data;
      if (data.isSuccess) {
        yield put({
          type: actionTypes.PUT_QUOTATION_SUCCESS,
          payload: data.data,
        });
      } else {
        yield put({
          type: actionTypes.PUT_QUOTATION_FAILURE,
          error: response,
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.PUT_QUOTATION_FAILURE,
        error: error,
      });
    }
  },
  getDetailQuote: function* (action) {
    let quoteId = action.payload
    try {
      let response = yield global.apiService.apiCall('get', `v1/request-quote/${quoteId}`);
      let data = response.data;
      if (data.isSuccess) {
        yield put({
          type: actionTypes.GET_DETAIL_QUOTE_SUCCESS,
          payload: data.data,
        });
      } else {
        yield put({
          type: actionTypes.GET_DETAIL_QUOTE_FAILURE,
          error: response,
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_DETAIL_QUOTE_FAILURE,
        error: error,
      });
    }
  },
  getPackage: function* (action) {
    try {
      let response = yield global.apiService.apiCall('get', 'v1/package');
      if (response.data.isSuccess) {
        let packageList = response.data.data
        yield put({
          type: actionTypes.GET_PACKAGE_SUCCESS,
          payload: packageList,
        })
      } else {
        yield put({
          type: actionTypes.GET_PACKAGE_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      } 
    } catch (error) {
      yield put({
        type: actionTypes.GET_PACKAGE_FAILURE,
        error: ['Something went wrong!']
        // error: error
      })
    } 
  },
  updatePackage: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', `v1/payment?method=${payload.method}`, payload);
      if (response.data.isSuccess) {
        let data = response.data.data
        yield put({
          type: actionTypes.UPDATE_PACKAGE_SUCCESS,
          payload: data,
        })
      } else {
        yield put({
          type: actionTypes.UPDATE_PACKAGE_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.UPDATE_PACKAGE_FAILURE,
        error: ['Something went wrong!']
        // error: error
      })
    }
  },
  postNewService: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/service', payload, true);
      if (response.data.isSuccess) {
        let newService = response.data.data
        yield put({
          type: actionTypes.POST_NEW_SERVICE_SUCCESS,
          payload: newService,
        })
      } else {
        yield put({
          type: actionTypes.POST_NEW_SERVICE_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.POST_NEW_SERVICE_FAILURE,
        error: ['Something went wrong!']
        // error: error
      })
    }
  },
  postQuote: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/request-quote', payload);
      let data = response.data;
      if (data.isSuccess === true) {
        yield put ({
          type: actionTypes.POST_QUOTE_SUCCESS,
          data: data.data,
        });
      } else {
        yield put({
          type: actionTypes.POST_QUOTE_FAILURE,
          error: response,
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.POST_QUOTE_FAILURE,
        error: error,
      });
    }
  },
  postEstimate: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('post', 'v1/estimate', payload);
      console.log('estimate response', response);
      if (response.data.isSuccess === true) {
        yield put ({
          type: actionTypes.POST_ESTIMATE_SUCCESS,
          data: response.data.data,
        });
      } else {
        yield put({
          type: actionTypes.POST_ESTIMATE_FAILURE,
          error: response.data.errors,
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.POST_ESTIMATE_FAILURE,
        error: error,
      });
    }
  }
};