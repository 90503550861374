import React from 'react';
import {
  Layout,
  Typography,
} from 'antd';
import './postnewservicestyles.less';


const {
  Content
} = Layout;

const {
  Title,
  Paragraph,
  Link,
} = Typography;


const NewRegister = () => {
  return (
    <React.Fragment>
      <Content
        id='layout-content-container'
      >
        <Typography>
          <Title
            id='title'
            level={3}
          >
            ĐĂNG KÝ VÀ KÍCH HOẠT TÀI KHOẢN B2BGOSELL
          </Title>
          <Paragraph>
          <strong>1. Đăng ký</strong><br/>
          Người sử dụng muốn mở <strong>Tài khoản B2BGosell</strong> tiến hành truy cập vào website  <Link>www.b2bgosell.com</Link>, sau khi đã tìm hiểu kỹ các quy định, sẽ tiến hành khai báo, cập nhật các thông tin <strong>đăng ký tài khoản B2BGosell</strong> và chấp nhận các điều khoản do <strong>B2BGosell</strong> quy định.<br/> 
          Có 2 loại tài khoản người dùng trên <strong>B2BGosell</strong> là: TK <strong>KHÁCH HÀNG</strong> và TK <strong>ĐỐI TÁC</strong><br/>

          <strong>1- Tài khoản KHÁCH HÀNG:</strong><br/>
          ∙ Bước 1: Chọn <strong>ĐĂNG KÝ</strong> để mở tài khoản -> chọn <strong>KHÁCH HÀNG</strong> -> chọn <strong>KẾ TIẾP</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/8pZNEwHgf1mhRl1JVhVVGpe2D5BLAcRqDnT5qj_ZUj4WBwtaWu4O0R2DB9dhGnckYm0fD0E9DXz-TXm0CzT1PHVrPoZRasSNUYfTBAIGpptNIKbH5rUodgilOYmNZFHIDnBPQDQLk7IBgCtDWw' alt='' />
          <Paragraph>
          ∙ Bước 2: Tại THÔNG TIN LIÊN HỆ CỦA BẠN, hệ thống tự tạo <strong>Mã tài khoản ID</strong> cho bạn -> điền thông tin của bạn gồm:
          <strong>- Email  -> Mật khẩu -> Họ tên -> Số điện thoại ->  Địa chỉ -> Chọn quốc gia của bạn</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/ppmNBAjdTi8ewZixeM488avaiBiEGH49NnwP7LREy9rFT7eAXoOWeZKOkPrnCgPbAw96hs_y6TKXOPoDiwzYegtvYOBwY2c48TQt8WnMTlmiNO6JiK6MiNerONoQKTAQK9lzg6kNQt-WgwMJ-Q' alt='' />
          <Paragraph>
          ∙ Bước 3: Tại THÔNG TIN CÔNG TY CỦA BẠN -> điền thông tin gồm:<br/>
          <strong>- Tên công ty  -> Địa chỉ công ty -> Email công ty -> Số điện thoại công ty ->  Website</strong> -> chọn <strong>“Tôi đồng ý với các điều khoản …”</strong> -> chọn <strong>ĐĂNG KÝ</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/l1ynLznAoBF31bOa3GAcreksv_tHssWBCYKUQBPpNGeklFE_KXhBubc67vsIT-ejcp6_GpZ7y1x-WvGZd0-KC4uNmSdeRdMdAo5BGnIst5ZTkAIlPg8k3nGf6fp4Pw_QjyCxnZoZf6PnJu_FEA' alt='' />
          <Paragraph>
          <strong>2- Tài khoản ĐỐI TÁC:</strong> có 2 loại Tài khoản ĐỐI TÁC là <strong>CÁ NH N</strong> và <strong>DOANH NGHIỆP</strong><br/>

          <strong>A. Tài khoản CÁ NH N/FREELANCER:</strong><br/>
          - Là tài khoản dành cho người dùng muốn cung cấp <strong>DỊCH VỤ</strong> với chuyên môn, khả năng của mình và tư cách là <strong>CÁ NH N</strong><br/>
          ∙ Bước 1:  Chọn <strong>ĐĂNG KÝ</strong> để mở tài khoản -> chọn <strong>ĐỐI TÁC</strong> -> chọn <strong>KẾ TIẾP</strong><br/>
          ∙ Bước 2:  Tại ĐĂNG KÝ MỚI TÀI KHOẢN -> chọn <strong>FREELANCER</strong> -> chọn <strong>CUNG CẤP DỊCH VỤ</strong> -> chọn <strong>KẾ TIẾP</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/AhkD8Rasn9r3EE1t93IpUVrkJc4zPecgcotQDla2jNs4ylIUPQRukfl5AaZVqwZ9anU7lMsDh8wEHtkvu-o0OEai2irOUzl-1OrAMGOlhtXM4-UiqyqTpdwtz_loAzLBgQi1bcl8bhibmyP5EA' alt='' />
          <img src='https://lh5.googleusercontent.com/1V1XfofP8RZMlZHfZK_gABIxT1odIubROd3qfcf7L2U_BxxnDbtIFM7Ll8twcyF5-eznR3SlpOiEuVyXfe8qcgEHR5tJXweZ_8R31ku5cz_F_SBtJHU9a3C2xKYt5auXjkz2l9N1WCtUFB55tA' alt='' />
          <Paragraph>
          ∙ Bước 3:  Tại THÔNG TIN LIÊN HỆ CỦA BẠN, hệ thống tự tạo <strong>Mã tài khoản ID</strong> cho bạn -> điền thông tin của bạn gồm:<br/>
          <strong>- Email  -> Mật khẩu -> Họ tên -> Số điện thoại ->  Địa chỉ -> Chọn quốc gia của bạn</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/sjSMh4QsFf3ep7j9ggIKlASvzjRJDI8WZ_4oXpjiRjzXwP8gmFYLdFLDVYLRqSF-sEPEAu6rGCX_MoRiHFJwmU-G-hDtC3G0TQFJY6NbHeHXP88anhkwdSauFogCu2dp4xn4szaxCte-QA6roA' alt='' />
          <Paragraph>
          ∙ Bước 4:  Tại NGÀNH NGHỀ HOẠT ĐỘNG CỦA BẠN<br/>
          -  Chọn <strong>CHUYÊN MÔN/DỊCH VỤ</strong> của bạn -> upload <strong>Bằng cấp, Chứng chỉ</strong> chuyên môn của bạn -> chọn <strong>“Tôi đồng ý với các điều khoản …”</strong>  -> chọn <strong>ĐĂNG KÝ</strong>
          </Paragraph>
          <img src='https://lh6.googleusercontent.com/Mh7N_8wEET1c8bM_iAXmt10SrGufZfasqoAJlX4-Z3Mihp4_g7FF-Vdk1_3B1WoRY6NxFJgutd4N6ZMb2Zfxag49667muikZT9AvNAH9B21DHZjkzMSNeGJS8XXKI3iiacxgfKIRGq9JLSDSNA' alt='' />
          <Paragraph>
          <strong>B. Tài khoản DOANH NGHIỆP:</strong><br/>
          - Là tài khoản dành cho người dùng muốn cung cấp <strong>DỊCH VỤ (or) BÁN SẢN PHẨM</strong> với tư cách là <strong>DOANH NGHIỆP/TỔ CHỨC</strong><br/>
          ∙ Bước 1:  Chọn <strong>ĐĂNG KÝ</strong> để mở tài khoản -> chọn  <strong>ĐỐI TÁC</strong> -> chọn <strong>KẾ TIẾP</strong><br/>
          ∙ Bước 2:  Tại ĐĂNG KÝ MỚI TÀI KHOẢN<br/>
          - Chọn <strong>CÔNG TY</strong>  ->  chọn <strong>CUNG CẤP DỊCH VỤ (or) BÁN SẢN PHẨM</strong>  -> chọn <strong>BÁN LẺ (or) BÁN SỈ (or) BÁN SỈ - LẺ</strong> -> chọn <strong>KẾ TIẾP</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/AhkD8Rasn9r3EE1t93IpUVrkJc4zPecgcotQDla2jNs4ylIUPQRukfl5AaZVqwZ9anU7lMsDh8wEHtkvu-o0OEai2irOUzl-1OrAMGOlhtXM4-UiqyqTpdwtz_loAzLBgQi1bcl8bhibmyP5EA' alt='' />
          <img src='https://lh6.googleusercontent.com/KgtzecmDn5RWlnvQmQvZ0_1x2HGcjQ5lopVJAVqnckNd96Wk65BQ7Ea29WxtH8BklHv9jd016WQwfS-BT4j-8SVfaTLPRRxFrM1CLwML_ucclCf0vUBp8AZsDdz4GhoLJvXYTfGPhUpoXYEI_g' alt='' />
          <Paragraph>
          <strong>* NẾU CÔNG TY BẠN CUNG CẤP DỊCH VỤ:</strong><br/>
          ∙ Bước 1:  Tại THÔNG TIN LIÊN HỆ CỦA BẠN, hệ thống tự tạo <strong>Mã tài khoản ID</strong> cho bạn -> điền thông tin của bạn gồm:<br/>
          <strong>- Email  -> Mật khẩu -> Họ tên -> Số điện thoại ->  Địa chỉ -> Chọn quốc gia của bạn</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/xRp3dhVQvVGrf9CNmGlbQmJbDIUsf4AdFEg5mmc7RPnlXcpnt-luSsJQeH0Nn28mQm_Ng9sl4ApeyAefzQoY5ljofF_1cIH8EYip7AxPe0f7x9xQHZ6ZRm5K6eybIy6OiRUaP8Z0xg7dom2pqg' alt='' />
          <Paragraph>
          ∙ Bước 2:  Tại THÔNG TIN CÔNG TY CỦA BẠN -> điền thông tin gồm:<br/>
          <strong>- Tên công ty  -> Địa chỉ công ty -> Email công ty -> Số điện thoại công ty ->  Website</strong>

          ∙ Bước 3:  Tại NGÀNH NGHỀ HOẠT ĐỘNG CỦA BẠN<br/>
          -> Chọn <strong>DỊCH VỤ</strong> bạn cung cấp  ->  upload <strong>Bằng cấp, Chứng chỉ, Giấy phép,Chứng từ</strong> có liên quan đến hoạt động kinh doanh của bạn  -> chọn <strong>“Tôi đồng ý với các điều khoản …”</strong> -><br/> chọn <strong>ĐĂNG KÝ</strong>
          </Paragraph>
          <img src='https://lh5.googleusercontent.com/lTmo-PbfIto_vA1JnPw6EbljyTVqbTlvMLJsdP3Xm7AZdtMZLTKqsSMk8-AvPuzVAIdW65qICRczBz_-XBBQw5CrA_valKP__o7LL0m5zZqvEZrAU670dlynZrdJmoRzom1Bj5LhWZyaAjkzSw' alt='' />
          <Paragraph>
          <strong>* NẾU CÔNG TY BẠN BÁN SẢN PHẨM:</strong><br/>

        ∙ Bước 1:  Tại THÔNG TIN LIÊN HỆ CỦA BẠN, hệ thống tự tạo <strong>Mã tài khoản ID</strong> cho bạn -> điền thông tin của bạn gồm:<br/>
        <strong>- Email  -> Mật khẩu -> Họ tên -> Số điện thoại ->  Địa chỉ -> Chọn quốc gia của bạn</strong>
          </Paragraph>
          <img src='https://lh4.googleusercontent.com/ezCjXTPshwqxziNPzvpSnoxPIkqbbOeaBFROD7pvLnYXDe0FPpevUmsEa7QWso8l0rZevgCdDPrB0WLUpEfXTRJcrVLvoOdkWPGobCk5FRiaQLQfZo5K8yofjQQo9Pcb86OVV_CWdmRVXf1ixg' alt='' />
          <Paragraph>
          ∙ Bước 2:  Tại THÔNG TIN CÔNG TY CỦA BẠN -> điền thông tin gồm:<br/>
          <strong>- Tên công ty  -> Địa chỉ công ty -> Email công ty -> Số điện thoại công ty ->  Website</strong> 

          ∙ Bước 3:  Tại NGÀNH NGHỀ HOẠT ĐỘNG CỦA BẠN<br/>
          -> Chọn <strong>SẢN PHẨM</strong> bạn cung cấp  ->  upload <strong>Bằng cấp, Chứng chỉ, Giấy phép,Chứng từ</strong> có liên quan đến hoạt động kinh doanh của bạn  -> chọn <strong>“Tôi đồng ý với các điều khoản …”</strong><br/> -> chọn <strong>ĐĂNG KÝ</strong>
          </Paragraph>
          <img src='https://lh3.googleusercontent.com/RYWZG3QQmdSimzTOfGgirYg7t5cbeEBbn2IVibfXECtA2uBEPRPlAKjUjif6a60VmEzVs5kqic0M0E0J1NowIVhDZgKJeMglD7vgFXgFyZNSxBvR27DZM4dcPoniggDl2GRY_EOaAvY9V5oTZA' alt='' />
          <Paragraph>
          <strong>2. Kích hoạt tài khoản</strong><br/>
- Bước 1:  Sau khi đăng ký tài khoản thành công, bạn có thể đăng bài SẢN PHẨM (hoặc) DỊCH VỤ theo đúng chức năng đã đăng ký trên tài khoản của bạn.<br/>
- Bước 2:  Bài đăng đầu tiên cũng là bước để Hệ thống/Admin kiểm tra thông tin tài khoản và kích hoạt cho tài khoản của bạn. Trường hợp thông tin của bạn chưa đầy đủ, Admin sẽ liên hệ bạn và đề nghị cung cấp thêm.<br/>
- Bước 3: Tài khoản được kích hoạt sau khi bài đăng đầu tiên của bạn được duyệt.<br/>

<strong>Nếu sau 3 ngày kể từ lúc bạn đăng bài đầu tiên nhưng chưa thấy hiển thị, hãy liên hệ Admin để được hỗ trợ.</strong>

          </Paragraph>
          <Paragraph>
          <Paragraph
            strong
            type='danger'
          >
            THÔNG TIN LIÊN HỆ HỖ TRỢ:
          </Paragraph>
          <strong>GREATLINK MAIHOUSE INVESTMENT COMPANY LIMITED (B2BGOSELL IN VIETNAM)</strong><br/>
          <strong>Add (new):</strong> 11A, Hong Ha, Ward 2, Tan Binh Distric, HoChiMinh City, Zip Code 700000, Vietnam<br/>
          Mobile: <strong>+84 936.168.503</strong><br/>
          Email: <Link>admin@b2bgosell.com;</Link><br/>
          Website: <Link>www.b2bgosell.com</Link><br/>

          <strong>CHÚC CÁC BẠN THÀNH CÔNG!</strong>
          </Paragraph>
        </Typography>
      </Content>
    </React.Fragment>
  );
};

export default NewRegister;