import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Steps,
	Radio,
	Form,
} from 'antd';
import 'antd/dist/antd.css';
import Modal_Login from '../Modal_Login/Modal_Login';
import Payment_FormAddress from '../Payment_FormAddress/Payment_FormAddress';
import { getTranslatedText } from '../../services/appServices';
import {
	doCreateOrder,
} from '../../redux/actions/order';
import {
	doCreateCartAction
} from '../../redux/actions/products';
import { bindActionCreators } from "redux";
import {
 usdCurrencyFormat,
 vndCurrencyFormat,
} from '../../services/appServices';


const RetailCheckout = (props) => {

	const [currentStep, setCurrentStep] = useState(1);
	const [paymentStep, setPaymentStep] = useState(0);
	const [contactShipPayload, setContactShipPayload] = useState(null);
	const [totalItem, setTotalItem] = useState(0);

	const {
		cart,
		isHadAccount,
		userId,
		actions,
	} = props;

	let current_language = localStorage.getItem('current_language') || 'vi';

	useEffect(() => {
		console.log('account', isHadAccount)
		if (cart.cart) {
			let total = 0;
			cart.cart.forEach(item => {
				total += 1;
			});
			setTotalItem(total);
		}
	}, []);

	const handleOnPaymentFinish = (values) => {
		setCurrentStep(2);
		const orderPayload = {
			order_name: contactShipPayload.name,
			order_address: contactShipPayload.address,
			order_city: contactShipPayload.city,
			order_phone: contactShipPayload.phone,
			order_email: contactShipPayload.email,
			order: cart.cart,
			total: totalItem,
			order_type: 'le',
			user_id: userId,
			payment_method: values.paymentMethod
		};
		actions.doCreateOrder(orderPayload);
		// toast.success('Your order is sent to suplier!');
	};

	const renderAllTotal = () => {
		if (current_language === 'vi') {
      var total = 0;
		  cart.cart.forEach(item => {
			  total += (item.quantity * item.price);
      });
      return (
        <React.Fragment>
          <div className="number">{vndCurrencyFormat.format(total)}</div>
        </React.Fragment>
      );
    } else {
      var total = 0;
		  cart.cart.forEach(item => {
			  total += (item.quantity * item.price);
		  })
      return (
        <React.Fragment>
          <div className="number">{usdCurrencyFormat.format(total)}</div>
        </React.Fragment>
      );
    }
	};

	return (
		<React.Fragment>
			<div
				style={{
					marginTop: '50px',
					height: '52vw',
				}}
				className="container bg_white mg-b-60"
			>
				<div className="row">
					<div className="col-12 product_sg_content cart_content">
						<div className="strip_bar">
							<div className="strip_bar">
								<Steps current={currentStep}>
									<Steps.Step title={getTranslatedText('order_title')} />
									<Steps.Step title={getTranslatedText('payment').toUpperCase()} />
									<Steps.Step title={getTranslatedText('send_order')} />
								</Steps>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- row main --> */}
				<div className="row row_checkout_2">
					<div className="col-6">
						{isHadAccount === false ? (
							<Modal_Login />
						) : (
								<div className="_box">
									{paymentStep === 1 ? (
										<Form onFinish={(values) => handleOnPaymentFinish(values)} className="form_shipping payment black">
											<h3 className="title">
												{getTranslatedText('payment_title')}
											</h3>
											<Form.Item
												name='paymentMethod'
											>
												<Radio.Group
													className='form-check'
												>
													<Radio
														value='direct'
													>
														{getTranslatedText('cash_pay')}
													</Radio>
													{/* <Radio
														value='banking'
													>
														{getTranslatedText('ngan_luong_pay')}
													</Radio> */}
												</Radio.Group>
											</Form.Item>
											<Form.Item>
												<button
													style={{
														width: '228px',
														height: '40px',
														backgroundColor: "red",
														fontWeight: 'bold',
														color: 'white',
														border: 'none',
														outline: '0'
													}}
												>
													{getTranslatedText('set_order')}
												</button>
											</Form.Item>
										</Form>
									) : (
											<Payment_FormAddress
												setPaymentStep={setPaymentStep}
												setContactShipPayload={setContactShipPayload}
											/>
										)}
								</div>
							)}
					</div>
					<div className="col-6">
						<table className="cart_product center">
							<tr className="top">
								<th>{getTranslatedText('product_name')}</th>
								<th className="left">{getTranslatedText('number_quantity')}</th>
								<th className="right">{getTranslatedText('total_money')}</th>
							</tr>
							<tbody>
								{cart.cart && cart.cart.map(item => (
									<tr>
										<td className="left">
											<div className="p_2_col">
												<div className="right">
													<h3 className="title">{item.product_name}</h3>
													<div>{getTranslatedText('provide_by')} <b>{item.provider_short_name}</b></div>
												</div>
											</div>
										</td>
										<td>
											{item.quantity}
										</td>
										{item.product_price_unit === 1 ? (
											<td className="total">{vndCurrencyFormat.format(item.quantity * item.price)}</td>	
										) : (
											<td className="total">{usdCurrencyFormat.format(item.quantity * item.price)}</td>	
										)}
										{/* <td className="total">${(item.quantity * item.price)}</td> */}
									</tr>
								))}
							</tbody>
						</table>
						<div className="total_cart">
							<div className="title">{getTranslatedText('total_money_pay')}</div>
							{renderAllTotal()}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		cart: state.cartReducer,
		isHadAccount: state.getUserFromCookiesReducer.isHadAccount,
		userId: state.getUserFromCookiesReducer.id,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				doCreateOrder,
				doCreateCartAction,
			},
			dispatch,
		),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RetailCheckout);