import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doLogin } from '../../redux/actions/authentication';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
} from 'antd';
import './Modal_Login.less';
import {
  Link
} from 'react-router-dom';
import {
  routers
} from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

const Modal_Login = (props) => {

  const {
    actions
  } = props;

  const onFinish = (values) => {
    console.log('value form', values);
    actions.doLogin(values.email, values.password);
  };

  return (
    <React.Fragment>
      <Row
        className='modal_login_container'
      >
        <Col
          md={24}
          xs={24}
        >
          <h1
            className='layout_title'
          >
            {getTranslatedText('login_title')}
          </h1>
          <p
            className='layout_subtitle'
          >
            {getTranslatedText('login_to_buy')}
          </p>
          <Form
            layout='vertical'
            onFinish={(values) => onFinish(values)}
          >
            <Form.Item
              name='email'
            >
              <Input
                placeholder='Email/ SĐT'
                className='modal_form_input_item'
              />
            </Form.Item>
            <Form.Item
              name='password'
            >
              <Input.Password
                placeholder='Mật khẩu'
                className='modal_form_input_item'
              />
            </Form.Item>
            <Row
            >
              <Col
                span={24}
                className='footer_item'
              >
                <Link
                  to={routers.account.forget_password}
                >
                  <p
                    className='forget_password_button'
                  >
                    {getTranslatedText('forget_password_title')}
                  </p>
                </Link>
              </Col>
              <Col
                span={24}
              >
                <Form.Item>
                  <Button
                    className='modal_login_button'
                    htmlType='submit'
                  >
                    {getTranslatedText('header_btn_login')}
                  </Button>
                </Form.Item>
              </Col>
              <Col
                span={24}
              >
                <Link
                  to={routers.account.sign_up}
                >
                  <button
                    className='modal_register_button'
                  >
                    {getTranslatedText('register_label')} {getTranslatedText('register_button_modal')}
                  </button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doLogin,
      },
      dispatch,
    ),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(Modal_Login);