import { put } from 'redux-saga/effects';
import * as actionTypes from '../actions/action_types';

export default {
  getDocument: function* (action) {
    let slug = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', `v1/post/find-by-slug/${slug}`);
      if (response) {
        let data = response.data;
        if (data.isSuccess) {
          yield put({
            type: actionTypes.GET_DOCUMENT_SUCCESS,
            payload: data.data
          })
        } else {
          yield put({
            type: actionTypes.GET_DOCUMENT_FAILURE,
            error: data.errors
          })
        }
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_DOCUMENT_FAILURE,
        error: error
      })
    }
  }
}