import React, { useEffect } from 'react';
import {toast} from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	doPostQuote,
	doGetDetailServices,
} from '../../redux/actions/services';
import { routers } from '../../config/routers';
import { Form, Input, Button, Row, Col } from 'antd';
import './Service_RequestPartnerEstimate.scss';
import { getTranslatedText } from '../../services/appServices';
import SunEditor from 'suneditor-react';


const Service_RequestPartnerEstimate = (props) => {

	const {
		currentServiceDetail,
		match,
		actions,
	} = props;

	useEffect(() => {
		actions.doGetDetailServices(match.params.service_id);
	}, []);

	const handleOnRequestFinish = (values) => {
		const requestQutationData = {
			request_info: values.request_info,
			category_id: currentServiceDetail.category_id,
			title: currentServiceDetail.title,
			description: currentServiceDetail.detail,
			service_id: parseInt(match.params.service_id),
			location: currentServiceDetail.location,
			skill: currentServiceDetail.skill,
			partner_id: currentServiceDetail.user_create
		};
		actions.doPostQuote(requestQutationData);
		// toast.success('Request quotation success!');
	}

	return (
		<React.Fragment>
			<div className="container bg_white black">
				<div className="row mg-t-30">
					<h1 className="title_sec bg_green">{currentServiceDetail.title}</h1>
					<div className="col-12 single_service">
						<div className="content">
							<div className="left">
								<div className="table_2">
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<img src={require("../../assets/service/avatar_2.png")} alt="" />
										<h3 className="title fs-17">{currentServiceDetail.user_name}</h3>
										<div className="rating mg-t-30">
											<span className="item active"></span>
											<span className="item active"></span>
											<span className="item active"></span>
											<span className="item active"></span>
											<span className="item"></span>
										</div>
										<div>
											<a href="" className="btn yellow_2 xs" data-toggle="modal" data-target="#modal_feedback">Xem hồ sơ</a>
										</div>
									</div>
									<div>
										{/* <p>
											{currentServiceDetail.detail}
										</p> */}
										<div
											dangerouslySetInnerHTML={{__html: currentServiceDetail.detail}}
										></div>
									</div>
								</div>
								<div className="category mg-t-15">
								<div style={{ display: 'flex', flexDirection: 'row'}} className="category mg-t-15">
									<p>{getTranslatedText('service_category')}:</p>
									<div style={{ paddingLeft: '36px'}} >
										{currentServiceDetail.child_category_name ? (
											<a href="" className="item">{currentServiceDetail.child_category_name}</a>
										) : (
											<a href='' className="item">{getTranslatedText('not_update')}</a>
										)}
										{currentServiceDetail.category_name ? (
											<a style={{marginLeft: '16px'}} href="" className="item">{currentServiceDetail.category_name}</a>
										) : (
											<a style={{marginLeft: '16px'}} className="item">{getTranslatedText('not_update')}</a>
										)}
									</div>
								</div>
								</div>
							</div>
							<div className="right">
								<div className="_info">
									<div className="_date mg-b-15">{currentServiceDetail.created_at}</div>
									<div className="_location mg-b-15">{currentServiceDetail.location}</div>
									<div className="_customer mg-b-15">{getTranslatedText('estimated_total')} {currentServiceDetail.customer} {getTranslatedText('customer_normal')}</div>
								</div>
								{/* <div className="pd-t-30 text-center align_center">
									<button
										onClick={() => handleOnRequestFinish()}
										className="btn green full shadow radius_50"
										type="button"
									>
										{getTranslatedText('btn_request_quotation').toUpperCase()}
									</button>
								</div> */}
							</div>
						</div>
					</div>
					{/* <!-- Send Quote --> */}
				</div>
			</div>
			<div className="container bg_white black mg-t-30 mg-b-40 pd-b-40">
        <h4 className='title_request'>{getTranslatedText('request_estimate_detail')}</h4>
				<div className="row">
					<Form onFinish={(values) => handleOnRequestFinish(values)}>
            <Row gutter={16}>
              <Col lg={16} xs={24}>
                <Form.Item
									name='request_info'
								>
                  <SunEditor
                    name="my-editor" 
                    enableToolbar={true}
                    height='700'
                    setOptions={{
                      imageFileInput: true,
                      buttonList: [
                        ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                        ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                      ]
                    }}
                    onFocus={(event) => {console.log(event)}}
                  />
                </Form.Item>   
              </Col>
              <Col lg={4} xs={24}>
                <p id='text_item'>{getTranslatedText('suggest_title')}
                    {getTranslatedText('request_estimate_suggest')}</p>
              </Col>
            </Row>
					  <div style={{marginTop: '100px'}} className="col col-xs-12 col-sm-12 col-md-8">
						  <div className="mg-t-25">
							  <div className="text-center mg-t-30 pd-b-10 ">
                  <Form.Item>
								    <Button
								      className="btn green shadow radius_50 "
								      htmlType='submit'
								    >
									    {getTranslatedText('btn_request_quotation').toUpperCase()}
								    </Button>
                  </Form.Item>
                </div>
						  </div>
					  </div>
          </Form>
					{/* <!-- end row --> */}
				</div>
			</div>

			{/* <!-- Popup feedback --> */}
			<div className="modal black fade " id="modal_feedback" tabindex="-1 " role="dialog " aria-labelledby="modal_feedback" aria-hidden="true ">
				<div className="modal-dialog modal-dialog-centered mw_1048 " role="document ">
					<div className="modal-content modal_feedback">
						<div className="left ">
							<div className="avatar">
								<img src={require("../../assets/service/avatar.png")} alt="" />
							</div>
							<div className="info">
								<div className="title">{currentServiceDetail.company_name}</div>
								<div className="sub">{currentServiceDetail.title}</div>
							</div>
							<div className="rating">
								<span className="item active"></span>
								<span className="item active"></span>
								<span className="item active"></span>
								<span className="item active"></span>
								<span className="item"></span>
								<span className="text">1.200 người đã đánh giá</span>
							</div>
							<div className="career_box">
								<p className="title">NGÀNH NGHỀ HOẠT ĐỘNG</p>
								{currentServiceDetail.child_category_name ? (
										<a href="" className="item">{currentServiceDetail.child_category_name}</a>
									) : (
										<a className="item">{getTranslatedText('not_update')}</a>
									)}
									{currentServiceDetail.category_name ? (
										<a href="" className="item">{currentServiceDetail.category_name}</a>
									) : (
										<a className="item">{getTranslatedText('not_update')}</a>
									)}
								{/* <a href="" className="item"> Hồ sơ pháp lý </a> */}
							</div>
							<div className="content">
								<p>{currentServiceDetail.detail}</p>
							</div>

							<form className="review_form">
								<div className="title">ĐÁNH GIÁ CỦA BẠN CHO ĐỐI TÁC NÀY</div>
								<div className="rating review">
									<span className="item"></span>
									<span className="item"></span>
									<span className="item"></span>
									<span className="item"></span>
									<span className="item"></span>
								</div>
								<div>
									<button className="btn gray ">Đánh giá</button>
								</div>
							</form>
						</div>
						{/* <!-- end left --> */}
						<div className="right ">
							<span className="close" data-dismiss="modal"></span>
							<div className="comment_box">
								<div className="top">
									<span className="title">PHẢN HỒI Ý KIẾN</span>
									<a href="http://" className="share">share</a>
								</div>
								<form action="" className="">
									<textarea name="comment" id="" placeholder="Viết nhận xét của bạn tại đây..."></textarea>
									<button className="btn yellow_2 small send pd-lr-20">GỬI</button>
								</form>
								<div className="list_comment">
									<div className="item">
										<div className="img">
											<img src={require("../../assets/service/avt_small_1.png")} alt="" />
										</div>
										<div className="content">
											<div>
												<span className="name">Nguyễn Hoàng</span>
												<span className="time">1h trước</span>
											</div>
											<div>
												Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống kịp thời, giúp chúng tôi giải quyết nhanh chóng công việc.
											</div>
										</div>
									</div>
									<div className="item">
										<div className="img">
											<img src={require("../../assets/service/avt_small_2.png")} alt="" />
										</div>
										<div className="content">
											<div>
												<span className="name">Phạm Thúy Vân</span>
												<span className="time">2 ngày trước</span>
											</div>
											<div>
												Cho tôi sự yên tâm trọn vẹn khi thực hiện công việc, và mang đến những giá trị cao khi hợp tác giải quyết các công việc với đơn vị chức năng.
											</div>
										</div>
									</div>
									<div className="item">
										<div className="img">
											<img src={require("../../assets/service/avatar.png")} alt="" />
										</div>
										<div className="content">
											<div>
												<span className="name">Nguyễn Hoàng</span>
												<span className="time">1h trước</span>
											</div>
											<div>
												Làm báo cáo đúng hạn, tư vấn nhanh chóng các tình huống kịp thời, giúp chúng tôi giải quyết nhanh chóng công việc.
											</div>
										</div>
									</div>
								</div>
								{/* <!-- End list cmt --> */}
							</div>
						</div>
						{/* <!-- End right --> */}
					</div>
				</div>
			</div>
			{/* <!-- End popup --> */}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		currentServiceDetail: state.serviceReducer.currentServiceDetail,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				doPostQuote,
				doGetDetailServices,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Service_RequestPartnerEstimate);