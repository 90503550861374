import * as actionTypes from '../actions/action_types';
import Cookies from 'universal-cookie';
import {toast} from "react-toastify";
import {routers} from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';

// app load check

const userCookiesInitialState = {
  isHadAccount: null,
  userName: 'B2BGosell',
  accountType: '',
  id: 0,
  balance: 0,
  short_name: '',
  isLoading: false,
};

export const getUserFromCookiesReducer = (state = userCookiesInitialState, action) => {
  let cookie = new Cookies();
  switch (action.type) {
    case actionTypes.GET_USER_FROM_COOKIE_REQUEST:
      return {
        ...state,
      isLoading: true,
      };
    case actionTypes.GET_USER_FROM_COOKIE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        isHadAccount: true,
        userName: action.payload.name,
        accountType: action.payload.account_type,
        id: action.payload.id,
        balance: action.payload.balance,
        short_name: action.payload.short_name,
        isLoading: false,
      };
    case actionTypes.GET_USER_FROM_COOKIE_FAILURE:
      console.log(action.payload);
      return {
        ...state,
        isHadAccount: false,
        isLoading: false
      };
    // Logout
    case actionTypes.LOGOUT_REQUEST:
      toast.success(getTranslatedText("logout_success"), {
        autoClose: 3000
      });
      cookie.remove('_user');
      cookie.remove('_token');
      localStorage.removeItem('localStorageCart')
      setTimeout(() => {
        return {
          ...state,
          isHadAccount: false
        }
      }, 1000);
    default:
      return state
  }
}

// AUTHENTICATION SESSION
// ////////////////////// /
const isAuthenticateState = {
  isLoading: false,
};

export const authenticationReducer = (state = isAuthenticateState, action) => {
  let cookie = new Cookies();
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOGIN_SUCCESS:
      if (action.payload.api_token) {
        console.log(action.payload);
        toast.success("Đăng nhập thành công");
        cookie.set('_token', action.payload.api_token, {maxAge: 18000});
        cookie.set('_user', action.payload, {maxAge: 18000});
        setTimeout(() => {
          window.location.pathname = routers.home;
        }, 400)
      } else {
        toast.error("Wrong token!");
      }
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.LOGIN_FAILURE:
      let msg = action.error.join(', ');
      toast.error(msg);
      return {
        ...state,
        isLoading: false,
      }

    // Register
    case actionTypes.CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.CREATE_ACCOUNT_SUCCESS:
      setTimeout(() => {
        window.location.pathname = routers.account.login
      }, 2000);
      toast.success('Register Success, please login!');
      return {
        ...state,
        isLoading: false,
      }
    case actionTypes.CREATE_ACCOUNT_FAILURE:
      toast.error(action.error.join(','));
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state

    case actionTypes.SEND_EMAIL_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SEND_EMAIL_RESET_PASSWORD_SUCCESS:
      toast.success("One email has been send to your mailbox! Please check it to reset password!");
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.SEND_EMAIL_RESET_PASSWORD_FAILURE:
      toast.error(action.error.join(','));
      return {
        ...state,
        isLoading: false,

      }
    case actionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      toast.success("Reset password successfully! Please login!");
      window.location.href = routers.account.login;
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.RESET_PASSWORD_FAILURE:
      toast.error(action.error.join(','));
      return {
        ...state,
        isLoading: false,
      }
  }
}

// REGISTER OPTION SESSION
// ////////////////////// //
const registerOptionState = {
  registerOption: '',
  saleType: '',
};
export const registerOptionReducer = (state = registerOptionState, action) => {
  switch (action.type) {
    case actionTypes.PARTNER_REGISTER:
      return {
        ...state,
        registerOption: action.payload,
      };
    case actionTypes.CUSTOMER_REGISTER:
      return {
        ...state,
        registerOption: action.payload,
      };
    case actionTypes.FREELANCER_REGISGER:
      return {
        ...state,
        registerOption: action.payload,
      };
    case actionTypes.COMPANY_REGISTER:
      return {
        ...state,
        registerOption: action.payload,
      }
    case actionTypes.SALE_TYPE:
      return {
        ...state,
        saleType: action.payload,
      }
    default:
      return state
  }
};

/// GET USER CODE WHEN REGISTER
const userCodeInitialState = {
  userCode: '',
  isLoading: false
}
export const userCodeReducer = (state = userCodeInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_CODE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_USER_CODE_SUCCESS:
      let code = action.payload
      console.log('user code reducer', code)
      return {
        ...state,
        userCode: code,
        isLoading: false
      }
    case actionTypes.GET_USER_CODE_FAILURE:
      toast.error(action.error.join(', '));
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};