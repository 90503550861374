import React, { useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDocumentAction } from '../../redux/actions/documents';

import './styles.less';

const InfoDocument = ({ actions, document }) => {
  const params = useParams();
  
  useEffect(() => {
    actions.getDocumentAction(params?.slug);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  
  return (
    <div className='content-container'>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(document?.content) }} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    document: state.documentReducer.data,  
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getDocumentAction
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoDocument);