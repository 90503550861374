import { takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/action_types';
import AuthSaga from './authentication';
import Category from './category';
import Services from './services';
import Product from './products';
import Upload from './upload';
import Dashboard from './dashboard';
import Order from './order';
import Estimate from './estimate';
import Payment from './payment';
import Notification from './notification';
import Ads from './ads';
import Search from './search';
import Settings from './settings';
import Language from './lang';
import Documents from './documents';

function* mainSaga() {
  // authenticate
  yield takeEvery(actionTypes.LOGIN_REQUEST, AuthSaga.login);
  yield takeEvery(actionTypes.CREATE_ACCOUNT_REQUEST, AuthSaga.register);
  yield takeEvery(actionTypes.GET_USER_CODE_REQUEST, AuthSaga.getUserCode);
  yield takeEvery(actionTypes.SEND_EMAIL_RESET_PASSWORD_REQUEST, AuthSaga.sendEmailResetPassword);
  yield takeEvery(actionTypes.RESET_PASSWORD_REQUEST, AuthSaga.resetPassword);
  yield takeEvery(actionTypes.GET_USER_FROM_COOKIE_REQUEST, AuthSaga.getUserFromCookie);
  //category
  yield takeEvery(actionTypes.GET_CATEGORY_SERVICE_REQUEST, Category.getCategoryService);
  yield takeEvery(actionTypes.GET_CATEGORY_REQUEST, Category.getCategory);
  yield takeEvery(actionTypes.GET_LOCATION_REQUEST, Category.getLocation);
  yield takeEvery(actionTypes.GET_CATEGORY_PRODUCT_REQUEST, Category.getCategoryProduct);
  yield takeEvery(actionTypes.GET_CHILDREN_CATEGORY_PRODUCT_REQUEST, Category.getChildrenCategoryProduct);
  yield takeEvery(actionTypes.GET_CHILDREN_CATEGORY_SERVICE_REQUEST, Category.getChildrenCategoryService);
  yield takeEvery(actionTypes.GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_REQUEST, Category.getChildrenCategoryServiceMultiChoice);
  //services
  yield takeEvery(actionTypes.POST_NEW_SERVICE_REQUEST, Services.postNewService);
  yield takeEvery(actionTypes.GET_SERVICES_REQUEST, Services.getServices);
  yield takeEvery(actionTypes.GET_DETAIL_SERVICE_REQUEST, Services.getDetailService);
  yield takeEvery(actionTypes.GET_QUOTE_REQUEST, Services.getQuote);
  yield takeEvery(actionTypes.POST_QUOTE_REQUEST, Services.postQuote);
  yield takeEvery(actionTypes.POST_ESTIMATE_REQUEST, Services.postEstimate);
  yield takeEvery(actionTypes.GET_PACKAGE_REQUEST, Services.getPackage);
  yield takeEvery(actionTypes.UPDATE_PACKAGE_REQUEST, Services.updatePackage);
  yield takeEvery(actionTypes.GET_SERVICE_WITH_FILTER_REQUEST, Services.getServiceWithFilter);
  yield takeEvery(actionTypes.GET_DETAIL_QUOTE_REQUEST, Services.getDetailQuote);
  yield takeEvery(actionTypes.UPDATE_ESTIMATE_REQUEST, Estimate.updateEstimate);
  yield takeEvery(actionTypes.PUT_QUOTATION_REQUEST, Services.putQuote);
  //product
  yield takeEvery(actionTypes.GET_PRODUCT_REQUEST, Product.getProduct);
  yield takeEvery(actionTypes.POST_PRODUCT_REQUEST, Product.postProduct);
  yield takeEvery(actionTypes.GET_PRODUCT_WITH_FILTER_REQUEST, Product.getProductWithFilter);
  yield takeEvery(actionTypes.GET_ALL_PRODUCT_REQUEST, Product.getAllProduct);
  // yield takeEvery(actionTypes.POST_ORDER_REQUEST, Product.postOrder);
  yield takeEvery(actionTypes.GET_ORDER_REQUEST, Product.getOrder);
  yield takeEvery(actionTypes.GET_DETAIL_ORDER_REQUEST, Product.getDetailOrder);
  
  //Upload
  yield takeEvery(actionTypes.UPLOAD_FILE_REQUEST, Upload.postFile);
  //Dashboard
  yield takeEvery(actionTypes.GET_USER_PROFILE_REQUEST, Dashboard.getUserProfile);
  yield takeEvery(actionTypes.PUT_USER_PROFILE_REQUEST, Dashboard.putUserProfile);
  //Order
  yield takeEvery(actionTypes.POST_ORDER_REQUEST, Order.createOrder);
  //Estimate
  yield takeEvery(actionTypes.GET_ESTIMATES_REQUEST, Estimate.getEstimates);
  yield takeEvery(actionTypes.GET_DETAIL_ESTIMATES_REQUEST, Estimate.getDetailEstimate);
  //Payment
  yield takeEvery(actionTypes.GET_PAYMENTS_REQUEST, Payment.getPayments);
  //Notification
  yield takeEvery(actionTypes.GET_NOTIFICATION_REQUEST, Notification.getNotification);
  yield takeEvery(actionTypes.PUT_NOTIFICATION_REQUEST, Notification.putNotification);
  // Advertiments
  yield takeEvery(actionTypes.GET_ADS_REQUEST, Ads.getAds);
  // Partner profile
  yield takeEvery(actionTypes.GET_PARTNER_PROFILE_REQUEST, Dashboard.getPartnerProfile);
  // Searching
  yield takeEvery(actionTypes.SEARCHING_REQUEST, Search.searching);

  yield takeEvery(actionTypes.PUT_ORDER_REQUEST, Order.putOrder);
  yield takeEvery(actionTypes.PUT_PAYMENTS_REQUEST, Payment.putPayments);
  yield takeEvery(actionTypes.GET_SETTING_REQUEST, Settings.getSettings);
  yield takeEvery(actionTypes.GET_CURRENT_LANGUAGE_REQUEST, Language.getCurrentLanguage);
  yield takeEvery(actionTypes.SET_CURRENT_LANGUAGE_REQUEST, Language.setCurrentLanguage);
  yield takeEvery(actionTypes.GET_DOCUMENT_REQUEST, Documents.getDocument);
};

export default mainSaga;