import * as actionTypes from '../actions/action_types';

const urlResponseState = {
  data: [],
  isLoading: false,
};

export const getPaymentsReducer = (state = urlResponseState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.PUT_PAYMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case actionTypes.PUT_PAYMENTS_FAILURE:
      return state;
    default:
      return state;
  }
};