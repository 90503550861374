import {
  GET_ESTIMATES_REQUEST,
  GET_DETAIL_ESTIMATES_REQUEST,
  UPDATE_ESTIMATE_REQUEST
} from './action_types';

export const doGetEstimates = (payload) => ({
  type: GET_ESTIMATES_REQUEST,
  payload: payload
});

export const doGetDetailEstimates = (payload) => ({
  type: GET_DETAIL_ESTIMATES_REQUEST,
  payload: payload
});

export const doUpdateEstimate = (payload) => ({
  type: UPDATE_ESTIMATE_REQUEST,
  payload: payload,
})