import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
	doCreateOrder,
	doPutOrder,
} from '../../redux/actions/order';
import {
	doGetSetting
} from '../../redux/actions/settings';
import {
	Row,
	Col,
	Form,
	Button,
	Input,
	Skeleton,
	Select,
} from 'antd';
import 'antd/dist/antd.css';
import './style.scss';
import { getTranslatedText } from '../../services/appServices';
import { PropTypes } from 'prop-types';
import {resource} from "../../assets/js/resource";

const OrderWholeSale = (props) => {

	const {
		actions,
		cart,
		userId,
		accountType,
		isLoading,
		orderInformation,
		cartRender,
		orderId,
		partnerId,
		setting_code,
	} = props;

	const [payFeeVisible, setPayFeeVisible] = useState(false);
	const [textVisible, setTextVisible] = useState('text');
	const [responseVisible, setResponseVisible] = useState(false);

	useEffect(() => {
		console.log(orderInformation);
		actions.doGetSetting('cost_view_order');
	}, []);

	// condition to render pay fee
	useEffect(() => {
		if (cartRender === 'local') {
			setPayFeeVisible(false);
			setTextVisible('text');
			setResponseVisible(false);
		} else {
			if (accountType === 'customer') {
				setPayFeeVisible(false);
				setTextVisible('text');
				if (orderInformation.is_accepted === 1) {
					setResponseVisible(true);
				}  else {
					setResponseVisible(false);
				}
			} else {
				if (orderInformation.show === 'password') {
					setPayFeeVisible(true);
					setTextVisible('password');
					setResponseVisible(true);
				} else {
					setPayFeeVisible(false);
					setTextVisible('text');
					setResponseVisible(true);
				}
			}
		}
	});

	// useEffect(() => {
	// 	if (cartRender === 'local') {
	// 		setTextVisible('text');
	// 	} else {
	// 		if (accountType === 'customer') {
	// 			setTextVisible('text');
	// 		} else {
	// 			if (orderInformation.show === 'password') {
	// 				setTextVisible('')
	// 			}
	// 		}
	// 	}
	// }, []);

	const handleFormOnFinish = (values) => {
		if (cartRender === 'server') {
			let payload = {
				orderId: orderId,
				bodyRequest: {
					response_from_partner: values.response_from_partner,
					is_accepted: 1
				}
			};
			actions.doPutOrder(payload);
		} else {
			var total = 0;
			cart.cart.forEach(item => {
				console.log(item);
				total += (item.price * item.quantity);
				console.log(total)
			});
			console.log(values);
			const orderPayload = {
				order_name: values.name,
				order_address: values.address,
				order_city: values.city,
				order_phone: values.phone,
				order_email: values.email,
				order: cart.cart,
				total: total,
				order_type: 'si',
				user_id: userId,
				request_from_client: values.request_from_client,
				partner_id: partnerId
			};
			actions.doCreateOrder(orderPayload);
		}
	};

	const handlePayButton = () => {
		const payload = {
			orderId: orderId,
			bodyRequest: {
				show: 1,
				partner_status: 'accept'
			}
		};
		actions.doPutOrder(payload);
	};

	return (
		<React.Fragment>
			{isLoading === true ? (
				<Skeleton />
			) : (
				<React.Fragment>
				<Col span={24}>
					<h3 className="fs-17 mg-tb-15">{getTranslatedText('your_request_for_supplier_title')}</h3>
				</Col>
				<Form 
					className='form_margin_center'
					onFinish={(values) => handleFormOnFinish(values)}
					initialValues={{
						request_from_client: orderInformation.request_from_client,
						response_from_partner: orderInformation.response_from_partner,
						name: orderInformation.name,
						address: orderInformation.address,
						email: orderInformation.email,
						city: orderInformation.city,
						phone: orderInformation.phone,
					}}
					layout='vertical'
				>
				<Row gutter={16} justify='center'>
					<Col span={12}>
						<Form.Item
							name='request_from_client'
						>
							<Input.TextArea />
						</Form.Item>
					</Col>
					{responseVisible ? (
						<Col span={12}>
							<Form.Item
								label={getTranslatedText('your_response_title')}
								name='response_from_partner'
							>
								<Input.TextArea />
							</Form.Item>
          	</Col>
					) : (
						null
					)}
					{payFeeVisible ? (
						<Col span={24}>
							<Row gutter={16}>
								<Col span={16}>
									<p>{getTranslatedText('pay_to_view_order')} {orderInformation.fee} XU</p>
								</Col>
								<Col span={8}>
									<Button onClick={() => handlePayButton()}>{getTranslatedText('pay_button')}</Button>
								</Col>
							</Row>
						</Col>
					) : (
						null
					)}
					<Col span={24}>
						<div className="info_supplier">
							<h3 className="title">{getTranslatedText('info_contact')}</h3>
							<div className="row">
								<div className="col">
									<Form.Item
										label={getTranslatedText('label_name')}
										name='name'
										rules={[{required: true, message: 'Please dont let this input emty!'}]}
									>
										<Input type={textVisible} />
									</Form.Item>
								</div>
								<div className="col">
									<Form.Item
										label={getTranslatedText('label_address')}
										name='address'
										rules={[{required: true, message: 'Please dont let this input emty!'}]}
									>
										<Input type={textVisible} />
									</Form.Item>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<Form.Item
										label={getTranslatedText('label_email')}
										name='email'
										rules={[{required: true, message: 'Please dont let this input emty!'}]}
									>
										<Input type={textVisible} />
									</Form.Item>
								</div>
								<div className="col">
									<Form.Item
										label={getTranslatedText('label_city')}
										name='city'
										rules={[{required: true, message: 'Please dont let this input emty!'}]}
									>
										<Select defaultValue={"VIET NAM"} className="form-control">
											{resource.country.map(item => (
												<Select.Option value={item.name}>{item.name}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</div>
							</div>
							<div className="row">
								<div className="col col-xs-12 col-sm-12 col-md-6">
									<Form.Item
										label={getTranslatedText('label_phone')}
										name='phone'
										rules={[{required: true, message: 'Please dont let this input emty!'}]}
									>
										<Input type={textVisible} />
								</Form.Item>
								</div>
							</div>
						</div>
					</Col>
					<Col span={24}>
						<div className="pd-tb-30 text-center-sm">
							<Form.Item>
								<Button htmlType='submit' className="btn green min_w162 mg-r-30">{getTranslatedText('send_order_now')}</Button>
							</Form.Item>
						</div>
					</Col>
			</Row>
				</Form>
				</React.Fragment>
			)}
    </React.Fragment>
  );
};

OrderWholeSale.propTypes = {
  orderInformation: PropTypes.object,
	isLoading: PropTypes.bool,
	cartRender: PropTypes.string,
}

const mapStateToProps = (state) => {
	return {
		cart: state.cartReducer,
		isHadAccount: state.getUserFromCookiesReducer.isHadAccount,
		userId: state.getUserFromCookiesReducer.id,
		partnerId: (state.cartReducer.cart[0])? state.cartReducer.cart[0].partner_id : '',
		setting_code: state.settingReducer.settings,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
				actions: bindActionCreators(
			{
				doCreateOrder,
				doPutOrder,
				doGetSetting,
			},
			dispatch,
		),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OrderWholeSale);