import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Form,
	Button,
	Checkbox,
	Input,
	Select,
} from 'antd';
import 'antd/dist/antd.css';
import {
	doGetLocation,
} from '../../redux/actions/category';
import { 
	doRegister,
	doRequestUserCode,
} from '../../redux/actions/authentication';
import './style.scss';
import {
	Link,
	useHistory,
} from 'react-router-dom';
import { routers } from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';
import {resource} from "../../assets/js/resource";

const RegisterCustomer = (props) => {
	
	const {
		actions,
		accountType,
		userCode,
		location,
	} = props;

	let history = useHistory();

	useEffect(() => {
		actions.doRequestUserCode(accountType);
		actions.doGetLocation();
	}, [])

	const onFinishForm = (values) => {
			const userRegisterInfomation = {
				short_name: userCode,
				name: values.name,
				email: values.email,
				phone: values.phone,
				password: values.password,
				cf_password: values.cf_password,
				account_type: accountType,
				address: values.address || '',
				city: values.city || '',
				company_name: values.company_name || '',
				company_address: values.company_address || '',
				company_email: values.company_email || '',
				company_website: values.company_website || '',
			};
			actions.doRegister(userRegisterInfomation);
	};

	const onFinishFailedForm = (errorInfo) => {
		console.log('fail', errorInfo);
	};

	return (
		<React.Fragment>
			<div className="register_partner container mg-b-60 ">
				<div className="row">
					<div className="name_page">
						<h3>{getTranslatedText('account_for_customer_title')}</h3>
					</div>
				</div>
				<div className="form_register_partner">
					<Form
						onFinish={(values) => onFinishForm(values)}
						onFinishFailed={(errorInfo) => onFinishFailedForm(errorInfo)}
					>
						<div className="sec_form">
							<h3 className="title_form">{getTranslatedText('new_register')}</h3>
							<hr className="dashed_707070" />
							<h3 className="title_form">{getTranslatedText('info_contact')}</h3>
							<div className="row">
								<div className="col-md-6 pd-r-30">
									<div className="item-info-account">
										<div className="row item_id_account">
											<div className="left">
												<p>{getTranslatedText('your_id_account')}</p>
											</div>
											<div className="right">
												<h3>{userCode}</h3>
											</div>
										</div>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='email'
											rules={[{
												required: true,
												message: 'Please input your email!',
												type: 'email'
											}]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_email')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='password'
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<Input.Password className="library_ui_input" placeholder={getTranslatedText('label_password')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='cf_password'
											rules={[{
												required: true,
												message: 'Please input your password comfirm!'
											}]}
										>
											<Input.Password className="library_ui_input" placeholder={getTranslatedText('label_comfirm_password')} />
										</Form.Item>
									</div>
								</div>
								<div className="col-md-6 pd-l-30">
									<div className="item-info-account">
										<Form.Item
											name='name'
											rules={[{
												required: true,
												message: 'Please input your name!'
										 	}]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_name')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='phone'
											// rules={[{
											// 	type: 'enum',
											// 	message: 'Invalid phone number'
											// }]}
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_phone')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='address'
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<input className="form-control" placeholder={getTranslatedText('label_address')} />
										</Form.Item>
									</div>
									<div className="item-info-account">
										<Form.Item
											name='city'
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<Select className="form-control">
												{resource.country.map(item => (
													<Select.Option value={item.name}>{item.name}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
								</div>
							</div>
							<hr className="dashed_707070" />
							<h3 className="title_form">{getTranslatedText('company_info_title')}</h3>
							<div className="item-info-account">
								<Form.Item
									name='company_name'
									rules={[{ required: true, message: 'Please input your password!' }]}
								>
									<input className="form-control" placeholder={getTranslatedText('company_name')} />
								</Form.Item>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="item-info-account mg-r-15">
										<Form.Item
											name='company_address'
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<input className="form-control" placeholder={getTranslatedText('company_address')} />
										</Form.Item>
									</div>
									<div className="item-info-account mg-r-15">
										<Form.Item
											name='company_email'
											rules={[{
												type: 'email',
												required: true,
												message: 'Please input your password!'
											}]}
										>
											<input className="form-control" placeholder={getTranslatedText('company_email')} />
										</Form.Item>
									</div>
								</div>
								<div className="col-md-6">
									<div className="item-info-account mg-l-15">
										<Form.Item
											name='company_city'
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<Select className="form-control">
												{resource.country.map(item => (
													<Select.Option value={item.name}>{item.name}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</div>
									<div className="item-info-account mg-l-15">
										<Form.Item
											name='company_website'
											rules={[{ required: true, message: 'Please input your password!' }]}
										>
											<input className="form-control" placeholder={getTranslatedText('company_website')} />
										</Form.Item>
									</div>
								</div>
							</div>
							<div className="policy_access mg-b-30">
								<div className="custom-control custom-checkbox mb-3 pd-t-30">
									<Form.Item
										name='checker'
										valuePropName='checked'
										rules={[{
											required: true,
											message: 'Please check agreement'
										}]}
									>
										<Checkbox>
											{getTranslatedText('aggrement_title')}
										</Checkbox>
									</Form.Item>
								</div>
							</div>
							<div className="group_btn_submit text-center row">
								<div className="col-md-6 col-sm-6 full_width_mobile">
									<a onClick={() => history.goBack()} className="back">{getTranslatedText('go_back')}</a>
								</div>
								<div className="btn_yellow_container col-md-6 col-sm-6 full_width_mobile">
									<Form.Item>
										<Button
											className="register"
											htmlType='submit'
										>
											{getTranslatedText('header_btn_sign_up')}
										</Button>
									</Form.Item>
								</div>
							</div>
							<div className="text-center col-12">
								<div className="text-center have_account mg-t-10">{getTranslatedText('account_already_have')} <Link to={routers.account.login}> <p>{getTranslatedText('login_now')}</p> </Link></div>
							</div>
							<div className="text-center col-12 pd-b-20 dksd text-center">
								<a href="#">{getTranslatedText('rule_title')}</a>
								<a href="#">{getTranslatedText('private_policy')}</a>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		accountType: state.registerOptionReducer.registerOption,
		userCode: state.userCodeReducer.userCode,
		location: state.serviceCategoryReducer.location,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				doRegister,
				doRequestUserCode,
				doGetLocation,
			},
			dispatch,
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RegisterCustomer);