import axios from "axios";
import env from '../env';
import {put} from "redux-saga/effects";
import {toast} from "react-toastify";
import * as Actions from '../redux/actions/action_types';
import {history} from '../index';
import qs from "query-string";
import Cookies from "universal-cookie";

export default class apiService {
  constructor() {
    let cookie = new Cookies();
    this._token = cookie.get('_token');
    this._auth = null;
    this._user_id = 0;
    this._url = env.api_url;
  }

  setToken(token) {
    this._token = token;
  }

  getToken() {
    return this._token;
  }

  getAuth() {
    return this._auth;
  }

  getUserFormLocal() {
    let user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    } else {
      return false;
    }
  }

  setAuthParams(authParams) {
    this._auth = authParams;
    if (authParams.hasOwnProperty("api_token")) {
      this._token = authParams.api_token;
    }
    if (authParams.hasOwnProperty("user")) {
      this._user_id = authParams.user.id;
    }
  }

  parseParamsFromUrl(params) {
    return qs.parse(params);
  }

  encodeQueryData(data) {
    let ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  }

  * apiCall(method, url, params, formData = false) {
    // console.log("params", params);
    let resp = false;
    let logOut = false;
    let headers = {};
    let language = localStorage.getItem('current_language') ?? "vi";
    // console.log("lang =>" + language);
    let text = "application/json";
    if (formData){
      text = "multipart/form-data";
      let form_data = new FormData();
      for ( let key in params ) {
        form_data.append(key, params[key]);
      }
      params = form_data;
    }
    // console.log("param after", params);
    headers["Content-Type"] = text;
    headers['lang'] = language;
    if (this._token) {
      headers.Authorization = "Bearer " + this._token;
    }
    try {
      // console.log(`${env.site_url}/api/${url}`)
      switch (method) {
        case "get": {
          resp = yield axios.get(
            `${env.site_url}/api/${url}?` + this.encodeQueryData(params),
            {headers}
          );
        }
          break;
        case "post": {
          resp = yield axios.post(`${env.site_url}/api/${url}`, params, {
            headers
          });
        }
          break;
        case "put": {
          resp = yield axios.put(`${env.site_url}/api/${url}`, params, {
            headers
          });
        }
          break;
        case "delete": {
          resp = yield axios.delete(`${env.site_url}/api/${url}`, {
            headers
          });
        }
          break;

      }

      // console.log("-------------");
      // console.log("api:", method, url);
      // console.log("header:", headers);
      // console.log("params:", params);
      // console.log("response: ", resp);
      // console.log("-------------");
      // console.log(resp.data);
      if (resp.data.hasOwnProperty("state") && resp.data.state === 401) {
        yield put({
          type: Actions.LOGOUT_REQUEST
        });
        history.push("/login");
        toast.error(resp.data.errors);
      }
    } catch (e) {
      //
    }
    if (logOut) {
      return false;
    }
    return resp;
  }

  apiFetch(url, config) {
    if (this._token) {
      config.headers = {
        Authorization: "Bearer " + this._token
      };
    }
    // if (_user_id) {
    //     config.body.append('id', _user_id);
    // }
    // console.log("upload url", `${env.api_url}api/${url}`);

    return fetch(`${env.api_url}api/${url}`, config);
  }
}
