import React from 'react';
import {
  Layout,
  Typography,
} from 'antd';
import './postnewservicestyles.less';


const {
  Content
} = Layout;

const {
  Title,
  Paragraph,
  Link,
} = Typography;


const AboutUsDoc = () => {
  return (
    <React.Fragment>
      <Content
        id='layout-content-container'
      >
        <Typography>
          <Title
            id='title'
            level={3}
          >
            {'B2BGosell chúng tôi là ai?'.toUpperCase()}
          </Title>
          <Paragraph>
            B2BGosell là sàn thương mại điện tử thuộc tổng công ty Greatlink Maihouse hoạt động với mục tiêu trở thành cầu nối giữa các cá nhân, doanh nghiệp với khách hàng ở trong và ngoài nước. Hoạt động kinh doanh chính của Sàn giao dịch thương mại B2BGosell là thương mại điện tử và giao dịch các sản phẩm. Đồng thời, B2BGosell là nơi trao đổi, kết nối giữa những người làm việc tự do/doanh nghiệp với các cá nhân/tổ chức/doanh nghiệp cần sử dụng dịch vụ.
          </Paragraph>
          <Paragraph>Mặc khác B2BGosell còn cung cấp dịch vụ thương mại toàn cầu cho các doanh nghiệp. Về điểm này, B2BGosell đóng vai trò như một đầu mối cung cấp hàng hóa xuất nhập khẩu, nhằm đưa sản phẩm của Việt Nam tiếp cận rộng rãi với thị trường trong và ngoài nước.</Paragraph>
          <Paragraph>
            B2BGosell sẽ sử dụng tất cả những công cụ marketing trên nền tảng của một Sàn Thương Mại Điện Tử, nhằm kết nối nhu cầu của bên mua với bên bán. Thêm vào đó, chúng tôi sẽ cung cấp đầy đủ dịch vụ cũng như điều khoản đảm bảo để tạo điều kiện cho các giao dịch này thành công nhất.
          </Paragraph>
          <Paragraph>
            Ưu tiên của B2BGosell sẽ là những lĩnh vực sau:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>Công nghệ thông tin – Viễn thông;</li>
              <li>Xây dựng – Vật liệu xây dựng;</li>
              <li>Thời trang – May mặc;</li>
              <li>Mỹ phẩm – Sản phẩm làm đẹp;</li>
              <li>Nông nghiệp – Máy móc nông nghiệp;</li>
              <li>Gỗ – Giấy;</li>
              <li>Nội thất – Trang trí;</li>
              <li>Máy móc – Chế tạo;</li>
              <li>Bất động sản;</li>
              <li>Du lịch;…</li>
            </ul>
          </Paragraph>
          <img alt='' src='http://greatlinkmaihouse.com/wp-content/uploads/2020/10/5-1-1024x528.jpg' />
          <Title
            level={5}
            style={{
              marginTop: '1.56vw',
              color: '#D2B0F1'
            }}
          >
            Đôi nét về dịch vụ cung cấp bởi B2BGosell
          </Title>
          <Paragraph>Sàn giao dịch thương mại điện tử B2BGosell cung cấp các dịch vụ bao gồm:</Paragraph>
          <Paragraph>
            <ul>
              <li>Sàn giao dịch B2BGOSELL trực tuyến giúp kết nối giữa các cá nhân/doanh nghiệp Việt Nam với nhau. Đồng thời là cầu nối giữa cá nhân/doanh nghiệp Việt Nam với nước ngoài.</li>
              <li>Giải pháp bán hàng/cung cấp dịch vụ chủ động cho freelancer và doanh nghiệp.</li>
              <li>Trở thành đầu mối cung cấp hàng hóa xuất nhập khẩu.</li>
              <li>Tư vấn giải pháp tối ưu nhân sự, kinh doanh và tăng trưởng lợi nhuận cho các doanh nghiệp.</li>
            </ul>
          </Paragraph>
          <Title
            level={5}
            style={{
              color: '#D2B0F1'
            }}
          >
            Hỗ trợ Freelancer/Doanh Nghiệp
          </Title>
          <Paragraph>
            B2BGosell hỗ trợ đối tác cá nhân/doanh nghiệp thông qua:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>Cung cấp một sàn giao dịch để doanh nghiệp và các đối tác cá nhân có thể cập nhật toàn bộ thông tin, lĩnh vực hoạt động, các sản phẩm/dịch vụ,… của mình nhằm minh bạch thông tin cũng như giúp khách hàng nắm được rõ những thông tin này.</li>
              <li>Thực hiện kết nối những yêu cầu báo giá của khách hàng tới Freelancer/Doanh nghiệp cung cấp dịch vụ/sản phẩm khách hàng mong muốn.</li>
              <li>Thường xuyên cập nhật thông tin về các cơ hội kinh doanh, các nhu cầu tìm kiếm đối tác, hay nhu cầu tìm nhà xuất nhập khẩu cho doanh nghiệp.</li>
              <li>Quảng bá qua các hệ thống trang B2BGOSELL trong nước và thế giới thông qua các diễn đàn hay các công cụ tìm kiếm…</li>
            </ul>
          </Paragraph>
          <img alt='' src='http://greatlinkmaihouse.com/wp-content/uploads/2020/10/8-1024x528.jpg' />
          <Title
            level={5}
            style={{
              marginTop: '1.56vw',
              color: '#D2B0F1'
            }}
          >
            Trở thành đối tác bán hàng cho doanh nghiệp
          </Title>
          <Paragraph>Sàn giao dịch thương mại điện tử B2BGosell sẽ là kênh phân phối sản phẩm thành công và hiệu quả cho các doanh nghiệp. Gián tiếp thúc đẩy doanh thu cho nhà cung cấp/doanh nghiệp đồng thời tăng cường khả năng hiển thị sản phẩm/thương hiệu của họ.</Paragraph>
          <Title
            level={5}
            style={{
              color: '#2D94E3'
            }}
          >
            Tầm nhìn
          </Title>
          <Paragraph>
          B2BGosell phấn đấu hướng đến một điểm kết nối hàng đầu thực hiện các chức năng đầy đủ của một trung tâm giao dịch hàng hóa – dịch vụ. B2BGosell cung cấp các giải pháp trọn gói nhằm kết nối bên mua và bên bán, đảm bảo các giao dịch thành công và hợp pháp.
          </Paragraph>
          <Title
            level={5}
            style={{
              color: '#2D94E3'
            }}
          >
            Sứ mệnh
          </Title>
          <Paragraph>Hiểu rõ được tầm quan trọng của thị trường B2B trực tuyến – B2BGosell cung cấp công cụ hữu hiệu xúc tiến thương mại cho doanh nghiệp để giao dịch kinh doanh và tiếp thị nhanh chóng, đồng thời kiệm chi phí giao dịch chung.</Paragraph>
          <Paragraph>Chúng tôi hoạt động với sứ mệnh gia tăng tỷ lệ thành công đem đến các cơ hội giao thương xuất nhập khẩu, tăng hiệu quả cho chi phí marketing để tìm kiếm khách hàng chất lượng cho doanh nghiệp.</Paragraph>
          <Paragraph>Song song với sứ mệnh mà chúng tôi đặt ra ở hiện tại thì đơn vị lựa chọn phương thức hoạt động bằng ứng dụng công nghệ thông tin, tích hợp nhiều tính năng và công cụ để giúp quá trình kết nối người bán và người mua dễ dàng, tiện lợi đồng thời giúp cho các doanh thương quyết định các thương vụ nhanh nhất và hữu hiệu nhất.</Paragraph>
          <Title
            level={5}
            style={{
              color: '#2D94E3'
            }}
          >
            Mục tiêu
          </Title>
          <Paragraph>Không ngừng nâng cao chất lượng dịch vụ cũng như quyền lợi khi đối tác cá nhân, doanh nghiệp hợp tác với B2BGosell.</Paragraph>
          <Paragraph>Mở rộng tìm kiếm các đối tác, hướng đến thị trường quốc tế để tăng lợi nhuận và tạo môi trường kinh doanh tốt nhất cho doanh nghiệp hợp tác với công ty.</Paragraph>
          <Paragraph>Rút ngắn chu trình bán hàng một cách tối ưu nhất để giao dịch mua bán diễn ra nhanh chóng và hiệu quả. Đồng thời đặt ra các chiến lược cụ thể theo từng giai đoạn để chi ngân sách của bạn vào các chương trình tiếp thị khách hàng mục tiêu một cách hợp lý nhất.</Paragraph>
          <Paragraph>Không ngừng tuyển chọn đội ngũ nhân viên chuyên nghiệp, kinh nghiệm, sáng tạo để xây dựng phương thức hoạt động trên thị trường marketing sản phẩm dịch vụ với các chương trình đa dạng dựa theo những đặc trưng kinh doanh riêng của họ.</Paragraph>
          <Title
            level={5}
            style={{
              color: '#2D94E3'
            }}
          >
            Giá trị cốt lõi
          </Title>
          <Paragraph>B2BGosell đem đến những giá trị tốt nhất cho đối tác là doanh nghiệp hay khách hàng cá nhân trên phương diện đôi bên cùng có lợi và hợp tác cùng nhau phát triển. B2BGosell định hướng “Uy Tín – Minh Bạch – Chính Trực – Tôn Trọng”. Những điều này được thể hiện rõ qua những điểm sau:</Paragraph>
          <Paragraph>
            <ul>
              <li>Chúng tôi mở rộng thị trường một cách chủ động và có chiến lược tạo dựng sự uy tín với đối tác khách hàng.</li>
              <li>Uy tín khi làm việc với khách hàng đối tác.</li>
              <li>Minh bạch trong mọi giao dịch, tất cả được thể hiện rõ ràng qua văn bản hợp tác.</li>
              <li>Kinh doanh theo quy định pháp luật và các quy chế đã đưa ra.</li>
              <li>Tiêu chí hợp tác, cách thức hoạt động của từng đối tượng kinh doanh được nêu cụ thể.</li>
              <li>Có trách nhiệm cao trong công việc, luôn lắng nghe ý kiến phản hồi và đánh giá từ đối tác, khách hàng để cùng nhau hoàn thiện và phát triển.</li>
            </ul>
          </Paragraph>
          <Paragraph>B2BGosell tâm niệm độ Uy Tín, tính Minh Bạch và Chính Trực chính là thước đo sự Tín Nhiệm của một đơn vị. Đồng thời, những yếu tố trên cũng là công cụ hiệu quả để xây dựng lòng tin và sự Tôn Trọng lẫn nhau giữa các đối tác trong giao lưu buôn bán.</Paragraph>
          <Paragraph>Với kinh nghiệm hoạt động lâu năm trong ngành – B2BGosell tự hào là một cầu nối trực tiếp giữa người mua/nhà nhập khẩu/doanh nghiệp với freelancer/nhà xuất khẩu/doanh nghiệp thông qua các tính năng và các công cụ hiện đại. Tối ưu với mục đích thúc đẩy tăng doanh số, phát triển kinh doanh.</Paragraph>
          <Paragraph>B2BGosell sẽ luôn luôn đồng hành mang đến sự phát triển vững mạnh và tạo dựng chỗ đứng cho các cá nhân/doanh nghiệp trên thị trường!</Paragraph>
          <Paragraph>
          <Paragraph
            strong
            type='danger'
          >
            THÔNG TIN LIÊN HỆ HỖ TRỢ:
          </Paragraph>
          <strong>GREATLINK MAIHOUSE INVESTMENT COMPANY LIMITED (B2BGOSELL IN VIETNAM)</strong><br/>
          <strong>Add (new):</strong> 11A, Hong Ha, Ward 2, Tan Binh Distric, HoChiMinh City, Zip Code 700000, Vietnam<br/>
          Mobile: <strong>+84 936.168.503</strong><br/>
          Email: <Link>admin@b2bgosell.com;</Link><br/>
          Website: <Link>www.b2bgosell.com</Link><br/>

          <strong>CHÚC CÁC BẠN THÀNH CÔNG!</strong>
          </Paragraph>
        </Typography>
      </Content>
    </React.Fragment>
  );
};

export default AboutUsDoc;