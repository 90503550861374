import React from 'react';
import SliderComponent from '../../../components/Slider/SliderComponent';
import ProductMain from '../../../components/Product_Main/Product_Main'
import PartnerLogoSlider from '../../../components/Partner/PartnerLogoSlider';
import PRComponent from '../../../components/AdsPRFeature/AdsPRFeature';


const ProductPage = () => {

	return (
		<React.Fragment>
			<SliderComponent
				type='product'
			/>
			<ProductMain
				isHot={true}
			/>
			<PRComponent
				type='product'
				key={1}
			/>
			<ProductMain
				hideFilterComponent={true}
			/>
			<PartnerLogoSlider />
		</React.Fragment>
	);
};

export default ProductPage;