import React from 'react';
import FormService from '../../../components/Form/FormService';


const NewServiceContainer = () => {

  return (
    <React.Fragment>
      <FormService />
    </React.Fragment>
  );
};

export default NewServiceContainer;

