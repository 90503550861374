import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {
  doGetProductRequest,
  doGetAllProduct,
  doCreateCartAction,
} from '../../redux/actions/products';
import {Helmet} from 'react-helmet-async';
import {
  Link,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import {
  routers
} from '../../config/routers';
import {
  Button,
  Skeleton,
  Carousel,
} from 'antd';
import {
  getTranslatedText,
  vndCurrencyFormat,
  usdCurrencyFormat,
} from '../../services/appServices';
import './Product_ProductSingle.less';
import _ from 'lodash';
import {toast} from 'react-toastify';
import env from '../../env';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 5
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1
  }
};
const responsiveProduct = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 1
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 1
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 1
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1
  }
};

const ProductDetail = (props) => {

  const [numberQuantity, setNumberQuantity] = useState(0);
  const [providerShortName, setProviderShortName] = useState('');

  const {product, productRelatedArray} = useSelector(state => ({
    product: state.getProductReducer.product,
    productRelatedArray: state.getProductsReducer.productArray || [],
  }));

  const {
    actions,
    history,
    accountType,
  } = props;

  const match = useRouteMatch('/products/detail/:short_name/:category_id/:product_id');

  useEffect(() => {
    setProviderShortName(match.params.short_name);
    actions.doGetProductRequest(match.params.product_id);
    actions.doGetAllProduct({
      category_id: match.params.category_id,
      limit: 5
    });
  }, [])

  const handleMinusOnClick = () => {
    const currentQuantity = parseInt(numberQuantity);
    const newQuantity = currentQuantity - 1;
    setNumberQuantity(newQuantity);
  };

  const handleAddOnClick = () => {
    const currentQuantity = parseInt(numberQuantity);
    const newQuantity = currentQuantity + 1;
    setNumberQuantity(newQuantity);
  };

  const handleRetailOrderButton = () => {

    if (accountType === 'partner-product' || accountType === 'partner-service') {
      toast.warn('Vui lòng đăng kí tài khoản khách hàng để thực hiện thao tác này');
    } else {
      const amount = (numberQuantity * product.price);

      const cartPayload = {
        cartItem: {
          product_name: product.name,
          product_id: product.id,
          price: product.price,
          product_unit: product.unit,
          quantity: numberQuantity,
          amount: amount,
          product_price_unit: product.unit_price,
          provider_short_name: product.short_name,
          partner_id: product.partner_id,
          product_avatar: product.avatar
        },
        orderType: 'le'
      }
      actions.doCreateCartAction(cartPayload);
      history.push(routers.order.cart_retail);
    }
  }

  const handleWholesaleOnClick = () => {
    if (accountType === 'partner-product' || accountType === 'partner-service') {
      toast.warn('Vui lòng đăng kí tài khoản khách hàng để thực hiện thao tác này');
    } else {
      const cartPayload = {
        cartItem: {
          product_name: product.name,
          product_id: product.id,
          price: product.price,
          product_unit: product.unit,
          quantity: 0,
          user_create: product.user_name,
          product_price_unit: product.unit_price,
          provider_short_name: product.short_name,
          partner_id: product.partner_id,
          product_avatar: product.avatar,
          partner_name: product.partner_name
        },
        orderType: 'si'
      }
      actions.doCreateCartAction(cartPayload);
      history.push(routers.order.cart_wholesale);
    }
  }

  const handleOnChangeInput = (e) => {
    setNumberQuantity(e.target.value)
  }

  return (
      <React.Fragment>
        <div className="container main_product">
          {product ? (
              <>
                <Helmet>
                  <title>{product.name}</title>
                  <meta name="description" content={product.description ? product.description : product.name}/>
                  {/* OpenGraph tags */}
                  <meta property="og:url"
                        content={encodeURI(`${env.frontend_url}/products/detail/${providerShortName}/${match.params.category_id}/${product.id}`)}/>
                  <meta property="og:title" content={product.name}/>
                  <meta property="og:description" content={product.description ? product.description : product.name}/>
                  <meta
                      property='og:image'
                      content={product.gallery ? encodeURI(product.gallery[0]) : "https://res.cloudinary.com/tainguyengkc/image/upload/v1606991548/b2b_logo_mmmm2g.png"}
                  />
                  <meta property="og:type" content="website"/>
                </Helmet>
                <div className="row">
                  <div className="col col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="product_info">
                      <h1 className="title product_title">{product.name}</h1>
                      <div className="row">
                        <div className="col-4">{getTranslatedText('suplier')}:</div>
                        <div className="col">{providerShortName}</div>
                      </div>
                      <div className="row">
                        <div className="col-4">{getTranslatedText('product_id')}:</div>
                        <div className="col">{product.id}</div>
                      </div>
                      <div className="border_top">
                        <p>{product.summary}</p>
                      </div>
                      <div className="row border_top price">
                        <div className="col-4">
                          <div className="lable">{getTranslatedText('price')}</div>
                          {product.unit_price === 1 ? (
                              <div className="value">{vndCurrencyFormat.format(product.price)}</div>
                          ) : (
                              <div className="value">{usdCurrencyFormat.format(product.price)}</div>
                          )}
                        </div>
                        <div className="col">
                          <div className="lable">{getTranslatedText('unit')}</div>
                          <div className=""><b>1 {product.unit}</b></div>
                        </div>
                      </div>
                      <div className="row_flex_2 border_top">
                        <div className="left">
                          <div><span
                              className="lable">{getTranslatedText('state')}:</span><span>{(product.out_of_stock) ? "Hết hàng" : "Còn hàng"}</span>
                          </div>
                          {!product.out_of_stock && (
                              <React.Fragment>
                                <div className="quantity_box">
                                  <span className="lable">{getTranslatedText('quantity')}:</span>
                                  <div className="amount quantity">
                                    <button className="icon minus" onClick={() => handleMinusOnClick()}>-</button>
                                    <input type="text" name="value" className="value qty" value={numberQuantity}
                                           onChange={(e) => handleOnChangeInput(e)}/>
                                    <button className="icon plus" onClick={() => handleAddOnClick()}>+</button>
                                  </div>
                                </div>
                                <button onClick={() => handleRetailOrderButton()}
                                        className="btn_buy_now btn yellow add_to_cart mg-t-15">
                                  <img src={require('../../assets/icon/cart_icon.png')}/>
                                  {getTranslatedText('btn_buy')}
                                </button>
                              </React.Fragment>
                          )}

                        </div>
                        <div className="right mw_220">
                          <div>
                            <p>{getTranslatedText('whole_sale_title')}</p>
                            <Button
                                style={{
                                  backgroundColor: '#029AFF',
                                  fontWeight: 'bold',
                                  height: '50px',
                                  borderRadius: '5px'
                                }}
                                className="mg-t-15"
                                onClick={() => handleWholesaleOnClick()}
                            >
                              {getTranslatedText('contact_suplier')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xs-12 col-sm-12 col-md-6 col-lg-6 product_gallery">
                    {/* <div className="poroduct_slide"> */}
                    {!product.gallery ? (
                        <Carousel
                            arrows
                            className='product_gallery_carousel'
                        >
                          <div className="item">
                            <img src={require("../../assets/product/wireframe.png")} alt=""/>
                          </div>
                          <div className="item">
                            <img src={require("../../assets/product/wireframe.png")} alt=""/>
                          </div>
                          <div className="item">
                            <img src={require("../../assets/product/wireframe.png")} alt=""/>
                          </div>
                          <div className="item">
                            <img src={require("../../assets/product/wireframe.png")} alt=""/>
                          </div>
                        </Carousel>
                    ) : (
                        <Carousel
                            arrows
                            className='product_gallery_carousel'
                        >
                          {product.gallery.map(item => (
                              <div
                                  className="item"
                              >
                                <img
                                    src={item}
                                    alt=""
                                />
                              </div>
                          ))}
                        </Carousel>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 product_sg_content">
                    <h2 className="_title product_detail_title">{getTranslatedText('product_information').toUpperCase()}</h2>
                    <div className="_content">
                      <div
                          dangerouslySetInnerHTML={{__html: product.detail}}
                      >
                      </div>
                    </div>
                  </div>
                </div>
              </>
          ) : (
              <div className="row">
                <Skeleton/>
              </div>
          )}
        </div>
        {/* <!-- Product Relative --> */}
        <div className="container p_relative">
          <h3 className="_title">{getTranslatedText('product_class')}</h3>
          {/* <div className="slider_p_relative"> */}
          <Carousel
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1000}
          >
            {productRelatedArray.map((p) => (
                <div style={{width: '240px'}} className="item_product">
                  <Link
                      to={routers.products.detail_product.replace(':category_id', product.category_id).replace(":product_id", p.id)}>
                    {p.avatar === "" ? (
                        <img src={require("../../assets/product/wireframe.png")} alt=""/>
                    ) : (
                        <img style={{height: '263px'}} src={p.avatar} alt=""/>
                    )}
                  </Link>
                  <div className="_content">
                    <div className="_title">{_.startCase(p.name)}</div>
                    {p.unit_price === 1 ? (
                        <div className='_price-wrap'>
                          <div className="_price">{vndCurrencyFormat.format(p.price)}</div>
                          <div className="_old">{vndCurrencyFormat.format(p.market_price)}</div>
                        </div>
                    ) : (
                        <div className='_price-wrap'>
                          <div className="_price">{usdCurrencyFormat.format(p.price)}</div>
                          <div className="_old">{usdCurrencyFormat.format(p.market_price)}</div>
                        </div>
                    )}
                    <a href="" className="btn small add_cart">MUA NGAY</a>
                  </div>
                </div>
            ))}

            {/* </div> */}
          </Carousel>
        </div>
      </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accountType: state.getUserFromCookiesReducer.accountType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
          doGetProductRequest,
          doGetAllProduct,
          doCreateCartAction
        },
        dispatch,
    )
  };
};


export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps)
)(ProductDetail);