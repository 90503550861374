import React, { useEffect} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doPutUserProfileAction,
  doGetUserProfileAction,
} from '../../../redux/actions/dashboard';
import {
  doGetQuote,
} from '../../../redux/actions/services';
import {
  doGetOrder,
} from '../../../redux/actions/products';
import {
  doGetEstimates
} from "../../../redux/actions/estimate";

import PropTypes from 'prop-types';

import CustomerProfileComponent from '../../../components/Dashboard_UserData/CustomerProfileComponent';
import PartnerProfileComponent from '../../../components/Dashboard_UserData/PartnerProfileComponent';
import CustomerListOrderingProduct from '../../../components/Dashboard_Product/CustomerListOrderingProduct';
import CustomerListOrderingService from '../../../components/Dashboard_Service/CustomerListOrderingService';
import PartnerListOrderingProduct from '../../../components/Dashboard_Product/PartnerListOrderingProduct';
import PartnerOrderedServices from '../../../components/Dashboard_Service/PartnerListOrderedServices';
import PartnerListQuotation from '../../../components/Dashboard_Service/PartnerListQuotation';

const Profile = (props) => {

  const {
    actions,
    userProfileData,
  } = props;

  useEffect(() => {
    actions.doGetUserProfileAction();
    actions.doGetQuote();
    actions.doGetOrder();
    actions.doGetEstimates();
  }, []);

  function renderProfileElement() {
    switch(userProfileData.personalData.account_type) {
      case 'customer':
        return (
          <React.Fragment>
            <CustomerProfileComponent
              profileData={userProfileData}
            />
            <CustomerListOrderingProduct />
            <CustomerListOrderingService />
          </React.Fragment>
        );
      case 'partner-product':
        return (
          <React.Fragment>
            <PartnerProfileComponent
              profileData={userProfileData}
            />
            <PartnerListQuotation />
            <PartnerOrderedServices />
            <PartnerListOrderingProduct />
          </React.Fragment>
        );
      case 'partner-service':
        return (
          <React.Fragment>
            <PartnerProfileComponent
              profileData={userProfileData}
            />
            <PartnerListQuotation />
            <PartnerOrderedServices />
            <PartnerListOrderingProduct />
          </React.Fragment>
        );
      case 'freelancer':
        return (
          <React.Fragment>
            <PartnerProfileComponent
              profileData={userProfileData}
            />
            <PartnerListQuotation />
            <PartnerOrderedServices />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment></React.Fragment>
        );
    }
  };
  


  return (
    <React.Fragment>
      {renderProfileElement()}
    </React.Fragment>
  );
};

Profile.propTypes = {
  userCookie: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.userProfileReducer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doPutUserProfileAction,
        doGetUserProfileAction,
        doGetQuote,
        doGetOrder,
        doGetEstimates,
      },
      dispatch,
    ),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
