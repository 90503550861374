import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUserFromCookieAction,
} from './redux/actions/authentication';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import './main.scss';
import './responsive.scss';
import "./assets/lib/default.css";
// import "./assets/lib/slick.css";
import "./assets/lib/animate.min.css";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';

import HomePage from './containers/Home/HomePage';
import SearchPage from './containers/Home/SearchPage';
import ProductContainerIndex from './containers/Product/index';
import CartContainerIndex from './containers/Cart/index';
import ServiceContainerIndex from './containers/Service/index';
import AccountContainerIndex from './containers/Account/index';
import DashboardContainerIndex from './containers/Dashboard/index';
import ContactPage from './containers/Contact/ContactPage';
import PaymentContainerIndex from './containers/Payment/index';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import Header from './components/Header/Header';
import FooterComponent from './components/Footer/FooterComponent';
import ForgetPasswordComponent from './components/ForgetPassword/ForgetPassword';
import Service_EstimatedComfirm from './components/Service_EstimatedComfirm/Service_EstimatedComfirm';
import Service_RequestPartnerEstimate from './components/Service_RequestPartnerEstimate/Service_RequestPartnerEstimate';
import ServiceFilterCategory from './components/ServiceFilterCategory/ServiceFilterCategory';
import PostNewService from './components/TutorialComponent/PostNewService';
import PostNewProduct from './components/TutorialComponent/PostNewProduct';
import NewRegister from './components/TutorialComponent/NewRegister';
import PostNewRequestQuote from './components/TutorialComponent/PostRequestQuotation';
import LanguageContainer from './containers/LanguageContainer';


import { routers } from './config/routers';
import ResetPasswordComponent from "./components/ResetPassword/ResetPassword";
import 'antd/dist/antd.css';
import AboutUsDoc from './components/TutorialComponent/AboutUsDoc';
import InfoDocument from './components/InfoDocuments';

import { renderToString } from 'react-dom/server';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getCurrentLanguage } from './redux/actions/lang';

const helmetContext = {};

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, authed, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routers.account.login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};


const App = (props) => {

  const {
    isLoading,
    userCookie,
    actions,
  } = props;

  useEffect(() => {
    actions.getUserFromCookieAction();
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      <LoadingOverlay
        active={isLoading}
        spinner={<ScaleLoader color='#CCD874' />}
      >
        <Router
          history={history}
        >
          <ScrollToTop />
          <div className='App'>
            <LanguageContainer />
            <Header />
            <Switch>
              <PrivateRoute
                authed={userCookie.isHadAccount}
                exact
                path={routers.services.service_request_partner_estimate}
                component={Service_RequestPartnerEstimate}
              />
              <Route
                exact
                path={routers.home}
              >
                <HomePage />
              </Route>
              <Route
                path={routers.search}
                component={SearchPage}
              />
              <Route
                path={routers.products.main}
                component={ProductContainerIndex}
              />
              <Route
                path={routers.services.main}
                component={ServiceContainerIndex}
              />
              <Route
                exact
                path={routers.services.filter_category}
                render={(props) => <ServiceFilterCategory {...props} />}
              />
              <Route
                path={routers.dashboard.main}
                component={DashboardContainerIndex}
              />
              <Route
                path={routers.order.cart}
                component={CartContainerIndex}
              />
              <Route
                path={routers.account.main}
                component={AccountContainerIndex}
              />
              <Route
                path={routers.payment.main}
                component={PaymentContainerIndex}
              />
              <Route
                exact
                path={routers.account.forget_password}
              >
                <ForgetPasswordComponent />
              </Route>
              <Route
                exact
                path={routers.account.reset_password}
              >
                <ResetPasswordComponent />
              </Route>
              <Route
                exact
                path={routers.dashboard.estimate_comfirm}
              >
                <Service_EstimatedComfirm />
              </Route>
              <Route
                exact
                path={routers.contact}
              >
                <ContactPage />
              </Route>
              <Route
                exact
                path={routers.tutorial.new_service}
              >
                <PostNewService />
              </Route>
              <Route
                exact
                path={routers.tutorial.new_product}
              >
                <PostNewProduct />
              </Route>
              <Route
                exact
                path={routers.tutorial.register}
              >
                <NewRegister />
              </Route>
              <Route
                exact
                path={routers.tutorial.request_quote}
              >
                <PostNewRequestQuote />
              </Route>
              <Route
                exact
                path={routers.about_us}
              >
                <AboutUsDoc />
              </Route>
              <Route
                exact
                path={routers.documents}
              >
                <InfoDocument />
              </Route>
            </Switch>
            <FooterComponent />
          </div>
        </Router>
        <ToastContainer />
      </LoadingOverlay>
    </HelmetProvider>
  )
}

const html = renderToString(App);

const { helmet } = helmetContext;


const mapStateToProps = (state) => {
  return {
    registerOption: state.registerOptionReducer.registerOption,
    isLoading: state.authenticationReducer.isLoading ||
      state.userCodeReducer.isLoading ||
      state.serviceReducer.isLoading ||
      state.serviceCategoryReducer.isLoading ||
      state.packageReducer.isLoading ||
      state.estimateReducer.isLoading ||
      state.getProductsReducer.isLoading ||
      state.getProductReducer.isLoading ||
      state.postProductReducer.isLoading ||
      state.settingReducer.isLoading ||
      state.getUserFromCookiesReducer.isLoading,
    userCookie: state.getUserFromCookiesReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getUserFromCookieAction,
        getCurrentLanguage,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);