import React from 'react';


import ProductListComponent from '../../../components/Product_Category/Product_Category';
import { useRouteMatch } from 'react-router-dom';
import { routers } from '../../../config/routers';


const ProductListContainer = () => {

  const match = useRouteMatch(routers.products.list);

  return (
    <React.Fragment>
      <ProductListComponent
        match={match}
      />
    </React.Fragment>
  );
};

export default ProductListContainer;