// The index root route Account

import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { routers } from '../../config/routers';

import PaymentContainer from './PaymentStatus/PaymentContainer';

const PaymentContainerInex = (props) => {

  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`${match.url}/success`}
          component={PaymentContainer}
        />
        <Route
          path={`${match.url}/failure`}
          component={PaymentContainer}
        />
      </Switch>
    </React.Fragment>
  );
};

export default connect(
  null,
  null,
)(PaymentContainerInex);