import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	doGetAllProduct,
} from '../../redux/actions/products';
import {
	Link
} from 'react-router-dom';
import ProductNewFilterComponent from '../ProductFilterComponent/ProductFilterNewComponent';
import {
	routers
} from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';
import ProductFeature from '../ProductFeature/ProductFeature';
import PRComponent from '../AdsPRFeature/AdsPRFeature';


const ProductMain = (props) => {

	const {
		actions,
		productsArray,
		isHot,
		hideFilterComponent
	} = props;

	useEffect(() => {
		actions.doGetAllProduct();
	}, []);

	return (
		<React.Fragment>
			<div className="container">
				<div className="row">
					{hideFilterComponent ? (
						null
					) : (
						<ProductNewFilterComponent />
					)}
					{isHot ? (
						<React.Fragment>
							<h2 className="title_sec border_top white">{getTranslatedText('feature_product_title')}</h2>
							<ProductFeature
								productsArray={productsArray}
								isHot={true}
							/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<h2 className="title_sec border_top white">{getTranslatedText('hot_product_title')}</h2>
							<ProductFeature
								productsArray={productsArray}
							/>
						</React.Fragment>
					)}
					{/* <div className="col col-12 text-center mg-t-20 mg-b-60">
						<Link
							to={routers.products.list.replace(':category_id', '0')}
						>
							<a href="#" className="btn yellow arrow_left radius_50">{getTranslatedText('btn_view_all_product')}</a>
						</Link>
					</div> */}
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		productsArray: state.getProductsReducer.productArray,
	}
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetAllProduct,
      },
     dispatch,
    ),
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductMain);