import * as actionTypes from '../actions/action_types';
import { toast } from 'react-toastify';

const serviceInitialState = {
  isLoading: false,
  parentServiceCategory: [],
  childrenServiceCategory: [],
  childrenServiceCategoryMultiChoice: [],
  location: []
};

export const serviceCategoryReducer = (state = serviceInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_CATEGORY_SERVICE_SUCCESS:
      let parent = [];
      let arr = action.payload;
      arr.forEach(service => {
        if (service.category_id == 0)
          parent.push(service)
      })
      return {
        ...state,
        parentServiceCategory: parent,
        isLoading: false
      }
    case actionTypes.GET_CATEGORY_SERVICE_FAILURE:
      toast.error(action.error.join(', '));
      return {
        ...state,
        isLoading: false
      }
    case actionTypes.GET_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_CATEGORY_SUCCESS:
      let p = [];
      let a = action.payload;
      a.forEach(service => {
        if (service.category_id == 0)
          p.push(service)
      })
      return {
        ...state,
        parentServiceCategory: p,
        isLoading: false
      }
    case actionTypes.GET_CATEGORY_FAILURE:
      toast.error(action.error.join(', '));
      return {
        ...state,
        isLoading: false
      }
    case actionTypes.GET_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_LOCATION_SUCCESS:
      let data = action.payload;
      return {
        ...state,
        location: data,
        isLoading: false
      }
    case actionTypes.GET_LOCATION_FAILURE:
      toast.error(action.error.join(', '));
      return {
        ...state,
        isLoading: false
      }
    case actionTypes.GET_CHILDREN_CATEGORY_SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_CHILDREN_CATEGORY_SERVICE_SUCCESS:
      // let currentChildrenServiceCategory = state.childrenServiceCategory;
      // action.payload.forEach(service => {
      //   currentChildrenServiceCategory.push(service);
      // });
      return {
        ...state,
        childrenServiceCategory: action.payload,
        isLoading: false,
      }
    case actionTypes.GET_CHILDREN_CATEGORY_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case actionTypes.GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_SUCCESS:
      let currentChildrenServiceCategory = state.childrenServiceCategoryMultiChoice;
      action.payload.forEach(service => {
        currentChildrenServiceCategory.push(service);
      });
      return {
        ...state,
        childrenServiceCategoryMultiChoice: currentChildrenServiceCategory,
        isLoading: false,
      }
    case actionTypes.GET_CHILDREN_CATEGORY_SERVICE_MULTI_CHOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
};


const prodcutInitialState = {
  isLoading: false,
  productCategory: [],
  parentProductCategory: [],
  childrenProductCategory: [],
};

export const productCategoryReducer = (state = prodcutInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.GET_CATEGORY_PRODUCT_SUCCESS:
      let parent = [];
      let arr = action.payload;
      arr.forEach(product => {
        if (product.category_id == 0)
          parent.push(product)
      })
      return {
        ...state,
        parentProductCategory: parent,
        isLoading: false
      }
    case actionTypes.GET_CATEGORY_PRODUCT_FAILURE:
      toast.error(action.error.join(', '));
      return {
        ...state,
        isLoading: false
      }
    case actionTypes.GET_CHILDREN_CATEGORY_PRODUCT_SUCCESS:
      return {
        ...state,
        childrenProductCategory: action.payload,
        isLoading: false
      }
    case actionTypes.GET_CHILDREN_CATEGORY_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
};