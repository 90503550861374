import React from 'react';

import ClientCheckQuotation from '../../../components/ClientCheckQuotation/ClientCheckQuotation';

const ViewEstimateContainer = () => {

  return (
    <React.Fragment>
      <ClientCheckQuotation />
    </React.Fragment>
  );
};

export default ViewEstimateContainer;