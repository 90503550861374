import React, { useEffect } from 'react';
import {
	connect,
} from 'react-redux';
import {
	bindActionCreators,
} from 'redux';
import {
	doCreateCartAction,
	doDeleteCartItemAction,
} from '../../redux/actions/products';
import {
	Steps,
} from 'antd';
import {
	Link,
} from 'react-router-dom';
import {
	routers
} from '../../config/routers';
import {
	usdCurrencyFormat,
	vndCurrencyFormat,
	getTranslatedText
} from '../../services/appServices';
import './styles/product-cart-retail-styles.less';


const CartRetail = (props) => {

	const {
		orderInfo,
		actions,
		cart
	} = props;

	let current_language = localStorage.getItem('current_language') || 'vi';

	useEffect(() => {
		console.log('order infor', orderInfo);
		console.log(cart.cart)
	}, []);

	const handleMinusOnClick = (item) => {
		if(item.quantity > 0) {
			const newQuantity = item.quantity - 1;
			const amount = (newQuantity * item.price);
			const cartPayload = {
				cartItem: {
					product_name: item.product_name,
					product_id: item.product_id,
					price: item.price,
					product_unit: item.product_unit,
					quantity: newQuantity,
					user_create: item.user_create,
					product_price_unit: item.product_price_unit,
					amount: amount,
					provider_short_name: item.provider_short_name,
					partner_id: item.partner_id,
					product_avatar: item.product_avatar
				},
				orderType: 'le'
			};
			actions.doCreateCartAction(cartPayload);
		}
	};

	const handleAddOnClick = (item) => {
		const newQuantity = item.quantity + 1;
		const amount = (newQuantity * item.price);
		const cartPayload = {
			cartItem: {
				product_name: item.product_name,
				product_id: item.product_id,
				price: item.price,
				product_unit: item.product_unit,
				quantity: newQuantity,
				user_create: item.user_create,
				product_price_unit: item.product_price_unit,
				amount: amount,
				provider_short_name: item.provider_short_name,
				partner_id: item.partner_id,
				product_avatar: item.product_avatar
			},
			orderType: 'le'
		};
		actions.doCreateCartAction(cartPayload);
	};

	const renderAllTotal = () => {
		if (current_language === 'vi') {
      var total = 0;
		  cart.cart.forEach(item => {
			  total += (item.quantity * item.price);
      });
      return (
        <React.Fragment>
          <div className="number">{vndCurrencyFormat.format(total)}</div>
        </React.Fragment>
      );
    } else {
      var total = 0;
		  cart.cart.forEach(item => {
			  total += (item.quantity * item.price);
		  })
      return (
        <React.Fragment>
          <div className="number">{usdCurrencyFormat.format(total)}</div>
        </React.Fragment>
      );
    }
	};

	const handleInputOnChange = (e, item) => {
    console.log(item);
    item.quantity = e.target.value;
    if (item.quantity === '') {
      item.quantity = null;
    } else {
      item.quantity = parseInt(item.quantity)
    }
    const newQuantity = item.quantity;
		const amount = (newQuantity * item.price);
		const cartPayload = {
      cartItem: {
        product_name: item.product_name,
        product_id: item.product_id,
        price: item.price,
        product_unit: item.product_unit,
        quantity: newQuantity,
        user_create: item.user_create,
        product_price_unit: item.product_price_unit,
        amount: amount,
        provider_short_name: item.provider_short_name,
				partner_id: item.partner_id,
				product_avatar: item.product_avatar
      },
      orderType: 'le'
    };
		actions.doCreateCartAction(cartPayload);
	};
	
	const handleOnDeleteItemClick = (item) => {
		console.log(item);
		actions.doDeleteCartItemAction(item);
	};

	return (
		<React.Fragment>
			<div style={{ marginTop: '50px' }} className="container bg_white mg-b-60">
				<div className="row">
					<div className="col-10  product_sg_content cart_content">
						<div className="strip_bar">
								<Steps>
									<Steps.Step title={getTranslatedText('order_title')} />
									<Steps.Step title={getTranslatedText('payment').toUpperCase()} />
									<Steps.Step title={getTranslatedText('send_order')} />
								</Steps>
						</div>

						<table className="cart_product">
							<tr className="top">
								<th>{getTranslatedText('product_name')}</th>
								<th className="center">{getTranslatedText('unit')}</th>
								<th className="left">{getTranslatedText('quantity')}</th>
								<th>{getTranslatedText('cost')}</th>
								<th className="right">{getTranslatedText('amount')}</th>
							</tr>
							<tbody>
								{cart.cart && cart.cart.map(item => (
									<tr>
										<td className="left">
											<div className="p_2_col">
												<div className="left">
													{item.product_avatar !== '' ? (
														<img src={item.product_avatar} alt="" />
													) : (
														<img src={require("../../assets/product/wireframe.png")} alt="" />
													)}
													<div>
														<a onClick={() => handleOnDeleteItemClick(item)}>{getTranslatedText('delete')}</a>
														<a>{getTranslatedText('view_product')}</a>
													</div>
												</div>
												<div className="right">
													<h3 className="title">{item.product_name}</h3>
													<div>{getTranslatedText('suplier')}: <b>{item.provider_short_name}</b></div>
													<div className='right_utility'>
														<a onClick={() => handleOnDeleteItemClick(item)}>{getTranslatedText('delete')}</a>
														<a>{getTranslatedText('view_product')}</a>
													</div>
												</div>
											</div>
										</td>
										<td className="dvt">{item.product_unit}</td>
										<td>
											<div 
												className="amount quantity"
												style={{
													display: 'flex'
												}}
											>
												<button className="icon minus" onClick={() => handleMinusOnClick(item)}>-</button>
												<input type="text" name="value" className="value qty" value={item.quantity} onChange={(e) => handleInputOnChange(e, item)} />
												<button className="icon plus" onClick={() => handleAddOnClick(item)}>+</button>
											</div>
										</td>
										{item.product_price_unit === 1 ? (
												<React.Fragment>
													<td className="price">{vndCurrencyFormat.format(item.price)}</td>
													<td className="total">{vndCurrencyFormat.format((item.quantity * item.price))}</td>
												</React.Fragment>
                      ) : (
												<React.Fragment>
													<td className="price">{usdCurrencyFormat.format(item.price)}</td>
													<td className="total">{usdCurrencyFormat.format((item.quantity * item.price))}</td>
												</React.Fragment>
                      )}
									</tr>
								))}
							</tbody>
						</table>
						<div className="total_cart">
							<div className="title">{getTranslatedText('total_money_pay')}</div>
							{/* <div className="number">{(item.quantity * item.price)}</div> */}
							{renderAllTotal()}
						</div>
						<div className="text-center mg-b-50">
							<Link
								to={routers.products.main}
							>
								<a href="#" className="btn yellow mg-r-15">{getTranslatedText('keep_buy').toUpperCase()}</a>
							</Link>
							<Link
								to={routers.order.cart_retail_checkout}

							>
								<button className="btn green">{getTranslatedText('payment').toUpperCase()}</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		orderInfo: state.orderReducer,
		cart: state.cartReducer,
		isHadAccount: state.getUserFromCookiesReducer.isHadAccount,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
			doCreateCartAction,
			doDeleteCartItemAction,
		},
			dispatch,
		)
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CartRetail);