import React from 'react';

import RetailCheckout from '../../../components/Product_RetailPayment/Product_Payment';
import NewRetailPayment from '../../../components/Product_RetailPayment/NewRetailPayment';

const CheckoutRetailContainer = () => {

  return (
    <React.Fragment>
      <NewRetailPayment />
      {/* <RetailCheckout /> */}
    </React.Fragment>
  );
};

export default CheckoutRetailContainer;
