import * as actionTypes from '../actions/action_types';
import { put } from 'redux-saga/effects';


export default {
  getEstimates: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', 'v1/estimate', payload);
      console.log('response', response);
      if (response.data.isSuccess) {
        let newService = response.data.data
        yield put({
          type: actionTypes.GET_ESTIMATES_SUCCESS,
          payload: newService,
        })
      } else {
        yield put({
          type: actionTypes.GET_ESTIMATES_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_ESTIMATES_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  },
  getDetailEstimate: function* (action) {
    let payload = action.payload;
    try {
      let response = yield global.apiService.apiCall('get', `v1/estimate/${payload}`);
      console.log('response', response);
      if (response.data.isSuccess) {
        let detailEstimate = response.data.data
        yield put({
          type: actionTypes.GET_DETAIL_ESTIMATES_SUCCESS,
          payload: detailEstimate,
        })
      } else {
        yield put({
          type: actionTypes.GET_DETAIL_ESTIMATES_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_DETAIL_ESTIMATES_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  },
  updateEstimate: function* (action) {
    let payload = action.payload;
    let estimateId = payload[1];
    let requestBody = payload[0]
    try {
      let response = yield global.apiService.apiCall('put', `v1/estimate/${estimateId}`, requestBody);
      console.log('response', response);
      if (response.data.isSuccess) {
        let detailEstimate = response.data.data
        yield put({
          type: actionTypes.UPDATE_ESTIMATE_SUCCESS,
          payload: detailEstimate,
        })
      } else {
        yield put({
          type: actionTypes.UPDATE_ESTIMATE_FAILURE,
          error: ['Something went wrong!']
          // error: response
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.UPDATE_ESTIMATE_FAILURE,
        error: ['Something went wrong!']
        // error: error
      });
    }
  },
};