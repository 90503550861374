import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  doGetLocation,
  doGetCategoryProduct,
  doGetChildrenCategoryProduct
} from "../../redux/actions/category";
import {
  doGetProductWithFilter,
  doGetAllProduct,
} from '../../redux/actions/products';
import {
  useHistory,
} from 'react-router-dom';
import {
  routers
} from '../../config/routers';
import {
  Form,
  Select,
  Button,
  Row,
  Col,
} from 'antd';
import { getTranslatedText } from '../../services/appServices';
import {resource} from "../../assets/js/resource";

import './product-filter-new-style.less';

function ProductFilterNewComponent(props) {

  const {
    actions,
    parentProductCategory,
    childrenProductCategory,
    userCookie,
  } = props;
  
  const history = useHistory();

  const [selectButtonText, setSelectButtonText] = useState(getTranslatedText('dropdow_product_category'));
  const [categoryParentId, setCategoryParentId] = useState(null);
  const [bussinessCategory, setBussinessCategory] = useState(getTranslatedText('select_category'));
  const [locationFilter, setLocationFilter] = useState(getTranslatedText('location_select_title'));

  useEffect(() => {
    actions.doGetCategoryProduct();
  }, []);

  const handleOnFinish = (values) => {
    console.log(values);
    const filterValue = {
      category_id: categoryParentId || 0,
      product_business_category: values.product_business_category || 0,
      location: values.location || " ",
    };
    if(filterValue) {
      actions.doGetProductWithFilter(filterValue);
    } else {
      actions.doGetAllProduct();
    }
  };

  const handleOnSelectItem = (e) => {
    console.log(e);
    setCategoryParentId(e);
    actions.doGetChildrenCategoryProduct(e);
  };

  const handleOnResetButton = () => {
    setCategoryParentId(null);
    setSelectButtonText(getTranslatedText('dropdow_product_category'));
    setBussinessCategory(null);
    actions.doGetAllProduct();
  };

  const goToPostNewProduct = () => {
    if (userCookie.isHadAccount) {
      history.push(routers.products.post_new_product);
    } else {
      history.push(routers.account.login);
    }
  }

  return (
    <React.Fragment>
      <Form
        id='product-filter-form'
        layout='vertical'
        onFinish={(values) => handleOnFinish(values)}
      >
        <Row gutter={[16,16]}>
          <Col
            id='green-select-border-radius-wrapper'
            xs={12}
            md={8}
            lg={6}
            xl={8}
          >
            <Form.Item>
              <Select
                id='green-select-border-radius'
                onChange={(e) => handleOnSelectItem(e)}
                defaultValue={selectButtonText}
              >
                {parentProductCategory && parentProductCategory.map(item => (
                  <Select.Option
                    value={item.id}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={4} xs={12}>
            <Form.Item
              label={getTranslatedText('label_product')}
              name='product_business_category'
            >
              <Select
                defaultValue={bussinessCategory}
              >
                {childrenProductCategory && childrenProductCategory.map(item => (
                  <Select.Option
                    value={item.id}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={4} xs={12}>
            <Form.Item
              label={getTranslatedText('label_city')}
              name='location'
            >
              <Select
                defaultValue={locationFilter}
              >
                {resource.country.map(item => (
                  <Select.Option value={item.name}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={2} xs={12}>
            <Form.Item
              label=' '
            >
              <Button
                htmlType='submit'
                id='btn-search'
              >
                {getTranslatedText('btn_find_now')}
              </Button>
            </Form.Item>
          </Col>
          <Col
            xs={12}
            md={2}
            lg={4}
            xl={2}
          >
            <Form.Item
             label=' '
            >
              <Button
                id='reset-filter-btn'
                onClick={() => handleOnResetButton()}
              >
                {getTranslatedText('reset_filter')}
              </Button>
            </Form.Item>
          </Col>
          {userCookie.accountType === "partner-product" || userCookie.accountType ==='partner-service' ? (
              <Col
                xs={12}
                md={4}
                lg={4}
                xl={4}
              >
                <Form.Item
                  label=''
                >
                  <Button
                    id='btn-post-product'
                    onClick={() => goToPostNewProduct()}
                  >
                    {getTranslatedText('btn_post_new_product')}
                  </Button>
              </Form.Item>
            </Col>
            ) : (
              null
            )}
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    parentProductCategory: state.productCategoryReducer.parentProductCategory,
    childrenProductCategory: state.productCategoryReducer.childrenProductCategory,
    userCookie: state.getUserFromCookiesReducer,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        doGetLocation,
        doGetCategoryProduct,
        doGetProductWithFilter,
        doGetChildrenCategoryProduct,
        doGetAllProduct,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductFilterNewComponent);