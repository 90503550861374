export const supportedLanguage = ["vi", "en", "jp"];

export const language = {
  lang_vi: {
    vi: "Tiếng Việt",
    en: "Tiếng Việt",
    jp: "Tiếng Việt",
  },
  lang_en: {
    vi: "English",
    en: "English",
    jp: "English",
  },
  lang_jp: {
    vi: "日本人",
    en: "日本人",
    jp: "日本人",
  },
  header_btn_login: {
    vi: "Đăng nhập",
    en: "Log In",
    jp: "ログイン",
  },
  header_btn_sign_up: {
    vi: "Đăng ký",
    en: "Sign Up",
    jp: "サインアップ",
  },
  homepage_title: {
    vi: "TRANG CHỦ",
    en: "HOME",
    jp: "ホーム",
  },
  products_title: {
    vi: "SẢN PHẨM",
    en: "PRODUCTS",
    jp: "製品",
  },
  service_title: {
    vi: "DỊCH VỤ",
    en: "SERVICES",
    jp: "サービス",
  },
  contact_title: {
    vi: "LIÊN HỆ",
    en: "CONTACT",
    jp: "お問い合わせ",
  },
  support_title: {
    vi: "HỖ TRỢ",
    en: "SUPPORT",
    jp: "サポート",
  },
  menu_item_marketing: {
    vi: "Marketing & Bán hàng",
    en: "Marketing & Sale",
    jp: "マーケティング & 販売",
  },
  menu_item_accountant: {
    vi: "Tài chính/Kế toán/Kiểm toán",
    en: "Finance/Accounting/Auditing",
    jp: "ふぃなんけ/会計/監査",
  },
  menu_item_sale: {
    vi: "Bán hàng",
    en: "Saler",
    jp: "売上高",
  },
  menu_item_architecture: {
    vi: "Kiến trúc & Xây dựng",
    en: "Architecture & Construct",
    jp: "アーキテクチャー & 構成概念",
  },
  menu_item_real_estate: {
    vi: "Bất động sản",
    en: "Real estate",
    jp: "不動産",
  },
  menu_item_finacial: {
    vi: "Tài chính",
    en: "Finance",
    jp: "ファイナンス",
  },
  menu_item_translator: {
    vi: "Dịch thuật",
    en: "Translator",
    jp: "翻訳",
  },
  menu_item_logistic: {
    vi: "Logistic/Vận chuyển/Giao hàng",
    en: "Logistic/Shipping/Delivery",
    jp: " 記号論理学の/運送/運送",
  },
  menu_item_all: {
    vi: "Tất cả dịch vụ",
    en: "All services",
    jp: "すべてのサービス",
  },
  menu_item_agriculture: {
    vi: "Nông sản/Nông nghiệp",
    en: "Agricultural Products/Agriculture",
    jp: "農産物/農産物",
  },
  menu_item_cosmetic: {
    vi: "Làm đẹp/Sức khoẻ",
    en: "Beauty/Health",
    jp: "美しさ/健康",
  },
  menu_item_food: {
    vi: "Nguyên vật liệu",
    en: "Materials",
    jp: "材料",
  },
  menu_item_technology: {
    vi: "Máy móc công nghệ/Thiết bị số",
    en: "Technology/Digital equipment",
    jp: "技術/デジタル機器",
  },
  menu_item_fashion: {
    vi: "Thời trang",
    en: "Fashion",
    jp: "ファッション",
  },
  menu_item_handmade: {
    vi: "Handmade",
    en: "Handmade",
    jp: "手作り",
  },
  menu_item_tool: {
    vi: "Máy móc/Cơ khí",
    en: "Machines/Mechanical",
    jp: "機械/機械の",
  },
  menu_item_office_tool: {
    vi: "Văn phòng phẩm",
    en: "Stationery",
    jp: "文房具",
  },
  menu_item_product_all: {
    vi: "Tất cả sản phẩm",
    en: "All products",
    jp: "すべての製品",
  },
  menu_item_post_tutorial: {
    vi: "Hướng dẫn đăng tin",
    en: "How to post news",
    jp: "投稿手順",
  },
  menu_item_deposit_tutorial: {
    vi: "Hướng dẫn đăng sản phẩm mới",
    en: "How to post new product",
    jp: "入金指示",
  },
  menu_item_project_manage_tutorial: {
    vi: "Hướng dẫn đăng ký tài khoản",
    en: "How to register an account",
    jp: "プロジェクト管理ガイド",
  },
  menu_item_create_order_tutorial: {
    vi: "Hướng dẫn tạo đơn hàng",
    en: "How to create an order",
  },
  menu_item_contact_partner_tutorial: {
    vi: "Hướng dẫn tạo yêu cầu báo giá dịch vụ",
    en: "How to post request quotation",
    jp: "パートナー連絡先ガイド",
  },
  menu_item_change_info_tutorial: {
    vi: "Hướng dẫn thay đổi thông tin tài khoản",
    en: "How to change account information",
  },
  menu_item_payment_tutorial: {
    vi: "Hướng dẫn thanh toán",
    en: "How to pay",
  },
  menu_item_account_upgrade_tutorial: {
    vi: "Điều khoản sử dụng",
    en: "Terms of use",
    jp: "アカウントをアップグレードする手順",
  },
  about_us_title: {
    vi: "VỀ CHÚNG TÔI",
    en: "ABOUT US",
    jp: "私たちについて",
  },
  info_title: {
    vi: "THÔNG TIN",
    en: "INFORMATION",
  },
  menu_item_general_title: {
    vi: "Giới thiệu chung",
    en: "General introduction",
    jp: "一般的な紹介",
  },
  menu_item_support_title: {
    vi: "Trợ giúp",
    en: "Support",
    jp: "アシスト",
  },
  menu_item_contact_title: {
    vi: "Liên hệ",
    en: "Contact",
    jp: "かんけい",
  },
  menu_item_question_answer: {
    vi: "Câu hỏi thường gặp",
    en: "FAQ",
    jp: "よくある質問",
  },
  follow_us_title: {
    vi: "THEO DÕI CHÚNG TÔI",
    en: "FOLLOW US",
    jp: "フォローしてください",
  },
  dropdow_product_category: {
    vi: "DANH MỤC SẢN PHẨM",
    en: "PRODUCT CATEGORY",
    jp: "製品一覧",
  },
  label_category: {
    vi: "Chọn danh mục",
    en: "Select category",
    jp: "カテゴリーを選択してください",
  },
  label_product: {
    vi: "Chọn sản phẩm",
    en: "Select product",
    jp: "製品を選択",
  },
  label_bussines: {
    vi: "Chọn ngành nghề",
    en: "Select career",
    jp: "仕事を選択",
  },
  btn_find_now: {
    vi: "Tìm ngay",
    en: "Find now",
    jp: "今すぐ探す",
  },
  btn_post_new_product: {
    vi: "ĐĂNG SẢN PHẨM MỚI",
    en: "POST NEW PRODUCT",
    jp: "新製品を投稿する",
  },
  btn_buy: {
    vi: "MUA NGAY",
    en: "BUY",
    jp: "今買う",
  },
  btn_view_all_product: {
    vi: "Xem tất cả sản phẩm",
    en: "All products",
    jp: "すべての製品を表示",
  },
  btn_cancel: {
    vi: "Huỷ",
    en: "Cancel",
    jp: "キャンセル",
  },
  btn_view_all_service: {
    vi: "Xem tất cả dịch vụ",
    en: "All services",
    jp: "すべてのサービスを表示",
  },
  btn_sign_up_partner_product: {
    vi: "ĐĂNG KÝ ĐỐI TÁC CUNG CẤP SẢN PHẨM",
    en: "REGISTERING PARTNERS OF SUPPLYING PRODUCTS",
    jp: "パートナーを登録して製品を提供する",
  },
  btn_sign_up_partner_service: {
    vi: "ĐĂNG KÝ ĐỐI TÁC CUNG CẤP DỊCH VỤ",
    en: "REGISTER PARTNERS PROVIDING SERVICE",
    jp: "サービスパートナーの登録",
  },
  dropdown_service_category: {
    vi: "DANH MỤC DỊCH VỤ",
    en: "SERVICE CATEGORY",
    jp: "サービスカタログ",
  },
  btn_post_new_service: {
    vi: "ĐĂNG TIN MỚI",
    en: "POST NEW",
    jp: "新しいアイテムを投稿する",
  },
  btn_request_quotation: {
    vi: "Yêu cầu báo giá",
    en: "Request a quote",
    jp: "",
  },
  btn_view_service_detail: {
    vi: "Xem chi tiết",
    en: "Detail",
    jp: "見積もりを依頼する",
  },
  feature_product_title: {
    vi: "SẢN PHẨM NỔI BẬT",
    en: "FEATURED PRODUCTS",
    jp: "ホットな製品",
  },
  hot_product_title: {
    vi: "SẢN PHẨM BÁN CHẠY",
    en: "SELLING PRODUCTS",
    jp: "ベストセラー商品",
  },
  suggest_service_title: {
    vi: "DỊCH VỤ ĐỀ XUẤT CHO BẠN",
    en: "RECOMMENDED SERVICES FOR YOU",
    jp: "あなたにおすすめのサービス",
  },
  btn_view_service_now: {
    vi: "XEM NGAY",
    en: "VIEW",
    jp: "今見る",
  },
  login_title: {
    vi: "ĐĂNG NHẬP TÀI KHOẢN",
    en: "LOGIN ACCOUNT",
    jp: "アカウントログイン",
  },
  login_to_buy: {
    vi: "Vui lòng đăng nhập để tiến hành mua hàng",
    en: "Please login to proceed with purchase",
    jp: "ログインして購入を続行してください",
  },
  register_button_modal: {
    vi: "Đăng ký ngay, miễn phí!",
    en: "Sign up now, it's free!",
    jp: "今すぐ登録して、無料です！",
  },
  register_label: {
    vi: "Bạn chưa có tài khoản?",
    en: "Do not have an account?",
    jp: "アカウントを持っていない?",
  },
  btn_payment: {
    vi: "TIẾN HÀNH THANH TOÁN",
    en: "CONDUCTING PAYMENT",
    jp: "同時支払い",
  },
  address_for_deliver: {
    vi: "THÔNG TIN GIAO HÀNG",
    en: "SHIPMENT DETAILS",
    jp: "発送詳細",
  },
  label_name: {
    vi: "Họ tên của bạn",
    en: "Your full name",
    jp: "あなたのフルネーム",
  },
  label_phone: {
    vi: "Điện thoại",
    en: "Phone number",
    jp: "テレホン",
  },
  label_email: {
    vi: "Email",
    en: "Email",
    jp: "電子メール",
  },
  label_address: {
    vi: "Địa chỉ",
    en: "Address",
    jp: "あてさき",
  },
  label_city: {
    vi: "Chọn khu vực",
    en: "Area",
    jp: "範囲",
  },
  company_name: {
    vi: "Tên công ty",
    en: "Company Name",
    jp: "社名",
  },
  company_phone: {
    vi: "Số điện thoại",
    en: "Phone number",
    jp: "会社の電話番号",
  },
  company_email: {
    vi: "Email công ty",
    en: "Company Email",
    jp: "会社のメール",
  },
  company_address: {
    vi: "Địa chỉ công ty",
    en: "Company address",
    jp: "会社の住所",
  },
  company_city: {
    vi: "Quốc gia",
    en: "Country",
    jp: "市",
  },
  company_password: {
    vi: "Mật khẩu",
    en: "Password",
    jp: "Password",
  },
  payment_title: {
    vi: "HÌNH THỨC THANH TOÁN",
    en: "PAYMENTS",
    jp: "お支払い方法",
  },
  btn_next: {
    vi: "Kế tiếp",
    en: "Next",
    jp: "次",
  },
  request_estimate_detail: {
    vi: "Thông tin thêm về dự án mà bạn muốn gửi cho cho ĐỐI TÁC:",
    en: "More information about the project you want to send to PARTNER:",
    jp: "パートナーに送信するプロジェクトの詳細",
  },
  account_type_title: {
    vi: "Loại tài khoản của bạn",
    en: "Your account type",
    jp: "アカウントの種類",
  },
  account_type_provider: {
    vi: "Bạn cung cấp cho khách hàng",
    en: "Your provider",
    jp: "あなたのプロバイダー",
  },
  current_coin: {
    vi: "Số XU trong ví của bạn",
    en: "Your XU",
    jp: "ウォレットの現在のXU番号",
  },
  add_more_coin: {
    vi: "Nạp thêm xu",
    en: "Recharge",
    jp: "再充電する",
  },
  history_payment: {
    vi: "Lịch sử",
    en: "Payment history",
    jp: "支払歴",
  },
  contact_information: {
    vi: "Thông tin liên hệ của bạn",
    en: "Contact Information",
    jp: "連絡先",
  },
  your_id_account: {
    vi: "Mã ID cho tài khoản của bạn",
    en: "Your ID",
    jp: "あなたの ID",
  },
  partner_service: {
    vi: "Đối tác dịch vụ",
    en: "Service Partner",
    jp: "サービスパートナー",
  },
  your_business: {
    vi: "Ngành nghề hoạt động:",
    en: "Your business",
    jp: "御用",
  },
  certificates: {
    vi: "Chứng chỉ, bằng cấp liên quan",
    en: "Your certificates",
    jp: "あなたの証明書",
  },
  dashboard: {
    vi: "Dashboard",
    en: "Dashboard",
    jp: "Dashboard",
  },
  add_service_category: {
    vi: "Thêm ngành nghề dịch vụ",
    en: "Add service",
    jp: "サービスラインを追加",
  },
  your_company_data: {
    vi: "Thông tin công ty bạn",
    en: "Your company information",
    jp: "あなたの会社情報",
  },
  company_website: {
    vi: "Website",
    en: "Website",
    jp: "Website",
  },
  estimate_sent_client: {
    vi: "Báo giá đã gửi khách hàng",
    en: "Estimate sent",
    jp: "送信された見積もり",
  },
  my_order_list: {
    vi: "CÁC ĐƠN HÀNG CỦA BẠN",
    en: "Your order list",
    jp: "ご注文リスト",
  },
  content_column: {
    vi: "Nội dung tin",
    en: "Content",
    jp: "ノイフン",
  },
  date_sent_column: {
    vi: "Ngày gửi",
    en: "Sent date",
    jp: "送信日",
  },
  status_estimated_column: {
    vi: "Tình trạng",
    en: "Status",
    jp: "状態",
  },
  label_change_password: {
    vi: "Đổi mật khẩu",
    en: "Change password here",
  },
  edit_profile_btn: {
    en: "Edit profile",
    vi: "Thay đổi thông tin",
  },
  label_password: {
    vi: "Mật khẩu",
    en: "Password",
    jp: "パスワード",
  },
  total_exchange: {
    vi: "TỔNG GIAO DỊCH ĐÃ THỰC HIỆN",
    en: "TOTAL TRANSACTIONS",
    jp: "行われたトランザクションの合計",
  },
  total_payed_coin: {
    vi: "TỔNG XU ĐÃ THANH TOÁN",
    en: "TOTAL PAID",
    jp: "総費用支払い",
  },
  total_coin: {
    vi: "TỔNG XU ĐÃ NẠP THÊM",
    en: "TOTAL RECHARGE",
    jp: "収集された合計",
  },
  history_exchange: {
    vi: "LỊCH SỬ GIAO DỊCH",
    en: "TRANSACTION HISTORY",
    jp: "取引履歴",
  },
  exchange_content_column: {
    vi: "NỘI DUNG GIAO DỊCH",
    en: "TRANSACTION CONTENT",
    jp: "取引内容",
  },
  cost_column: {
    vi: "MỨC PHÍ",
    en: "CHARGES",
    jp: "料金",
  },
  request_estimate: {
    vi: "Yêu cầu báo giá",
    en: "Request a estimate",
    jp: "見積もりを依頼する",
  },
  estimated_total: {
    vi: "Đã chào giá",
    en: "Bidding",
    jp: "入札",
  },
  service_category: {
    vi: "Ngành nghề hoạt động",
    en: "Business activities",
    jp: "事業活動",
  },
  customers: {
    vi: "Khách hàng",
    en: "Customers",
    jp: "お客様",
  },
  suplier: {
    vi: "Nhà cung cấp",
    en: "Supplier",
    jp: "サプライヤー",
  },
  product_id: {
    vi: "Mã sản phẩm",
    en: "Product code",
    jp: "製品コード",
  },
  price: {
    vi: "Đơn giá",
    en: "Unit price",
    jp: "価格単位",
  },
  unit: {
    vi: "ĐVT",
    en: "Unit",
    jp: "単位",
  },
  state: {
    vi: "Trạng thái",
    en: "Status",
    jp: "状態",
  },
  quantity: {
    vi: "Số lượng",
    en: "Amount",
    jp: "量",
  },
  whole_sale_title: {
    vi: "Bạn muốn mua số lượng lớn sản phẩm này",
    en: "You want to buy large quantities of this product",
    jp: "この商品を大量に購入したい",
  },
  contact_suplier: {
    vi: "Thông tin nhà cung cấp",
    en: "Contact supplier",
    jp: "サプライヤ",
  },
  product_information: {
    vi: "Thông tin sản phẩm",
    en: "Product information",
    jp: "商品情報",
  },
  product_class: {
    vi: "SẢN PHẨM LIÊN QUAN KHÁC",
    en: "RELATED PRODUCTS",
    jp: "関連製品",
  },
  name: {
    vi: "Tên",
    en: "Name",
    jp: "名前",
  },
  product_name: {
    vi: "Tên sản phẩm",
    en: "Product name",
    jp: "商品名",
  },
  amount: {
    vi: "Tổng số",
    en: "Total",
    jp: " 完全な",
  },
  cost: {
    vi: "Đơn giá",
    en: "Unit price",
    jp: "単価",
  },
  delete: {
    vi: "Xoá",
    en: "Delete",
    jp: "削る",
  },
  view_product: {
    vi: "Xem sản phẩm",
    en: "View product",
    jp: "製品を見る",
  },
  total_money_pay: {
    vi: "Tổng tiền thanh toán",
    en: "Total payment",
    jp: "お支払い総額",
  },
  keep_buy: {
    vi: "Mua tiếp",
    en: "Continue shopping",
    jp: "ショッピングを続ける",
  },
  payment: {
    vi: "Thanh toán",
    en: "Payment",
    jp: "支払い",
  },
  my_order: {
    vi: "Đơn hàng của tôi",
    en: "My order",
    jp: "私の注文",
  },
  order_infor: {
    vi: "Thông tin đơn hàng",
    en: "Information order",
    jp: "情報注文",
  },
  view_service_detail: {
    vi: "Xem chi tiết dịch vụ",
    en: "See service details",
    jp: "サービス詳細を見る",
  },
  wallet: {
    vi: "Thông tin ví",
    en: "Wallet",
    jp: "サービス詳細を見る",
  },
  my_request_estimate: {
    en: "My Quotation",
    vi: "Tin đã yêu cầu báo giá của tôi",
    jp: "私の引用",
  },
  estimate_waiting: {
    en: "Quotation Requesting",
    vi: "Các tin đang chờ báo giá",
    jp: "見積依頼",
  },
  product_waiting_price: {
    en: "My Order",
    vi: "Các sản phẩm đang chờ báo giá",
    jp: "私の注文",
  },
  account_info: {
    en: "Your Account",
    vi: "Thông tin tài khoản",
    jp: "貴方のアカウント",
  },
  wallet_info: {
    en: "Your Wallet",
    vi: "Thông tin ví",
    jp: "あなたの財布",
  },
  logout: {
    en: "Log out",
    vi: "Đăng xuất",
    jp: "ログアウト",
  },
  no_filter_product: {
    en: "Did not find the product you are interested in!",
    vi: "Không tìm thấy sản phẩm bạn quan tâm!",
    jp: "興味のある製品が見つかりませんでした！",
  },
  no_filter_service: {
    en: "Did not find the service you are interested in!",
    vi: "Không tìm thấy dịch vụ bạn quan tâm!",
    jp: "興味のあるサービスが見つかりませんでした！",
  },
  set_order: {
    en: "ORDERED",
    vi: "ĐẶT MUA",
    jp: "命令",
  },
  service_provide: {
    en: "Service Provider",
    vi: "Cung cấp dịch vụ",
    jp: "サービスプロバイダー",
  },
  sale_product: {
    en: "Sell products",
    vi: "Bán sản phẩm",
    jp: "製品を売る",
  },
  each_sale: {
    en: "Retail",
    vi: "Bán lẻ",
    jp: "小売り",
  },
  whole_sale: {
    en: "Wholesale",
    vi: "Bán sỉ",
    jp: "卸し売り",
  },
  both_sale: {
    en: "Both sale",
    vi: "Bán sỉ - lẻ",
    jp: "小売り - 卸し売り",
  },
  account_type_for: {
    en: "ACCOUNT FOR PARTNERS",
    vi: "TÀI KHOẢN DÀNH CHO ĐỐI TÁC",
    jp: "パートナーのアカウント",
  },
  new_register: {
    en: "REGISTER NEW ACCOUNT",
    vi: "ĐĂNG KÝ MỚI TÀI KHOẢN",
    jp: "新しいアカウントを登録する",
  },
  account_type_text: {
    en: "Your account type",
    vi: "Loại tài khoản của bạn",
    jp: "アカウントの種類",
  },
  what_provider: {
    en: "What do you offer to customers?",
    vi: "Bạn cung cấp gì cho khách hàng?",
    jp: "顧客に何を提供しますか?",
  },
  sale_method: {
    en: "Your form of sale",
    vi: "Hình thức bán hàng của bạn",
    jp: "あなたの販売形態",
  },
  info_contact: {
    en: "YOUR CONTACT INFORMATION",
    vi: "THÔNG TIN LIÊN HỆ CỦA BẠN",
    jp: "あなたの連絡先情報",
  },
  label_comfirm_password: {
    en: "Comfirm the password",
    vi: "Nhập lại mật khẩu",
    jp: "パスワードを確認する",
  },
  company_info_title: {
    en: "YOUR COMPANY/YOUR PERSONAL INFORMATION",
    vi: "THÔNG TIN CÔNG TY/THÔNG TIN CÁ NHÂN CỦA BẠN",
    jp: "あなたの会社情報",
  },
  bussines_category_title: {
    en: "Operating industry of your company",
    vi: "Ngành nghề hoạt động của công ty bạn",
    jp: "あなたの会社の運営業界",
  },
  freelancer_category_title: {
    en: "YOUR BUSINESS LINES",
    vi: "NGÀNH NGHỀ HOẠT ĐỘNG CỦA BẠN",
    jp: "あなたのビジネスライン",
  },
  upload_cetificate_title: {
    en: "Upload relevant qualifications, certificates, certificates to show your ability to customers:",
    vi: "Upload những bằng cấp, chứng chi, chứng nhận liên quan để thể hiện năng lực của bạn với khách hàng:",
    jp: "関連する資格、証明書、証明書をアップロードして顧客にあなたの能力を示します：",
  },
  aggrement_title: {
    en: "I agree with the terms and policies of B2BGOSELL",
    vi: "Tôi đồng ý với các điều khoản và chính sách của B2BGOSELL",
    jp: "B2BGOSELLの利用規約に同意します",
  },
  rule_title: {
    en: "Terms of use",
    vi: "Điều khoản sử dụng",
    jp: "利用規約",
  },
  private_policy: {
    en: "Privacy policy",
    vi: "Chính sách riêng tư",
    jp: "個人情報保護方針",
  },
  welcome_title: {
    en: "B2BGOSELL, WELCOME!",
    vi: "B2BGOSELL, CHÀO MỪNG BẠN!",
    jp: "B2BGOSELL ゴセル、ようこそ！",
  },
  thank_letter: {
    en: "Thank you for trusting and choosing to accompany us.",
    vi: "Cảm ơn bạn đã tin tưởng và lựa chọn đồng hành cùng chúng tôi.",
    jp: "私たちを信頼し、同行することを選択していただきありがとうございます。",
  },
  account_type_select_title: {
    en: "Who do you want to create an account for?",
    vi: "Bạn muốn tạo tài khoản dành cho ai?",
    jp: "誰のアカウントを作成しますか？",
  },
  partner: {
    en: "PARTNER",
    vi: "ĐỐI TÁC",
    jp: "仲間",
  },
  customer: {
    en: "CUSTOMER",
    vi: "KHÁCH HÀNG",
    jp: "得意先, 客",
  },
  welcome_partner: {
    en: "Wellcome! Please fill in the information below to create an account",
    vi: "Chào mừng bạn! Hãy điền các thông tin bên dưới để tạo tài khoản",
    jp: "元気！ accoを作成するには、以下の情報を入力してください",
  },
  freelancer: {
    en: "Freelancer",
    vi: "Freelancer",
    jp: "Freelancer",
  },
  company_title: {
    en: "Company",
    vi: "Công Ty",
    jp: "会社",
  },
  company_product_title: {
    en: "Sale of products (For companies only)",
    vi: "Bán sản phẩm (Chỉ dành cho công ty)",
    jp: "製品の販売（企業のみ）",
  },
  select_sale_type_title: {
    en: "Please select your sales form",
    vi: "Vui lòng chọn hình thức bán hàng của bạn",
    jp: "販売フォームを選択してください",
  },
  account_for_customer_title: {
    en: "ACCOUNT FOR CUSTOMERS",
    vi: "TÀI KHOẢN DÀNH CHO KHÁCH HÀNG",
    jp: "顧客のアカウント",
  },
  go_back: {
    en: "Come back",
    vi: "Trở lại",
    jp: "戻って来る",
  },
  account_already_have: {
    en: "Do you already have an account?",
    vi: "Bạn đã có tài khoản?",
    jp: "すでにアカウントをお持ちですか？",
  },
  login_now: {
    en: "Log in now",
    vi: "Đăng nhập ngay",
    jp: "ここでログイン",
  },
  login_welcome_title: {
    en: "Wellcome! Please log in to your account.",
    vi: "Chào mừng bạn! Hãy đăng nhập vào tài khoản của bạn.",
    jp: "元気！アカウントにログインしてください。",
  },
  remember_title: {
    en: "Remember me",
    vi: "Nhớ tôi",
    jp: "私を覚えてますか",
  },
  forget_password_title: {
    en: "Forgot password?",
    vi: "Quên mật khẩu?",
    jp: "パスワードをお忘れですか？",
  },
  become_partner_1: {
    en: "BECOME",
    vi: "TRỞ THÀNH",
    jp: "ベコメ",
  },
  become_partner_2: {
    en: "WITH US",
    vi: "VỚI CHÚNG TÔI",
    jp: "私達と一緒に",
  },
  customer_id: {
    en: 'Customer"s code',
    vi: "Mã khách hàng",
    jp: "顧客のコード",
  },
  date_post: {
    en: "Date Submitted",
    vi: "Ngày đăng",
    jp: "提出日",
  },
  estimate_request_title: {
    en: "REQUEST YOUR PRICE",
    vi: "YÊU CẦU BÁO GIÁ CỦA BẠN",
    jp: "価格をリクエスト",
  },
  quotation_waiting: {
    en: "NEWS WAITING FOR QUOTES FOR CUSTOMERS",
    vi: "TIN ĐANG CHỜ BÁO GIÁ CHO KHÁCH HÀNG",
    jp: "顧客の見積もりを待っているニュース",
  },
  btn_ignore: {
    en: "Refuse",
    vi: "Từ chối",
    jp: "れふせ",
  },
  request_was_processed: {
    en: "The request has been processed",
    vi: "Yêu cầu đã được xử lý",
    jp: "リクエストは処理されました",
  },
  btn_show_more: {
    en: "Show More",
    vi: "Xem nhiều hơn",
    jp: "もっと見せる",
  },
  order_code: {
    en: "CODE ORDERS",
    vi: "MÃ ĐƠN HÀNG",
    jp: "コード注文",
  },
  sale_method_column: {
    en: "FORM",
    vi: "HÌNH THỨC",
    jp: "形状",
  },
  date_create_order: {
    en: "DAY TRADING",
    vi: "NGÀY GIAO DỊCH",
    jp: "デイトレード",
  },
  total_amount: {
    en: "TOTAL VALUE",
    vi: "TỔNG GIÁ TRỊ",
    jp: "総価値",
  },
  status_column: {
    en: "STATUS",
    vi: "TÌNH TRẠNG",
    jp: "地位",
  },
  partner_status_column: {
    en: "PARTNER STATUS",
    vi: "TÌNH TRẠNG PHÍA ĐỐI TÁC",
    jp: "地位",
  },
  is_being_considered: {
    en: "Is considering",
    vi: "Đang xem xét",
    jp: "検討中",
  },
  accepted: {
    en: "Accepted",
    vi: "Đã chấp nhận",
    jp: "受け入れた",
  },
  is_denied: {
    en: "Denied",
    vi: "Đã từ chối",
    jp: "拒否された",
  },
  estimated_number: {
    en: "Already quoted",
    vi: "Đã báo giá",
    jp: "すでに引用",
  },
  hide_info_title: {
    en: "The information below is currently hidden. You will see this contact information if you agree with the quote that PARTNER has sent to you.",
    vi: "Hiện tại thông tin bên dưới đang bị ẩn. Bạn sẽ nhìn thấy các thông tin liên hệ này nếu bạn đồng ý với báo giá mà ĐỐI TÁC đã gửi đến bạn.",
    jp: "現在、以下の情報は非表示になっています。 PARTNERから送信された見積もりに同意すると、これらの連絡先情報が表示されます",
  },
  our_contact_info: {
    en: "OUR CONTACT INFORMATION",
    vi: "THÔNG TIN LIÊN HỆ CỦA CHÚNG TÔI",
    jp: "私たちの連絡先情報",
  },
  our_cefiticate: {
    en: "OUR CERTIFICATES, GRANTS, RELATED CERTIFICATES",
    vi: "CÁC CHỨNG CHỈ, BẰNG CẤP, CHỨNG NHẬN LIÊN QUAN CỦA CHÚNG TÔI",
    jp: "私たちの証明書、助成金、関連する証明書",
  },
  connect_checkbox: {
    en: "You agree that the B2BGOSELL party is only a communication bridge for the PROVIDER, and the SERVICE REQUEST has the opportunity to work together, and WE are not responsible for the law in the agreements. , the contract works between the two sides.",
    vi: "Bạn đồng ý rằng bên B2BGOSELL  chỉ là cầu nối thông tin cho bên CUNG CẤP DỊCH VỤ, và bên YÊU CẦU DỊCH VỤ có cơ hội làm việc với nhau, và CHÚNG TÔI không chịu trách nhiệm liên quan đến pháp luật trong các thỏa thuận, hợp đồng làm việc giữa 2 bên với nhau.",
    jp: "B2BGOSELLはプロバイダーの単なるコミュニケーションブリッジであり、サービスリクエストは共同で作業する機会があり、WEは両当事者間の合意、契約。",
  },
  number_comment: {
    en: "people rated",
    vi: "người đã đánh giá",
    jp: "評価された人",
  },
  category_active_title: {
    en: "BUSINESS LINES",
    vi: "NGÀNH NGHỀ HOẠT ĐỘNG",
    jp: "ビジネスライン",
  },
  your_comment: {
    en: "YOUR REVIEWS TO THIS PARTNER",
    vi: "ĐÁNH GIÁ CỦA BẠN CHO ĐỐI TÁC NÀY",
    jp: "このパートナーへのあなたのレビュー",
  },
  review: {
    en: "Evaluate",
    vi: "Đánh giá",
    jp: "評価する",
  },
  feedback: {
    en: "FEEDBACK COMMENTS",
    vi: "PHẢN HỒI Ý KIẾN",
    jp: "フィードバックコメント",
  },
  share_title: {
    en: "Share",
    vi: "Chia sẻ",
    jp: "分け前",
  },
  send_title: {
    en: "SEND",
    vi: "GỬI",
    jp: "送る",
  },
  before_title: {
    en: "before",
    vi: "trước",
    jp: "べふぉれ",
  },
  day_title: {
    en: "day",
    vi: "ngày",
    jp: "昼間",
  },
  tell_us_your_service: {
    en: "Tell us about your service",
    vi: "Hãy nói với chúng tôi về dịch vụ của bạn",
    jp: "あなたのサービスについて教えてください",
  },
  select_your_service: {
    en: "Choose your service category",
    vi: "Chọn danh mục dịch vụ của bạn",
    jp: "サービスカテゴリを選択してください",
  },
  select_job_title: {
    en: "Select a specific job",
    vi: "Chọn ngành nghề cụ thể",
    jp: "特定の仕事を選択",
  },
  address_job: {
    en: "Work location",
    vi: "Địa điểm làm việc",
    jp: "勤務地",
  },
  service_name_title: {
    en: "Give a name to your service",
    vi: "Đặt một tên cho dịch vụ của bạn",
    jp: "サービスに名前を付けます",
  },
  summary_title: {
    en: "Key summary information about your service",
    vi: "Thông tin tóm tắt chính về dịch vụ của bạn",
    jp: "サービスに関する重要な要約情報",
  },
  suggest_title: {
    en: "Suggestions:",
    vi: "Gợi ý:",
    jp: "提案：",
  },
  summary_suggest: {
    en: "These summary information help customers decide quickly you to perform the service. (up to 200 words)",
    vi: "Những thông tin tóm tắt này giúp khách hàng quyết định lựa chọn nhanh chóng bạn để thực hiện dịch vụ. (tối đa 200 chữ)",
    jp: "これらの概要情報は、顧客がサービスを実行するかどうかをすばやく決定するのに役立ちます。 （最大200ワード）",
  },
  detail_title: {
    en: "Please describe your service in detail",
    vi: "Hãy mô tả chi tiết về dịch vụ của bạn",
    jp: "サービスについて詳しく説明してください",
  },
  detail_suggest: {
    en: `- Introduce about your company / yourself
    - Projects have been implemented in the past time
    - Outstanding experiences and skills you have.
    - Some special skills you want to show to customers.`,
    vi: `- Giới thiệu về công ty/ bản thân của bạn
    - Các dự án đã thực hiện trong thời gian qua
    - Các kinh nghiệm và kỹ năng nổi bật mà bạn có.
    - Một số kỹ năng đặc biệt mà bạn muốn thể hiện với khách hàng.`,
    jp: `- あなたの会社/あなた自身について紹介します
    - 過去にプロジェクトが実施された
    - あなたが持っている卓越した経験とスキル。
    - 顧客に見せたいいくつかの特別なスキル。`,
  },
  company_or_person_title: {
    en: "Your company / individual name",
    vi: "Tên công ty/ Cá nhân của bạn",
    jp: "あなたの会社/個人名",
  },
  post_new_service_rule_title: {
    en: "When posting new information, you agree to agree to the terms of use of B2BGOSELL and do not disclose any personal contact information in the detailed description of the project.",
    vi: "Khi đăng tin mới, bạn xác nhận đồng ý các điều khoản sử dụng của B2BGOSELL và  không để lộ bất kỳ thông tin liên lạc cá nhân nào trong phần mô tả chi tiết về dự án.",
    jp: "ニュースアイテムを投稿するときは、B2BGOSELLの利用規約に同意し、プロジェクトの説明で個人の連絡先の詳細を開示しないでください。",
  },
  post_service_now: {
    en: "POST NOW",
    vi: "ĐĂNG TIN NGAY",
    jp: "今すぐ投稿",
  },
  customer_normal: {
    en: "customer",
    vi: "khách hàng",
    jp: "得意先, 客",
  },
  request_estimate_suggest: {
    en: `- Request for execution time
    - Projects that have been done to customers in the recent time
    - High professional, technical and confidentiality requirements for your project information.`,
    vi: `- Yêu cầu về thời gian thực hiện
    - Những dự án đã thực hiện cho các khách hàng trong thời gian vừa qua
    - Các yêu cầu về chất lượng chuyên môn, kỹ thuật và tính bảo mật cao cho các thông tin dự án của bạn.`,
    jp: `-実行時間のリクエスト
    -最近顧客に行われたプロジェクト
    -プロジェクト情報に対する専門的、技術的、機密性の高い要件。`,
  },
  order_title: {
    en: "ORDER",
    vi: "ĐƠN HÀNG",
    jp: "命令",
  },
  send_order: {
    en: "SEND ORDER",
    vi: "GỬI ĐƠN HÀNG",
    jp: "注文を送る",
  },
  cash_pay: {
    en: "Cash payment on delivery",
    vi: "Thanh toán tiền mặt khi nhận hàng",
    jp: "代金引換",
  },
  atm_pay: {
    en: "Payment by domestic ATM card / Internet banking",
    vi: "Thanh toán bằng thẻ ATM nội địa / Internet banking",
    jp: "国内ATMカード/インターネットバンキングでのお支払い",
  },
  visa_pay: {
    en: "Pay with international cards Visa, Master, JCB",
    vi: "Thanh toán bằng thẻ quốc tế Visa, Master, JCB",
    jp: "国際カードビザ、マスター、jcbで支払う",
  },
  number_quantity: {
    en: "Quantity purchased",
    vi: "Số lượng mua",
    jp: "購入数量",
  },
  total_money: {
    en: "Amount",
    vi: "Thành tiền",
    jp: "総額",
  },
  provide_by: {
    en: "Provided by:",
    vi: "Cung cấp bởi:",
    jp: "によって提供された：",
  },
  host_intro_title: {
    en: "ABOUT B2BGOSELL",
    vi: "GIỚI THIỆU B2BGOSELL",
    jp: "B2BGOSELLについて",
  },
  intro_para: {
    en: `B2BGosell provides an information channel to connect partners providing products / services to customers with essential needs.

    B2BGosell currently offers hundreds of thousands of products and services from more than 30 categories serving millions of customers nationwide.
 With the motto "Bring benefits to both Partners and Customers", we are constantly working every day to bring the highest benefits and create more outstanding value for users.`,
    vi: `B2BGosell mang đến một kênh thông tin kết nối  giữa đối tác cung cấp các SẢN PHÂM / DỊCH VỤ với  khách hàng đang có nhu cầu thiết  yếu.
    
    B2BGosell hiện đang cung cấp hàng trăm ngàn sản phẩm và dịch vụ từ hơn 30 ngành hàng phục vụ hàng triệu khách hàng trên toàn quốc.
    Với phương châm hoạt động “Mang lại lợi ích cho cả Đối tác và Khách hàng”, chúng tôi luôn không ngừng nỗ lực hàng ngày để mang đến những lợi ích cao nhất và tạo thêm nhiều giá trị vượt trội cho người dùng.`,
    jp: `B2BGosellは、重要なニーズを持つ顧客に製品/サービスを提供するパートナーをつなぐ情報チャネルを提供します。

    B2BGosellは現在、30を超えるカテゴリから数十万の製品とサービスを提供し、全国の数百万の顧客にサービスを提供しています。
    パートナーとお客様の両方にメリットをもたらす」をモットーに、私たちは常に最高のメリットをもたらし、ユーザーにより優れた価値を生み出すように日々努力しています。`,
  },
  host_contact_title: {
    en: "CONTACT INFORMATION B2BGOSELL",
    vi: "THÔNG TIN LIÊN HỆ B2BGOSELL",
    jp: "連絡先情報B2Bゴセル",
  },
  host_name: {
    en: "GREATLINK MAIHOUSE INVESTMENT CO. LTD",
    vi: "CÔNG TY TNHH ĐẦU TƯ GREATLINK MAIHOUSE",
    jp: "GREATLINK MAIHOUSE投資会社。株式会社",
  },
  host_address: {
    en: "11A  Hồng Hà, Phường 2, Quận Tân Bình, TP.HCM, Việt Nam.",
    vi: "11A  Hồng Hà, Phường 2, Quận Tân Bình, TP.HCM, Việt Nam.",
  },
  host_map_location_title: {
    en: "OFFICE B2BGOSELL",
    vi: "VĂN PHÒNG B2BGOSELL",
    jp: "オフィスB2Bゴセル",
  },
  for_user: {
    en: "FOR PARTNERS - CUSTOMERS",
    vi: "DÀNH CHO ĐỐI TÁC - KHÁCH HÀNG",
    jp: "パートナー向け-顧客",
  },
  who_are_you: {
    en: "",
    vi: "Bạn là ai?",
    jp: "",
  },
  require: {
    en: "Who are you?",
    vi: "Bắt buộc",
    jp: "あなたは誰？",
  },
  tax_number: {
    en: "Tax code",
    vi: "Mã số thuế",
    jp: "税法",
  },
  messenge: {
    en: "Your message",
    vi: "Tin nhắn của bạn",
    jp: "あなたのメッセージ",
  },
  send_request_now: {
    en: "SEND NOW",
    vi: "GỬI NGAY",
    jp: "今すぐ送る",
  },
  recharge_now: {
    en: "RIGHT NOW",
    vi: "NẠP NGAY",
    jp: "",
  },
  select_package: {
    en: "",
    vi: "Chọn gói XU phù hợp với bạn",
    jp: "遅れずに",
  },
  xu_intro_title: {
    en: "These XU will help freelancers and clients connect with each other quickly and work more effectively on B2BGOSELL.",
    vi: "Các XU này sẽ giúp freelancer và khách hàng kết nối với nhau nhanh chóng và làm việc hiệu quả hơn trên B2BGOSELL.",
    jp: "これらのXUは、フリーランサーとクライアントがすばやく接続し、B2BGOSELLでより効果的に機能するのに役立ちます",
  },
  current_xu_title: {
    en: "XU number in your Wallet",
    vi: "Số XU trong Ví của bạn",
    jp: "ウォレットの現在のXU番号",
  },
  quick_intro: {
    en: "Quick start Guide",
    vi: "Hướng dẫn nhanh",
    jp: "クイックスタートガイド",
  },
  xu_what_use_for: {
    en: "Xu used to do",
    vi: "Xu dùng để làm gì",
    jp: "XU は以前使用していた",
  },
  tutorial: {
    en: "Instructions for using XU",
    vi: "Hướng dẫn sử dụng XU",
    jp: "XUの使用方法",
  },
  upgrade_account_tutorial: {
    en: "Terms & Conditions",
    vi: "Điều khoản sử dụng",
    jp: "アカウントをアップグレードする手順",
  },
  quick_support: {
    en: "Quick support",
    vi: "Hỗ trợ nhanh",
    jp: "迅速なサポート",
  },
  tell_about_product: {
    en: "Tell us about your product",
    vi: "Hãy nói với chúng tôi về sản phẩm của bạn",
    jp: "あなたの製品について教えてください",
  },
  select_product_category: {
    en: "Select a product category",
    vi: "Chọn danh mục sản phẩm",
    jp: "製品カテゴリーを選択してください",
  },
  select_invidual_product: {
    en: "Choose specific products",
    vi: "Chọn sản phẩm cụ thể",
    jp: "特定の製品を選択する",
  },
  unit_count: {
    en: "Unit",
    vi: "Đơn vị tính",
    jp: "構成単位",
  },
  market_price: {
    en: "Market price",
    vi: "Giá thị trường",
    jp: "市場価格",
  },
  money_unit: {
    en: "Currency",
    vi: "Loại tiền tệ",
    jp: "貨幣",
  },
  product_summary_info: {
    en: "Product summary information",
    vi: "Thông tin tóm tắt sản phẩm",
    jp: "製品概要情報",
  },
  select_avatar: {
    en: "Choose an avatar",
    vi: "Chọn ảnh đại diện",
    jp: "アバターを選ぶ",
  },
  upload: {
    en: "Upload. Use picture with 1:1 ratio for good display",
    vi: "Tải lên. Sử dụng ảnh tỉ lệ 1:1 cho hiển thị tối ưu nhất",
    jp: "コンピュータ",
  },
  product_detail_info: {
    en: "The detail information of product",
    vi: "Thông tin chi tiết sản phẩm",
    jp: "製品の詳細情報",
  },
  product_upload_suggest: {
    en: "Upload certificates related to the product. For example: safety paper, inspection, origin of goods, ...",
    vi: "Upload các giấy chứng nhận liên quan đến sản phẩm. VD: giấy ATVSTP, kiểm định, xuất xứ hàng hóa,...",
    jp: "製品に関連する証明書をアップロードします。例：食品安全衛生証明書、検査、商品の原産地、... ",
  },
  post_product_aggrement_1: {
    en: "When posting new products, you confirm the agreement",
    vi: "Khi đăng sản phẩm mới, bạn xác nhận đồng ý các",
    jp: "新製品を投稿するとき、あなたは契約を確認します",
  },
  post_product_aggrement_2: {
    en: "terms of use",
    vi: "điều khoản sử dụng",
    jp: "利用規約",
  },
  post_product_aggrement_3: {
    en: "of",
    vi: "của",
    jp: "の",
  },
  post_product_aggrement_4: {
    en: "and do not disclose any personal contact information in the product description or the attached image.",
    vi: "và không để lộ bất kỳ thông tin liên lạc cá nhân nào trong phần mô tả chi tiết về sản phẩm hoặc hình ảnh đính kèm.",
    jp: "製品に個人の連絡先情報を開示しない",
  },
  post_product_now: {
    en: "POST PRODUCTS NOW",
    vi: "ĐĂNG SẢN PHẨM NGAY",
    jp: "今すぐ製品を投稿する",
  },
  banner_title: {
    en: "Specialist RENTAL, FREELANCER, for any job",
    vi: "Dịch vụ THUÊ CHUYÊN GIA, FREELANCER cho bất kì công việc nào",
    jp: "スペシャリストレンタル、フリーランサー、どんな仕事にも",
  },
  view_service: {
    en: "VIEW SERVICE",
    vi: "XEM DỊCH VỤ",
    jp: "サービスの表示",
  },
  your_request_for_supplier_title: {
    en: "YOUR REQUIREMENTS FOR SUPPLIERS",
    vi: "YÊU CẦU CỦA BẠN CHO NHÀ CUNG CẤP",
    jp: "サプライヤーの要件",
  },
  request_suggestion_title: {
    en: `You can take notes to send information to the supplier more clearly about:
      - In stock time
      - Delivery time
      - Product expiry date
      - Payments
      ...
    `,
    vi: `Bạn có thể ghi chú để gửi thông tin đến nhà cung cấp rõ hơn về:
    - Thời gian có hàng
    - Thời gian giao hàng
    - Hạn sử dụng sản phẩm
    - Hình thức thanh toán
    ...`,
    jp: `メモを取り、次の情報をより明確にサプライヤーに送信できます。
      -在庫あり
      - 納期
      -製品の有効期限
      -支払い
      ....`,
  },
  send_order_now: {
    en: "SEND ORDER NOW",
    vi: "GỬI THÔNG TIN",
    jp: "今すぐ注文する",
  },
  my_current_order: {
    en: "MY ORDER",
    vi: "ĐƠN HÀNG CỦA TÔI",
    jp: "私の注文",
  },
  your_response_title: {
    en: "YOUR FEEDBACK",
    vi: "PHẢN HỒI CỦA BẠN",
    jp: "あなたのフィードバック",
  },
  notify_fee_phara: {
    en: "Currently, customers need to buy products that are hidden. To connect immediately to customers, please pay service fee from B2BGOSELL. Immediately after payment of the service fee, full contact information of the customer will be displayed to you.",
    vi: "Hiện tại thông tin khách hàng cần mua sản phẩm đang bị ẩn Để tiến hành kết nối ngay với khách hàng, xin vui lòng thanh toán phí dịch vụ từ B2BGOSELL. Ngay sau khi thanh toán phí dịch vụ, thông tin liên hệ đầy đủ của khách hàng sẽ được hiển thị đến bạn.",
    jp: "現在、非表示の商品をご購入いただく必要がありますが、すぐにご利用いただくには、B2BGOSELLからサービス料をお支払いください。 サービス料の支払い直後に、お客様の完全な連絡先情報が表示されます。",
  },
  fee_title: {
    en: "Service fee from B2BGOSELL",
    vi: "Phí dịch vụ từ B2BGOSELL",
    jp: "B2BGOSELLからのサービス料",
  },
  pay_fee_now_title: {
    en: "PAYMENT OF FEES NOW",
    vi: "THANH TOÁN PHÍ NGAY",
    jp: "手数料の支払い",
  },
  infor_estimated_title: {
    en: "",
    vi: "THÔNG TIN CHÀO GIÁ CỦA BẠN CHO DỰ ÁN NÀY",
    jp: "",
  },
  budget: {
    en: "Your suggested price for this project",
    vi: "Mức giá đề nghị của bạn cho dự án này",
    jp: "このプロジェクトの推奨価格",
  },
  bonus_info_mess: {
    en: "More information about the project you want to send to customers:",
    vi: "Thông tin thêm về dự án mà bạn muốn gửi cho cho khách hàng:",
    jp: "顧客に送信するプロジェクトの詳細：",
  },
  coin_not_enoungh: {
    en: "The number of XU in your wallet is not enough to make this quote. Please add more to activate this quote.",
    vi: "Số XU trong ví của bạn không đủ để thực hiện báo giá này. Vui lòng nạp thêm để kích hoạt báo giá này.",
    jp: "ウォレットのXU番号では、この見積もりを作成するのに十分ではありません。この見積もりを有効にするには、さらに追加してください。",
  },
  charge_now: {
    en: "RIGHT UP NOW",
    vi: "NẠP XU NGAY",
    jp: "今すぐ",
  },
  send_estimate_now: {
    en: "SEND QUOTATION NOW",
    vi: "GỬI BÁO GIÁ NGAY",
    jp: "今すぐ見積もりを送る",
  },
  not_update: {
    en: "Not update",
    vi: "Chưa cập nhật",
    jp: "更新されていません",
  },
  login_expried: {
    en: "Your login section is expried, please login again",
    vi: "Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại",
    jp: "ログインセッションの有効期限が切れています。もう一度ログインしてください",
  },
  notification_title: {
    en: "Notification",
    vi: "Thông báo",
    jp: "通知",
  },
  logout_success: {
    en: "Logout successfully!",
    vi: "Đăng xuất thành công!",
    jp: "Logout successfully!",
  },
  update_estimate_success: {
    en: "Update estimate successfully!",
    vi: "Sửa báo giá thành công!",
    jp: "Update estimate successfully!",
  },
  post_estimate_success: {
    en: "Post estimate to customer success!",
    vi: "Gửi báo giá thành công",
    jp: "Post estimate to customer success!",
  },
  post_order_success: {
    en: "Post order successfully!",
    vi: "Gửi đơn hàng thành công!",
    jp: "Post order successfully!",
  },
  purchase_success: {
    en: "Purchase success!",
    vi: "Thanh toán thành công!",
    jp: "Purchase success!",
  },
  post_response_order_success: {
    en: "Post response from order successfully!",
    vi: "Gửi phản hồi về đơn hàng thành công!",
    jp: "Post response from order successfully!",
  },
  ngan_luong_pay: {
    en: "Ngân Lượng Payment",
    vi: "Thanh toán Ngân Lượng",
    jp: "お支払い Ngân Lượng",
  },
  bank_pay: {
    en: "Bank account transfer",
    vi: "Thanh toán chuyển khoản qua ngân hàng",
    jp: "銀行振込",
  },
  ngan_luong_button: {
    en: "Pay via Ngân Lượng",
    vi: "Chuyển đến Ngân Lượng",
    jp: "に転送 Ngân Lượng",
  },
  post_order_is_procceses: {
    en: "Your order will be proccesed",
    vi: "Đơn hàng của bạn sẽ được xử lý",
    jp: "ご注文は処理されます",
  },
  payment_success: {
    en: "Your payment is success",
    vi: "Thanh toán của bạn đã thành công",
    jp: "お支払いが完了しました",
  },
  payment_failure: {
    en: "Your payment is failure. Contact with admin, please",
    vi: "Thanh toán của bạn đã thất bại. Vui lòng liên hệ Admin để giải quyết",
    jp: "お支払いに失敗しました。 解決のために管理者に連絡してください",
  },
  back_to_homepage: {
    en: "Back to homepage",
    vi: "Trở về trang chủ",
    jp: "Back to homepage",
  },
  select_category: {
    en: "Select category",
    vi: "Chọn danh mục",
    jp: "カテゴリを選択",
  },
  view_order: {
    en: "View order",
    vi: "Xem đơn hàng",
    jp: "ご注文を表示",
  },
  pay_via_bank: {
    en: "Transfer",
    vi: "Đã chuyển khoản ngân hàng",
    jp: "転送",
  },
  saving: {
    en: "saving",
    vi: "tiết kiệm",
    jp: "転送",
  },
  transfer_noti: {
    en: "Information sent to admin",
    vi: "Đã gửi thông tin cho admin",
    jp: "管理者に送信される情報",
  },
  reset_filter: {
    en: "All item",
    vi: "Xem tất cả",
    jp: "フィルタをクリアします",
  },
  update_cefiticate: {
    vi: "Thêm chứng chỉ, giấy phép",
    en: "Update cefiticate",
    jp: "",
  },
  pay_to_view_order: {
    en: "Pay service fee to see customer information:",
    vi: "Thanh toán phí dịch vụ để xem thông tin khách hàng",
    jp: "",
  },
  pay_button: {
    en: "Pay",
    vi: "Thanh toán",
    jp: "",
  },
  finish_process_btn: {
    en: "FINISH SERVICE REQUEST",
    vi: "KẾT THÚC YÊU CẦU BÁO GIÁ",
  },
  host_bank_account: {
    en: "GREATLINKMAIHOUSE INVESTMENT COMPANY LIMITED",
    vi: "CÔNG TY TNHH ĐẦU TƯ GREATLINKMAIHOUSE",
  },
  host_bank_number: {
    en: "Account Number: 183412183",
    vi: "STK: 183412183",
  },
  host_bank_branch: {
    en: "Branch: Go Vap",
    vi: "Chi nhánh: Gò Vấp",
  },
  host_bank_name: {
    en: "Bank: VPBank",
    vi: "Ngân hàng: VPBank",
  },
  host_transfer: {
    en: "Transfer message: [user code] - [Charge XU GOSELL]",
    vi: "Nội dung chuyển khoản: [Mã người dùng] - [nạp XU GOSELL]",
  },
  host_transfer_example: {
    en: "Example: [BGC682873] - [Charge XU GOSELL]",
    vi: "Ví dụ nội dung chuyển khoản: [BGC682873] - [Nap XU GOSELL]",
  },
  service_send_estimate_fee: {
    en: "Fee from B2BGOSELL",
    vi: "Phí dịch vụ từ B2BGOSELL",
  },
  payment_code: {
    en: "Payment Code",
    vi: "Mã giao dịch",
  },
  created_at: {
    en: "Created At",
    vi: "Ngày tạo",
  },
  update_profile_success: {
    en: "Update profile successs",
    vi: "Cập nhật thông tin tài khoản thành công",
  },
  update_profile_failure: {
    en: "Opps! We have a error, contact admin to check",
    vi: "Opps! Đã có lỗi xảy ra, vui lòng liên hệ admin để được hỗ trợ",
  },
  btn_view_now: {
    en: "View now",
    vi: "Xem ngay",
  },
  location_select_title: {
    en: "Select country",
    vi: "Chọn quốc gia",
  },
  client_check_quotation_title: {
    en: "PARTNERS HAVE QUOTATION TO YOU",
    vi: "CÁC ĐỐI TÁC ĐÃ BÁO GIÁ CHO BẠN",
  },
  free_first_quotation: {
    en: "(You are free to open any first quotation)",
    vi: "(Bạn được mở miễn phí báo giá đầu tiên bất kỳ)",
  },
  quotation_suggest_price: {
    en: "Quotation price for this project",
    vi: "Mức giá đề nghị cho dự án này",
  },
  btn_contact_info: {
    en: "CONTACT INFORMATION",
    vi: "THÔNG TIN LIÊN LẠC",
  },
  btn_reject_quotation: {
    en: "YOU HAVE DISCLAIMED THIS PRICE",
    vi: "BẠN ĐÃ TỪ CHỐI BÁO GIÁ NÀY",
  },
  what_need_service: {
    en: "Let us know what SERVICE you are in need.",
    vi: "Hãy cho chúng tôi biết bạn đang cần DỊCH VỤ nào.",
  },
  request_title: {
    en: "The title needs a quote",
    vi: "Tiêu đề cần báo giá",
  },
  request_content: {
    en: "Please describe your project in detail",
    vi: "Hãy mô tả chi tiết về dự án của bạn",
  },
  skill_need: {
    en: "Skills required by Company / Freelancer to have",
    vi: "Kỹ năng yêu cầu Công ty/ Freelancer phải có",
  },
  confirm_request_quote: {
    en: "By posting a new message, you confirm your agreement to the B2BGOSELL terms of use and do not disclose any personal contact information in the detailed project description.",
    vi: "Khi đăng tin mới, bạn xác nhận đồng ý các điều khoản sử dụng của B2BGOSELL và không để lộ bất kỳ thông tin liên lạc cá nhân nào trong phần mô tả chi tiết về dự án.",
  },
  menu_item_term_of_use_title: {
    en: "Terms of use",
    vi: "Điều khoản sử dụng",
  },
  menu_item_trading_condition: {
    en: "General trading conditions",
    vi: "Điều kiện giao dịch chung",
  },
  menu_item_privacy: {
    en: "Information privacy policy",
    vi: "Chính sách bảo mật thông tin",
  },
  menu_item_shipment: {
    en: "Shipping method",
    vi: "Phương thức vận chuyển",
  },
  menu_item_paymnet: {
    en: "Payment Process",
    vi: "Quy trình thanh toán",
  },
  menu_item_refund: {
    en: "Return and Refund Policy",
    vi: "Chính sách đổi trả và hoàn tiền",
  },
  menu_item_complain: {
    en: "Complaint settlement policy",
    vi: "Chính sách giải quyết khiếu nại",
  },
  menu_item_how_we_work: {
    en: "Website operation regulations",
    vi: "Quy chế hoạt động website",
  },
  company_location: {
    en: "Address: 11A Hong Ha, Ward 2, Tan Binh District, Ho Chi Minh city, Viet Nam",
    vi: "Địa chỉ: 11A Hồng Hà, Phường 2, Quận Tân Bình, Thành phố Hồ Chí Minh, Việt Nam",
  },
  company_registe_document: {
    en: "Certificate of business registration",
    vi: "Giấy chứng nhận đăng ký doanh nghiệp",
  },
  business_code: {
    en: "Enterprise code",
    vi: "Mã số doanh nghiệp",
  },
  business_since: {
    en: "Since",
    vi: "Ngày cấp",
  },
  business_registe_at: {
    en: "Registe at",
    vi: "Nơi cấp",
  },
  ho_chi_minh_registration_office: {
    en: "Issued by the Department of Planning and Investment of Ho Chi Minh City on 06/05/2019",
    vi: "Do Sở Kế hoạch & Đầu tư TP Hồ Chí Minh cấp ngày 06/05/2019",
  },
  host_info: {
    en: "Owner information",
    vi: "Thông tin chủ sở hữu",
  },
  contact_footer_title: {
    en: "Hotline/Zalo/WhatsApp receive feedback",
    vi: "Hotline/Zalo/WhatsApp phản ánh thông tin: (+84) 936 168 503",
  },
  policy_remind_title: {
    en: "Your profile will be used to support your experience throughout this website. For details, see the consumer information protection policy at",
    vi: "Hồ sơ của bạn sẽ được sử dụng để hỗ trợ trải nghiệm của bạn trên toàn bộ trang web này. Xem chi tiết tại chính sách bảo vệ thông tin người tiêu dùng tại",
  },
  here_title: {
    en: "here",
    vi: "đây",
  },
};
