import React, { useState, useEffect } from 'react';
import {
  Carousel,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAdsAction,
} from '../../redux/actions/ads';

import './styles/ads-pr-slider-styles.less';
import { getTranslatedText } from '../../services/appServices';

const PRComponent = (props) => {

  const {
    type,
    actions,
    adsData,
    key
  } = props;

  const [prArray, setPrArray] = useState([]);


  useEffect(() => {
    actions.getAdsAction();
  }, []);

  useEffect(() => {
    let array = [];
		switch(type) {
			case 'product':
				adsData.forEach(element => {
					if (element.type === 'product-highlight') {
						array.push(element)
					}
				});
        break;
			case 'service':
				adsData.forEach(element => {
					if (element.type === 'service-highlight') {
						array.push(element)
					}
				});
        break;
		}
		setPrArray(array);
    console.log('PR', prArray);
  }, [adsData]);
  

  return (
    <React.Fragment>
      <Carousel
        className="col-12 row_news_3"
        slidesToShow={2}
        autoplay
        autoplaySpeed={2000}
        dots={false}
      >
        {prArray && prArray.map(item => (
          <React.Fragment>
            <div className="_item">
              <img src={item.banner} alt="" />
              <div className="_text">
                {item.title}
              </div>
              <a href={item.url} className="btn btn-main radius_50 arrow_right">{getTranslatedText('btn_view_now')}</a>
            </div>
          </React.Fragment>
        ))}
      </Carousel>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
	return {
		adsData: state.adsReducer.data,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			{
				getAdsAction,
			},
			dispatch
		),
	};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PRComponent);