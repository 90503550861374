import React from 'react';
import {
  Row,
  Col,
  Select,
} from 'antd';
import 'antd/dist/antd.css';
import './Service_EstimatedComfirm.scss';

import { getTranslatedText } from '../../services/appServices';

const Service_EstimatedComfirm = () => {

  return (
    <>
      <div style={{ marginBottom: '100px', paddingBottom: '80px'}} className='container bg_white black'>
        <div className="row mg-t-30">
          <Row gutter={16}>
            <Col span={16}>
              <h2 className='backdrop_title'>{getTranslatedText('your_estimated_info')}</h2>
              <div className='estimate_info_container'>
                <div className='info_item'>
                  <Row gutter={16}>
                    <Col span={20}>
                      <p className='service_name_label'>{getTranslatedText('service_name')}</p>
                      <h3 className='service_name'>Service name here</h3>
                    </Col>
                    <Col span={4}>
                      <a className='btn_view_detail'>{getTranslatedText('view_detail')}</a>
                    </Col>
                  </Row>
                </div>
                <div className='info_item'>
                  <Row gutter={16}>
                    <Col span={12}>
                      <p className='item_label'>{getTranslatedText('project_budget')}</p>
                    </Col>
                    <Col span={12}>
                      <div className='budget_container'>
                        <p className='budget_item'>25.000.000</p>
                        <select className='budget_item_select'></select>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className='info_item'>
                  <Row gutter={16}>
                    <Col span={20}>
                      <p className='item_label'>{getTranslatedText('service_free_from_b2b')}</p>
                    </Col>
                    <Col span={4}>
                      <div className='service_fee_container'> 
                        <p className='service_fee_box'>30</p>
                        <p>{getTranslatedText('coin')}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className='coin_infor_container'>
                <p className='coin_infor_header'>{getTranslatedText('your_amount_coin')}</p>
                <p className='coin_amount'>25</p>
                <p className='notify_title'>{getTranslatedText('your_coin_is_not_enoungh')}</p>
                <div className='coint_container_footer'>
                  <button className='btn_send_estimate'>{getTranslatedText('send_estimate_now')}</button>
                  <button className='btn_add_coin'>{getTranslatedText('deposit_coin_now')}</button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Service_EstimatedComfirm;